/**
 * Authentication sagas.
 * 
 * Responsible for the process of logging in, refreshing tokens and logging out.
 */

import { take, takeEvery, call, put, select } from 'redux-saga/effects'
import * as actions from './actions'
import { authenticate, logOut } from './functions'
import { SagaIterator } from 'redux-saga'
import { store } from 'modules/root'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import { readyAction } from 'modules/root/actions'
import { anythingRequiresUpload } from 'modules/database/selectors'
import platform from 'modules/platform'

function* handleLogin(action: actions.LoginAction): SagaIterator {
	console.log('AUTH: handleLogin')
	try {
		yield call(authenticate, action.payload.username, action.payload.password)
	} catch (error) {
		yield put(actions.loginError(error as firebase.FirebaseError))
	}
}

function* handleLogout(): SagaIterator {
	console.log('AUTH: handleLogout')

	const requiresUpload = (yield select(anythingRequiresUpload)) as boolean
	if (requiresUpload) {
		platform.alert('You have unsynced changes. Please go online to sync your changes before you sign out of your account.', 'Unsynced Changes')
		return
	}

	yield call(logOut)
}

export default function* authSaga(): SagaIterator {
	/* Wait for the state to be ready, as we read the state in the loggedIn function.
	The state isn't immediately ready, as we use react-persist to load persisted state,
	which happens asynchronously.
	*/
	yield take(readyAction)
	yield takeEvery(actions.login, handleLogin)
	yield takeEvery(actions.logout, handleLogout)

	console.log('AUTH: listening to auth state changed')
	firebase.auth().onAuthStateChanged(function(user: firebase.User | null) {
		if (user) {
			// User is signed in.
			store.dispatch(actions.loggedIn(user))
		} else {
			// User is signed out.
			store.dispatch(actions.loggedOut())
		}
	})
}
