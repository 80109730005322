import * as React from 'react'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CurtainForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import * as cf from 'modules/product/curtains/functions'
import { FormFieldVariant } from 'modules/forms/components/FormRow'
import { Controller, forComponentProps, wrapComponent, Input } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CurtainFormSpecifications extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('specifications')

	public render() {
		return this.renderWorkroomInstructions(this.controller)
	}

	private renderWorkroomInstructions = (controller: Controller<dtp.CurtainSpecifications | undefined>) => {
		const notFirstCurtain = this.props.path[0] !== 'curtain1'

		const values = controller.snapshot().value || {}
		const measures = this.rootController.snapshot('measures').value
		const trackWidthCalculation = measures && cf.calculateTrackWidth(measures)

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{this.props.curtainName} specifications</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Curtain}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						specialGroup={
							notFirstCurtain ? {
								items: [{
									name: 'Curtain 1',
									type: 'curtain1',
								}],
							} : undefined
						}
						productToItems={cf.curtainProductGroupItems.bind(undefined, this.props.productId)}
					/>
				)}

				<ff.Display title="Calculated track width" value={trackWidthCalculation && trackWidthCalculation.getResult()} units="mm" calculation={trackWidthCalculation} variant={FormFieldVariant.Short} />
				<ff.ComboBox title="Workroom supplier" prop="workroomSupplier" code={ComboBoxCode.WORKROOM_SUPPLIER} controller={controller} staticValues={['Prestige CMT']} />
				<ff.ComboBox title="Header type" prop="headerType" code={ComboBoxCode.HEADER_TYPE} controller={controller} />
				<ff.Dropdown 
					title="Draw" 
					value={values.draw}
					onChange={controller.snapshot('draw').onChange}
					options={[
						{ label: 'Even pair', value: dtp.CurtainDraw.evenPair },
						{ label: 'Lefthand draw', value: dtp.CurtainDraw.lefthandDraw },
						{ label: 'Righthand draw', value: dtp.CurtainDraw.righthandDraw },
						{ label: 'Uneven pair', value: dtp.CurtainDraw.unevenPair },
					]}
				/>
				<ff.Dropdown 
					title="Stacking options" 
					value={values.stacking}
					onChange={controller.snapshot('stacking').onChange}
					options={[
						{ label: 'Left', value: dtp.CurtainStackingOptions.left },
						{ label: 'Right', value: dtp.CurtainStackingOptions.right },
						{ label: 'Nil', value: dtp.CurtainStackingOptions.nil },
					]}
				/>

				{values.draw === dtp.CurtainDraw.unevenPair && (
					<>
						<ff.Numeric title="Width left" prop="widthLeft" units="mm" controller={controller} />
						<ff.Numeric title="Width right" prop="widthRight" units="mm" controller={controller} />
					</>
				)}

				<ff.Numeric title="Hem allowance" prop="hemAllowance" units="mm" placeholder={`${dtp.CurtainSpecificationsHemAllowanceDefault}`} controller={controller} />
				<ff.Numeric title="Hooking position" prop="hooking" units="mm" controller={controller} />

				<ff.Heading>Pricing</ff.Heading>
				{/* <p>TODO list of combo boxes</p> */}

				<ff.Radios
					title="Making price method"
					prop="makingPriceMethod"
					controller={controller}
					options={[
						{ label: 'Per drop', value: dtp.CurtainPricingMethod.unit },
						{ label: 'Per metre', value: dtp.CurtainPricingMethod.metre },
					]}
				/>

				{values.makingPriceMethod === dtp.CurtainPricingMethod.unit || !values.makingPriceMethod ? (
					<ff.Price title="Making price" units="drop" prop="makingPricePerDrop" project={this.props.project} controller={controller} />
				) : (
					<ff.Price title="Making price" units="metre" prop="makingPricePerMetre" project={this.props.project} controller={controller} />
				)}

				<ff.Heading>Extras</ff.Heading>
				<Input.Indexed 
					controller={controller}
					prop="extras" 
					renderEach={(eachController, cursor, actions) => {
						return (
							<div key={cursor.index} className="extra form-layout">
								<ff.ComboBox 
									title="Extra name" 
									code={ComboBoxCode.CURTAIN_EXTRAS} 
									controller={eachController} 
									prop="name"
									staticValues={[
										'Handshank Surcharge',
										'Pattern Match Surcharge',
										'Trim Surcharge',
										'Velvet Surcharge',
										'Voile Surcharge',
										'Weights',
										'XL Surcharge',
									]}
								/>
								<ff.Price title="Price" project={this.props.project} controller={eachController} prop="pricing" />
								<button onClick={() => actions.onRemove(cursor.index)} type="button" className="button-link -secondary -micro">Remove</button>
							</div>
						)
					}}
					renderAfter={(actions) => {
						return (
							<div className="row -addextra">
								<div className="button-group">
									<button onClick={() => actions.onPush({})} type="button" className="button-link -secondary -small -icon -new -action">Add Extra</button>
								</div>
							</div>
						)
					}}
				/>

				<ff.TextArea title="Workroom instructions" prop="notes" controller={controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'curtain1') {
			this.onCopyFromCurtain1()
			return
		}

		const product = item.value as dtp.CurtainDetail
		if (product && product.specifications) {
			this.controller.snapshot().onChange(product.specifications)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected curtain doesn’t have specifications.')
		}
	}

	private onCopyFromCurtain1 = () => {
		const root = this.props.rootFormValue
		if (root.curtain1 && root.curtain1.specifications) {
			this.controller.snapshot().onChange(root.curtain1.specifications)
			this.setState({ showCopy: false })
		} else {
			platform.alert('Curtain 1 does not have specifications available')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(CurtainFormSpecifications)
