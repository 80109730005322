import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/ProjectDetailsWrapper'
import ProjectDetails from '../containers/ProjectDetails'
import { NavigationController, NavigatorToolbar, RouteProps } from 'onsenui-react-redux-navigator'
import * as df from 'modules/database/functions'
import { BackButton, Page } from 'react-onsenui'
import * as t from '../types'
import StorageMessageBar from 'modules/storage/containers/StorageMessageBar'
import { OwnProps as NavigatorToolbarProps, RenderToolbarActions } from 'onsenui-react-redux-navigator/dist/containers/NavigatorToolbar'

export default class ProjectDetailsWrapper extends React.Component<OwnProps & Props & Actions & RouteProps<t.ProjectRouteProps>> {

	public render() {
		return (
			<Page className="screen-project" renderToolbar={this.renderToolbar}>
				{this.props.project && (
					<ProjectDetails projectId={this.props.project.projectId} />
				)}
				<StorageMessageBar />
			</Page>
		)
	}

	private renderToolbar = () => {
		const { project } = this.props
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const BackButtonWithChildren = BackButton as any

		return (
			<NavigatorToolbar 
				route={this.props.route} 
				title={project && df.projectTitle(project)} 
				renderLeft={(navigationController: NavigationController, props: NavigatorToolbarProps, actions: RenderToolbarActions) => {
					const previousRoute = actions.previousRoute()
					if (!previousRoute) {
						return null
					}
					return (
						<div className="left">
							{previousRoute.component === 'ProjectsList' ? (
								<BackButtonWithChildren onClick={actions.pop.bind(this, navigationController)} className="-home">
									Projects
								</BackButtonWithChildren>
							) : (
								<BackButtonWithChildren onClick={actions.pop.bind(this, navigationController)}>
									Back
								</BackButtonWithChildren>
							)}
						</div>
					)
				}}
				center={(
					<div className="contents">
						<h1 className="title-page">{project && df.projectTitle(project)}</h1>
					</div>
				)}
			/>
		)
	}
}
