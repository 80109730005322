import * as dt from 'modules/database/types'
import { v4 as uuid } from 'uuid'

export function makeNewProduct(type: dt.ProductType): dt.Product {
	const now = new Date()
	return {
		productId: newProductId(),
		type: type,
		whenCreated: now.toISOString(),
		whenUpdated: now.toISOString(),
		schemaVersion: dt.CurrentProductSchemaVersion,
	}
}

function newProductId(): dt.ProductID {
	return uuid()
}
