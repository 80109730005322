/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { Props, Actions, ContainerOwnProps } from '../containers/ComboBox'
import FormRow from './FormRow'
import { ActionMeta } from 'react-select'
import Creatable from 'react-select/creatable'
import * as _ from 'lodash'
import { Snapshot, forComponentProps } from 'changeling'
import { ComboBoxValueType } from 'modules/combobox/types'

export type ComponentProps = OwnProps & Props & Actions & ContainerOwnProps

interface MyOptionType {
	label: string
	value: ComboBoxValueType
}

interface OwnProps extends Snapshot<ComboBoxValueType | undefined> {
	staticValues?: ComboBoxValueType[]
}

export default class ComboBox extends React.Component<ComponentProps> {

	private controller = forComponentProps(this)

	public render() {
		const { title } = this.props

		const snapshot = this.controller.snapshot()
		const value = snapshot.value
		const options = this.options()
		const selectedValue = value ? options.find(option => option.value === value) || null : null
		
		return (
			<FormRow title={title} type="select">
				<Creatable
					placeholder="Select or enter new…"
					value={selectedValue}
					isClearable={true}
					onChange={this.handleChange}
					options={options}
					isOptionDisabled={(option) => {
						return !option.value
					}}
					className="combobox"
					classNamePrefix="combobox"
				/>
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<ComponentProps>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.code !== nextProps.code) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		if (!_.isEqual(this.props.values, nextProps.values)) {
			return true
		}
		return false
	}

	private options = () => {
		const result: { label: string; value: ComboBoxValueType }[] = []
		if (this.props.values.project.length) {
			result.push({
				label: 'From this project…',
				value: '',
			})
			this.props.values.project.forEach((value) => {
				result.push({
					label: `${value.value}`,
					value: value.value,
				})
			})

			if (this.props.values.user.length) {
				result.push({
					label: '',
					value: '',
				})
			}
		}
		if (this.props.staticValues && this.props.staticValues.length) {
			let pushedSeparator = false
			this.props.staticValues.forEach(sv => {
				if (result.findIndex(r => r.value === sv) === -1) {
					if (!pushedSeparator) {
						result.push({
							label: 'Suggested…',
							value: '',
						})
						pushedSeparator = true
					}
					
					result.push({
						label: `${sv}`,
						value: sv,
					})
				}
			})
		}
		if (this.props.values.user.length) {
			let pushedSeparator = false

			this.props.values.user.forEach((value) => {
				if (result.findIndex(r => r.value === value.value) === -1) {
					if (!pushedSeparator) {
						result.push({
							label: 'Used previously…',
							value: '',
						})
						pushedSeparator = true
					}
					
					result.push({
						label: `${value.value}`,
						value: value.value,
					})
				}
			})
		}
		return result
	}

	private handleChange = (value: MyOptionType | readonly MyOptionType[] | undefined | null, actionMeta: ActionMeta<MyOptionType>) => {
		const newValue = value as MyOptionType | undefined

		this.props.onUseValue(newValue ? newValue.value : undefined)
		this.props.onChange(newValue ? newValue.value : undefined)
	}
}
