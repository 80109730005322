import actionCreatorFactory from 'typescript-fsa'
import * as t from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('User')

export const downloadUser = actionCreator('DOWNLOAD_USER')
export const downloadedUser = actionCreator<t.User>('DOWNLOADED_USER')
export const downloadedUserAdmin = actionCreator<t.UserAdministrative>('DOWNLOADED_USER_ADMIN')

export const saveUser = actionCreator('SAVE_USER')
export const updateEditingUser = actionCreator<Partial<t.User>>('UPDATE_EDITING_USER')
export const closeUserDetails = actionCreator('CLOSE_USER_DETAILS')

export interface ChangePasswordPayload {
	currentPassword: string
	newPassword: string
}

export const changePassword = actionCreator<ChangePasswordPayload>('CHANGE_PASSWORD')
