/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/CopyFromProject'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as dt from 'modules/database/types'
import * as ds from 'modules/database/selectors'

/* Import module actions */
// import * as a from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	project: dt.Project
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	// exampleProperty: string
	projects: DeepReadonlyArray<dt.Project>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		projects: ds.allProjects(state).filter(p => p.projectId !== ownProps.project.projectId),
		// exampleProperty: state.template.name,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(a.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
