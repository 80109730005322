import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { combineReducers } from 'redux'

import * as actions from './actions'

/* Import reducers from our modules */
import * as auth from '../auth/reducer'
import * as routing from 'onsenui-react-redux-navigator'
import * as project from 'modules/project/reducer'
import * as database from 'modules/database/reducer'
import * as product from 'modules/product/reducer'
import * as combobox from 'modules/combobox/reducer'
import * as storage from 'modules/storage/reducer'
import * as user from 'modules/user/reducer'
// import * as another from '../another/reducers'

/**
 * The root store state. Include sub-states for all of the modules / ducks.
 * All of these should be annotated `readonly`, as should everything down
 * the tree of StoreState interfaces, and their contents.
 */
interface MutableStoreState {
	auth: auth.StoreState
	routing: routing.RoutingStoreState
	project: project.StoreState
	database: database.StoreState
	product: product.StoreState
	combobox: combobox.StoreState
	storage: storage.StoreState
	user: user.StoreState

	ready: boolean
}

export type StoreState = DeepReadonly<MutableStoreState>

const readyReducer = reducerWithInitialState(false)
	.case(actions.readyAction, (state, payload) => (true))

/**
 * The root reducer, combines reducers for all of the modules / ducks.
 */
export const reducer = combineReducers<StoreState>({
	auth: auth.reducer,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	routing: routing.reducer as any,
	project: project.reducer,
	database: database.reducer,
	product: product.reducer,
	combobox: combobox.reducer,
	storage: storage.reducer,
	user: user.reducer,
	
	ready: readyReducer,
})
