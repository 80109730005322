/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { ComponentProps } from '../containers/Login'
import { ChangeEvent } from 'react'
import { Page, Toolbar, BottomToolbar } from 'react-onsenui'
import produce from 'immer'

import { MyPage } from '../../../App'
import Register from './Register'

/**
 * Interface for private internal component state.
 */
interface State {
	username: string
	password: string
	register: boolean
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	username: '',
	password: '',
	register: false,
}

export default class Login extends React.Component<ComponentProps, State> {

	public state = INITIAL_STATE

	public render() {
		const { loggedIn, loggingIn } = this.props
		const { register } = this.state

		if (loggedIn) {
			return <MyPage />
		} else if (loggingIn) {
			return (
				<Page renderToolbar={this.renderToolbar} renderBottomToolbar={this.renderBottomToolbar}>
					<section className="signin-form">
						<div className="loading-state -reversed">
							<h1 className="text">Logging in…</h1>
						</div>
					</section>
				</Page>
			)
		} else if (register) {
			return this.renderRegister()
		} else {
			return this.renderLogin()
		}
	}

	private renderLogin() {
		const { error } = this.props
		return (
			<Page renderToolbar={this.renderToolbar} renderBottomToolbar={this.renderBottomToolbar}>
				<section className="signin-form">
					<form className="form-layout" onSubmit={this.onSubmit}>
						<h1 className="site-logo">Maqasa</h1>
						<h2 className="title-sub _sr-only">Sign in</h2>

						{error && (
							<p className="title-sub">{error}</p>
						)}

						<div className="row">
							<div className="form-field">
								<label className="label _sr-only">Username</label>
								<div className="form-input -text">
									<input
										type="email"
										name="username"
										placeholder="Username"
										className="field"
										onChange={this.handleUsername}
										value={this.state.username}
										autoComplete="username"
										autoFocus={true}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field">
								<label className="label _sr-only">Password</label>
								<div className="form-input -text">
									<input
										type="password"
										name="password"
										placeholder="Password"
										className="field"
										onChange={this.handlePassword}
										defaultValue={this.state.password}
										autoComplete="current-password"
									/>
								</div>
							</div>
						</div>
						<div className="row -buttons">
							<div className="form-field">
								<div className="button-group">
									<button type="submit" className="button-link -reversed" onClick={this.doLogin}>Sign in</button>
								</div>
							</div>
						</div>
						<div className="row -buttons">
							<div className="form-field">
								<div className="button-group">
									<button className="button-link -secondary" onClick={this.doRegister}>Sign up for a free trial</button>
								</div>
							</div>
						</div>
					</form>
				</section>
			</Page>
		)
	}

	private renderRegister() {
		return (
			<Register onCancel={this.doCancelRegister} />
		)
	}

	private renderToolbar = () => {
		return (
			<Toolbar />
		)
	}

	private renderBottomToolbar = () => {
		return (
			<BottomToolbar />
		)
	}

	private doLogin = () => {
		this.props.onLogin(this.state.username, this.state.password)
		this.setState({ password: '' })
	}

	private doRegister = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.setState(produce((draft: State) => {
			draft.register = true
		}))
	}

	private doCancelRegister = () => {
		this.setState(produce((draft: State) => {
			draft.register = false
		}))
	}

	private handleUsername = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			username: e.target.value,
		})
	}

	private handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			password: e.target.value,
		})
	}

	private onSubmit = (evt: React.FormEvent) => {
		evt.preventDefault()
		this.doLogin()
	}

}
