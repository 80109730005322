import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import Calculation from 'modules/root/calculations'
import * as _ from 'lodash'
import { calculatePricing } from 'modules/project/calculations'
import { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import * as df from 'modules/database/functions'
import { GenericFabricOptions, GenericFabricTargetCalculations, FabricQuantities, calculateGenericFabricQuantity, calculateGenericFabricPricing } from 'modules/product/common/fabric'
import { calculateGenericHardwarePricing } from 'modules/product/common/hardware'
import { sumGenericPricing, GenericPricing } from 'modules/product/common/pricing'

/** Return the effective CurtainSet of the given curtain. */
export function curtainSet(curtain: dtp.Curtain): dtp.CurtainSet {
	if (curtain.overview && curtain.overview.curtainSet) {
		return curtain.overview.curtainSet
	}

	return dtp.CurtainSet.Single
}

/** Return the string to use to refer to the hardware, depending upon the choice of rod or track */
export function rodOrTrack(curtain: dtp.CurtainDetail): string {
	if (curtain && curtain.hardware && curtain.hardware.rod) {
		if (curtain.hardware.rod === dtp.RodOrTrack.Rod) {
			return 'Rod'
		}
		if (curtain.hardware.rod === dtp.RodOrTrack.Track) {
			return 'Track'
		}
	}

	return 'Rod / Track'
}

export function trackLength(curtain: dtp.Curtain) {
	const measures = curtain.curtain1 && curtain.curtain1.measures
	return measures ? calculateTrackWidth(measures).getResultOrUndefined() : undefined
}

/** Returns the unique fabrics names used for the curtain. */
export function uniqueFabricNames(curtain: dtp.Curtain): string[] {
	return _.uniq(eachCurtain(curtain, d => d.fabric && d.fabric.fabric ? d.fabric.name : undefined))
}

export function uniqueLiningNames(curtain: dtp.Curtain): string[] {
	return _.uniq(eachCurtain(curtain, d => d.fabric && d.fabric.lined && d.fabric.liningOptions ? d.fabric.liningOptions.type : undefined))
}

export function uniqueHeaderTypes(curtain: dtp.Curtain): string[] {
	return _.uniq(eachCurtain(curtain, d => d.specifications && d.specifications.headerType))
}

/** Execute the given function with each curtain detail in the given curtain and append to an array if the result is defined. */
function eachCurtain<T>(curtain: dtp.Curtain, f: (detail: dtp.CurtainDetail) => T | undefined): T[] {
	const theCurtainSet = curtainSet(curtain)

	const result: T[] = []
	if (curtain.curtain1) {
		const aResult = f(curtain.curtain1)
		if (aResult) {
			result.push(aResult)
		}
	}
	if (curtain.curtain2 && theCurtainSet === dtp.CurtainSet.Double) {
		const aResult = f(curtain.curtain2)
		if (aResult) {
			result.push(aResult)
		}
	}
	return result
}

export function uniqueHardwareNames(curtain: dtp.Curtain): string[] {
	return _.uniq(eachCurtain(curtain, d => d.hardware && d.hardware.rod && d.hardware.rodOptions ? d.hardware.rodOptions.code : undefined))
}

export function uniqueHardwareColours(curtain: dtp.Curtain): string[] {
	return _.uniq(eachCurtain(curtain, d => d.hardware && d.hardware.rod && d.hardware.rodOptions ? d.hardware.rodOptions.color : undefined))
}

export function uniqueFinialTypes(curtain: dtp.Curtain): string[] {
	return _.uniq(eachCurtain(curtain, d => d.hardware && d.hardware.finialsApplied && d.hardware.finialsOptions 
		? d.hardware.finialsOptions.type : undefined))
}

export function makingPriceMethod(detail: dtp.CurtainDetail): dtp.CurtainPricingMethod {
	if (detail.specifications && detail.specifications.makingPriceMethod) {
		return detail.specifications.makingPriceMethod
	}

	return dtp.CurtainPricingMethod.unit
}

function measuresStackSizeRight(measures: dtp.CurtainMeasures) {
	if (measures.stackSizesEqual === false) {
		return measures.stackSizeRight
	} else {
		return measures.stackSizeLeft
	}
}

export function measuresReturnSizeRight(measures: dtp.CurtainMeasures) {
	if (measures.returnSizesEqual === false) {
		return measures.returnSizeRight
	} else {
		return measures.returnSizeLeft
	}
}

/** Calculate the width of track for the given curtain measures. */
export function calculateTrackWidth(measures: dtp.CurtainMeasures, ignoreCustom?: boolean): Calculation<number> {
	const calculation = new Calculation(NaN)

	if (!ignoreCustom && measures.useCustomTrackLength) {
		const result = calculation.toNumber(measures.customTrackLength, 'Custom track length')
		return calculation.withResult(result)
	}

	const total = calculation.toNumber(measures.archToArchWidth, 'Architrave to Architrave (Width)') + 
		calculation.toNumber(measures.stackSizeLeft, 'Stack Left', ['measures.stackSizeLeft'], { defaultValue: 0, operation: '+' }) + 
		calculation.toNumber(measuresStackSizeRight(measures), 'Stack Right', ['measures.stackSizeRight'], { defaultValue: 0, operation: '+' })
	return calculation.withResult(total)
}

/** Calcuate the width of curtain for the given curtain measures. */
export function calculateCurtainWidth(measures: dtp.CurtainMeasures): Calculation<number> {
	const calculation = new Calculation(NaN)

	const trackWidth = calculation.mergeErrors(calculateTrackWidth(measures))

	const total = trackWidth + 
		calculation.toNumber(measures.returnSizeLeft, 'Return Left', ['measures.returnSizeLeft'], { defaultValue: 0, operation: '+' }) + 
		calculation.toNumber(measuresReturnSizeRight(measures), 'Return Right', ['measures.returnSizeRight'], { defaultValue: 0, operation: '+' })
	return calculation.withResult(total)
}

/** Calculate the curtain drop for the given curtain measures. */
export function calculateFinishedLength(measures: dtp.CurtainMeasures, ignoreCustom?: boolean): Calculation<number> {
	const calculation = new Calculation(NaN)

	if (!ignoreCustom && measures.useCustomFinishedLength) {
		const result = calculation.toNumber(measures.customFinishedLength, 'Custom drop')
		return calculation.withResult(result)
	}

	const method = measures.finishedLengthCalculationMethod
	if (!method) {
		return calculation.withMissing('Drop calculation method')
	}

	let total = 0

	switch (method) {
		case dtp.CurtainLengthCalculationMethod.ceiling: {
			total += calculation.toNumber(measures.ceilingToFloor, 'Ceiling to floor', ['measures.ceilingToFloor'])
			total -= calculation.toNumber(measures.curtainBelowCeiling, 'Curtain distance below ceiling', ['measures.curtainBelowCeiling'], { defaultValue: 0, operation: '-' })
			break
		}
		case dtp.CurtainLengthCalculationMethod.cornice: {
			total += calculation.toNumber(measures.corniceToFloor, 'Cornice to floor', ['measures.corniceToFloor'])
			total -= calculation.toNumber(measures.curtainBelowCornice, 'Curtain distance below cornice', ['measures.curtainBelowCornice'], { defaultValue: 0, operation: '-' })
			break
		}
		case dtp.CurtainLengthCalculationMethod.aboveArchitrave: {
			total += calculation.toNumber(measures.archToFloor, 'Architrave to floor', ['measures.archToFloor'])
			total += calculation.toNumber(measures.curtainAboveArch, 'Curtain height above architrave')
			break
		}
		case dtp.CurtainLengthCalculationMethod.onArchitrave: {
			total += calculation.toNumber(measures.archToFloor, 'Architrave to floor', ['measures.archToFloor'])
			total -= calculation.toNumber(measures.curtainBelowArch, 'Curtain distance below architrave', ['measures.curtainBelowArch'], { defaultValue: 0, operation: '-' })
			break
		}
		default:
			calculation.withErrorMessage(`Unsupported curtain length calculation method: ${method}`)
			break
	}

	/* Allowances */
	total -= calculation.toNumber(measures.floorAllowance, 'Flooring allowance', ['measures.floorAllowance'], { defaultValue: 0, operation: '-' })
	total += calculation.toNumber(measures.hemDetails, 'Hem detail', ['measures.hemDetails'], { defaultValue: 0, operation: '+' })
	total -= calculation.toNumber(measures.hemDetailsDeduct, 'Hem detail', ['measures.hemDetailsDeduct'], { defaultValue: 0, operation: '-' })
	total += calculation.toNumber(measures.otherAllowance, 'Other allowance', ['measures.otherAllowance'], { defaultValue: 0, operation: '+' })
	total -= calculation.toNumber(measures.otherAllowanceDeduct, 'Other allowance', ['measures.otherAllowanceDeduct'], { defaultValue: 0, operation: '-' })

	return calculation.withResult(total)
}

/** Calculate the curtain quantities for the given curtain. */
export function calculateCurtainSetQuantities(curtain: dtp.Curtain): Calculation<CurtainQuantities[]> {
	const calculation = new Calculation<CurtainQuantities[]>([])
	const theCurtainSet = curtainSet(curtain)
	const curtain1 = curtain.curtain1
	const curtain2 = curtain.curtain2

	if (!curtain1) {
		return calculation
	}
	const quantity1 = calculateCurtainQuantities(curtain1)

	switch (theCurtainSet) {
		case dtp.CurtainSet.Single:
			return calculation.withResult([calculation.mergeErrors(quantity1)])
		case dtp.CurtainSet.Double:
			if (!curtain2) {
				return calculation.withResult([calculation.mergeErrors(quantity1)])
					.withErrorMessage('The second curtain has not been configured')
			}

			const quantity2 = calculateCurtainQuantities(curtain2)
			return calculation.withResult([calculation.mergeErrors(quantity1), calculation.mergeErrors(quantity2)])
		default:
			return calculation.withErrorMessage(`Unsupported curtain set: ${theCurtainSet}`)
	}
}

interface CurtainQuantities {
	fabric?: FabricQuantities
	lining?: FabricQuantities
	interlining?: FabricQuantities
	trim?: number
}

export function calculateCurtainQuantities(detail: dtp.CurtainDetail): Calculation<CurtainQuantities> {
	const result: CurtainQuantities = {}
	const calculation = new Calculation(result)

	const fabric = detail.fabric
	const measures = detail.measures
	const specifications = detail.specifications
	if (!fabric) {
		return calculation.withErrorMessage('Fabric options are not set')
	}
	if (!measures) {
		return calculation.withErrorMessage('Measures are not set')
	}
	// if (!specifications) {
	// 	return calculation.withErrorMessage('Specifications are not set')
	// }

	const hemAllowanceCalculation = new Calculation(NaN)
	const hemAllowance = hemAllowanceCalculation.toNumber(specifications && specifications.hemAllowance, 'Hem allowance', ['specifications.hemAllowance'], { defaultValue: dtp.CurtainSpecificationsHemAllowanceDefault, operation: '+' })
	hemAllowanceCalculation.withResult(hemAllowance)
	
	const targetCalculations: GenericFabricTargetCalculations = {
		width: calculateCurtainWidth(measures),
		length: calculateFinishedLength(measures),
		hemAllowance: hemAllowanceCalculation,
		sideHemAllowance: new Calculation(200),
		quantity: new Calculation(1),
	}

	if (fabric.lined) {
		if (!fabric.liningOptions) {
			calculation.withErrorMessage('Lining options are not set')
		} else {
			const liningOptions: GenericFabricOptions = {
				...fabric.liningOptions,
				fullness: fabric.fullness,
			}

			result.lining = calculation.mergeErrors(calculateGenericFabricQuantity(liningOptions, targetCalculations, 'Lining', 'fabric.liningOptions'))

			if (fabric.liningOptions.interlined) {
				if (!fabric.liningOptions.interliningOptions) {
					calculation.withErrorMessage('Interlining options are not set')
				} else {
					const interliningOptions: GenericFabricOptions = {
						...fabric.liningOptions.interliningOptions,
						fullness: fabric.fullness,
					}
			
					result.interlining = calculation.mergeErrors(calculateGenericFabricQuantity(interliningOptions, targetCalculations, 'Interlining', 'fabric.liningOptions.interliningOptions'))
				}
			}
		}
	}

	if (fabric.fabric) {
		result.fabric = calculation.mergeErrors(calculateGenericFabricQuantity(fabric, targetCalculations, 'Curtain', 'fabric'))
	}

	if (fabric.trim && fabric.trimOptions) {
		const curtainWidth = calculation.mergeErrors(calculateCurtainWidth(measures))
		const curtainFinishedLength = calculation.mergeErrors(calculateFinishedLength(measures))
		const fullness = calculation.toNumber(fabric.fullness, 'Trim fabric fullness')
		const curtainWidthWithFullness = curtainWidth * (1 + fullness / 100)
		const draws = calculateDraws(specifications)

		let trimTotal = 0
		if (fabric.trimOptions.hem) {
			trimTotal += curtainWidthWithFullness + 70 /* To allow trim to be tucked around */
		}
		if (fabric.trimOptions.leadingEdge) {
			trimTotal += (curtainFinishedLength + 100) * draws
		}
		if (fabric.trimOptions.outerEdge) {
			trimTotal += (curtainFinishedLength + 100) * draws
		}
		if (trimTotal === 0) {
			calculation.withErrorMessage('Trim has not specified hem, leading edge or outer edge.')
		}
		result.trim = trimTotal
	}
	return calculation
}

function calculateDraws(specs?: dtp.CurtainSpecifications) {
	if (specs && specs.draw) {
		if (specs.draw === dtp.CurtainDraw.evenPair || specs.draw === dtp.CurtainDraw.unevenPair) {
			return 2
		} 
		if (specs.draw === dtp.CurtainDraw.lefthandDraw || specs.draw === dtp.CurtainDraw.righthandDraw) {
			return 1
		} 
	}

	return 2
}

interface CurtainHardwarePricing {
	rod?: Calculation<GenericPricing>
	finials?: Calculation<GenericPricing>
	bends?: Calculation<GenericPricing>
	holdbacks?: Calculation<GenericPricing>
	flickSticks?: Calculation<GenericPricing>
	automation?: Calculation<GenericPricing>
	total?: Calculation<GenericPricing>
}

interface CurtainSpecificationsPricing {
	making?: Calculation<GenericPricing>
	extras?: Calculation<GenericPricing>
	total?: Calculation<GenericPricing>
}

interface CurtainPricings {
	fabric?: Calculation<GenericPricing>
	lining?: Calculation<GenericPricing>
	interlining?: Calculation<GenericPricing>
	trim?: Calculation<GenericPricing>
	totalFabric?: Calculation<GenericPricing>
	specifications?: CurtainSpecificationsPricing
	hardware?: CurtainHardwarePricing
	total?: Calculation<GenericPricing>
}

export function calculateCurtainPricing(detail: dtp.CurtainDetail, taxDetails: dt.TaxDetails | undefined): CurtainPricings {
	const result: CurtainPricings = {}

	const fabricQuantitiesCalculation = calculateCurtainQuantities(detail)
	const fabricQuantities = fabricQuantitiesCalculation.getResult()

	if (fabricQuantities.fabric && detail.fabric) {
		result.fabric = calculateGenericFabricPricing(detail.fabric, fabricQuantities.fabric, taxDetails, 'Fabric', 'fabric').withErrors(fabricQuantitiesCalculation)
	} else if (fabricQuantitiesCalculation.isErrored()) {
		result.fabric = new Calculation<GenericPricing>({}).withErrors(fabricQuantitiesCalculation)
	}
	if (fabricQuantities.lining && detail.fabric && detail.fabric.liningOptions) {
		result.lining = calculateGenericFabricPricing(
			{
				...detail.fabric.liningOptions,
				fullness: detail.fabric.fullness,
			},
			fabricQuantities.lining,
			taxDetails,
			'Lining',
			'fabric.liningOptions',
		)
		
		if (fabricQuantities.interlining && detail.fabric.liningOptions.interlined && detail.fabric.liningOptions.interliningOptions) {
			result.interlining = calculateGenericFabricPricing(
				{
					...detail.fabric.liningOptions.interliningOptions,
					fullness: detail.fabric.fullness,
				},
				fabricQuantities.interlining,
				taxDetails,
				'Interlining',
				'fabric.liningOptions.interliningOptions',
			)
		}
	}
	if (fabricQuantities.trim && detail.fabric && detail.fabric.trimOptions) {
		result.trim = new Calculation<GenericPricing>({})
		result.trim.withResult({
			pricing: result.trim.mergeErrors(calculatePricing(detail.fabric.trimOptions.price, taxDetails, fabricQuantities.trim / 1000, 'Trim', 'fabric.trimOptions')),
		})
	}

	result.totalFabric = sumGenericPricing(result.fabric, result.lining)
	result.totalFabric = sumGenericPricing(result.totalFabric, result.interlining)
	result.totalFabric = sumGenericPricing(result.totalFabric, result.trim)

	if (fabricQuantities && detail.specifications) {
		result.specifications = {}
		const method = makingPriceMethod(detail)
		switch (method) {
			case dtp.CurtainPricingMethod.unit: {
				const pricing = detail.specifications.makingPricePerDrop
				if (!pricing || pricing.mode !== dt.PricingMode.NA) {
					result.specifications.making = new Calculation<GenericPricing>({})
					if (!fabricQuantities.fabric) {
						result.specifications.making.withErrorMessage('Making cost requires fabric quantities')
					} else {
						const drops = fabricQuantities.fabric.drops
						if (drops === undefined) {
							result.specifications.making.withErrorMessage('Making cost requires fabric drops')
						} else {
							let dropsForMaking = drops
							if (detail.specifications.draw === dtp.CurtainDraw.evenPair && dropsForMaking % 2 === 1) {
								/* evenPair curtains with an odd number of drops result in needing to cut one drop in half, so we charge for the making cost of an additional drop */
								dropsForMaking += 1
							}
							result.specifications.making.withResult({
								pricing: result.specifications.making.mergeErrors(calculatePricing(pricing, taxDetails, dropsForMaking, 'Making cost')),
							})
						}
					}
				}
				break
			}
			case dtp.CurtainPricingMethod.metre: {
				const pricing = detail.specifications.makingPricePerMetre
				if (!pricing || pricing.mode !== dt.PricingMode.NA) {
					result.specifications.making = new Calculation<GenericPricing>({})
					if (!fabricQuantities.fabric) {
						result.specifications.making.withErrorMessage('Making cost requires fabric quantities')
					} else {
						const quantity = fabricQuantities.fabric.quantity
						if (quantity === undefined) {
							result.specifications.making.withErrorMessage('Making cost requires fabric quantity')
						} else {
							result.specifications.making.withResult({
								pricing: result.specifications.making.mergeErrors(calculatePricing(pricing, taxDetails, quantity / 1000, 'Making cost')),
							})
						}
					}
				}
				break
			}
			default:
				result.specifications.making = new Calculation<GenericPricing>({})
				result.specifications.making.withErrorMessage(`Unsupported making pricing method: ${method}`)
				break
		}
		result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.making)

		if (detail.specifications.extras) {
			result.specifications.extras = detail.specifications.extras.reduce(
				(prev, curr) => {
					const extraPricing = new Calculation<GenericPricing>({})
					const p = calculatePricing(curr.pricing, taxDetails, 1, curr.name || 'Extra')
					extraPricing.withResult({
						pricing: extraPricing.mergeErrors(p),
					})
					return sumGenericPricing(prev, extraPricing)!
				},
				new Calculation<GenericPricing>({}),
			)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.extras)
		}
	}
	if (detail.hardware) {
		result.hardware = {}

		if (detail.hardware.rod) {
			result.hardware.rod = calculateGenericHardwarePricing(
				detail.hardware.rodOptions, 
				taxDetails, 
				rodOrTrack(detail),
				undefined,
				1,
			)
			result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.rod)
		
			if (detail.hardware.finialsApplied) {
				result.hardware.finials = calculateGenericHardwarePricing(detail.hardware.finialsOptions, taxDetails, 'Finials')
				result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.finials)
			}
			if (detail.hardware.bendsApplied) {
				result.hardware.bends = calculateGenericHardwarePricing(detail.hardware.bendsOptions, taxDetails, 'Bends')
				result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.bends)
			}
		}
		if (detail.hardware.holdbacksApplied) {
			result.hardware.holdbacks = calculateGenericHardwarePricing(
				detail.hardware.holdbackOptions, 
				taxDetails, 
				'Holdbacks',
				(options: dtp.HoldbackOptions, calculation) => {
					const quantity = calculation.toNumber(options.quantityLeft, 'Holdbacks quantity left', ['hardware.holdbackOptions.quantityLeft'], { operation: '+', defaultValue: 0 }) +
						calculation.toNumber(options.quantityRight, 'Holdbacks quantity right', ['hardware.holdbackOptions.quantityRight'], { operation: '+', defaultValue: 0 })
					return { ...options, quantity }
				},
			)
			result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.holdbacks)
		}
		if (detail.hardware.flickSticks) {
			result.hardware.flickSticks = calculateGenericHardwarePricing(detail.hardware.flickSticksOptions, taxDetails, 'Flick sticks')
			result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.flickSticks)
		}
		if (detail.hardware.automation) {
			result.hardware.automation = calculateGenericHardwarePricing(detail.hardware.automationOptions, taxDetails, 'Automation', undefined, 1)
			result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.automation)
		}
	}

	result.total = sumGenericPricing(result.totalFabric, result.specifications && result.specifications.total)
	result.total = sumGenericPricing(result.total, result.hardware && result.hardware.total)

	return result
}

/** Provides product groups for the CopyFromOtherProduct component, splitting each curtain out to its two parts. */
export function curtainProductGroupItems(productId: dt.ProductID, product: dt.Product): CopyFromProductGroupItem[] {
	const result: CopyFromProductGroupItem[] = []

	if (product.type === dt.ProductType.Curtain && product.productId !== productId) {
		const curtain = product.details as dtp.Curtain
		const theCurtainSet = curtainSet(curtain)
		if (curtain.curtain1) {
			result.push({
				name: theCurtainSet === dtp.CurtainSet.Double ? df.productTitle(product) + ' (C1)' : df.productTitle(product),
				type: 'curtain',
				value: curtain.curtain1,
			})
		}
		if (curtain.curtain2 && theCurtainSet === dtp.CurtainSet.Double) {
			result.push({
				name: df.productTitle(product) + ' (C2)',
				type: 'curtain',
				value: curtain.curtain2,
			})
		}
	}

	return result
}
