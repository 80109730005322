/**
 * Action creators for authentication.
 */

import actionCreatorFactory from 'typescript-fsa'
import firebase from 'firebase/compat/app'

const actionCreator = actionCreatorFactory('Auth')

export interface LoginRequestPayload {
	username: string
	password: string
}
export const login = actionCreator<LoginRequestPayload>('LOGIN')
export type LoginAction = ReturnType<typeof login>

/** The user has been logged out. */
export const logout = actionCreator('LOGOUT')

export const loggedIn = actionCreator<firebase.User>('LOGGED_IN')

export const loggedOut = actionCreator('LOGGED_OUT')

export const loginError = actionCreator<firebase.FirebaseError>('LOGIN_ERROR')
