import * as React from 'react'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CurtainForm'
import * as cf from '../functions'
import { formatNumber } from 'modules/root/functions'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { Controller, forComponentProps, Input, wrapComponent } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}
class CurtainFormMeasures extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('measures')

	public render() {
		return this.renderMeasures(this.controller)
	}

	private renderMeasures = (controller: Controller<dtp.CurtainMeasures | undefined>) => {
		const measures = controller.snapshot().value || {}
		const sameStackSize = measures.stackSizesEqual !== false
		const sameReturnSize = measures.returnSizesEqual !== false

		const trackWidthCalculation = cf.calculateTrackWidth(measures, true)
		const curtainWidthCalculation = cf.calculateCurtainWidth(measures)
		const curtainHeightCalculation = cf.calculateFinishedLength(measures, true)

		const finishedLengthCalculationMethod = measures.finishedLengthCalculationMethod

		const notFirstCurtain = this.props.path[0] !== 'curtain1'

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{this.props.curtainName} measures</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Curtain}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						specialGroup={
							notFirstCurtain ? {
								items: [{
									name: 'Curtain 1',
									type: 'curtain1',
								}],
							} : undefined
						}
						productToItems={cf.curtainProductGroupItems.bind(undefined, this.props.productId)}
					/>
				)}

				<ff.Heading>Calculate curtain width</ff.Heading>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">
							Architrave to Architrave
							
							{/* <a href class="copy">Copy from Curtain 1</a> */}
						</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Width</label>
								<Input.Number type="number" prop="archToArchWidth" className="field" controller={controller} />
							</div>
							{/* <div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Height</label>
								<Input.Number type="number" name="archToArchHeight" className="field" controller={controller} />
							</div> */}
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Stack size</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Left</label>
								<Input.Number type="number" prop="stackSizeLeft" className="field" controller={controller} />
							</div>
							<div className="joiner">
								<button type="button" className={`link${sameStackSize ? ' -linked' : ''}`} onClick={this.onSameStackSize}>Same size for both</button>
							</div>
							<div className="form-input -text -stacked">
								<label className="label">Right</label>
								{!sameStackSize ? (
									<Input.Number type="number" prop="stackSizeRight" className="field" controller={controller} />
								) : (
									<input type="number" className="field" disabled={true} value={measures.stackSizeLeft || ''} />
								)}
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Return size</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Left</label>
								<Input.Number type="number" prop="returnSizeLeft" className="field" controller={controller} />
							</div>
							<div className="joiner">
								<button type="button" className={`link${sameReturnSize ? ' -linked' : ''}`} onClick={this.onSameReturnSize}>Same size for both</button>
							</div>
							<div className="form-input -text -stacked">
								<label className="label">Right</label>
								{!sameReturnSize ? (
									<Input.Number type="number" prop="returnSizeRight" className="field" controller={controller} />
								) : (
									<input type="number" className="field" disabled={true} value={measures.returnSizeLeft || ''} />
								)}
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				
				<div className="row -subsection">
					<div className="form-field -topalign">
						<h2 className="label -strong">Calculated track length</h2>
						<div className="form-input -text -stacked">
							{trackWidthCalculation.isSuccess() ? (
								<p className="value">{formatNumber(trackWidthCalculation.getResult())} mm</p>
							) : measures.useCustomTrackLength ? null : trackWidthCalculation.formatErrors()}
							<p className="note">{trackWidthCalculation.formatFormula()}</p>
							<ul className="option-inputs -inline">
								<li className="option -checkbox">
									<label className="label">
										<Input.Checkable type="checkbox" prop="useCustomTrackLength" className="checkbox" controller={controller} checkedValue={true} /><span className="substitute" />
										Specify a custom track length
									</label>
								</li>
							</ul>
							{measures.useCustomTrackLength && (
								<div className="form-input -text">
									<Input.Number type="number" prop="customTrackLength" className="field" controller={controller} /><small className="units">mm</small>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="row -subsection">
					<div className="form-field -topalign">
						<h2 className="label -strong">Calculated curtain width</h2>
						<div className="form-input -text -stacked">
							{curtainWidthCalculation.isSuccess() ? (
								<p className="value">{formatNumber(curtainWidthCalculation.getResult())} mm</p>
							) : curtainWidthCalculation.formatErrors()}
							<p className="note">{curtainWidthCalculation.formatFormula()}</p>
						</div>
					</div>
				</div>
				
				<ff.Heading>Calculate drop</ff.Heading>
				<ff.Radios
					prop="finishedLengthCalculationMethod"
					controller={controller}
					title="Calculation method"
					inline={false}
					options={[
						{ label: 'Fixing relative to ceiling', value: dtp.CurtainLengthCalculationMethod.ceiling },
						{ label: 'Fixing relative to cornice', value: dtp.CurtainLengthCalculationMethod.cornice },
						{ label: 'Fixing above architrave', value: dtp.CurtainLengthCalculationMethod.aboveArchitrave },
						{ label: 'Fixing on architrave', value: dtp.CurtainLengthCalculationMethod.onArchitrave },
					]}
				/>
				{finishedLengthCalculationMethod === dtp.CurtainLengthCalculationMethod.cornice && (
					<>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Cornice to floor</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">Height</label>
										<Input.Number type="number" prop="corniceToFloor" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Curtain distance below cornice</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">Distance</label>
										<Input.Number type="number" prop="curtainBelowCornice" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
					</>
				)}
				{finishedLengthCalculationMethod === dtp.CurtainLengthCalculationMethod.ceiling && (
					<>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Ceiling to floor</label>
								<div className="group">
									{/* <div className="form-input -text -stacked">
										<label className="label">Wall to wall</label>
										<Input.Number type="number" name="wallToWall" className="field" controller={controller} />
									</div>
									<div className="joiner" /> */}
									<div className="form-input -text -stacked">
										{/* <label className="label">Ceiling to floor</label> */}
										<label className="label">Height</label>
										<Input.Number type="number" prop="ceilingToFloor" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Curtain distance below ceiling</label>
								<div className="group">
									<div className="form-input -text -stacked">
										{/* <label className="label">Ceiling to floor</label> */}
										<label className="label">Distance</label>
										<Input.Number type="number" prop="curtainBelowCeiling" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
					</>
				)}
				{finishedLengthCalculationMethod === dtp.CurtainLengthCalculationMethod.aboveArchitrave && (
					<>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Architrave to floor</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">Height</label>
										<Input.Number type="number" prop="archToFloor" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div> 
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Curtain height above architrave</label>
								<div className="group">
									<div className="form-input -text -stacked">
										{/* <label className="label">Ceiling to floor</label> */}
										<label className="label">Distance</label>
										<Input.Number type="number" prop="curtainAboveArch" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
					</>
				)}
				{finishedLengthCalculationMethod === dtp.CurtainLengthCalculationMethod.onArchitrave && (
					<>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Architrave to floor</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">Height</label>
										<Input.Number type="number" prop="archToFloor" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div> 
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Curtain distance below architrave</label>
								<div className="group">
									<div className="form-input -text -stacked">
										{/* <label className="label">Ceiling to floor</label> */}
										<label className="label">Distance</label>
										<Input.Number type="number" prop="curtainBelowArch" className="field" controller={controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
					</>
				)}
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Allowances</label>
						<div className="group -full-width">
							<div className="form-input -text -stacked">
								<label className="label">Deduct flooring allowance</label>
								<Input.Number type="number" prop="floorAllowance" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label" />
						<div className="group -full-width">
							<div className="form-input -text -stacked">
								<label className="label">Add hem detail</label>
								<Input.Number type="number" prop="hemDetails" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Deduct hem detail</label>
								<Input.Number type="number" prop="hemDetailsDeduct" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label" />
						<div className="group -full-width">
							<div className="form-input -text -stacked">
								<label className="label">Add other allowance</label>
								<Input.Number type="number" prop="otherAllowance" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Deduct other allowance</label>
								<Input.Number type="number" prop="otherAllowanceDeduct" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row -subsection">
					<div className="form-field -topalign">
						<h2 className="label -strong">Calculated drop</h2>
						<div className="form-input -text -stacked">
							{curtainHeightCalculation.isSuccess() ? (
								<p className="value">{formatNumber(curtainHeightCalculation.getResult())} mm</p>
							) : measures.useCustomFinishedLength ? null : curtainHeightCalculation.formatErrors()}
							
							<p className="note">{curtainHeightCalculation.formatFormula()}</p>
							<ul className="option-inputs -inline">
								<li className="option -checkbox">
									<label className="label">
										<Input.Checkable type="checkbox" prop="useCustomFinishedLength" className="checkbox" controller={controller} checkedValue={true} /><span className="substitute" />
										Specify a custom drop
									</label>
								</li>
							</ul>
							{measures.useCustomFinishedLength && (
								<div className="form-input -text">
									<Input.Number type="number" prop="customFinishedLength" className="field" controller={controller} /><small className="units">mm</small>
								</div>
							)}
						</div>
					</div>
				</div>

				<ff.Heading>Other reference measurements</ff.Heading>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Room</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Wall to wall</label>
								<Input.Number type="number" prop="wallToWall" className="field" controller={controller} />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Ceiling to floor</label>
								<Input.Number type="number" prop="ceilingToFloor" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Architrave</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Width</label>
								<Input.Number type="number" prop="archToArchWidth" className="field" controller={controller} />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Height</label>
								<Input.Number type="number" prop="archToArchHeight" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label" />
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">To left wall</label>
								<Input.Number type="number" prop="wallToArchLeft" className="field" controller={controller} />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">To right wall</label>
								<Input.Number type="number" prop="wallToArchRight" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label" />
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">To ceiling</label>
								<Input.Number type="number" prop="ceilingToArch" className="field" controller={controller} />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">To floor</label>
								<Input.Number type="number" prop="floorToArch" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Sill</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">To floor</label>
								<Input.Number type="number" prop="sillToFloor" className="field" controller={controller} />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
			</>
		)
	}

	private onSameStackSize = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const snapshot = this.controller.snapshot('stackSizesEqual')
		const value = snapshot.value || false

		const sameSize = value !== false
		const newSameSize = !sameSize

		snapshot.onChange(newSameSize)
	}

	private onSameReturnSize = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const snapshot = this.controller.snapshot('returnSizesEqual')
		const value = snapshot.value || false

		const sameReturnSize = value !== false
		const newSameReturnSize = !sameReturnSize

		snapshot.onChange(newSameReturnSize)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'curtain1') {
			this.onCopyFromCurtain1()
			return
		}

		const product = item.value as dtp.CurtainDetail
		if (product && product.measures) {
			this.controller.snapshot().onChange(product.measures)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected curtain doesn’t have measures.')
		}
	}

	private onCopyFromCurtain1 = () => {
		const root = this.props.rootFormValue
		if (root.curtain1 && root.curtain1.measures) {
			this.controller.snapshot().onChange(root.curtain1.measures)
			this.setState({ showCopy: false })
		} else {
			platform.alert('Curtain 1 does not have measures available')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(CurtainFormMeasures)
