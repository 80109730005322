import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/ProjectDetails'
import * as _ from 'lodash'
import * as f from '../functions'
import * as dt from 'modules/database/types'
import * as dtc from 'modules/database/types/curtains'
import * as dtb from 'modules/database/types/blinds'
import * as dtcu from 'modules/database/types/cushions'
import * as df from 'modules/database/functions'
import * as cf from 'modules/product/curtains/functions'
import * as pbf from 'modules/product/blinds/functions'
import * as pcuf from 'modules/product/cushions/functions'
import * as ff from 'modules/forms'
import { formatMillimetresToMetres, formatNumber } from 'modules/root/functions'
import PhotoDisplay from 'modules/forms/components/PhotoDisplay'
import Moment from 'react-moment'
import createQuote from 'modules/project/quote'
import createWorkroomOrderForm from 'modules/project/purchaseorders/workroom'
import createFabricOrderForm from 'modules/project/purchaseorders/fabric'
import createHardwareOrderForm from 'modules/project/purchaseorders/hardware'
import CopyFromProject from 'modules/project/containers/CopyFromProject'
import { availablePurchaseOrders, AvailablePurchaseOrderSupplier } from 'modules/project/purchaseorders'
import FormRow, { FormFieldVariant } from 'modules/forms/components/FormRow'
import { PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { forComponentProps, Controller, Input } from 'changeling'
import { titleCase } from 'title-case'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
	showDeletedProducts: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
	showDeletedProducts: false,
}

type ComponentProps = Props & Actions & OwnProps

export default class ProjectDetails extends React.Component<ComponentProps, State> {

	public state = INITIAL_STATE

	private controller = forComponentProps(this, 'project', 'onChangeProject')

	public render() {
		if (!this.props.project) {
			return null
		}

		return (
			<div className="site-body -split">
				<div className="width-limit -site">
					{this.renderSidebar()}
					<div className="project-body">
						<div className="content">
							<form className="form-layout">
								{!this.isReadOnly() ? (
									<>
										<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy products from…</a>
										<ff.Heading type="section">Project details</ff.Heading>
									</>
								) : (
									<>
										<ff.Heading type="section">Archived project details</ff.Heading>
										<p>This project has been archived and cannot be edited. To unarchive the project, click the <em>Edit project</em> button.</p>
									</>
								)}

								{this.state.showCopy && !this.isReadOnly() && (
									<CopyFromProject project={this.props.project} onCopy={this.onCopy} onCancel={this.onCancelCopy} />
								)}

								<ff.Heading>Products</ff.Heading>
								<div className="product-types">
									{dt.AllProductTypes.map(productType => this.renderProductType(productType))}
								</div>
								{this.renderProjectCosts()}
								{this.renderPurchaseOrders()}
								<br />
								<small className="subtle">{this.props.projectId}</small>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}

	public shouldComponentUpdate(nextProps: ComponentProps, nextState: State) {
		if (!_.isEqual(this.props.project, nextProps.project)) {
			return true
		}
		if (!_.isEqual(this.props.productsByType, nextProps.productsByType)) {
			return true
		}
		if (!_.isEqual(this.state, nextState)) {
			return true
		}
		return false
	}

	private isReadOnly = () => {
		return this.isArchived()
	}

	private isArchived = () => {
		return !!this.props.project.whenArchived
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private renderSidebar = () => {
		const { project } = this.props

		return (
			<aside className="project-sidebar">
				{project.photo && project.photo.imagePath ? (
					<figure className="media-element">
						<a href="#" onClick={this.zoomPhoto} className="image">
							<div className="aspect">
								<PhotoDisplay path={project.photo.annotatedImagePath || project.photo.imagePath} alt={df.projectTitle(this.props.project)} />
							</div>
						</a>
					</figure>
				) : (
					<figure className="media-element">
						<a href="#" onClick={this.props.onOpenEditForm} className="image">
							<div className="aspect"></div>
						</a>
					</figure>
				)}
				<div className="content">
					<div className="button-group">
						<a onClick={this.exportQuote} className="button-link -small -action -icon -quote">Quote</a>
						<a onClick={this.props.onOpenEditForm} className="button-link -small -reversed">Edit<span className="extended"> project</span></a>
						<a onClick={this.onDuplicate} className="button-link -small -reversed">Duplicate<span className="extended"> project</span></a>
					</div>

					{this.renderAdminOptions()}
					{this.renderProjectInfo()}
					{this.renderClientInfo()}
				</div>
			</aside>
		)
	}

	private zoomPhoto = (evt: React.MouseEvent) => {
		evt.preventDefault()
	}

	private renderAdminOptions() {
		return (
			<div className="button-group">
				<a onClick={this.exportProject} className="button-link -small -reversed">Export Project</a>
				{this.props.deletedProducts.length > 0 && (
					this.state.showDeletedProducts ? (
						<a onClick={this.toggleShowDeletedProducts} className="button-link -small -reversed">Hide Deleted Products</a>
					) : (
						<a onClick={this.toggleShowDeletedProducts} className="button-link -small -reversed">Show {this.props.deletedProducts.length} Deleted Product{this.props.deletedProducts.length !== 1 ? 's' : ''}</a>
					)
				)}
			</div>
		)
	}

	private toggleShowDeletedProducts = (evt: React.MouseEvent) => {
		this.setState(state => ({
			...state,
			showDeletedProducts: !state.showDeletedProducts,
		}))
	}

	private exportProject = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const blob = new Blob([JSON.stringify(this.props.project, undefined, 2)], { type: 'application/json' })
		return f.share({}, blob, (this.props.project.name || 'Project') + '.json')
	}

	private renderProjectInfo = () => {
		const { project } = this.props
		return (
			<div className="section">
				<h2 className="title-sub">Project info</h2>
				{project.whenArchived && (
					<p>Archived <Moment date={project.whenArchived} format="D MMM YYYY" />.</p>
				)}
				<p>
					{project.name && (
						<><span className="item -title"><strong>{project.name}</strong></span><br/></>
					)}
					{project.address && (
						<><span className="item">{project.address}</span><br/></>
					)}
					{project.contactName && (
						<><span className="item">{project.contactName}</span><br/></>
					)}
					{project.phone && (
						<><span className="item"><abbr>PH:</abbr> <a href={`tel:${project.phone}`}>{project.phone}</a></span><br/></>
					)}
					{project.mobile && (
						<><span className="item"><abbr>MOB:</abbr> <a href={`tel:${project.mobile}`}>{project.mobile}</a></span><br/></>
					)}
					{project.email && (
						<><span className="item -truncate"><a href={`mailto:${project.email}`}>{project.email}</a></span></>
					)}
					{/* <span className="item"><strong>Date:</strong> 15 July 2018</span><br/> */}
					{/* <span className="item"><strong>Quote No:</strong> Q01234</span><br/> */}
					{/* <span className="item"><strong>Location:</strong> {this.props.project.address}</span> */}
					{project.taxDetails && (
						<><span className="item">{project.taxDetails.taxRate}% {project.taxDetails.taxName}</span><br/></>
					)}
				</p>
			</div>
		)
	}

	private renderClientInfo = () => {
		const { project: { client } } = this.props

		if (!client) {
			return null
		}
		
		return (
			<div className="section">
				<h2 className="title-sub">Client</h2>
				<p>
					{client.name && (
						<><span className="item -title"><strong>{client.name}</strong></span><br/></>
					)}
					{client.address && (
						<><span className="item">{client.address}</span><br/></>
					)}
					{client.phone && (
						<><span className="item"><abbr>PH:</abbr> <a href={`tel:${client.phone}`}>{client.phone}</a></span><br/></>
					)}
					{client.mobile && (
						<><span className="item"><abbr>MOB:</abbr> <a href={`tel:${client.mobile}`}>{client.mobile}</a></span><br/></>
					)}
					{client.email && (
						<><span className="item -truncate"><a href={`mailto:${client.email}`}>{client.email}</a></span></>
					)}
				</p>
			</div>
		)
	}

	private renderProductType = (productType: dt.ProductType) => {
		const products = this.props.productsByType.productsByType[productType] ? this.props.productsByType.productsByType[productType].products : []
		if (this.state.showDeletedProducts) {
			products.push(...this.props.deletedProductsByType.productsByType[productType]?.products || [])
		}
		const info = df.productTypeInfo(productType)
		return (
			<div key={productType} className={'product -open'}>
				<h3 className="title-section"><span className="count">{products.length}</span> <span className="name">{info.collectiveName}</span></h3>
				<div className="content">
					{products.length > 0 && (
						<table className="table">
							<thead>
								{this.renderProductsHeader(productType)}
							</thead>
							<tbody>
								{
									products.map((product, index) => this.renderProduct(product, index))
								}
							</tbody>
						</table>
					)}
					{!this.isReadOnly() &&
						<button type="button" className="button-link -secondary -small" onClick={this.onAddProduct.bind(this, productType)}>Add {info.name}</button>
					}
				</div>
			</div>
		)
	}

	private renderProductsHeader = (productType: dt.ProductType) => {
		switch (productType) {
			case dt.ProductType.Curtain:
				return (
					<tr>
						<th scope="col">Room</th>
						<th scope="col">Set type</th>
						<th scope="col">Window</th>
						<th scope="col">Track length</th>
						<th scope="col">Fabric</th>
						<th scope="col">Fabric Qty.</th>
						<th/>
					</tr>
				)
			case dt.ProductType.Cushion:
				return (
					<tr>
						<th scope="col">Room</th>
						<th scope="col">Type</th>
						<th scope="col">Quantity</th>
						<th scope="col">Measurements</th>
						<th scope="col">Fabric</th>
						<th scope="col">Fabric Qty.</th>
						<th/>
					</tr>
				)
			case dt.ProductType.Blind:
				return (
					<tr>
						<th scope="col">Room</th>
						<th scope="col">Type</th>
						<th scope="col">Window</th>
						<th scope="col">Blind width</th>
						<th scope="col">Fabric</th>
						<th scope="col">Fabric Qty.</th>
						<th/>
					</tr>
				)
			default:
				return null
		}
	}

	private renderProduct = (someProduct: dt.Product, index: number) => {
		switch (someProduct.type) {
			case dt.ProductType.Curtain: {
				const details = someProduct.details as dtc.Curtain | undefined
				if (!details) {
					return
				}
				const curtainSet = cf.curtainSet(details)
				const fabrics = cf.uniqueFabricNames(details)
				const quantitiesCalculation = cf.calculateCurtainSetQuantities(details)
				const quantities = quantitiesCalculation.getResult()
				const trackLength = cf.trackLength(details)

				const curtain1Pricing = details.curtain1 && cf.calculateCurtainPricing(details.curtain1, undefined).total
				const curtain2Pricing = details.curtain2 && cf.calculateCurtainPricing(details.curtain2, undefined).total
				const hasCurtain2 = details.overview && details.overview.curtainSet === dtc.CurtainSet.Double
				const hasError = quantitiesCalculation.isErrored() || (curtain1Pricing && curtain1Pricing.isErrored()) || (hasCurtain2 && curtain2Pricing && curtain2Pricing.isErrored())
				return (
					<tr key={index} onClick={this.onEditProduct.bind(this, someProduct)} className={someProduct.deleted ? '-deleted' : ''}>
						<th className="name" scope="row">{details.overview && details.overview.room}</th>
						<td className="type"><label className="label">Set type:</label><p className="data">{curtainSet === dtc.CurtainSet.Single ? 'Single' : 'Double'}</p></td>
						<td className="window"><label className="label">Window:</label><p className="data">{details.overview && details.overview.window}</p></td>
						<td className="quantity"><label className="label">Track Length:</label><p className="data">{trackLength && `${formatNumber(trackLength)} mm`}</p></td>
						<td className="fabric"><label className="label">Fabric:</label><p className="data">{fabrics.join(' / ')}</p></td>
						<td className="quantity"><label className="label">Fabric Qty:</label><p className="data">{quantities.filter(q => !!q.fabric).filter(q => !!q.fabric!.quantity).map(q => `${formatMillimetresToMetres(q.fabric!.quantity!)} m`).join(' / ')}</p></td>
						{!this.isReadOnly() && (
							someProduct.deleted ? (
								<td className="actions">
									<a onClick={this.onUndeleteProduct.bind(this, someProduct)}>Restore</a>
								</td>
							) : (
								<td className={hasError ? 'actions -haswarning' : 'actions'}>
									{hasError ? <span className="icon -warning">⚠︎</span> : null}
									<a className="icon -edit">Edit</a>
									<a className="icon -delete" onClick={this.onDeleteProduct.bind(this, someProduct)}>Delete</a>
								</td>
							)
						)}
					</tr>
				)
			}
			case dt.ProductType.Cushion: {
				const details = someProduct.details as dtcu.Cushion | undefined
				if (!details) {
					return
				}

				const cushionMeasurements = pcuf.measurements(details)
				const cushionFabrics = pcuf.distinctCushionFabricQuantities(details)
				const cushionPricing = pcuf.calculateProductPricing(details, undefined)
				const hasError = cushionFabrics.map(f => f.quantities).find(q => q && q.isErrored()) || (cushionPricing.total && cushionPricing.total.isErrored())
				return (
					<tr key={index} onClick={this.onEditProduct.bind(this, someProduct)}>
						<th className="name" scope="row">{details.overview && details.overview.room}</th>
						<td className="type"><label className="label">Type:</label><p className="data">{titleCase((details.overview && details.overview.cushionType) || '')}</p></td>
						<td className="quantity"><label className="label">Quantity:</label><p className="data">{details.overview && details.overview.quantity}</p></td>
						<td className="quantity"><label className="label">Measurements:</label><p className="data">{cushionMeasurements}</p></td>
						<td className="fabric"><label className="label">Fabric:</label><p className="data">{cushionFabrics.map(f => f.name).map(n => n ? n : 'Unknown').join(' / ')}</p></td>
						<td className="quantity"><label className="label">Fabric Qty:</label><p className="data">{cushionFabrics.map(f => f.quantities).map(q => q && !q.isErrored() ? `${formatMillimetresToMetres(q.getResult().quantity)} m` : 'ERR').join(' / ')}</p></td>
						{!this.isReadOnly() && (
							<td className={hasError ? 'actions -haswarning' : 'actions'}>
								{hasError ? <span className="icon -warning">⚠︎</span> : null}
								<a className="icon -edit">Edit</a>
								<a className="icon -delete" onClick={this.onDeleteProduct.bind(this, someProduct)}>Delete</a>
							</td>
						)}
					</tr>
				)
			}
			case dt.ProductType.Blind: {
				const details = someProduct.details as dtb.Blind | undefined
				if (!details) {
					return
				}
				
				const fabric = (details.overview?.blindType === dtb.BlindType.Roller)
					? details.specifications?.rollerBlindFabricName
					: details.fabric && details.fabric.fabric ? details.fabric.name : undefined
				const quantitiesCalculation = pbf.calculateQuantities(details)
				const quantities = quantitiesCalculation.getResult()
				const blindWidth = details.measures && pbf.calculateWidth(details.measures).getResultOrUndefined()
				const blindPricing = pbf.calculateProductPricing(details, undefined).total
				const hasError = quantitiesCalculation.isErrored() || (blindPricing && blindPricing.isErrored())
				return (
					<tr key={index} onClick={this.onEditProduct.bind(this, someProduct)}>
						<th className="name" scope="row">{details.overview && details.overview.room}</th>
						<td className="type"><label className="label">Type:</label><p className="data">{titleCase((details.overview && details.overview.blindType) || '')}</p></td>
						<td className="window"><label className="label">Window:</label><p className="data">{details.overview && details.overview.window}</p></td>
						<td className="quantity"><label className="label">Blind Width:</label><p className="data">{blindWidth && `${formatNumber(blindWidth)} mm`}</p></td>
						<td className="fabric"><label className="label">Fabric:</label><p className="data">{fabric}</p></td>
						<td className="quantity"><label className="label">Fabric Qty:</label><p className="data">{quantities.fabric && quantities.fabric.quantity ? `${formatMillimetresToMetres(quantities.fabric.quantity)} m` : null}</p></td>
						{!this.isReadOnly() && (
							<td className={hasError ? 'actions -haswarning' : 'actions'}>
								{hasError ? <span className="icon -warning">⚠︎</span> : null}
								<a className="icon -edit">Edit</a>
								<a className="icon -delete" onClick={this.onDeleteProduct.bind(this, someProduct)}>Delete</a>
							</td>
						)}
					</tr>
				)
			}
			default:
				return null
		}
	}

	private onDeleteProduct = (product: dt.Product, evt: React.MouseEvent) => {
		evt.preventDefault()
		evt.stopPropagation()

		const title = df.productTitle(product)
		
		if (window.confirm(`Are you sure you want to DELETE ${title} from this project?`)) {
			this.props.onDeleteProduct(product.productId)
		}
	}

	private onUndeleteProduct = (product: dt.Product, evt: React.MouseEvent) => {
		evt.preventDefault()
		evt.stopPropagation()

		this.props.onUndeleteProduct(product.productId)
	}

	private onAddProduct = (productType: dt.ProductType, evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onNewProduct(productType)
	}

	private onEditProduct = (product: dt.Product, evt: React.MouseEvent) => {
		evt.preventDefault()
		evt.stopPropagation()
		this.props.onEditProduct(product)
	}

	private exportQuote = () => {
		createQuote(this.props.project, this.props.user).then(blob => {
			const options: SocialSharingOptions = {
				subject: 'Quote from Maqasa',
			}
			return f.share(options, blob, (this.props.project.name || 'Project') + ' Quote.xlsx')
		}).catch(error => {
			console.error(error)
			platform.alert(`Failed to export the quote.\n\n${error.message}`)
		})
	}

	private renderProjectCosts = () => {
		return this.renderProjectCostsDetail(this.controller.controller('costs'))
	}

	private renderProjectCostsDetail = (controller: Controller<dt.ProjectCostDetails | undefined>) => {
		const readOnly = this.isReadOnly()
		return (
			<>
				<ff.Heading>Project costs</ff.Heading>
				<div className="project-costs">
					<ff.Price title="Travel" prop="travel" project={this.props.project} controller={controller} readOnly={readOnly} />
					<ff.Price title="Consulting" prop="consulting" project={this.props.project} controller={controller} readOnly={readOnly} />
					<ff.Price title="Installation" prop="installation" project={this.props.project} controller={controller} readOnly={readOnly} />
					<ff.Price title="Additional charges" prop="additionalCharges" project={this.props.project} controller={controller} readOnly={readOnly} />
					<ff.Text title="Additional charges label" prop="additionalChargesLabel" maxLength={20} controller={controller} readOnly={readOnly} />
				</div>
			</>
		)
	}

	private renderPurchaseOrders = () => {
		return this.renderPurchaseOrdersDetail(this.controller.controller('purchaseOrders'))
	}

	private renderPurchaseOrdersDetail = (controller: Controller<dt.ProjectPurchaseOrders | undefined>) => {
		const pos = availablePurchaseOrders(this.props.project)
		return (
			<>
				<ff.Heading>Purchase orders</ff.Heading>
				{this.renderPurchaseOrdersSection('Fabric', pos.fabric, this.exportFabricOrder, controller.controller('fabric'))}
				{this.renderPurchaseOrdersSection('Workroom / Installer', pos.workroom, this.exportWorkroomOrder, controller.controller('workroom'))}
				{this.renderPurchaseOrdersSection('Hardware', pos.hardware, this.exportHardwareOrder, controller.controller('hardware'))}
			</>
		)
	}

	private renderPurchaseOrdersSection = (title: string, suppliers: AvailablePurchaseOrderSupplier[], exportFunction: (supplier: AvailablePurchaseOrderSupplier) => Promise<void>, controller: Controller<dt.ProjectPurchaseOrderTypeDetails | undefined>) => {
		if (!suppliers.length) {
			return null
		}
		return (
			<>
				<h3 className="title-small">{title}</h3>
				{suppliers.map((supplier) => this.renderPurchaseOrderSupplier(supplier, exportFunction, controller))}
			</>
		)
	}

	private renderPurchaseOrderSupplier = (supplier: AvailablePurchaseOrderSupplier, exportFunction: (supplier: PurchaseOrderSupplier) => Promise<void>, controller: Controller<dt.ProjectPurchaseOrderTypeDetails | undefined>) => {
		const supplierFormController = controller.controller(supplier.supplier)
		const supplierFormSnapshot = supplierFormController.snapshot()
		const supplierFormValue = supplierFormSnapshot.value || {}

		function doExport() {
			exportFunction({
				supplier: supplier.supplier,
				purchaseOrderNumber: supplierFormValue.purchaseOrderNumber,
			}).then(() => {
				supplierFormSnapshot.onChange({
					...(supplierFormSnapshot.value || {}),
					lastExported: new Date().toISOString(),
				})
			})
		}
		
		return (
			<FormRow key={supplier.supplier} title={supplier.supplier} type="text" fieldVariant={FormFieldVariant.WithButton}>
				<Input.String prop="purchaseOrderNumber" placeholder="P/O number" controller={supplierFormController} className="field" />
				<button type="button" onClick={doExport} className="button-link -small -secondary -action -icon -quote">Export</button>
				<br />
				{supplierFormValue.lastExported ? (
					<small className="note">Last exported <Moment to={supplierFormValue.lastExported} /></small>
				) : (
					<small className="note">Never exported</small>
				)}
			</FormRow>
		)
	}

	private exportFabricOrder = (supplier: PurchaseOrderSupplier) => {
		return createFabricOrderForm(this.props.project, supplier, this.props.user).then(blob => {
			const options: SocialSharingOptions = {
				subject: 'Purchase Order from Maqasa',
			}
			return f.share(options, blob, (this.props.project.name || 'Project') + ' Fabric Order.xlsx')
		}).catch(error => {
			console.error(error)
			platform.alert(`Failed to export the purchase order.\n\n${error}`)
		})
	}

	private exportWorkroomOrder = (supplier: PurchaseOrderSupplier) => {
		return createWorkroomOrderForm(this.props.project, supplier, this.props.user).then(blob => {
			const options: SocialSharingOptions = {
				subject: 'Purchase Order from Maqasa',
			}
			return f.share(options, blob, (this.props.project.name || 'Project') + ' Workroom Order.xlsx')
		}).catch(error => {
			console.error(error)
			platform.alert(`Failed to export the purchase order.\n\n${error}`)
		})
	}

	private exportHardwareOrder = (supplier: PurchaseOrderSupplier) => {
		return createHardwareOrderForm(this.props.project, supplier, this.props.user).then(blob => {
			const options: SocialSharingOptions = {
				subject: 'Purchase Order from Maqasa',
			}
			return f.share(options, blob, (this.props.project.name || 'Project') + ' Hardware Order.xlsx')
		}).catch(error => {
			console.error(error)
			platform.alert(`Failed to export the purchase order.\n\n${error}`)
		})
	}

	private onCopy = (project: dt.Project) => {
		if (!window.confirm(`Are you sure you want to copy items from ${project.name} to this project?`)) {
			return
		}

		const productsSnapshot = this.controller.snapshot('products')
		productsSnapshot.onChange({
			...productsSnapshot.value,
			...project.products,
		})
	}

	private onDuplicate = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.props.onDuplicateProject(this.props.project)
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}
