import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import { AvailablePurchaseOrders, addSupplier } from 'modules/project/purchaseorders'

export function addBlindsPurchaseOrders(project: dt.Project, products: DeepReadonly<dt.Product[]>, result: AvailablePurchaseOrders) {
	for (const product of products) {
		if (!product.details) {
			continue
		}

		const curtain = product.details as dtp.Blind
		if (curtain) {
			handleBlindDetails(curtain, result)
		}
	}
}

function handleBlindDetails(d: dtp.Blind, result: AvailablePurchaseOrders) {
	/* Fabric */
	if (d.fabric) {
		if (d.fabric.fabric && d.fabric.supplier) {
			addSupplier(result.fabric, d.fabric.supplier)
		}
		if (d.fabric.lined && d.fabric.liningOptions) {
			if (d.fabric.liningOptions.supplier) {
				addSupplier(result.fabric, d.fabric.liningOptions.supplier)
			}
			if (d.fabric.liningOptions.interlined && d.fabric.liningOptions.interliningOptions && d.fabric.liningOptions.interliningOptions.supplier) {
				addSupplier(result.fabric, d.fabric.liningOptions.interliningOptions.supplier)
			}
		}
		if (d.fabric.trim && d.fabric.trimOptions && d.fabric.trimOptions.supplier) {
			addSupplier(result.fabric, d.fabric.trimOptions.supplier)
		}
	}

	/* Workroom */
	if (d.specifications && d.specifications.workroomSupplier) {
		addSupplier(result.workroom, d.specifications.workroomSupplier)
	}

	/* Hardware */
	if (d.hardware) {
		if (d.hardware.automation && d.hardware.automationOptions && d.hardware.automationOptions.supplier) {
			addSupplier(result.hardware, d.hardware.automationOptions.supplier)
		}
	}
}
