import { RootStoreState } from 'modules/root'
import { UpdateProductPayload } from 'modules/database/actions'
import { createSelector } from 'reselect'

export const projectId = (state: RootStoreState) => state.product.projectId
export const currentProduct = (state: RootStoreState) => state.product.currentProduct

/**
 * Returns the current product details.
 */
export const currentProductFormValue = createSelector(
	currentProduct,
	(product) => {
		return product && product.details ? product.details : {}
	},
)

// export const currentRoom = (state: RootStoreState) => state.room.currentRoom
// export const currentProject = (state: RootStoreState) => state.project.currentProject

export const updateProductPayload = (state: RootStoreState): UpdateProductPayload => ({ projectId: state.product.projectId!, product: state.product.currentProduct! })
