import * as React from 'react'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CushionForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { wrapComponent, forComponentProps } from 'changeling'
import * as cf from '../functions'
import { FormFieldVariant } from 'modules/forms/components/FormRow'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CushionFormSpecifications extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('specifications')

	public render() {
		const measures = this.rootController.snapshot('measures').value
		const widthCalculation = measures && cf.calculateWidth(measures)
		const finishedLengthCalculation = measures && cf.calculateFinishedLength(measures)
		const depthCalculation = measures && cf.calculateDepth(this.props.value)

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{cf.cushionName(this.props.value)} specifications</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Cushion}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
					/>
				)}

				<ff.ComboBox title="Workroom supplier" prop="workroomSupplier" code={ComboBoxCode.WORKROOM_SUPPLIER} controller={this.controller} staticValues={['Prestige CMT']} />
				{widthCalculation && (
					<ff.Display title="Cushion width" value={widthCalculation.getResult()} units="mm" variant={FormFieldVariant.Short} calculation={widthCalculation} />
				)}
				{finishedLengthCalculation && (
					<ff.Display title="Cushion height" value={finishedLengthCalculation.getResult()} units="mm" variant={FormFieldVariant.Short} calculation={finishedLengthCalculation} />
				)}
				{depthCalculation && (
					<ff.Display title="Cushion depth" value={depthCalculation.getResult()} units="mm" variant={FormFieldVariant.Short} calculation={depthCalculation} />
				)}
				<ff.Price title="Making pricing" prop="makingPrice" project={this.props.project} controller={this.controller} />

				<ff.Toggle
					title="Top stitching?"
					controller={this.controller}
					prop="topStitched"
					options={([
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					])}
					showClear={true}
				/>

				<ff.TextArea title="Workroom instructions" prop="notes" controller={this.controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = (item.value as dt.Product).details as dtp.Cushion
		if (product && product.specifications) {
			this.controller.snapshot().onChange(product.specifications)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected cushion doesn’t have specifications.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(CushionFormSpecifications)
