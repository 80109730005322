import { createStore, compose, applyMiddleware, StoreEnhancer, Middleware, Store } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction'
import createSagaMiddleware from 'redux-saga'

import { offline } from '@redux-offline/redux-offline'
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults'
import { Config as OfflineConfig } from '@redux-offline/redux-offline/lib/types'

import rootSaga from './sagas'
import { setAuthConfig } from '../auth'
import platform from '../platform'

import { persistReadyAction } from './actions'
import { StoreState as RootStoreState, reducer } from './reducer'

import * as routing from 'onsenui-react-redux-navigator'
import { captureException } from '@sentry/core'

import NativeStorageEngine from './persist-cordova'
import IndexedDBStorage from './persist-localforage'

import { loggedOut } from 'modules/auth/actions'
import { NOT_LOGGED_IN_ERROR_MESSAGE } from './errors'

export type { StoreState as RootStoreState } from './reducer'

export const routingStoreStateSelector = (state: RootStoreState) => state.routing as routing.RoutingStoreState

export let store: Store<RootStoreState>

const PERSIST_KEY_PREFIX = 'react-redux-typescript-pattern'

export async function init(): Promise<void> {
	/**
	 * Create the redux-saga middleware.
	 */
	const sagaMiddleware = createSagaMiddleware({
		onError: (error, info) => {
			if (error.message === NOT_LOGGED_IN_ERROR_MESSAGE) {
				store.dispatch(loggedOut())
			} else {
				console.error(`Caught saga error: ${error}`, info)
				captureException(error)
				platform.alert(`An internal error has occurred: ${error.message}`)
			}
		},
	})

	/**
	 * Create the redux-offline configuration, based on the default configuration.
	 */
	const offlineConfig: OfflineConfig = {
		...defaultOfflineConfig,

		/**
		 * This callback occurs after redux-persist has rehydrated our Redux state.
		 */
		persistCallback: () => {
			/* Let our app know that the application state has been rehydrated and is ready to be used. */
			store.dispatch(persistReadyAction())
		},

		persistOptions: {
			blacklist: ['ready'],
			keyPrefix: PERSIST_KEY_PREFIX,
			debounce: 1000,
			storage: IndexedDBStorage('Maqasa'),
		},

		/**
		 * This function is used to handle actions tagged for redux-offline to handle.
		 */
		// effect: handleEffect,

		/**
		 * This function determines whether to discard a request, or to retry in, in the event
		 * of an error.
		 */
		// discard: handleDiscard,

		/* The commented out function below hard-codes the app to function as if it is offline. */
		// detectNetwork: function(callback: (online: boolean) => void) {
		// 	callback(false)
		// },
	}

	let middlewares: Middleware[] = [sagaMiddleware]
	middlewares = platform.customiseReduxMiddleware(middlewares)

	/**
	 * Enhancers for the store.
	 */
	const enhancers = compose(
		/* Add the redux-offline store enhancer */
		offline(offlineConfig),
		/* Add the middlewares */
		// eslint-disable-next-line prefer-spread
		applyMiddleware.apply(null, middlewares),
		/* Include the devtools. Comment this out if you don't want to use the dev tools. */
		devToolsEnhancer({}),
	) as StoreEnhancer<RootStoreState>

	/**
	 * Create the store. We do not include an initial state, as each of the module / duck
	 * reducers includes its own initial state.
	 */
	store = createStore(reducer, enhancers)

	/* Run the root saga */
	sagaMiddleware.run(rootSaga)

	/* Create the authentication config */
	setAuthConfig(platform.createAuthConfiguration())
}

window.addEventListener('unhandledrejection', function(ev) {
	const reason = `${ev.reason}`
	if (reason === 'popPage is already running.') {
		/* Ignoring known error from Onsen */
		ev.preventDefault()
		return
	}

	if (typeof ev.reason === 'object' && ev.reason instanceof Error) {
		if (ev.reason.message.indexOf('auth/network-request-failed') !== -1) {
			/* Ignore Firebase networking errors... they are transient network errors.
			   The message we've seen is:
			     FirebaseErrror: Firebase: A network AuthError (such as timeout, interrupted connection or unreachable host)
				 has occurred. (auth/network-request-failed)
			 */
			ev.preventDefault()
			return
		}
		
		platform.alert(`An unexpected error occurred, please send a screenshot of this error for support:\n\n${reason}\n\n${ev.reason.stack}`)
		ev.preventDefault()
		return
	}

	// if (reason.indexOf('FirebaseError') !== -1 && reason.indexOf('auth/network-request-failed')) {
	// 	/* This usually means the app is offline, and we'll detect that separately */
	// 	ev.preventDefault()
	// 	return
	// }

	platform.alert(`An unexpected error occurred, please send a screenshot of this error for support:\n\n${reason}`)
	
	ev.preventDefault()
})
