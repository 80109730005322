import * as React from 'react'
import { Page } from 'react-onsenui'
import * as ff from 'modules/forms'
import { forComponentState, Input } from 'changeling'
import produce from 'immer'
import { register, RegistrationForm } from '../functions'
import { allCountries } from 'country-region-data'
import { NavigatorToolbar, NavigationController } from 'onsenui-react-redux-navigator'
import platform from 'modules/platform'

interface OwnProps {
	onCancel: () => void
}

interface State extends RegistrationForm {
	confirmPassword: string
	terms?: boolean
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	emailAddress: '',
	password: '',
	confirmPassword: '',
}

export default class Register extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	private controller = forComponentState(this)

	public render() {
		const countryOptions: { label: string; value: string }[] = allCountries.map(country => {
			return {
				label: country[0],
				value: country[1],
			}
		})
		countryOptions.unshift({
			label: '',
			value: '',
		})

		return (
			<Page className="screen-modal">
				<div className="modal-content">
					<div className="width-limit -site -pageboundaries">
						<div className="body">
							<NavigatorToolbar 
								route={{ component: '' }} 
								title="Sign up for a free trial"
								renderToolbar={(navigationController: NavigationController, props: { title: string }) => {
									return (
										<header className="modal-header">
											<div className="contents">
												<a onClick={this.props.onCancel} className="back">Cancel</a>
												<h1 className="title-page">{props.title}</h1>
											</div>
										</header>
									)
								}}
							/>
							<div className="modal-body">
								<form className="form-layout">
									<ff.Text title="Given name" prop="givenName" controller={this.controller} />
									<ff.Text title="Family name" prop="familyName" controller={this.controller} />
									<ff.Text controller={this.controller} prop="emailAddress" title="Email address" type="email" />
									<ff.Text controller={this.controller} prop="password" title="Password" type="password" />
									<ff.Text controller={this.controller} prop="confirmPassword" title="Confirm password" type="password" />

									<ff.Text title="Organisation" prop="organisation" controller={this.controller} />
									<ff.FormRow type="select" title="Country">
										<Input.Select controller={this.controller} prop="country" options={countryOptions} className="select" />
									</ff.FormRow>
									
									<div className="row">
										<ul className="option-inputs">
											<li className="option -radio">
												<label className="label">
													<Input.Checkable controller={this.controller} prop="terms" type="checkbox" checkedValue={true} className="checkbox" /><span className="substitute"/>
													I have read and agree to the <a href="https://maqasa.app/terms" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Terms of Use</a> and 
													<a href="https://maqasa.app/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Privacy Policy</a>.		
												</label>
											</li>
										</ul>	
									</div>

									<div className="row -buttons">
										<div className="button-group">
											<button type="submit" onClick={this.onRegister} className="button-link -action">Sign up</button>
											<button type="button" onClick={this.onCancel} className="button-link -text">Cancel</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Page>
		)
	}

	private onRegister = async(evt: React.MouseEvent) => {
		evt.preventDefault()

		if (!this.state.emailAddress || this.state.emailAddress.length < 5) {
			platform.alert('Please enter your email address.')
			return
		}

		if (!this.state.password || this.state.password.length < 8) {
			platform.alert('Please make your password at least 8 characters long.')
			return
		}

		if (this.state.password !== this.state.confirmPassword) {
			platform.alert('Your password was not entered the same both times. Please re-enter your password.')
			this.setState(produce((draft: State) => {
				draft.password = ''
				draft.confirmPassword = ''
			}))
			return
		}

		if (!this.state.terms) {
			platform.alert('You must read and accept the Terms of Use and Privacy Policy before creating an account.')
			return
		}

		try {
			await register(this.state)
		} catch (error) {
			platform.alert(`Registration failed: ${(error as Error).message}`)
		}
	}

	private onCancel = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onCancel()
	}
}
