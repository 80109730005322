import { reducerWithInitialState } from 'typescript-fsa-reducers'

/* Import our module's actions */
import * as a from './actions'
import * as t from './types'
import * as f from './functions'
import produce from 'immer'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	userData: t.ComboBoxUserData
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	/* Note that we end each property with a comma, so we can add new properties without modifying this line
	(improve your git diffs!).
	 */
	userData: {
		comboBoxes: {},
	},
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

/** Reducer function for the exampleAction that returns a new state using an implicit return. */
reducer.case(a.useValue, (state, payload): StoreState => produce(state, draft => {
	f.useValue(draft.userData as t.ComboBoxUserData, payload.code, payload.value, payload.context)
}))

reducer.case(a.downloadedComboBoxData, (state, payload): StoreState => produce(state, draft => {
	draft.userData.comboBoxes[payload.code] = payload.data
}))
