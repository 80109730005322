import { take } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { readyAction } from 'modules/root/actions'
import { runQueuedUploads } from 'modules/storage'

/** The saga function for this module that is run from our root saga. */
export default function* storageSagas(): SagaIterator {
	yield take(readyAction)
	
	runQueuedUploads()
}
