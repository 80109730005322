import * as dt from 'modules/database/types'
import { FabricOrientation, PatternRepeatOptions } from 'modules/database/types/fabric'
import Calculation from 'modules/root/calculations'
import { calculatePricing } from 'modules/project/calculations'
import { roundUpWithLeeway, roundUpTo100, formatNumber } from 'modules/root/functions'
import { GenericPricing } from './pricing'

export interface FabricQuantities {
	quantity?: number
	drops?: number
	cutLength?: number
}

export interface GenericFabricOptions {
	width?: number
	orientation?: FabricOrientation
	fullness?: number
	patternRepeat?: boolean
	patternRepeatOptions?: PatternRepeatOptions
	price?: dt.Pricing
}

export interface GenericFabricTargetCalculations {
	width: Calculation<number>
	length: Calculation<number>
	hemAllowance: Calculation<number>
	sideHemAllowance: Calculation<number>
	quantity: Calculation<number>
}

export function calculateGenericFabricPricing(fabric: GenericFabricOptions, quantityCalculation: FabricQuantities, taxDetails: dt.TaxDetails | undefined, name: string, property?: string): Calculation<GenericPricing> {
	const result: GenericPricing = {}
	const calculation = new Calculation(result)

	const fabricPricing = fabric.price

	if (quantityCalculation.quantity === undefined) {
		return calculation
	}

	/* Fabric pricing is per metre, but quantity is in mm, so divide by 1000 */
	result.pricing = calculation.mergeErrors(calculatePricing(fabricPricing, taxDetails, quantityCalculation.quantity / 1000, name, property))
	return calculation
}

export function calculateGenericFabricQuantity(fabric: GenericFabricOptions, targetCalculations: GenericFabricTargetCalculations, name: string, property?: string): Calculation<FabricQuantities> {
	const result: FabricQuantities = {}
	const calculation = new Calculation(result)

	const targetWidth = calculation.mergeErrors(targetCalculations.width)
	const fullness = calculation.toNumber(fabric.fullness, `${name} fabric fullness`)
	const fabricWidth = calculation.toNumber(fabric.width, `${name} fabric width`)
	const orientation = fabric.orientation || FabricOrientation.Drop
	const targetLength = calculation.mergeErrors(targetCalculations.length)
	const hemAllowance = calculation.mergeErrors(targetCalculations.hemAllowance)
	const curtainWidthWithFullness = targetWidth * (1 + fullness / 100)
	const patternRepeat = calculation.toBoolean(fabric.patternRepeat, false)
	if (patternRepeat && !fabric.patternRepeatOptions) {
		return calculation.withErrorMessage('Pattern repeat chosen, but pattern repeat options are not set.')
	}

	const sideHemAllowance = calculation.mergeErrors(targetCalculations.sideHemAllowance)
	const quantity = calculation.mergeErrors(targetCalculations.quantity)

	switch (orientation) {
		case FabricOrientation.Drop: {
			if (!patternRepeat) {
				const drops = roundUpWithLeeway((curtainWidthWithFullness + sideHemAllowance) / fabricWidth)
				if (!isNaN(drops)) {
					result.drops = drops * quantity
				}
				const cutLength = roundUpTo100(targetLength + hemAllowance)
				if (!isNaN(cutLength)) {
					result.cutLength = cutLength
				}

				const fabricQuantity = drops * cutLength
				if (!isNaN(fabricQuantity)) {
					result.quantity = roundUpTo100(fabricQuantity) * quantity
				}
				return calculation
			} else {
				const patternRepeatWidth = calculation.toNumber(fabric.patternRepeatOptions!.horizontalRepeat, 'Fabric horizontal repeat', ['fabric.patternRepeatOptions.horizontalRepeat'], { defaultValue: 0, operation: '+' })
				const patternRepeatHeight = calculation.toNumber(fabric.patternRepeatOptions!.verticalRepeat, 'Fabric vertical repeat', ['fabric.patternRepeatOptions.verticalRepeat'], { defaultValue: 0, operation: '+' })
				if (!patternRepeatWidth && !patternRepeatHeight) {
					calculation.withErrorMessage('Pattern repeat chosen, but neither a horizontal nor vertical repeat was set.')
				}

				let drops: number
				if (patternRepeatWidth > 0) {
					const horizontalQuantity = Math.ceil((curtainWidthWithFullness + sideHemAllowance) / patternRepeatWidth)
					const curtainWidthWithRepeat = patternRepeatWidth * horizontalQuantity
					drops = roundUpWithLeeway(curtainWidthWithRepeat / fabricWidth)
				} else {
					drops = roundUpWithLeeway((curtainWidthWithFullness + sideHemAllowance) / fabricWidth)
				}

				if (!isNaN(drops)) {
					result.drops = drops * quantity
				}

				let cutLength: number
				if (patternRepeatHeight > 0) {
					const verticalQuantity = Math.ceil((targetLength + hemAllowance) / patternRepeatHeight)
					cutLength = roundUpTo100(patternRepeatHeight * verticalQuantity)
				} else {
					cutLength = roundUpTo100(targetLength + hemAllowance)
				}
				
				if (!isNaN(cutLength)) {
					result.cutLength = cutLength
				}

				const fabricQuantity = drops * cutLength + patternRepeatHeight * (drops >= 3 && drops % 2 === 1 ? 1 : 0) /* if the total Qty of Drops is an odd number and >= 3, then add 1 vertical pattern repeat. */
				if (!isNaN(fabricQuantity)) {
					result.quantity = roundUpTo100(fabricQuantity) * quantity
				}
				return calculation
			}
		}
		case FabricOrientation.Continuous: {
			if (targetLength > fabricWidth - hemAllowance) {
				calculation.withErrorMessage(
					`The ${name} fabric width is not sufficient to run continuous. ${targetLength}mm is required but the fabric width (${fabricWidth}mm) less the hem allowance (${hemAllowance}mm) is only ${fabricWidth - hemAllowance}mm. Check fabric width and either change to drops or reduce hem allowance to fit.`,
					property ? [`${property}.width`] : undefined,
				)
			}
			result.drops = quantity
			let cutLength = roundUpTo100(curtainWidthWithFullness + sideHemAllowance) /* Allow for side hems */

			if (patternRepeat) {
				const patternRepeatWidth = calculation.toNumber(fabric.patternRepeatOptions!.horizontalRepeat, 'Fabric horizontal repeat', ['fabric.patternRepeatOptions!.horizontalRepeat'], { defaultValue: 0, operation: '+' })
				const patternRepeatHeight = calculation.toNumber(fabric.patternRepeatOptions!.verticalRepeat, 'Fabric vertical repeat', ['fabric.patternRepeatOptions!.verticalRepeat'], { defaultValue: 0, operation: '+' })
				if (!patternRepeatWidth && !patternRepeatHeight) {
					calculation.withErrorMessage('Pattern repeat chosen, but neither a horizontal nor vertical repeat was set.')
				}

				if (patternRepeatHeight) {
					cutLength += patternRepeatHeight
				}			
			}
			
			if (!isNaN(cutLength)) {
				result.cutLength = cutLength * quantity
				result.quantity = roundUpTo100(cutLength * quantity)
			}
			
			return calculation
		}
		case FabricOrientation.Tessellate: {
			const targetWidthPlusHem = targetWidth + sideHemAllowance
			const targetLengthPlusHem = targetLength + hemAllowance

			const widthOrientationCols = Math.floor(fabricWidth / targetWidthPlusHem)
			const heightOrientationCols = Math.floor(fabricWidth / targetLengthPlusHem)

			if (widthOrientationCols === 0 && heightOrientationCols === 0) {
				return calculation.withErrorMessage(`Fabric is too narrow. Requires at least ${formatNumber(Math.min(targetWidthPlusHem, targetLengthPlusHem))} mm.`)
			}

			const widthOrientationRowLength = targetLengthPlusHem
			const heightOrientationRowLength = targetWidthPlusHem

			const widthOrientationCutLength = Math.ceil(quantity / widthOrientationCols) * widthOrientationRowLength
			const heightOrientationCutLength = Math.ceil(quantity / heightOrientationCols) * heightOrientationRowLength

			result.drops = 1
			result.cutLength = Math.min(widthOrientationCutLength, heightOrientationCutLength)
			result.quantity = roundUpTo100(result.cutLength)
			return calculation
		}
		case FabricOrientation.TesellateHorizontalOnly: {
			const targetWidthPlusHem = targetWidth + sideHemAllowance
			const targetLengthPlusHem = targetLength + hemAllowance

			const widthOrientationCols = Math.floor(fabricWidth / targetWidthPlusHem)

			if (widthOrientationCols === 0) {
				return calculation.withErrorMessage(`Fabric is too narrow. Requires at least ${formatNumber(targetWidthPlusHem)} mm.`)
			}

			const widthOrientationRowLength = targetLengthPlusHem
			const widthOrientationCutLength = Math.ceil(quantity / widthOrientationCols) * widthOrientationRowLength

			result.drops = 1
			result.cutLength = widthOrientationCutLength
			result.quantity = roundUpTo100(result.cutLength)
			return calculation
		}
		case FabricOrientation.Lineal: {
			const targetWidthPlusHem = targetWidth + sideHemAllowance
			const targetLengthPlusHem = (targetLength + hemAllowance) * quantity

			const rows = Math.ceil(targetLengthPlusHem / fabricWidth)
			const rowLength = targetWidthPlusHem

			result.drops = 1
			result.cutLength = rowLength * rows
			result.quantity = roundUpTo100(result.cutLength)
			return calculation
		}
		default:
			return calculation.withErrorMessage(`Unsupported fabric orientation: ${orientation}`)
	}
}

export interface FabricAndQuantity {
	supplier?: string
	collection?: string
	name?: string
	quantities?: Calculation<FabricQuantities>
}

/** Returns an array of distinct fabrics and their summed quantities */
export function distinctFabricQuantities(fabrics: FabricAndQuantity[]) {
	const result: FabricAndQuantity[] = []
	OUTER: for (const newFabric of fabrics) {
		for (const existingFabric of result) {
			if (newFabric.supplier === existingFabric.supplier && newFabric.collection === existingFabric.collection && newFabric.name === existingFabric.name) {
				if (newFabric.quantities) {
					if (existingFabric.quantities) {
						existingFabric.quantities = sumFabricQuantities(existingFabric.quantities, newFabric.quantities)
					} else {
						existingFabric.quantities = newFabric.quantities
					}
				}
				continue OUTER
			}
		}

		result.push({
			...newFabric,
		})
	}
	return result
}

export function sumFabricQuantities(a: Calculation<FabricQuantities> | undefined, b: Calculation<FabricQuantities> | undefined): Calculation<FabricQuantities> | undefined {
	if (a === undefined) {
		return b
	}
	const aresult = a.getResult()
	if (b === undefined) {
		return a
	}
	const bresult = b.getResult()
	const result = new Calculation<FabricQuantities>({
		cutLength: (aresult.cutLength ? aresult.cutLength : 0) + (bresult.cutLength ? bresult.cutLength : 0),
		drops: (aresult.drops ? aresult.drops : 0) + (bresult.drops ? bresult.drops : 0),
		quantity: (aresult.quantity ? aresult.quantity : 0) + (bresult.quantity ? bresult.quantity : 0),
	})
	
	result.withErrors(a)
	result.withErrors(b)
	return result
}
