import * as React from 'react'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './BlindForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { wrapComponent, forComponentProps, Controller } from 'changeling'
import { blindName } from '../functions'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class BlindFormHardware extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('hardware')

	public render() {
		const hardware = this.props.value.hardware || {}

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{blindName(this.props.value)} hardware</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Blind}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
					/>
				)}

				<ff.Heading>Automation</ff.Heading>
				<ff.Toggle
					title="Automation required?"
					controller={this.controller}
					prop="automation"
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]}
					showClear={true}
				/>
				{hardware.automation === true && this.renderAutomationOptions(this.controller.controller('automationOptions'))}
			</>
		)
	}
	
	private renderAutomationOptions = (controller: Controller<dtp.AutomationOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" code={ComboBoxCode.HARDWARE_SUPPLIER} controller={controller} prop="supplier" />
				<ff.ComboBox title="Motor Brand" code={ComboBoxCode.AUTOMATION_MOTOR_BRAND} controller={controller} prop="motorBrand" />
				<ff.Toggle
					prop="wiredSide" 
					title="Wiring" 
					controller={controller}
					showClear={true}
					options={[
						{ label: 'Left', value: 'left' },
						{ label: 'Right', value: 'right' },
					]}
				/>
				<ff.Toggle 
					prop="hardWired" 
					title="" 
					controller={controller}
					options={[
						{ label: 'Hard-wired', value: true },
						{ label: 'Wireless', value: false },
					]}
				/>
				<ff.Toggle 
					prop="remotes" 
					title="Remotes" 
					controller={controller}
					options={[
						{ label: 'Remotes', value: true },
						{ label: 'No remotes', value: false },
					]}
				/>

				{controller.snapshot('remotes').value === true && (
					<>
						<ff.Numeric title="Quantity of remotes" prop="numberOfRemotes" units="remotes" controller={controller} />
						<ff.Numeric title="Number of remote channels" prop="numberOfRemoteChannels" units="channels" controller={controller} />
						<ff.ComboBox title="Colour of remotes" prop="colourOfRemotes" code={ComboBoxCode.COLOUR} comboContext="control" controller={controller} />
						<ff.ComboBox title="Remote model" prop="remoteModel" code={ComboBoxCode.REMOTE_MODEL} controller={controller} />
					</>
				)}

				<ff.Price title="Pricing" prop="price" project={this.props.project} controller={controller} />

				<ff.TextArea title="Electrical contractor notes" prop="electricalContractor" controller={controller} />
				<ff.TextArea title="Builders notes" prop="builder" controller={controller} />
				<ff.TextArea title="Automation notes" prop="notes" controller={controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = (item.value as dt.Product).details as dtp.Blind
		if (product && product.hardware) {
			this.controller.snapshot().onChange(product.hardware)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected blind doesn’t have hardware.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(BlindFormHardware)
