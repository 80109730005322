/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/CopyFromProject'
import * as ff from 'modules/forms/index'
import * as dt from 'modules/database/types'
import { compareProjectsByName } from '../functions'

/**
 * Interface for private internal component state.
 */
interface State {
	selectedValue: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	selectedValue: '',
}

interface MyOwnProps {
	copyButtonLabel?: string
	onCopy: (project: dt.Project) => void
	onCancel: () => void
}

export default class CopyFromProject extends React.Component<OwnProps & Props & Actions & MyOwnProps, State> {

	public state = INITIAL_STATE

	public render() {
		const sortedProjects = [...this.props.projects].sort(compareProjectsByName)
		return (
			<div className="copy-form">
				<ff.Heading>Copy from</ff.Heading>
				<div className="form-input -select">
					<select className="select" onChange={this.onSelectChange} value={this.state.selectedValue}>
						<option />
						{sortedProjects.map((p, index) => (
							<option key={index} value={p.projectId}>{p.name}</option>
						))}
					</select>
				</div>
				<div className="button-group">
					<button type="button" onClick={this.onCopy} disabled={!this.state.selectedValue} className="button-link -secondary -small">{this.props.copyButtonLabel || 'Copy'}</button>
					<button type="button" onClick={this.onCancel} className="button-link -small -text">Cancel</button>
				</div>
			</div>
		)
	}

	private onSelectChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedIndex = evt.target.selectedIndex
		const selectedValue = evt.target.options[selectedIndex].value
		this.setState({ selectedValue })
	}

	private onCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.state.selectedValue) {
			const selectedProject = this.props.projects.find(p => p.projectId === this.state.selectedValue)
			if (selectedProject) {
				this.props.onCopy(selectedProject)
			}
		}
	}

	private onCancel = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onCancel()
	}

}
