import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/CurtainForm'
import { RouteProps } from 'onsenui-react-redux-navigator'

import ProductFormOverview from './CurtainFormOverview'
import ProductFormFabric from './CurtainFormFabric'
import ProductFormMeasures from './CurtainFormMeasures'
import ProductFormSpecifications from './CurtainFormSpecifications'
import ProductFormHardware from './CurtainFormHardware'
import ProductFormInstallation from './CurtainFormInstallation'
import ProductFormCalculations from './CurtainFormCalculations'

import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as f from '../functions'
import $ from 'jquery'
import { Snapshot, forComponentProps } from 'changeling'

enum NavigateOption {
	Forward,
	Backward,
}

export interface SubFormProps extends Snapshot<dtp.CurtainDetail> {
	curtainName: string
	rootFormValue: dtp.Curtain
	project: dt.Project
	productId: dt.ProductID
	path: string[]
}

/**
 * Interface for private internal component state.
 */

enum FormPart {
	Overview = 'Overview',
	Fabric1 = 'Fabric1',
	Measures1 = 'Measures1',
	Specifications1 = 'Specifications1',
	Hardware1 = 'Hardware1',
	Installation1 = 'Installation1',
	Calculations1 = 'Calculations1',
	Fabric2 = 'Fabric2',
	Measures2 = 'Measures2',
	Specifications2 = 'Specifications2',
	Hardware2 = 'Hardware2',
	Installation2 = 'Installation2',
	Calculations2 = 'Calculations2',
}

const FormPartsSingle = [FormPart.Overview, FormPart.Measures1, FormPart.Fabric1, FormPart.Specifications1, FormPart.Hardware1, 
	FormPart.Installation1, FormPart.Calculations1]
const FormPartsDouble = [FormPart.Overview, FormPart.Measures1, FormPart.Fabric1, FormPart.Specifications1, FormPart.Hardware1, 
	FormPart.Installation1, FormPart.Calculations1, FormPart.Measures2, FormPart.Fabric2, FormPart.Specifications2, FormPart.Hardware2, 
	FormPart.Installation2, FormPart.Calculations2]

interface State {
	menuOpen: boolean
}

const INITIAL_STATE: State = {
	menuOpen: false,
}

export default class CurtainForm extends React.Component<Props & Actions & OwnProps & RouteProps, State> {

	public state = INITIAL_STATE
	
	private controller = forComponentProps(this, 'formValue', 'onChangeFormValue')

	public render() {
		return (
			<div className="site-body -split">
				<div className="width-limit -site">
					{this.renderSidebar()}
					{this.renderMobileNav()}
				
					<div className="project-body">
						<div className="content">
							
							<form className="form-layout" onSubmit={this.onSubmit}>
								{this.renderForm()}
							</form>
							<br />
						</div>
					</div>
				</div>
			</div>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<Props & Actions & OwnProps & RouteProps>, nextState: State) {
		if (this.props.project !== nextProps.project) {
			return true
		}
		if (this.props.productId !== nextProps.productId) {
			return true
		}
		if (this.props.currentForm !== nextProps.currentForm) {
			return true
		}
		if (this.props.formValue !== nextProps.formValue) {
			return true
		}
		if (this.state !== nextState) {
			return true
		}
		return true
	}

	public componentDidUpdate() {
		const toolbar = $('ons-toolbar')
		const mobileNav = $('.mobile-nav')
		if (mobileNav) {
			mobileNav.css('top', toolbar.outerHeight() + 'px')
		}
	}

	public componentDidMount() {
		const toolbar = $('ons-toolbar')
		const mobileNav = $('.mobile-nav')
		if (mobileNav) {
			mobileNav.css('top', toolbar.outerHeight() + 'px')
		}
	}

	private renderSidebar = () => {
		const curtainSet = f.curtainSet(this.props.formValue)
		return (
			<aside className={'project-sidebar mobile-hide' + (this.state.menuOpen ? ' menu-open' : '')}>
				<div className="content">
					<nav className="side-nav">
						<ul className="navitems">
							{this.renderNavItem(FormPart.Overview, 'Overview')}
							<li className="navitem -subsection"><strong className="link">Curtain 1</strong>
								<ul className="subnavitems">
									{this.renderNavItem(FormPart.Measures1, 'Measures')}
									{this.renderNavItem(FormPart.Fabric1, 'Fabric & lining & trim')}
									{this.renderNavItem(FormPart.Specifications1, 'Specifications')}
									{this.renderNavItem(FormPart.Hardware1, 'Hardware')}
									{this.renderNavItem(FormPart.Installation1, 'Installation')}
									{this.renderNavItem(FormPart.Calculations1, 'Calculations')}
								</ul>
							</li>
							{curtainSet === dtp.CurtainSet.Double && (
								<>
									<li className="navitem -subsection"><strong className="link">Curtain 2</strong>
										<ul className="subnavitems">
											{this.renderNavItem(FormPart.Measures2, 'Measures')}
											{this.renderNavItem(FormPart.Fabric2, 'Fabric & lining & trim')}
											{this.renderNavItem(FormPart.Specifications2, 'Specifications')}
											{this.renderNavItem(FormPart.Hardware2, 'Hardware')}
											{this.renderNavItem(FormPart.Installation2, 'Installation')}
											{this.renderNavItem(FormPart.Calculations2, 'Calculations')}
										</ul>
									</li>
								</>
							)}
							<li className="navitem -subsection"><strong className="link"><a onClick={this.onNewProduct}>+ New curtain</a></strong></li>
						</ul>
					</nav>
				</div>
			</aside>
		)
	}

	private renderMobileNav = () => {
		return (
			<div className={'mobile-nav' + (this.state.menuOpen ? ' menu-open' : '')}>
				<label className={'prev label' + (this.leftNavText() === '' ? ' -empty' : '')} onClick={this.onNavigateLeft}>{this.leftNavText()}</label>
				<div className="site-menu-hamburgers main" onClick={this.toggleMenu}>
					<label className="menuburger js-menuburger" htmlFor="site-drawer-state" aria-hidden="true">
						<span className="ingredient -bun -top">
							<span className="bar"/>
						</span>
						<span className="ingredient -patty">
							<span className="bar"/>
						</span>
						<span className="ingredient -bun -bottom">
							<span className="bar"/>
						</span>
					</label>
				</div>
				<label className={'next label' + (this.rightNavText() === '' ? ' -empty' : '')} onClick={this.onNavigateRight}>{this.rightNavText()}</label>
			</div>
		)
	}

	private currentForm = () => {
		return this.props.currentForm as FormPart || FormPart.Overview
	}

	private renderForm = () => {
		switch (this.currentForm()) {
			case FormPart.Overview:
				return (
					<ProductFormOverview project={this.props.project} productId={this.props.productId} path={['currentProduct']} controller={this.controller} prop="this" />
				)
			case FormPart.Fabric1:
				return <ProductFormFabric curtainName="Curtain 1" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain1']} controller={this.controller.controller('curtain1')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Measures1:
				return <ProductFormMeasures curtainName="Curtain 1" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain1']} controller={this.controller.controller('curtain1')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Specifications1:
				return <ProductFormSpecifications curtainName="Curtain 1" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain1']} controller={this.controller.controller('curtain1')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Hardware1:
				return <ProductFormHardware curtainName="Curtain 1" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain1']} controller={this.controller.controller('curtain1')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Installation1:
				return <ProductFormInstallation curtainName="Curtain 1" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain1']} controller={this.controller.controller('curtain1')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Calculations1:
				return <ProductFormCalculations curtainName="Curtain 1" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain1']} controller={this.controller.controller('curtain1')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Fabric2:
				return <ProductFormFabric curtainName="Curtain 2" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain2']} controller={this.controller.controller('curtain2')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Measures2:
				return <ProductFormMeasures curtainName="Curtain 2" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain2']} controller={this.controller.controller('curtain2')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Specifications2:
				return <ProductFormSpecifications curtainName="Curtain 2" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain2']} controller={this.controller.controller('curtain2')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Hardware2:
				return <ProductFormHardware curtainName="Curtain 2" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain2']} controller={this.controller.controller('curtain2')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Installation2:
				return <ProductFormInstallation curtainName="Curtain 2" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain2']} controller={this.controller.controller('curtain2')} prop="this" rootFormValue={this.props.formValue} />
			case FormPart.Calculations2:
				return <ProductFormCalculations curtainName="Curtain 2" project={this.props.project} productId={this.props.productId} path={['currentProduct', 'curtain2']} controller={this.controller.controller('curtain2')} prop="this" rootFormValue={this.props.formValue} />
			default:
				return (
					<p>Unsupported form: {this.currentForm()}</p>
				)
		}
	}

	private renderNavItem = (form: FormPart, title: string) => {
		return (
			<li className={'navitem' + (this.currentForm() === form ? ' -selected' : '')}><a onClick={this.onChooseNavItem.bind(this, form)} className="link">{title}</a></li>
		)
	}

	private onChooseNavItem = (form: FormPart) => {
		this.props.onChooseProductForm(form)
		this.setState({ menuOpen: false })
	}

	private toggleMenu = () => {
		this.setState({
			menuOpen: !this.state.menuOpen,
		})
	}

	private onSubmit = (evt: React.FormEvent) => {
		evt.preventDefault()
	}

	private navigate = (option: NavigateOption) => {
		const curtainSetDouble = f.curtainSet(this.props.formValue) === dtp.CurtainSet.Double ? true : false
		const formEnum = curtainSetDouble ? FormPartsDouble : FormPartsSingle

		const current = formEnum.indexOf(this.currentForm())
		switch (option) {
			case NavigateOption.Backward:
				if (current > 0) {
					this.onChooseNavItem(formEnum[current - 1])
				}
				break
			case NavigateOption.Forward:
				if (current < formEnum.length - 1) {
					this.onChooseNavItem(formEnum[current + 1])
				}
				break
			default:
				break
		}
	}

	private onNavigateLeft = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.navigate(NavigateOption.Backward)
	}

	private onNavigateRight = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.navigate(NavigateOption.Forward)
	}

	private leftNavText = (): string => {
		const curtainSetDouble = f.curtainSet(this.props.formValue) === dtp.CurtainSet.Double ? true : false
		const formEnum = curtainSetDouble ? FormPartsDouble : FormPartsSingle
		let result = (formEnum.indexOf(this.currentForm()) - 1 >= 0) ? formEnum[formEnum.indexOf(this.currentForm()) - 1].toString() : ''

		if (result.substr(result.length - 1) === '1' || result.substr(result.length - 1) === '2') {
			result = result.substr(0, result.length - 1)
		}

		return result
	}

	private rightNavText = (): string => {
		const curtainSetDouble = f.curtainSet(this.props.formValue) === dtp.CurtainSet.Double ? true : false
		const formEnum = curtainSetDouble ? FormPartsDouble : FormPartsSingle
		let result = ((formEnum.indexOf(this.currentForm()) + 1) < formEnum.length) ? formEnum[formEnum.indexOf(this.currentForm()) + 1].toString() : ''

		if (result.substr(result.length - 1) === '1' || result.substr(result.length - 1) === '2') {
			result = result.substr(0, result.length - 1)
		}

		return result
	}

	private onNewProduct = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.props.onNewProduct(this.props.project.projectId, dt.ProductType.Curtain)
	}
}
