/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow from './FormRow'
import * as _ from 'lodash'
import { Input, Snapshot, forComponentProps, wrapComponent } from 'changeling'

interface OwnProps<T> extends Snapshot<T | undefined> {
	title: string
	options: DropdownOption<T>[]
	inline?: boolean
}

export interface DropdownOption<T> {
	label: string
	value: T
}

class Radios<T> extends React.Component<OwnProps<T>> {

	private static COUNTER = 0

	private controller = forComponentProps(this)
	private counter: number

	public constructor(props: OwnProps<T>) {
		super(props)

		this.counter = ++Radios.COUNTER
	}

	public render() {
		const { title, options, inline } = this.props
		return (
			<FormRow title={title} type="options">
				<ul className={`option-inputs${inline === undefined || inline === true ? ' -inline' : ''}`}>
					{options.map((option, index) => (
						<li className="option -radio" key={index}>
							<label className="label">
								<Input.Checkable
									type="radio"
									name={`radio-${this.counter}`}
									className="radio"
									checkedValue={option.value}
									controller={this.controller}
									prop="this"
								/>
								<span className="substitute"/>
								{option.label}
							</label>
						</li>
					))}
				</ul>
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps<T>>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		if (!_.isEqual(this.props.options, nextProps.options)) {
			return true
		}
		return false
	}

}

export default wrapComponent(Radios)
