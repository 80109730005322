import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import * as cf from './functions'
import { QuoteSummary } from 'modules/project/quote'
import { titleCase } from 'title-case'
import { toNumber } from 'modules/root/functions'
import { exportQuoteSheet, HeaderRow } from '../common/quotes'

interface Row {
	room?: string
	cushionType?: string
	quantity?: number
	fabric?: string
	colour?: string
	sellPrice?: number
	size?: string
	fill?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.room,
		row.cushionType,
		row.quantity,
		row.fabric,
		row.fill,
		row.colour,
		row.size,
		row.sellPrice,
	]
}

export default function exportQuote(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, summary: QuoteSummary) {
	const header: HeaderRow<Row> = {
		room: 'Room',
		cushionType: 'Cushion Type',
		quantity: 'Quantity ()',
		fabric: 'Fabric',
		colour: 'Colour',
		sellPrice: 'Price ($)',
		size: 'Size (mm)',
		fill: 'Fill',
	}

	exportQuoteSheet(sheet, products, project.taxDetails, summary, {
		header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(p: dtp.Cushion, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary): Row[] {
	const pricing = cf.calculateProductPricing(p, taxDetails)
	if (pricing.total && pricing.total.isErrored()) {
		throw new Error(pricing.total.formatErrorsAsText())
	}

	const totalPricing = pricing.total && pricing.total.getResult()

	const data: Row = {}
	data.room = p.overview && p.overview.room
	data.cushionType = titleCase((p.overview && p.overview.cushionType) || '')
	data.quantity = p.overview ? toNumber(p.overview.quantity) : undefined
	data.fabric = p.fabric && p.fabric.name
	data.fill = p.fabric && p.fabric.fillOptions && p.fabric.fillOptions.type
	data.colour = p.fabric && p.fabric.colour
	data.sellPrice = totalPricing && totalPricing.pricing ? totalPricing.pricing.rrp : undefined
	
	if (p.measures) {
		data.size = p.overview && p.overview.cushionType === dtp.CushionType.Squab ? `${p.measures.width}×${p.measures.height}×${p.measures.depth}` : `${p.measures.width}×${p.measures.height}`
	}

	if (totalPricing && totalPricing.pricing) {
		summary.totalPricing.cost += totalPricing.pricing.cost
		summary.totalPricing.rrp += totalPricing.pricing.rrp
	}

	return [data]
}
