import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import { AvailablePurchaseOrders, addSupplier } from 'modules/project/purchaseorders'

export function addCushionsPurchaseOrders(project: dt.Project, products: DeepReadonly<dt.Product[]>, result: AvailablePurchaseOrders) {
	for (const product of products) {
		if (!product.details) {
			continue
		}

		const curtain = product.details as dtp.Cushion
		if (curtain) {
			handleCushionDetails(curtain, result)
		}
	}
}

function handleCushionDetails(d: dtp.Cushion, result: AvailablePurchaseOrders) {
	/* Fabric */
	if (d.fabric) {
		if (d.fabric.fabric && d.fabric.supplier) {
			addSupplier(result.fabric, d.fabric.supplier)
		}
		if (d.fabric.backFabricDifferent && d.fabric.backFabricOptions && d.fabric.backFabricOptions.supplier) {
			addSupplier(result.fabric, d.fabric.backFabricOptions.supplier)
		}
		if (d.fabric.fill && d.fabric.fillOptions && d.fabric.fillOptions.supplier) {
			addSupplier(result.fabric, d.fabric.fillOptions.supplier)
		}
		if (d.fabric.piping && d.fabric.pipingOptions && d.fabric.pipingOptions.supplier) {
			addSupplier(result.fabric, d.fabric.pipingOptions.supplier)
		}
		if (d.fabric.trim && d.fabric.trimOptions && d.fabric.trimOptions.supplier) {
			addSupplier(result.fabric, d.fabric.trimOptions.supplier)
		}
		if (d.fabric.flange && d.fabric.flangeOptions && d.fabric.flangeOptions.supplier) {
			addSupplier(result.fabric, d.fabric.flangeOptions.supplier)
		}
	}

	/* Workroom */
	if (d.specifications && d.specifications.workroomSupplier) {
		addSupplier(result.workroom, d.specifications.workroomSupplier)
	}
}
