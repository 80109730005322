export type ProjectID = string
export type ProductID = string

export interface Project {
	name?: string
	address: string
	propertyType: PropertyType
	contactName?: string
	notes?: string
	phone?: string
	mobile?: string
	email?: string
	photo?: AnnotatedImage
	projectId: ProjectID
	client?: Client
	taxDetails?: TaxDetails
	products: ProjectProducts
	costs?: ProjectCostDetails
	purchaseOrders?: ProjectPurchaseOrders
	whenCreated: string
	whenUpdated: string
	whenArchived?: string
	whenDownloaded?: string
	whenUploaded?: string
	schemaVersion: number

	deleted?: boolean
	whenDeleted?: string
}

export const CurrentProjectSchemaVersion = 1

export enum PropertyType {
	House = 'house',
}

export interface Client {
	name: string
	address?: string
	phone?: string
	mobile?: string
	email?: string
}

export interface TaxDetails {
	taxRate: number
	taxName: string
}

export interface ProjectCostDetails {
	travel?: Pricing
	consulting?: Pricing
	installation?: Pricing
	additionalCharges?: Pricing
	additionalChargesLabel?: string
}

export interface ProjectPurchaseOrders {
	fabric?: ProjectPurchaseOrderTypeDetails
	workroom?: ProjectPurchaseOrderTypeDetails
	hardware?: ProjectPurchaseOrderTypeDetails
}

export interface ProjectPurchaseOrderTypeDetails {
	[supplier: string]: ProjectPurchaseOrderDetails | undefined
}

export interface ProjectPurchaseOrderDetails {
	purchaseOrderNumber?: string
	lastExported?: string
}

export interface ProjectProducts {
	[productId: string]: Product | undefined
}

export enum ProductType {
	Curtain = 'Curtain',
	Cushion = 'Cushion',
	Blind = 'Blind',
}

export const AllProductTypes = [ProductType.Curtain, ProductType.Blind, ProductType.Cushion]

export interface ProductTypeInfo {
	name: string
	collectiveName: string
}

export interface Product {
	productId: ProductID
	type: ProductType
	details?: ProductDetails
	whenCreated: string
	whenUpdated: string
	whenDownloaded?: string
	whenUploaded?: string
	schemaVersion: number

	deleted?: boolean
	whenDeleted?: string
}

export const CurrentProductSchemaVersion = 4

export interface ProductDetails {

}

/** Stores the data for an image that can be annotated. */
export interface AnnotatedImage {
	imagePath?: string
	annotatedImagePath?: string
	annotationsPath?: string
}

export interface Pricing {
	mode?: PricingMode
	cost?: number
	rrp?: number
	markup?: number
}

export enum PricingMode {
	Cost = 'cost',
	RRP = 'rrp',
	NA = 'na',
}
