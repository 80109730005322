import { AnnotatedImage, Pricing } from 'modules/database/types'
import { CommonOverview, CommonProductDetails } from './common'
import { FabricOrientation, PatternRepeatOptions } from './fabric'

export interface Blind extends CommonProductDetails {
	overview?: BlindOverview
	measures?: BlindMeasures
	fabric?: BlindFabric
	specifications?: BlindSpecifications
	hardware?: BlindHardware
	installation?: BlindInstallation
}

export interface BlindOverview extends CommonOverview {
	blindType?: BlindType
}

export enum BlindType {
	Roman = 'roman',
	Roller = 'roller',
	Venetian = 'venetian',
}

export interface BlindMeasures {
	widthCalculationMethod?: BlindWidthCalculationMethod
	archToArchWidthInside?: number
	archToArchWidthOutside?: number
	returnSizeLeft?: number
	returnSizeRight?: number
	returnSizesEqual?: boolean
	useCustomWidth?: boolean
	customWidth?: number

	finishedLengthCalculationMethod?: BlindFinishedLengthCalculationMethod
	archToArchHeightInside?: number
	archToArchHeightOutside?: number
	aboveArch?: number
	belowArch?: number
	stackHeight?: number
	useCustomHeight?: boolean
	customHeight?: number
	
	wallToWall?: number
	ceilingToFloor?: number
	archToArchWidth?: number
	archToArchHeight?: number
	wallToArchLeft?: number
	wallToArchRight?: number
	ceilingToArch?: number
	floorToArch?: number
	sillToFloor?: number
}

export interface BlindFabric {
	fabric?: boolean
	supplier?: string
	collection?: string
	name?: string
	colour?: string
	photo?: AnnotatedImage
	width?: number
	orientation?: FabricOrientation
	price?: Pricing
	patternRepeat?: boolean
	patternRepeatOptions?: PatternRepeatOptions
	lined?: boolean
	liningOptions?: LiningOptions
	trim?: boolean
	trimOptions?: TrimOptions
}

export interface BlindSpecifications {
	workroomSupplier?: string
	headerType?: BlindHeaderType
	fixingDetails?: string
	hemAllowance?: number
	controlPosition?: BlindControlPosition
	controlType?: BlindControlType
	controlLength?: number
	controlColour?: string
	makingPrice?: Pricing
	notes?: string

	rollerBlindFabricCollection?: string
	rollerBlindFabricName?: string
	rollerBlindFabricColour?: string
	rollerBlindFabricPhoto?: AnnotatedImage
	rollerBlindSetNo?: string
	rollerBlindBottomRailShape?: string
	rollerBlindBottomRailColour?: string

	venetianMaterial?: VenetianMaterial
	venetianSize?: number
	venetianColour?: string
	
	chainColour?: string
	chainTensioner?: boolean
	chainTensionerOptions?: BlindHardwareOptions
	fascia?: boolean
	fasciaOptions?: BlindHardwareOptions
	sillClips?: boolean
	sillClipsOptions?: BlindHardwareOptions
	softLiftSpringAssist?: boolean
	softLiftSpringAssistOptions?: BlindHardwareOptions
	steelChain?: boolean
	steelChainOptions?: BlindHardwareOptions
	butting?: boolean
	bracketColour?: string

	extras?: BlindExtra[]
}

export interface BlindHardware {
	automation?: boolean
	automationOptions?: AutomationOptions
}

export interface BlindInstallation {
	notes?: string
}

export enum BlindWidthCalculationMethod {
	inside = 'inside',
	outside = 'outside',
}

export enum BlindFinishedLengthCalculationMethod {
	inside = 'inside',
	outside = 'outside',
}

export interface LiningOptions {
	type?: string
	width?: number
	orientation?: FabricOrientation
	price?: Pricing
	supplier?: string
	colour?: string
	interlined?: boolean
	interliningOptions?: InterliningOptions
}

export interface InterliningOptions {
	type?: InterliningTypes
	width?: number
	orientation?: FabricOrientation
	price?: Pricing
	supplier?: string
}

export enum InterliningTypes {
	Light = 'light',
	Heavy = 'heavy'
}

export interface TrimOptions {
	supplier?: string
	name?: string
	price?: Pricing
	hem?: boolean
	leadingEdge?: boolean
	outerEdge?: boolean
}

export enum VenetianMaterial {
	Aluminium = 'Aluminium',
	Wood = 'Wood',
	Honeycomb = 'Honeycomb',
}

export enum BlindHeaderType {
	standard = 'Standard',
	backRoll = 'Back Roll',
	flatStack = 'Flat Stack',
	flatStackWithFadeSkirt = 'Flat Stack with Fade Skirt',
	frontRoll = 'Front Roll',
	graduated = 'Graduated',
	relaxed = 'Relaxed',
	festoon = 'Festoon',
}

export enum BlindControlPosition {
	left = 'Left',
	right = 'Right',
}

export enum BlindControlType {
	chain = 'Chain',
	cord = 'Cord',
}

export interface AutomationOptions {
	supplier?: string
	remotes?: boolean
	numberOfRemotes?: number
	numberOfRemoteChannels?: number
	colourOfRemotes?: string
	notes?: string
	motorBrand?: string
	wiredSide?: 'left' | 'right'
	hardWired?: boolean
	remoteModel?: string
	electricalContractor?: string
	builder?: string
	price?: Pricing
}

export interface BlindHardwareOptions {
	price?: Pricing
}

export interface BlindExtra {
	name?: string
	pricing?: Pricing
}
