import actionCreatorFactory from 'typescript-fsa'

import * as dt from 'modules/database/types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Project')

/** Choose the project that you want to look at. */
export const chooseProject = actionCreator<dt.ProjectID>('CHOOSE_PROJECT')

export type ChooseProjectAction = ReturnType<typeof chooseProject>

/** Receive the project data into the project module, so we can view it and edit it. */
export const updateEditingProject = actionCreator<dt.Project>('UPDATE_EDITING_PROJECT')

/** Save the project data in the project module back to the database module. */
export const saveProject = actionCreator('SAVE_PROJECT')

/** Duplicate the project*/
export const duplicateProject = actionCreator<dt.Project>('DUPLICATE_PROJECT')
export type DuplicateProjectAction = ReturnType<typeof duplicateProject>

/** Open the form to create a new project. */
export const newProject = actionCreator('NEW_PROJECT')

export const importProject = actionCreator<dt.Project>('IMPORT_PROJECT')

/** Open the form to edit the current project. */
export const editProject = actionCreator<dt.ProjectID>('EDIT_PROJECT')
export type EditProjectAction = ReturnType<typeof editProject>

export const closeProjectForm = actionCreator('CLOSE_PROJECT_FORM')

export const openUserDetails = actionCreator('OPEN_USER_DETAILS')

export const openChangePassword = actionCreator('OPEN_CHANGE_PASSWORD')
