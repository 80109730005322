import * as React from 'react'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { wrapComponent, Snapshot, forComponentProps } from 'changeling'
import produce from 'immer'

interface Props extends Snapshot<dtp.Curtain> {
	path: string[]
	project: dt.Project
	productId: dt.ProductID
}

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CurtainFormOverview extends React.Component<Props, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('overview')
	
	public render() {
		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">Room layout</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Curtain}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						copyButtonLabel="Copy All"
					/>
				)}

				<ff.ComboBox title="Room" prop="room" code={ComboBoxCode.ROOM} controller={this.controller} />
				<ff.ComboBox title="Window" prop="window" code={ComboBoxCode.WINDOW} controller={this.controller} />

				<ff.Photo title="Photo of window - BEFORE" path={[...this.props.path, 'overview', 'photo']} projectId={this.props.project.projectId} controller={this.controller} prop="photo" />
				<ff.Photo title="Photo of window - AFTER" path={[...this.props.path, 'overview', 'photoAfter']} projectId={this.props.project.projectId} controller={this.controller} prop="photoAfter" />
				
				<ff.Heading>Curtain sets</ff.Heading>
				<ff.Toggle 
					title="Single set or Double set" 
					prop="curtainSet" 
					controller={this.controller}
					options={[
						{ label: 'Single set', value: dtp.CurtainSet.Single },
						{ label: 'Double set', value: dtp.CurtainSet.Double },
					]}
				/>
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = item.value as dt.Product
		if (product.details) {
			const other = product.details as dtp.Curtain

			const snapshot = this.rootController.snapshot()
			snapshot.onChange(produce(snapshot.value, draft => {
				if (other.overview) {
					if (!draft.overview) {
						draft.overview = {}
					}
					draft.overview.curtainSet = other.overview.curtainSet
				}
				if (other.curtain1) {
					draft.curtain1 = other.curtain1
				}
				if (other.curtain2) {
					draft.curtain2 = other.curtain2
				}
			}))
			this.setState({ showCopy: false })
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(CurtainFormOverview)
