/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { savePhoto, getAssetURL, convertURLForLocal } from 'modules/storage'
import resizer from 'image-resizer-js'
import { blobToArrayBuffer } from 'blob-util'
import { ProgressCircular } from 'react-onsenui'
import { store } from 'modules/root'
import * as a from '../actions'
import * as dt from 'modules/database/types'
import * as _ from 'lodash'
import { Snapshot, wrapComponent } from 'changeling'
import platform from 'modules/platform'

interface OwnProps extends Snapshot<dt.AnnotatedImage | undefined> {
	title: string
	path: string[]
	projectId: dt.ProjectID
}

interface State {
	photoPath?: string
	photoUrl?: string
	photoError?: string
	loading: boolean
}

const INITIAL_STATE: State = {
	loading: false,
}

class Photo extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	public render() {
		const { title } = this.props

		const { loading, photoUrl, photoError } = this.state
		if (photoUrl) {
			console.log('PHOTO: rendering photo', convertURLForLocal(photoUrl))
		}

		return (
			<div className="row -buttons">
				<div className="form-field -topalign">
					<label className="label">{title}</label>
					<div className="form-input">
						{/* {value && (
							<p>{value}</p>
						)} */}
						{loading && (
							<ProgressCircular indeterminate={true} />
						)}
						{!loading && photoUrl && (
							<>
								<figure className="media-element">
									<div className="image">
										<img src={convertURLForLocal(photoUrl)} alt="" />
									</div>
								</figure>
							</>
						)}
						{!loading && photoError && (
							<p>{photoError}</p>
						)}
						<div className="button-group -expand -photoactions">
							{navigator.camera ? (
								<>
									<button type="button" className="button-link -secondary -small -icon -photos" onClick={this.cordovaChooseImage}>Choose <span className="extended">photo</span></button>
									<button type="button" className="button-link -secondary -small -icon -camera" onClick={this.cordovaCaptureImage}>Camera</button>
								</>
							) : (
								<label className="upload">
									<div className="button-link -secondary -small -icon -photos">Choose image</div>
									<input type="file" accept="image/*" className="file" onChange={this.onChooseImage} />
								</label>
							)}
							{!loading && photoUrl && window.plugins && window.plugins.socialsharing && (
								<button type="button" className="button-link -secondary -small -icon -share" onClick={this.shareImage}>Share</button>
							)}
							{!loading && photoUrl && (
								<>
									{this.props.path.length > 0 && <button type="button" className="button-link -secondary -small -icon -annotate -notext" onClick={this.annotateImage}>Annotate</button>}
									<button type="button" className="button-link -text -small -warning -icon -delete" onClick={this.removeImage}>Remove</button>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	public componentDidMount() {
		this.loadImage()
	}

	public componentDidUpdate() {
		this.loadImage()
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps>, nextState: Readonly<State>): boolean {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (!_.isEqual(this.props.path, nextProps.path)) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		if (this.props.projectId !== nextProps.projectId) {
			return true
		}
		if (!_.isEqual(nextState, this.state)) {
			return true
		}
		return false
	}

	private shareImage = (evt: React.MouseEvent) => {
		evt.preventDefault()
		window.plugins.socialsharing.shareWithOptions(
			{
				subject: 'Maqasa photo',
				files: [this.state.photoUrl!],
			},
			(result) => {
				console.log('PHOTO: share complete', result)
			},
			(error) => {
				platform.alert(`The photo could not be shared: ${error}`)
			},
		)
	}

	private annotateImage = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const { value, path } = this.props
		if (value) {
			store.dispatch(a.annotateImage({ value, path }))
		}
	}

	private removeImage = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (window.confirm('Are you sure you want to remove this photo?')) {
			this.props.onChange(undefined)
		}
	}

	private loadImage = () => {
		const { value } = this.props
		/* Legacy data has value as a string containing the image path */
		const imagePath = typeof value === 'string' ? value : (value ? value.annotatedImagePath ? value.annotatedImagePath : value.imagePath : undefined)

		if (!imagePath) {
			if (this.state.photoUrl) {
				this.setState({ photoPath: undefined, photoUrl: undefined, photoError: undefined })
			}
			return
		}

		if (imagePath !== this.state.photoPath) {
			this.setState({ loading: true, photoPath: imagePath })
			console.log('PHOTO: loading image', imagePath)
			getAssetURL(imagePath).then(url => {
				console.log('PHOTO: got url', url)
				this.setState({
					photoPath: imagePath,
					photoUrl: url,
					loading: false,
					photoError: undefined,
				})
			}).catch(error => {
				console.log('PHOTO: failed to get url', error)
				this.setState({
					photoPath: imagePath,
					photoUrl: undefined,
					loading: false,
					photoError: 'Failed to download photo',
				})
			})
		}
	}

	/** Choose an image from the HTML5 image chooser */
	private onChooseImage = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if (evt.target.files && evt.target.files.length) {
			const blob = evt.target.files[0]
			blobToArrayBuffer(blob).then(photo => this.uploadArrayBuffer(photo, true))
				.catch(error => {
					console.error(`PHOTO: failed to get blob from photo: ${error}`)
					platform.alert(`Failed to convert the photo: ${error}`)
				})
		}
	}

	/** https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-camera/ */
	private cordovaCaptureImage = () => {
		navigator.camera.getPicture(this.cordovaHandleImageData.bind(this, false), this.handleCameraError, {
			sourceType: Camera.PictureSourceType.CAMERA,
			quality: 90,
			destinationType: Camera.DestinationType.FILE_URI,
			encodingType: Camera.EncodingType.JPEG,
			allowEdit: true,
			correctOrientation: true,
			saveToPhotoAlbum: true,
			targetWidth: 800,
			targetHeight: 800,
		})
	}

	private cordovaChooseImage = () => {
		navigator.camera.getPicture(this.cordovaHandleImageData.bind(this, true), this.handleCameraError, {
			sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
			destinationType: Camera.DestinationType.FILE_URI,
			encodingType: Camera.EncodingType.JPEG,
			mediaType: Camera.MediaType.PICTURE,
			correctOrientation: true,
		})
	}

	private cordovaHandleImageData = (resize: boolean, imageUri: string) => {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this
		window.resolveLocalFileSystemURL(
			imageUri, 
			(entry) => {
				if (entry.isFile) {
					// TODO we could use the file entry to populate the cache?
					const fileEntry = entry as FileEntry
					fileEntry.file(
						(file) => {
							/* We need to use FileReader to read an ArrayBuffer as it's not a real File */
							const reader = new FileReader()
							reader.onloadend = function() {
								const contents = this.result
								if (contents) {
									/* contents is an ArrayBuffer */
									self.uploadArrayBuffer(contents as ArrayBuffer, resize)
								}
							}
							reader.readAsArrayBuffer(file)
						},
						(error) => {
							platform.alert(`Failed to access the photo: ${error.code}`)
						},
					)
				}
			},
			(error) => {
				platform.alert(`Failed to access the filesystem to get the photo: ${error.code}`)
			},
		)
	}

	private uploadArrayBuffer = (photo: ArrayBuffer, resize: boolean) => {
		if (resize) {
			resizer(photo, { maxWidth: 800, maxHeight: 800, quality: 90, type: 'image/jpeg' }).then(resizedPhoto => {
				this.uploadArrayBuffer(resizedPhoto, false)
			}).catch(error => {
				console.error('PHOTO: failed to resize photo', error)
				platform.alert(`The photo could not be resized: ${error}`)
			})
		} else {
			savePhoto(photo, this.props.projectId).then(path => {
				console.log('PHOTO: upload completed to path', path)
		
				this.props.onChange({
					imagePath: path,
				})
			}).catch(error => {
				console.log('PHOTO: upload failed', error)
			})
		}
	}
	
	private handleCameraError = (message: string) => {
		if (message === 'No Image Selected') {
			return
		}

		platform.alert(`An error occurred while capturing the photo: ${message}`)
	}
}

export default wrapComponent(Photo)
