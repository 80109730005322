import { actionCreatorFactory } from 'typescript-fsa'

/** Action creator factory for root actions. */
const actionCreator = actionCreatorFactory()

/** Action dispatched when the app state is ready */
export const readyAction = actionCreator('READY')

/** Action dispatched when the app state has been rehydrated */
export const persistReadyAction = actionCreator('PERSIST_READY')
