import * as React from 'react'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { wrapComponent, Snapshot, forComponentProps } from 'changeling'
import produce from 'immer'

interface Props extends Snapshot<dtp.Cushion> {
	path: string[]
	project: dt.Project
	productId: dt.ProductID
}

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CushionFormOverview extends React.Component<Props, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('overview')

	public render() {
		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">Room layout</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Cushion}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						copyButtonLabel="Copy All"
					/>
				)}

				<ff.ComboBox title="Room" code={ComboBoxCode.ROOM} controller={this.controller} prop="room" />
				
				<ff.Heading>Cushion details</ff.Heading>

				<ff.Numeric title="Quantity" controller={this.controller} prop="quantity" units="cushions" />
				<ff.Toggle 
					title="Cushion type" 
					prop="cushionType" 
					controller={this.controller}
					options={[
						{ label: dtp.CushionType.Standard, value: dtp.CushionType.Standard },
						{ label: dtp.CushionType.Squab, value: dtp.CushionType.Squab },
					]}
				/>
				<ff.Photo title="Photo" path={[...this.props.path, 'overview', 'photo']} projectId={this.props.project.projectId} controller={this.controller} prop="photo" />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = item.value as dt.Product
		if (product.details) {
			const other = product.details as dtp.Cushion

			const snapshot = this.rootController.snapshot()
			snapshot.onChange(produce(snapshot.value, draft => {
				if (other.overview) {
					if (!draft.overview) {
						draft.overview = {}
					}
					draft.overview.cushionType = other.overview.cushionType
				}
				if (other.fabric) {
					draft.fabric = other.fabric
				}
				if (other.measures) {
					draft.measures = other.measures
				}
				if (other.specifications) {
					draft.specifications = other.specifications
				}
			}))
			this.setState({ showCopy: false })
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(CushionFormOverview)
