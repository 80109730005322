import * as React from 'react'
import * as dtp from 'modules/database/types/blinds'
import * as dtf from 'modules/database/types/fabric'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './BlindForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import * as ccf from 'modules/product/curtains/functions'
import * as cf from '../functions'
import { forComponentProps, Controller, wrapComponent } from 'changeling'
import { blindName } from '../functions'
import { productTitle } from 'modules/database/functions'
import { CurtainDetail } from 'modules/database/types/curtains'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class BlindFormFabric extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('fabric')

	public render() {
		const value = this.controller.snapshot().value || {}

		const quantitiesCalculation = cf.calculateQuantities(this.props.value)

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{blindName(this.props.value)} fabric</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Blind}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						productToItems={(p: dt.Product): CopyFromProductGroupItem[] => {
							if (!p.details || p.productId === this.props.productId) {
								return []
							}
							
							if (p.type === dt.ProductType.Blind && (p.details as dtp.Blind).overview && (p.details as dtp.Blind).overview!.blindType === dtp.BlindType.Roman) {
								return [{
									name: productTitle(p),
									type: 'product',
									value: p,
								}]
							}
							if (p.type === dt.ProductType.Curtain) {
								return ccf.curtainProductGroupItems(this.props.productId, p)
							}

							return []
						}}
					/>
				)}

				<ff.Heading>Fabric</ff.Heading>
				<ff.Toggle 
					title="Fabric required?" 
					controller={this.controller}
					prop="fabric"
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.fabric === true && (
					<>
						<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={this.controller} />
						<ff.ComboBox title="Collection" prop="collection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.supplier} controller={this.controller} />
						<ff.ComboBox title="Fabric" prop="name" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.supplier}|${value.collection}`} controller={this.controller} />
						<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={this.controller} />
						<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={this.controller} />
						<ff.Photo title="Photo of fabric" prop="photo" path={[...this.props.path, 'fabric', 'photo']} projectId={this.props.project.projectId} controller={this.controller} />
					
						<ff.Numeric title="Width" units="mm" prop="width" controller={this.controller} />
						<ff.Radios 
							title="" 
							prop="orientation" 
							controller={this.controller}
							options={[
								{ label: 'Drop', value: dtf.FabricOrientation.Drop },
								{ label: 'Continuous', value: dtf.FabricOrientation.Continuous },
							]}
						/>
						{quantitiesCalculation.formatErrorsForProperty('fabric.width')}
						
						<ff.Toggle 
							title="Pattern repeat" 
							prop="patternRepeat" 
							controller={this.controller}
							options={[
								{ label: 'Repeat', value: true },
								{ label: 'No repeat', value: false },
							]} 
							showClear={true}
						/>
						
						{value.patternRepeat === true && this.renderPatternRepeatOptions(this.controller.controller('patternRepeatOptions'))}
					</>
				)}

				<ff.Heading>Lining</ff.Heading>
				<ff.Toggle 
					title="Lining required?" 
					prop="lined" 
					controller={this.controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.lined === true && this.renderLiningOptions(this.controller.controller('liningOptions'))}

				<ff.Heading>Trim</ff.Heading>
				<ff.Toggle 
					title="Trim required?" 
					prop="trim" 
					controller={this.controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.trim === true && this.renderTrimOptions(this.controller.controller('trimOptions'))}
			</>
		)
	}

	private renderPatternRepeatOptions = (controller: Controller<dtf.PatternRepeatOptions | undefined>) => {
		return (
			<>
				<ff.Numeric title="Horizontal repeat" units="mm" prop="horizontalRepeat" controller={controller} />
				<ff.Numeric title="Vertical repeat" units="mm" prop="verticalRepeat" controller={controller} />
			</>
		)
	}

	private renderLiningOptions = (controller: Controller<dtp.LiningOptions | undefined>) => {
		const value = controller.snapshot().value || {}
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Lining" prop="type" code={ComboBoxCode.LINING} controller={controller} />
				<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
				<ff.Radios 
					title="" 
					prop="orientation" 
					controller={controller}
					options={[
						{ label: 'Drop', value: dtf.FabricOrientation.Drop },
						{ label: 'Continuous', value: dtf.FabricOrientation.Continuous },
					]}
				/>
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />

				<ff.Heading>Interlining</ff.Heading>
				<ff.Toggle
					title="Interlining / Bumf required?" 
					prop="interlined" 
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.interlined === true && this.renderInterliningOptions(controller.controller('interliningOptions'))}
			</>
		)
	}

	private renderInterliningOptions = (controller: Controller<dtp.InterliningOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.Radios 
					title="Type"
					prop="type"
					controller={controller}
					options={[
						{ label: 'Light', value: dtp.InterliningTypes.Light },
						{ label: 'Heavy', value: dtp.InterliningTypes.Heavy },
					]}
				/>
				<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
				<ff.Radios 
					title="" 
					prop="orientation" 
					controller={controller}
					options={[
						{ label: 'Drop', value: dtf.FabricOrientation.Drop },
						{ label: 'Continuous', value: dtf.FabricOrientation.Continuous },
					]}
				/>
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
			</>
		)
	}

	private renderTrimOptions = (controller: Controller<dtp.TrimOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Trim" prop="name" code={ComboBoxCode.TRIM} controller={controller} />
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
				<ff.Radios
					title="Hem"
					prop="hem"
					controller={controller}
					options={[
						{ label: 'Yes', value: true },
						{ label: 'No', value: false },
					]}
				/>
				<ff.Radios
					title="Leading edge"
					prop="leadingEdge"
					controller={controller}
					options={[
						{ label: 'Yes', value: true },
						{ label: 'No', value: false },
					]}
				/>
				<ff.Radios
					title="Outer edge"
					prop="outerEdge"
					controller={controller}
					options={[
						{ label: 'Yes', value: true },
						{ label: 'No', value: false },
					]}
				/>
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'product') {
			const product = (item.value as dt.Product).details as dtp.Blind
			if (product && product.fabric) {
				this.controller.snapshot().onChange(product.fabric)
				this.setState({ showCopy: false })
			} else {
				platform.alert('The selected blind doesn’t have fabric.')
			}
		}

		if (item.type === 'curtain') {
			const curtain = item.value as CurtainDetail
			if (curtain && curtain.fabric) {
				this.controller.snapshot().onChange(curtain.fabric as dtp.BlindFabric)
				this.setState({ showCopy: false })
			} else {
				platform.alert('The selected curtain doesn’t have fabric.')
			}
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(BlindFormFabric)
