import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as cf from '../functions'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { toNumber } from 'modules/root/functions'
import { addImageToImagesSummary } from 'modules/project/images'
import { titleCase } from 'title-case'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'
import { outputCurtainProduct } from './common'

interface Row {
	collection?: string
	name?: string
	colour?: string
	width?: number
	drops?: number
	cutLength?: number
	total?: number
	room?: string
	window?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.collection,
		row.name,
		row.colour,
		row.width,
		row.drops,
		row.cutLength,
		row.total,
		// row.room,
		// row.window,
	]
}

const Header: HeaderRow<Row> = {
	collection: 'Fabric Collection', 
	name: 'Fabric', 
	colour: 'Fabric Colour', 
	width: 'Fabric Width (mm)', 
	drops: 'Fabric Drops ()', 
	cutLength: 'Fabric Cut Length (mm)', 
	total: 'Fabric Total (m)',
	room: 'Room',
	window: 'Window',
}

export default function exportWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header: Header,
		outputProduct: (_supplier, _product, _summary) => {
			return outputCurtainProduct(_supplier, _product, _summary, outputCurtain)
		},
		rowToRow,
	})
}

function outputCurtain(supplier: PurchaseOrderSupplier, o: dtp.CurtainOverview, curtainSet: string, c: dtp.CurtainDetail | undefined, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c || !c.fabric) {
		return []
	}

	const outputFabric = (c.fabric.fabric && c.fabric.supplier === supplier.supplier)
	const outputLining = (c.fabric.lined && c.fabric.liningOptions && c.fabric.liningOptions.supplier === supplier.supplier)
	const outputInterlining = (c.fabric.lined && c.fabric.liningOptions && c.fabric.liningOptions.interlined && 
		c.fabric.liningOptions.interliningOptions && c.fabric.liningOptions.interliningOptions.supplier === supplier.supplier)
	const outputTrim = (c.fabric.trim && c.fabric.trimOptions && c.fabric.trimOptions.supplier === supplier.supplier)

	if (!outputFabric && !outputLining && !outputInterlining && !outputTrim) {
		return []
	}

	const quantities = cf.calculateCurtainQuantities(c).getResult()
	const result: Row[] = []
	
	// 'Fabric Collection', 'Fabric', 'Fabric Colour', Fabric Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
	if (outputFabric) {
		const data: Row = {}
		data.collection = c.fabric.collection
		data.name = c.fabric.name
		data.colour = c.fabric.colour
		data.width = toNumber(c.fabric.width)
		data.drops = quantities.fabric ? quantities.fabric.drops : undefined
		data.cutLength = quantities.fabric ? quantities.fabric.cutLength : undefined
		data.total = quantities.fabric && quantities.fabric.quantity ? quantities.fabric.quantity / 1000 : undefined
		data.room = o.room
		data.window = o.window
		result.push(data)
	}

	// 'Lining', 'Lining Colour', 'Lining Width (mm)', 'Lining Drops ()', 'Lining Cut Length (mm)', 
	if (outputLining && c.fabric.liningOptions) {
		const data: Row = {}
		data.name = c.fabric.liningOptions.type
		data.colour = c.fabric.liningOptions.colour
		data.width = toNumber(c.fabric.liningOptions.width)
		data.drops = quantities.lining ? quantities.lining.drops : undefined
		data.cutLength = quantities.lining ? quantities.lining.cutLength : undefined
		data.total = quantities.lining && quantities.lining.quantity ? quantities.lining.quantity / 1000 : undefined
		data.room = o.room
		data.window = o.window
		result.push(data)
	}

	// 'Interlining', 'Interlining Width (mm)', 'Interlining Drops ()', 'Interlining Cut Length (mm)', 
	if (outputInterlining && c.fabric.liningOptions && c.fabric.liningOptions.interliningOptions) {
		const data: Row = {}
		data.name = c.fabric.liningOptions.interliningOptions.type ? titleCase(c.fabric.liningOptions.interliningOptions.type + ' Bumf') : undefined
		data.width = toNumber(c.fabric.liningOptions.interliningOptions.width)
		data.drops = quantities.interlining ? quantities.interlining.drops : undefined
		data.cutLength = quantities.interlining ? quantities.interlining.cutLength : undefined
		data.total = quantities.interlining && quantities.interlining.quantity ? quantities.interlining.quantity / 1000 : undefined
		data.room = o.room
		data.window = o.window
		result.push(data)
	}

	// 'Trim'
	if (outputTrim && c.fabric.trimOptions) {
		const data: Row = {}
		data.name = c.fabric.trimOptions.name
		data.cutLength = quantities.trim
		data.total = quantities.trim ? quantities.trim / 1000 : undefined
		data.room = o.room
		data.window = o.window
		result.push(data)
	}
		
	if (c.fabric && c.fabric.photo) {
		addImageToImagesSummary(summary.images, {
			photo: c.fabric.photo,
			key: `fabric: ${c.fabric.supplier} ${c.fabric.name}`, 
			title: `${c.fabric.supplier || ''}: ${c.fabric.name || ''}`, 
			specificName: `${o.room || ''} ${o.window || ''}`,
		})
	}

	return result
}
