import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import { AvailablePurchaseOrderSupplier } from 'modules/project/purchaseorders'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'
import { toNumber } from 'modules/root/functions'
import * as cf from '../functions'

interface Row {
	product?: string
	room?: string
	quantity?: number
	width?: number
	height?: number
	depth?: number

	fabricSupplier?: string
	fabricCollection?: string
	fabric?: string
	fabricColour?: string
	fabricWidth?: number
	fabricCutLength?: number

	backFabricSupplier?: string
	backFabricCollection?: string
	backFabric?: string
	backFabricColour?: string
	backFabricWidth?: number
	backFabricCutLength?: number

	fillSupplier?: string
	fillType?: string

	pipingFabricSupplier?: string
	pipingFabricCollection?: string
	pipingFabric?: string
	pipingFabricColour?: string
	pipingFabricWidth?: number
	pipingCutLength?: number

	flangeFabricSupplier?: string
	flangeFabricCollection?: string
	flangeFabric?: string
	flangeFabricColour?: string
	flangeFabricWidth?: number
	flangeWidth?: number
	flangeCutLength?: number

	trimSupplier?: string
	trim?: string

	topStitching?: boolean

	workroomInstructions?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.product,
		row.room,
		row.quantity,
		row.width,
		row.height,
		row.depth,

		row.fabricSupplier,
		row.fabricCollection,
		row.fabric,
		row.fabricColour,
		row.fabricWidth,
		row.fabricCutLength,

		row.backFabricSupplier,
		row.backFabricCollection,
		row.backFabric,
		row.backFabricColour,
		row.backFabricWidth,
		row.backFabricCutLength,

		row.fillSupplier,
		row.fillType,

		row.pipingFabricSupplier,
		row.pipingFabricCollection,
		row.pipingFabric,
		row.pipingFabricColour,
		row.pipingFabricWidth,
		row.pipingCutLength,

		row.flangeFabricSupplier,
		row.flangeFabricCollection,
		row.flangeFabric,
		row.flangeFabricColour,
		row.flangeFabricWidth,
		row.flangeWidth,
		row.flangeCutLength,

		row.trimSupplier,
		row.trim,

		row.topStitching,
		row.workroomInstructions,
	]
}

const Header: HeaderRow<Row> = {
	product: 'Product', 
	room: 'Room', 
	quantity: 'Quantity ()', 
	width: 'Width (mm)', 
	height: 'Height (mm)', 
	depth: 'Depth (mm)',

	fabricSupplier: 'Fabric Supplier', 
	fabricCollection: 'Fabric Collection', 
	fabric: 'Fabric', 
	fabricColour: 'Fabric Colour', 
	fabricWidth: 'Fabric Width (mm)', 
	fabricCutLength: 'Fabric Cut Length (mm)', 

	backFabricSupplier: 'Back Fabric Supplier',
	backFabricCollection: 'Back Fabric Collection', 
	backFabric: 'Back Fabric', 
	backFabricColour: 'Back Fabric Colour', 
	backFabricWidth: 'Back Fabric Width (mm)', 
	backFabricCutLength: 'Back Fabric Cut Length (mm)', 

	fillSupplier: 'Fill Supplier', 
	fillType: 'Fill Type',

	pipingFabricSupplier: 'Piping Supplier', 
	pipingFabricCollection: 'Piping Collection', 
	pipingFabric: 'Piping', 
	pipingFabricColour: 'Piping Colour', 
	pipingFabricWidth: 'Piping Fabric Width (mm)', 
	pipingCutLength: 'Piping Cut Length (mm)',

	flangeFabricSupplier: 'Flange Supplier', 
	flangeFabricCollection: 'Flange Collection', 
	flangeFabric: 'Flange', 
	flangeFabricColour: 'Flange Colour', 
	flangeFabricWidth: 'Flange Fabric Width (mm)', 
	flangeWidth: 'Flange Width (mm)', 
	flangeCutLength: 'Flange Cut Length (mm)',

	trimSupplier: 'Trim Supplier', 
	trim: 'Trim', 

	topStitching: 'Top Stitching',

	workroomInstructions: 'Workroom Instructions',
}

export default function exportCurtainsWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header: Header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(supplier: AvailablePurchaseOrderSupplier, c: dtp.Cushion, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c || !c.specifications || c.specifications.workroomSupplier !== supplier.supplier) {
		return []
	}

	const o = c.overview
	if (!o) {
		return []
	}

	const cushionType = o.cushionType

	const data: Row = {}

	// 'Product', 'Room', 'Quantity', 
	switch (cushionType) {
		case dtp.CushionType.Standard: {
			data.product = 'Cushions'
			break
		}
		case dtp.CushionType.Squab: {
			data.product = 'Squabs'
			break
		}
		default:
			data.product = 'Cushions'
			break
	}
	data.room = o.room
	data.quantity = toNumber(o.quantity)

	if (c.measures) {
		data.width = c.measures.width
		data.height = c.measures.height
		if (cushionType === dtp.CushionType.Squab) {
			data.depth = c.measures.depth
		}
	}

	const quantities = cf.calculateQuantities(c).getResult()

	// 'Fabric Supplier', 'Fabric Collection', 'Fabric / Material', 'Fabric / Material Colour', 'Fabric Orientation', 'Fabric / Blade Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
	if (c.fabric && c.fabric.fabric) {
		data.fabricSupplier = c.fabric.supplier
		data.fabricCollection = c.fabric.collection
		data.fabric = c.fabric.name
		data.fabricColour = c.fabric.colour
		data.fabricWidth = toNumber(c.fabric.width)
		data.fabricCutLength = quantities.fabric ? quantities.fabric.getResult().cutLength : undefined
	}
	if (c.fabric && c.fabric.backFabricDifferent && c.fabric.backFabricOptions) {
		data.backFabricSupplier = c.fabric.backFabricOptions.supplier
		data.backFabricCollection = c.fabric.backFabricOptions.collection
		data.backFabric = c.fabric.backFabricOptions.name
		data.backFabricColour = c.fabric.backFabricOptions.colour
		data.backFabricWidth = toNumber(c.fabric.backFabricOptions.width)
		data.backFabricCutLength = quantities.backFabric ? quantities.backFabric.getResult().cutLength : undefined
	}
	if (c.fabric && c.fabric.fill && c.fabric.fillOptions) {
		data.fillSupplier = c.fabric.fillOptions.supplier
		data.fillType = c.fabric.fillOptions.type
	}
	if (c.fabric && c.fabric.piping && c.fabric.pipingOptions) {
		data.pipingFabricSupplier = c.fabric.pipingOptions.supplier
		data.pipingFabricCollection = c.fabric.pipingOptions.collection
		data.pipingFabric = c.fabric.pipingOptions.name
		data.pipingFabricColour = c.fabric.pipingOptions.colour
		data.pipingFabricWidth = toNumber(c.fabric.pipingOptions.width)
		data.pipingCutLength = quantities.piping ? quantities.piping.getResult().cutLength : undefined
	}
	if (c.fabric && c.fabric.flange && c.fabric.flangeOptions) {
		data.flangeFabricSupplier = c.fabric.flangeOptions.supplier
		data.flangeFabricCollection = c.fabric.flangeOptions.collection
		data.flangeFabric = c.fabric.flangeOptions.name
		data.flangeFabricColour = c.fabric.flangeOptions.colour
		data.flangeFabricWidth = toNumber(c.fabric.flangeOptions.width)
		data.flangeWidth = toNumber(c.fabric.flangeOptions.flangeWidth)
		data.flangeCutLength = quantities.flange ? quantities.flange.getResult().cutLength : undefined
	}

	// 'Trim Supplier', 'Trim', 
	if (c.fabric && c.fabric.trim && c.fabric.trimOptions) {
		data.trimSupplier = c.fabric.trimOptions.supplier
		data.trim = c.fabric.trimOptions.name
	}

	data.topStitching = c.specifications && c.specifications.topStitched

	// 'Workroom Instructions', 'Installation Notes',
	data.workroomInstructions = c.specifications.notes

	return [data]
}
