import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as cf from './functions'
import { QuoteSummary } from 'modules/project/quote'
import { titleCase } from 'title-case'
import { HeaderRow, exportQuoteSheet } from '../common/quotes'

interface Row {
	room?: string
	window?: string
	type?: string
	header?: string
	fabric?: string
	lining?: string
	control?: string
	controlColour?: string
	sellPrice?: number
}

// const header = sheet.addRow(['Room', 'Window', 'Type', 'Header', 'Fabric', 'Lining', 'Control', 'Colour', '', taxName ? `Sell Price incl ${taxName}` : 'Sell Price'])
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.room,
		row.window,
		row.type,
		row.header,
		row.fabric,
		row.lining,
		row.control,
		row.controlColour,
		row.sellPrice,
	]
}

export default function exportQuote(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, summary: QuoteSummary) {
	const header: HeaderRow<Row> = {
		room: 'Room',
		window: 'Window',
		type: 'Type',
		header: 'Header',
		fabric: 'Fabric',
		lining: 'Lining',
		control: 'Control',
		controlColour: 'Colour',
		sellPrice: 'Price ($)',
	}

	exportQuoteSheet(sheet, products, project.taxDetails, summary, {
		header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(p: dtp.Blind, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary): Row[] {
	const pricing = cf.calculateProductPricing(p, taxDetails)
	if (pricing.total && pricing.total.isErrored()) {
		throw new Error(pricing.total.formatErrorsAsText())
	}

	const totalPricing = pricing.total && pricing.total.getResult()

	const row: Row = {}
	row.room = p.overview && p.overview.room
	row.window = p.overview && p.overview.window
	row.type = titleCase((p.overview && p.overview.blindType) || '')
	row.header = p.specifications && p.specifications.headerType
	if (p.overview?.blindType === dtp.BlindType.Roman) {
		row.fabric = p.fabric?.name
	} else if (p.overview?.blindType === dtp.BlindType.Roller) {
		row.fabric = p.specifications?.rollerBlindFabricName
	}
	row.lining = p.fabric && p.fabric.lined && p.fabric.liningOptions ? p.fabric.liningOptions.type : undefined
	row.control = p.specifications && p.specifications.controlType
	row.controlColour = p.specifications && p.specifications.controlColour
	row.sellPrice = totalPricing && totalPricing.pricing ? totalPricing.pricing.rrp : undefined
	
	if (totalPricing && totalPricing.pricing) {
		summary.totalPricing.cost += totalPricing.pricing.cost
		summary.totalPricing.rrp += totalPricing.pricing.rrp
	}

	// addFabricImage(c, summary.images)
	return [row]
}
