/**
 * Migrations for our database models.
 */

import * as dt from 'modules/database/types'
import * as dtc from 'modules/database/types/curtains'
import * as _ from 'lodash'

export function migrateProject(project: dt.Project): void {
	if (!project.schemaVersion) {
		/* Project photos are now annotated images */
		if (typeof project.photo === 'string') {
			project.photo = {
				imagePath: project.photo,
			}
		}

		project.schemaVersion = dt.CurrentProjectSchemaVersion
		project.whenUpdated = new Date().toISOString() /* Update whenUpdated so we download this change */
	}
}

export function migrateProduct(product: dt.Product): void {
	if (!product.schemaVersion) {
		if (product.type === dt.ProductType.Curtain) {
			const curtain = product.details as dtc.Curtain

			/* Addition of CurtainFabric.fabric; default to true */
			if (curtain.curtain1 && curtain.curtain1.fabric && curtain.curtain1.fabric.fabric === undefined) {
				curtain.curtain1.fabric.fabric = true
			}
			if (curtain.curtain2 && curtain.curtain2.fabric && curtain.curtain2.fabric.fabric === undefined) {
				curtain.curtain2.fabric.fabric = true
			}
		}
		product.schemaVersion = 1
		product.whenUpdated = new Date().toISOString() /* Update whenUpdated so we download this change */
	}

	if (product.schemaVersion === 1) {
		if (product.type === dt.ProductType.Curtain) {
			const curtain = product.details as dtc.Curtain

			/* Hardware changes */
			if (curtain.curtain1 && curtain.curtain1.hardware) {
				schema2MigrateHardware(curtain.curtain1.hardware)
			}
			if (curtain.curtain2 && curtain.curtain2.hardware) {
				schema2MigrateHardware(curtain.curtain2.hardware)
			}
		}
		product.schemaVersion = 2
		product.whenUpdated = new Date().toISOString() /* Update whenUpdated so we download this change */
	}
	
	if (product.schemaVersion === 2) {
		if (product.type === dt.ProductType.Curtain) {
			const curtain = product.details as dtc.Curtain

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (curtain.curtain1 && curtain.curtain1.hardware && (curtain.curtain1.hardware.bracketsApplied as any) === 'None') {
				curtain.curtain1.hardware.bracketsApplied = undefined
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (curtain.curtain2 && curtain.curtain2.hardware && (curtain.curtain2.hardware.bracketsApplied as any) === 'None') {
				curtain.curtain2.hardware.bracketsApplied = undefined
			}
		}
		product.schemaVersion = 3
		product.whenUpdated = new Date().toISOString() /* Update whenUpdated so we download this change */
	}

	if (product.schemaVersion === 3) {
		if (product.type === dt.ProductType.Curtain) {
			const curtain = product.details as dtc.Curtain

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (curtain.curtain1 && curtain.curtain1.hardware && (curtain.curtain1.hardware as any).notes) {
				if (!curtain.curtain1.hardware.rodOptions) {
					curtain.curtain1.hardware.rodOptions = {}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				curtain.curtain1.hardware.rodOptions.notes = (curtain.curtain1.hardware as any).notes
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (curtain.curtain2 && curtain.curtain2.hardware && (curtain.curtain2.hardware as any).notes) {
				if (!curtain.curtain2.hardware.rodOptions) {
					curtain.curtain2.hardware.rodOptions = {}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				curtain.curtain2.hardware.rodOptions.notes = (curtain.curtain2.hardware as any).notes
			}
		}
		product.schemaVersion = 4
		product.whenUpdated = new Date().toISOString() /* Update whenUpdated so we download this change */
	}
}

function schema2MigrateHardware(target: dtc.CurtainHardware) {
	const legacy = target as dtc.LegacyCurtainHardware

	target.rod = legacy.rodOrTrack
	if (legacy.hardwareRequired && legacy.hardwareOptions) {
		target.rodOptions = {
			supplier: legacy.hardwareOptions.supplier,
			fixingDetails: legacy.hardwareOptions.fixingDetails,
			code: legacy.hardwareOptions.rodCode,
			color: legacy.hardwareOptions.rodColor,
			height: legacy.hardwareOptions.rodHeight,
			price: legacy.hardwareOptions.rodPrice,
		}

		if (legacy.noHardwareOptions) {
			target.numberOfHooks = legacy.noHardwareOptions.numberOfHooks
		}

		target.finialsApplied = legacy.hardwareOptions.finialsApplied
		target.finialsOptions = legacy.hardwareOptions.finialsOptions
		target.bracketsApplied = legacy.hardwareOptions.bracketsApplied
		target.bracketsOptions = legacy.hardwareOptions.bracketsOptions
		target.cordingApplied = legacy.hardwareOptions.cordingApplied
		target.cordingOptions = legacy.hardwareOptions.cordingOptions
		target.bendsApplied = legacy.hardwareOptions.bendsApplied
		target.bendsOptions = legacy.hardwareOptions.bendsOptions
		target.holdbacksApplied = legacy.hardwareOptions.holdbacksApplied
		target.holdbackOptions = legacy.hardwareOptions.holdbackOptions
		target.flickSticks = legacy.hardwareOptions.flickSticks
		target.flickSticksOptions = legacy.hardwareOptions.flickSticksOptions
		target.automation = legacy.hardwareOptions.automation
		target.automationOptions = legacy.hardwareOptions.automationOptions
		;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(target as any).notes = legacy.hardwareOptions.notes

		deleteUndefined(target)
		if (target.rodOptions) {
			deleteUndefined(target.rodOptions)
		}
	}
}

/** Undefined values cause issues with Firebase:
 * <pre>DocumentReference.set() called with invalid data. Unsupported field value: undefined</pre>
 * 
 * So we just delete those values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deleteUndefined(target: any) {
	_.keys(target).forEach(k => {
		if (target[k] === undefined) {
			delete (target[k])
		}
	})
}
