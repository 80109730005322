import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import Calculation from 'modules/root/calculations'
import { FabricQuantities, GenericFabricTargetCalculations, calculateGenericFabricQuantity, calculateGenericFabricPricing, distinctFabricQuantities, FabricAndQuantity } from 'modules/product/common/fabric'
import { sumGenericPricing, GenericPricing } from 'modules/product/common/pricing'
import { calculatePricing } from 'modules/project/calculations'
import { FabricOrientation } from 'modules/database/types/fabric'
import { roundUpTo100, formatNumber } from 'modules/root/functions'

const CUSHION_FULLNESS = 0

export function cushionTypeName(type: dtp.CushionType, long?: boolean): string {
	switch (type) {
		case dtp.CushionType.Standard: return long ? 'Standard Cushion' : 'Standard'
		case dtp.CushionType.Squab: return long ? 'Squab' : 'Squab'
		default:
			throw new Error(`cushionsTypeName: Unknown cushion type ${type}`)
	}
}

export function cushionName(cushion: dtp.Cushion): string {
	if (cushion.overview && cushion.overview.cushionType) {
		return cushionTypeName(cushion.overview.cushionType, true)
	} else {
		return 'Cushion'
	}
}

export function measurements(cushion: dtp.Cushion): string | undefined {
	const cushionWidth = cushion.measures && calculateWidth(cushion.measures).getResultOrUndefined()
	const cushionDepthCalculation = calculateDepth(cushion)
	const cushionDepth = cushionDepthCalculation && cushionDepthCalculation.getResultOrUndefined()
	const cushionLength = cushion.measures && calculateFinishedLength(cushion.measures).getResultOrUndefined()

	if (cushionWidth !== undefined && cushionDepth !== undefined && cushionLength !== undefined) {
		return `${formatNumber(cushionWidth)} × ${formatNumber(cushionLength)} x ${formatNumber(cushionDepth)} mm`
	} else if (cushionWidth !== undefined && cushionLength !== undefined) {
		return `${formatNumber(cushionWidth)} × ${formatNumber(cushionLength)} mm`
	} else {
		return undefined
	}
}

export function calculateWidth(measures: dtp.CushionMeasures): Calculation<number> {
	const calculation = new Calculation(NaN)

	return calculation.withResult(calculation.toNumber(measures.width, 'Width'))
}

export function calculateFinishedLength(measures: dtp.CushionMeasures): Calculation<number> {
	const calculation = new Calculation(NaN)

	return calculation.withResult(calculation.toNumber(measures.height, 'Height'))
}

export function calculateDepth(cushion: dtp.Cushion): Calculation<number> | undefined {
	const calculation = new Calculation(NaN)

	if (!cushion.overview || cushion.overview.cushionType !== dtp.CushionType.Squab) {
		return undefined
	}
	const measures = cushion.measures
	if (!measures) {
		return undefined
	}
	return calculation.withResult(calculation.toNumber(measures.depth, 'Depth'))
}

interface CushionQuantities {
	quantity?: Calculation<number>
	fabric?: Calculation<FabricQuantities>
	backFabric?: Calculation<FabricQuantities>
	piping?: Calculation<FabricQuantities>
	flange?: Calculation<FabricQuantities>
	trim?: Calculation<FabricQuantities>
}

export function distinctCushionFabricQuantities(detail: dtp.Cushion): FabricAndQuantity[] {
	const quantities = calculateQuantities(detail).getResultOrUndefined()
	if (!quantities) {
		return []
	}
	
	const input: FabricAndQuantity[] = []

	if (quantities.fabric && detail.fabric) {
		input.push({
			supplier: detail.fabric.supplier,
			collection: detail.fabric.collection,
			name: detail.fabric.name,
			quantities: quantities.fabric,
		})
	}
	if (quantities.backFabric && detail.fabric && detail.fabric.backFabricOptions) {
		input.push({
			supplier: detail.fabric.backFabricOptions.supplier,
			collection: detail.fabric.backFabricOptions.collection,
			name: detail.fabric.backFabricOptions.name,
			quantities: quantities.backFabric,
		})
	}
	if (quantities.piping && detail.fabric && detail.fabric.pipingOptions) {
		input.push({
			supplier: detail.fabric.pipingOptions.supplier,
			collection: detail.fabric.pipingOptions.collection,
			name: detail.fabric.pipingOptions.name,
			quantities: quantities.piping,
		})
	}

	return distinctFabricQuantities(input)
}

export function calculateQuantities(detail: dtp.Cushion): Calculation<CushionQuantities> {
	const result: CushionQuantities = {}
	const calculation = new Calculation(result)

	if (!detail.overview) {
		return calculation.withErrorMessage('Blind overview options are not set')
	}
	const cushionType = detail.overview.cushionType
	if (!cushionType) {
		return calculation.withErrorMessage('Cushion type is not set')
	}

	const fabric = detail.fabric
	const measures = detail.measures
	if (!fabric) {
		return calculation.withErrorMessage('Fabric options are not set')
	}
	if (!measures) {
		return calculation.withErrorMessage('Measures are not set')
	}

	const quantityCalculation = new Calculation(NaN)
	quantityCalculation.withResult(quantityCalculation.toNumber(detail.overview.quantity, 'Quantity', ['overview.quantity'], { defaultValue: 1, operation: '+' }))
	result.quantity = quantityCalculation

	if (fabric.fabric) {
		let fabricQuantityCalcluation = quantityCalculation
		if (!fabric.backFabricDifferent) {
			fabricQuantityCalcluation = new Calculation(NaN)
			fabricQuantityCalcluation.withResult(2 * quantityCalculation.toNumber(detail.overview.quantity, 'Quantity', ['overview.quantity'], { defaultValue: 1, operation: '+' }))
		}

		const targetCalculations: GenericFabricTargetCalculations = {
			width: calculateWidth(measures),
			length: calculateFinishedLength(measures),
			hemAllowance: new Calculation(30),
			sideHemAllowance: new Calculation(30),
			quantity: fabricQuantityCalcluation,
		}
		
		result.fabric = calculateGenericFabricQuantity(
			{
				...fabric, 
				fullness: CUSHION_FULLNESS,
				orientation: FabricOrientation.TesellateHorizontalOnly,
			},
			targetCalculations, 
			'Cushion',
			'fabric',
		)
		if (fabric.backFabricDifferent) {
			result.backFabric = calculateGenericFabricQuantity(
				{
					...fabric.backFabricOptions, 
					fullness: CUSHION_FULLNESS,
					orientation: FabricOrientation.TesellateHorizontalOnly,
				},
				targetCalculations, 
				'Cushion Back',
				'fabric.backFabricOptions',
			)
		}
	}

	const perimeterCalculation = new Calculation(NaN)
	perimeterCalculation.withResult(perimeterCalculation.toNumber(measures.width, 'Width') * 2 + perimeterCalculation.toNumber(measures.height, 'Height') * 2)

	if (fabric.piping) {
		const targetCalculations: GenericFabricTargetCalculations = {
			width: new Calculation(80),
			length: perimeterCalculation,
			hemAllowance: new Calculation(100),
			sideHemAllowance: new Calculation(0),
			quantity: quantityCalculation,
		}

		result.piping = calculateGenericFabricQuantity(
			{
				...fabric.pipingOptions, 
				fullness: CUSHION_FULLNESS,
				orientation: FabricOrientation.Lineal,
			},
			targetCalculations, 
			'Cushion piping',
			'fabric.pipingOptions',
		)
	}

	if (fabric.flange) {
		const flangeWidthCalculation = new Calculation(NaN)
		if (fabric.flangeOptions) {
			flangeWidthCalculation.withResult(flangeWidthCalculation.toNumber(fabric.flangeOptions.flangeWidth, 'Flange width') * 2)
		} else {
			flangeWidthCalculation.withErrorMessage('Flange options not set')
		}

		const targetCalculations: GenericFabricTargetCalculations = {
			width: flangeWidthCalculation,
			length: perimeterCalculation,
			hemAllowance: new Calculation(100),
			sideHemAllowance: new Calculation(30),
			quantity: quantityCalculation,
		}

		result.flange = calculateGenericFabricQuantity(
			{
				...fabric.flangeOptions, 
				fullness: CUSHION_FULLNESS,
				orientation: FabricOrientation.Lineal,
			},
			targetCalculations, 
			'Cushion flange',
			'fabric.flangeOptions',
		)
	}

	if (fabric.trim) {
		const quantities: FabricQuantities = {
			drops: 1,
			cutLength: perimeterCalculation.getResult(),
			quantity: roundUpTo100(perimeterCalculation.getResult()),
		}
		result.trim = new Calculation(quantities)
	}

	return calculation
}

interface CushionPricings {
	fabric?: Calculation<GenericPricing>
	backFabric?: Calculation<GenericPricing>
	fill?: Calculation<GenericPricing>
	piping?: Calculation<GenericPricing>
	flange?: Calculation<GenericPricing>
	trim?: Calculation<GenericPricing>
	specifications?: Calculation<GenericPricing>
	total?: Calculation<GenericPricing>
}

export function calculateProductPricing(detail: dtp.Cushion, taxDetails: dt.TaxDetails | undefined): CushionPricings {
	const result: CushionPricings = {}

	if (!detail.overview) {
		return result
	}

	const quantityCalculation = new Calculation(NaN)
	quantityCalculation.withResult(quantityCalculation.toNumber(detail.overview.quantity, 'Quantity', ['overview.quantity'], { defaultValue: 1, operation: '+' }))

	const fabricQuantitiesCalculation = calculateQuantities(detail)
	const fabricQuantities = fabricQuantitiesCalculation.getResult()

	if (fabricQuantities.fabric && detail.fabric) {
		result.fabric = calculateGenericFabricPricing(
			{
				...detail.fabric, 
				fullness: CUSHION_FULLNESS,
			},
			fabricQuantities.fabric.getResult(),
			taxDetails,
			'Fabric',
			'fabric',
		).withErrors(fabricQuantitiesCalculation)
	} else if (fabricQuantitiesCalculation.isErrored()) {
		result.fabric = new Calculation<GenericPricing>({}).withErrors(fabricQuantitiesCalculation)
	}
	if (fabricQuantities.backFabric && detail.fabric && detail.fabric.backFabricOptions) {
		result.backFabric = calculateGenericFabricPricing(
			{
				...detail.fabric.backFabricOptions, 
				fullness: CUSHION_FULLNESS,
			},
			fabricQuantities.backFabric.getResult(),
			taxDetails,
			'Back fabric',
			'fabric.backFabricOptions',
		).withErrors(fabricQuantitiesCalculation)
	} else if (fabricQuantitiesCalculation.isErrored()) {
		result.backFabric = new Calculation<GenericPricing>({}).withErrors(fabricQuantitiesCalculation)
	}
	if (detail.fabric && detail.fabric.fill && detail.fabric.fillOptions) {
		const fillPricing: GenericPricing = {}
		result.fill = new Calculation(fillPricing)
		fillPricing.pricing = result.fill.mergeErrors(calculatePricing(detail.fabric.fillOptions.price, taxDetails, detail.overview ? detail.overview.quantity || 1 : 1, 'Fill', 'fabric.fillOptions'))
	}
	if (fabricQuantities.piping && detail.fabric && detail.fabric.pipingOptions) {
		result.piping = calculateGenericFabricPricing(
			{
				...detail.fabric.pipingOptions, 
				fullness: CUSHION_FULLNESS,
			},
			fabricQuantities.piping.getResult(),
			taxDetails,
			'Piping',
			'fabric.pipingOptions',
		).withErrors(fabricQuantitiesCalculation)
	} else if (fabricQuantitiesCalculation.isErrored()) {
		result.piping = new Calculation<GenericPricing>({}).withErrors(fabricQuantitiesCalculation)
	}
	if (fabricQuantities.flange && detail.fabric && detail.fabric.flangeOptions) {
		result.flange = calculateGenericFabricPricing(
			{
				...detail.fabric.flangeOptions, 
				fullness: CUSHION_FULLNESS,
			},
			fabricQuantities.flange.getResult(),
			taxDetails,
			'Flange',
			'fabric.flangeOptions',
		).withErrors(fabricQuantitiesCalculation)
	} else if (fabricQuantitiesCalculation.isErrored()) {
		result.flange = new Calculation<GenericPricing>({}).withErrors(fabricQuantitiesCalculation)
	}

	if (detail.specifications) {
		const pricing = detail.specifications.makingPrice
		if (pricing && !quantityCalculation.isErrored()) {
			result.specifications = new Calculation<GenericPricing>({})
			result.specifications.withResult({
				pricing: result.specifications.mergeErrors(calculatePricing(pricing, taxDetails, quantityCalculation.getResult(), 'Making cost', 'specifications.makingPrice')),
			})
		} else {
			result.specifications = new Calculation<GenericPricing>({}).withErrors(quantityCalculation)
		}
	}

	result.total = sumGenericPricing(result.fabric, result.backFabric)
	result.total = sumGenericPricing(result.total, result.fill)
	result.total = sumGenericPricing(result.total, result.piping)
	result.total = sumGenericPricing(result.total, result.flange)
	result.total = sumGenericPricing(result.total, result.trim)
	result.total = sumGenericPricing(result.total, result.specifications)
	
	return result
}
