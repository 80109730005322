/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/AnnotateImageScreen'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { navigatorPop } from 'onsenui-react-redux-navigator'

/* Import module actions */
import * as a from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	// exampleProperty: string
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSave: (path: string, dataPath: string[], annotatedImage: Blob, annotations: unknown) => void
	onCancel: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		// exampleProperty: state.template.name,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSave: (path, dataPath, annotatedImage, annotations) => {
		dispatch(a.annotatedImage({
			path,
			dataPath,
			annotatedImage,
			annotations: JSON.stringify(annotations),
		}))
	},
	onCancel: () => {
		dispatch(navigatorPop('maqasa'))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
