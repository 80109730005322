import * as _ from 'lodash'
import * as dtp from 'modules/database/types/curtains'
import * as cf from '../functions'
import { PurchaseOrderSupplier, WorkroomSummary } from 'modules/project/purchaseorders/types'

type OutputCurtainFunction<ROW> = (supplier: PurchaseOrderSupplier, o: dtp.CurtainOverview, curtainSet: string, c: dtp.CurtainDetail | undefined, summary: WorkroomSummary) => ROW[]

export function outputCurtainProduct<ROW>(supplier: PurchaseOrderSupplier, d: dtp.Curtain, summary: WorkroomSummary, outputCurtain: OutputCurtainFunction<ROW>): ROW[] {
	const curtainSet = cf.curtainSet(d)
	let result: ROW[] = []

	result = result.concat(outputCurtain(supplier, d.overview!, _.capitalize(curtainSet) + (curtainSet === dtp.CurtainSet.Double ? ' C1' : ''), d.curtain1, summary))
	if (curtainSet === dtp.CurtainSet.Double) {
		result = result.concat(outputCurtain(supplier, d.overview!, _.capitalize(curtainSet) + ' C2', d.curtain2, summary))
	}
	return result
}
