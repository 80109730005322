import { takeEvery, put, call, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as a from './actions'
import * as t from './types'
import { navigatorPush, navigatorPop } from 'onsenui-react-redux-navigator'
import { saveAnnotations, SaveAnnotationsResult } from 'modules/storage'
import * as prs from 'modules/product/selectors'
import * as pra from 'modules/product/actions'
import * as dt from 'modules/database/types'
import * as pjs from 'modules/project/selectors'
import * as pja from 'modules/project/actions'
import produce from 'immer'

function* handleAnnotateImageAction(action: a.AnnotateImageAction): SagaIterator {
	const props: t.AnnotateRouteProps = {
		image: action.payload.value, 
		path: action.payload.path,
	}
	yield put(navigatorPush({
		navigator: 'maqasa',
		route: {
			component: 'AnnotateImageScreen',
			props,
		},
		options: {
			animation: 'lift',
		},
	}))
}

function* handleAnnotatedImageAction(action: a.AnnotatedImageAction): SagaIterator {
	yield put(navigatorPop('maqasa'))
	
	const annotationsPaths = (yield call(saveAnnotations, action.payload.path, action.payload.annotatedImage, action.payload.annotations)) as SaveAnnotationsResult
	const newValue: dt.AnnotatedImage = {
		imagePath: action.payload.path,
		annotatedImagePath: annotationsPaths.annotatedImagePath,
		annotationsPath: annotationsPaths.annotationsPath,
	}
	
	const dataPathType = action.payload.dataPath[0]
	const dataPath = action.payload.dataPath.slice(1)

	if (dataPathType === 'currentProduct') {
		const currentProduct = (yield select(prs.currentProduct)) as ReturnType<typeof prs.currentProduct>
		if (currentProduct) {
			const form = (yield select(prs.currentProductFormValue)) as ReturnType<typeof prs.currentProductFormValue>

			const modifiedForm = deepSet(form, dataPath, newValue)
			yield put(pra.updateCurrentProductDetails(modifiedForm))
		}
	} else if (dataPathType === 'currentProject') {
		const currentProject = (yield select(pjs.currentProject)) as ReturnType<typeof pjs.currentProject>
		if (currentProject) {
			const modifiedProject = deepSet(currentProject, dataPath, newValue)
			yield put(pja.updateEditingProject(modifiedProject))
		}
	} else {
		throw new Error(`Unsupported annotation data path type: ${dataPathType}`)
	}
}

/** The saga function for this module that is run from our root saga. */
export default function* formsSaga(): SagaIterator {
	yield takeEvery(a.annotateImage, handleAnnotateImageAction)
	yield takeEvery(a.annotatedImage, handleAnnotatedImageAction)
}

function deepSet<P>(form: P, dataPath: string[], newValue: unknown): P {
	return produce(form, draft => {
		let working = draft
		for (let i = 0; i < dataPath.length - 1; i++) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			working = (working as any)[dataPath[i]]
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(working as any)[dataPath[dataPath.length - 1]] = newValue
	})
}
