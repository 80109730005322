import * as React from 'react'
import { NavigatorToolbar, RouteProps } from 'onsenui-react-redux-navigator'
import { Props, Actions, OwnProps } from '../containers/EditProjectForm'
import { Page } from 'react-onsenui'
import * as dt from 'modules/database/types'
import * as df from 'modules/database/functions'
import * as ff from 'modules/forms'
import { Controller, forComponentProps } from 'changeling'

type MyComponentProps = Props & Actions & OwnProps & RouteProps<unknown>

export default class EditProjectForm extends React.Component<MyComponentProps> {

	private controller = forComponentProps(this, 'project', 'onProjectChange')

	public render() {
		const { project, newProject } = this.props
		if (!project) {
			return null
		}

		return (
			<Page className="screen-modal">
				<div className="modal-content">
					<div className="width-limit -site -pageboundaries">
						<div className="body">
							<NavigatorToolbar 
								route={this.props.route} 
								title={this.props.project ? df.projectTitle(this.props.project) : 'New project'} 
								renderToolbar={(navigationController: unknown, props: { title: string }, actions: unknown) => {
									return (
										<header className="modal-header">
											<div className="contents">
												<a onClick={this.props.onCancel} className="back">Cancel</a>
												<h1 className="title-page"><div className="site-logo -reversed">Maqasa</div></h1>
											</div>
										</header>
									)
								}}
							/>
							<div className="modal-body">
								<form className="form-layout">
									<ff.Heading>Project</ff.Heading>
									{this.renderProjectDetails(this.controller)}

									<ff.Heading>Client</ff.Heading>
									{this.renderClientDetails(this.controller.controller('client'))}

									<ff.Heading>Notes</ff.Heading>
									<ff.TextArea title="Project Notes" prop="notes" controller={this.controller} />
						
									<div className={newProject ? 'row -buttons' : 'row -buttons -split'}>
										{newProject ? (
											<div className="button-group">
												<a onClick={this.onSave} className="button-link -action">Create project</a>
											</div>
										) : (
											<>
												<div className="button-group">
													{!project.whenArchived ? (
														<button type="button" onClick={this.onArchive} className="button-link -secondary">Archive project</button>
													) : (
														<button type="button" onClick={this.onUnarchive} className="button-link -secondary">Unarchive project</button>
													)}
												</div>
												<div className="button-group">
													<button type="button" onClick={this.onSave} className="button-link -action">Save project</button>
													<button type="button" onClick={this.props.onCancel} className="button-link -secondary">Cancel</button>
												</div>
											</>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Page>
		)
	}

	private renderClientDetails = (controller: Controller<dt.Client | undefined>) => {
		return (
			<>
				<ff.Text title="Client name" prop="name" controller={controller} placeholder={this.props.newProject ? this.props.project && this.props.project.contactName : undefined}/>
				<ff.Text title="Client address" prop="address" controller={controller} placeholder={this.props.newProject ? this.props.project && this.props.project.address : undefined}/>
				<ff.Text title="Client phone" prop="phone" type="tel" controller={controller} placeholder={this.props.newProject ? this.props.project && this.props.project.phone : undefined}/>
				<ff.Text title="Client mobile" prop="mobile" type="tel" controller={controller} placeholder={this.props.newProject ? this.props.project && this.props.project.mobile : undefined}/>
				<ff.Text title="Client email address" prop="email" type="email" controller={controller} placeholder={this.props.newProject ? this.props.project && this.props.project.email : undefined}/>
			</>
		)
	}

	private renderProjectDetails = (controller: Controller<dt.Project | undefined>) => {
		return (
			<>
				<ff.Text title="Project name *" prop="name" controller={controller} />
				<ff.Text title="Project address" prop="address" controller={controller} />
				{this.props.project && <ff.Photo title="Project photo" prop="photo" path={['currentProject', 'photo']} projectId={this.props.project.projectId} controller={controller} />}
				<ff.Text title="Contact name" prop="contactName" controller={controller} />
				<ff.Text title="Contact phone" prop="phone" type="tel" controller={controller} />
				<ff.Text title="Contact mobile" prop="mobile" type="tel" controller={controller} />
				<ff.Text title="Contact email address" prop="email" type="email" controller={controller} />
				{this.renderTaxDetails(controller.controller('taxDetails'))}
			</>
		)
	}

	private renderTaxDetails = (controller: Controller<dt.TaxDetails | undefined>) => {
		return (
			<>
				<ff.Numeric title="Tax rate" prop="taxRate" units="%" controller={controller} />
				<ff.Text title="Tax name (abbreviation)" prop="taxName" maxLength={4} controller={controller} />
			</>
		)
	}

	private onClose = () => {
		this.props.onCancel()
	}

	private onSave = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (this.props.project) {
			const clientSnapshot = this.controller.snapshot('client')
			const client: dt.Client = { ...(clientSnapshot.value || { name: '' }) }
			if (this.props.project.contactName && (!client.name)) {
				client.name = this.props.project.contactName
			}
			if (!client.address) {
				client.address = this.props.project.address
			}
			if (!client.phone) {
				client.phone = this.props.project.phone
			}
			if (!client.email) {
				client.email = this.props.project.email
			}
			if (!client.mobile) {
				client.mobile = this.props.project.mobile
			}
			clientSnapshot.onChange(client)
		}

		this.props.onSaveProject()
	}

	private onArchive = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (!window.confirm('Are you sure you want to archive this project?')) {
			return
		}

		this.controller.snapshot('whenArchived').onChange(new Date().toISOString())
		this.props.onSaveProject()
	}

	private onUnarchive = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.controller.snapshot('whenArchived').onChange(undefined)
		this.props.onSaveProject()
	}
}
