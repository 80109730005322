import { take, takeEvery, call, put, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as da from 'modules/database/actions'
import * as dt from 'modules/database/types'
import * as aa from 'modules/auth/actions'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as s from './selectors'
import { store } from 'modules/root'
import { persistReadyAction } from 'modules/root/actions'
import { db, setDatabase, setStorage } from './index'
import { OfflineStatusChangeAction } from '@redux-offline/redux-offline/lib/types'
import { migrateProduct, migrateProject } from './migration'
import { cloneDeepWithoutUndefined } from './functions'
import { currentUser } from 'modules/auth/selectors'
import { NOT_LOGGED_IN_ERROR_MESSAGE } from 'modules/root/errors'

let unsubscribes: (() => void)[] = []

function* handleUpdateProject(action: da.UpdateProjectAction): SagaIterator {
	if (action.payload) {
		yield call(updateProject, action.payload.projectId)
	}
}

function* updateProject(projectId: dt.ProjectID): SagaIterator {
	/* We get the project from state as the reducer has made changes */
	const project = (yield select(s.projectByIdOrUndefined, projectId)) as ReturnType<typeof s.projectByIdOrUndefined>

	if (project) {
		yield call(uploadProject, project)
	}
}

function* handleUpdateWholeProject(action: ReturnType<typeof da.updateWholeProject>): SagaIterator {
	if (action.payload) {
		/* We get the project again from state as the reducer has made changes */
		const projectId = action.payload.projectId
		const project = (yield select(s.projectByIdOrUndefined, projectId)) as ReturnType<typeof s.projectByIdOrUndefined>

		if (project) {
			yield call(uploadProject, project)

			const products = (yield select(s.projectProductsById, projectId)) as ReturnType<typeof s.projectProductsById>
			for (const product of products) {
				yield call(uploadProduct, projectId, product)
			}
		}
	}
}

function* handleUpdateProduct(action: da.UpdateProductAction): SagaIterator {
	if (action.payload) {
		yield call(updateProduct, action.payload.projectId, action.payload.product.productId)
	}
}

function* updateProduct(projectId: dt.ProjectID, productId: dt.ProductID): SagaIterator {
	const user = firebase.auth().currentUser
	if (!user) {
		throw new Error(NOT_LOGGED_IN_ERROR_MESSAGE)
	}

	/* We get the product from state as the reducer has made changes */
	const project = (yield select(s.projectByIdOrUndefined, projectId)) as ReturnType<typeof s.projectByIdOrUndefined> 
	const product = (yield select(s.productByIdOrUndefined, projectId, productId)) as ReturnType<typeof s.productByIdOrUndefined>

	if (project) {
		yield call(() => uploadProject(project))
	}
	if (product) {
		yield call(() => uploadProduct(projectId, product))
	}
}

function* handleDeleteProject(action: da.DeleteProjectAction): SagaIterator {
	yield call(updateProject, action.payload)
}

function* handleDeleteProduct(action: da.DeleteProductAction): SagaIterator {
	yield call(updateProduct, action.payload.projectId, action.payload.productId)
}

function* handleUndeleteProduct(action: da.DeleteProductAction): SagaIterator {
	yield call(updateProduct, action.payload.projectId, action.payload.productId)
}

function* handleLoginDone(): SagaIterator {
	console.log('DATABASE: handleLoginDone')
	
	unsubscribes.forEach(u => u())
	unsubscribes = []

	/* Upload our offline recovery first so our changes aren't lost. Note that we don't have any conflict resolution. */
	yield call(offlineRecovery)
	
	downloadProjects()
}

function handleLogout() { 
	unsubscribes.forEach(u => u())
	unsubscribes = []
}

function* handleOfflineStatusChanged(action: OfflineStatusChangeAction) {
	yield put(da.onlineStateChange(action.payload.online))

	if (action.payload.online) {
		const user = (yield select(currentUser)) as ReturnType<typeof currentUser>
		if (user) {
			yield call(offlineRecovery)
		}
	}
}

let offlineRecoveryVersion = 0

function* offlineRecovery() {
	const currentOfflineRecoveryVersion = ++offlineRecoveryVersion

	try {
		console.log('OFFLINE RECOVERY: Checking for offline changes')
		const projects = (yield select(s.projectsRequiringUpload)) as ReturnType<typeof s.projectsRequiringUpload>
		console.log(`OFFLINE RECOVERY: Uploading ${projects.length} projects`)
		
		for (const project of projects) {
			console.log(`OFFLINE RECOVERY: Uploading project ${project.projectId}`)
			yield call(uploadProject, project)

			if (currentOfflineRecoveryVersion !== offlineRecoveryVersion) {
				console.log('OFFLINE RECOVERY: Cancelling old recovery process')
				return
			}
		}

		const allProjects = (yield select(s.allProjects)) as ReturnType<typeof s.projectsRequiringUpload>
		for (const project of allProjects) {
			const products = (yield select(s.productsRequiringUpload, project.projectId)) as ReturnType<typeof s.productsRequiringUpload>
			if (products.length > 0) {
				console.log(`OFFLINE RECOVERY: Uploading ${products.length} products for project ${project.projectId}`)
				for (const product of products) {
					console.log(`OFFLINE RECOVERY: Uploading product ${product.productId} for project ${project.projectId}`)
					yield call(uploadProduct, project.projectId, product)

					if (currentOfflineRecoveryVersion !== offlineRecoveryVersion) {
						console.log('OFFLINE RECOVERY: Cancelling old recovery process')
						return
					}
				}
			}
		}

		console.log(`OFFLINE RECOVERY: Completed for ${projects.length} projects`)
	} catch (error) {
		console.error(`OFFLINE RECOVERY: Failed: ${error}`)
	}
}

function* uploadProject(project: DeepReadonly<dt.Project>) {
	const user = firebase.auth().currentUser
	if (!user) {
		throw new Error(NOT_LOGGED_IN_ERROR_MESSAGE)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let _project: any = { ...project }
	delete _project.products /* As these are a collection, not part of the document */
	delete _project.whenUploaded
	delete _project.whenDownloaded
	_project = cloneDeepWithoutUndefined(_project)

	yield call(() => db.collection('users').doc(user.uid).collection('projects').doc(_project.projectId).set(_project))
	yield put(da.uploadedProject(project))
}

function* uploadProduct(projectId: dt.ProjectID, product: DeepReadonly<dt.Product>) {
	const user = firebase.auth().currentUser
	if (!user) {
		throw new Error(NOT_LOGGED_IN_ERROR_MESSAGE)
	}

	let _product = { ...product }
	delete _product.whenUploaded
	delete _product.whenDownloaded
	_product = cloneDeepWithoutUndefined(_product)

	yield call(() => db.collection('users').doc(user.uid).collection('projects').doc(projectId).collection('products').doc(product.productId).set(_product))
	yield put(da.uploadedProduct({
		projectId,
		product,
	}))
}

function downloadProjects() {
	const user = firebase.auth().currentUser
	if (!user) {
		return
	}
	
	const unsubscribe = db.collection('users').doc(user.uid).collection('projects').onSnapshot(function(querySnapshot: firebase.firestore.QuerySnapshot) {
		const downloadedProjects: da.Projects = {}
		const counts = {
			newProjects: 0,
			updatedProjects: 0,
			removedProjects: 0,
		}
		const removedProjects: string[] = []

		querySnapshot.docChanges().forEach(change => {
			/* Check if the change is from the server or local */
			if (!change.doc.metadata.hasPendingWrites) {
				const project = change.doc.data() as dt.Project

				if (change.type !== 'removed') {
					mangleIncomingProject(project)

					const existingProject = store.getState().database.projects[project.projectId]
					if (!existingProject) {
						console.debug('DATABASE: new project', project, 'type', change.type, 'from cache', querySnapshot.metadata.fromCache)
						downloadedProjects[project.projectId] = project
						counts.newProjects++
					} else if (fixDate(existingProject.whenUpdated) < fixDate(project.whenUpdated)) {
						console.debug('DATABASE: updating project', project, 'type', change.type, 'from cache', querySnapshot.metadata.fromCache)
						downloadedProjects[project.projectId] = project
						counts.updatedProjects++
					}
				} else {
					counts.removedProjects++
					removedProjects.push(project.projectId)
				}

				/* If the project is new, also listen to its products. When we first load, all projects are added, so we listen
				   to their products on load, and then get changes in downloadProducts.
				 */
				if (change.type === 'added') {
					downloadProducts(user, project.projectId)
				}
			}
		})

		if (counts.newProjects > 0 || counts.updatedProjects > 0 || counts.removedProjects > 0) {
			console.log(`DATABASE: importing ${counts.newProjects} new projects and ${counts.updatedProjects} updated projects and ${counts.removedProjects} removed projects`)
			store.dispatch(da.downloadedProjects(downloadedProjects))
			for (const projectId of removedProjects) {
				store.dispatch(da.deleteProject(projectId))
			}
		}
	})
	unsubscribes.push(unsubscribe)
}

function downloadProducts(user: firebase.User, projectId: dt.ProjectID) {
	const unsubscribe = db.collection('users').doc(user.uid).collection('projects').doc(projectId).collection('products').onSnapshot(function(querySnapshot: firebase.firestore.QuerySnapshot) {
		const payload: da.DownloadedProductsPayload = {
			projectId,
			products: [],
		}
		
		querySnapshot.docChanges().forEach(change => {
			/* Check if the change is from the server or local */
			if (!change.doc.metadata.hasPendingWrites) {
				const product = change.doc.data() as dt.Product

				if (change.type !== 'removed') {
					mangleIncomingProduct(product)

					const existingProject = s.projectByIdOrUndefined(store.getState(), projectId)
					if (existingProject) {
						const existingProduct = existingProject.products[product.productId]
						if (!existingProduct) {
							console.debug('DATABASE: new product', product.productId, 'project', projectId, 'type', change.type, 'from cache', querySnapshot.metadata.fromCache)
							payload.products.push(product)
						} else if (fixDate(existingProduct.whenUpdated) < fixDate(product.whenUpdated)) {
							console.debug('DATABASE: updating product', product.productId, 'project', projectId, 'type', change.type, 'from cache', querySnapshot.metadata.fromCache)
							payload.products.push(product)
						}
					} else {
						console.warn('DATABASE: project', projectId, 'not found when downloading product', product.productId)
					}
				}
			}
		})

		store.dispatch(da.downloadedProducts(payload))
	})
	unsubscribes.push(unsubscribe)
}

/**
 * Some old data had dates in a Date.toString() form so we fix them to the ISO date standard.
 * @param dateString 
 */
function fixDate(dateString: string): string {
	if (dateString.match(/^[0-9]/)) {
		return dateString
	} else {
		return new Date(dateString).toISOString()
	}
}

function mangleIncomingProject(project: dt.Project): void {
	migrateProject(project)
	
	project.whenDownloaded = new Date().toISOString()
}

function mangleIncomingProduct(product: dt.Product): void {
	migrateProduct(product)

	product.whenDownloaded = new Date().toISOString()
}

async function initialiseFirestore(): Promise<void> {
	const config = {
		apiKey: 'AIzaSyD6eZkicIvrDOK8tKQ3slWqAInYUXQtYOU',
		authDomain: 'maqasa-development.firebaseapp.com',
		databaseURL: 'https://maqasa-development.firebaseio.com',
		projectId: 'maqasa-development',
		storageBucket: 'maqasa-development.appspot.com',
		messagingSenderId: '547833136100',
	}
	firebase.initializeApp(config)

	const storage = firebase.storage()
	setStorage(storage)
	console.log('STORAGE: initialised')

	const newDatabase = firebase.firestore()
	const settings: firebase.firestore.Settings = {
		merge: true,
	}
	newDatabase.settings(settings)

	setDatabase(newDatabase)
}

/** The saga function for this module that is run from our root saga. */
export default function* databaseSaga(): SagaIterator {
	yield take(persistReadyAction)
	yield call(initialiseFirestore)

	console.log('DATABASE: initialised')
	yield put(da.databaseReady())

	yield takeEvery(da.updateProject, handleUpdateProject)
	yield takeEvery(da.updateWholeProject, handleUpdateWholeProject)
	yield takeEvery(da.deleteProject, handleDeleteProject)
	yield takeEvery(da.updateProduct, handleUpdateProduct)
	yield takeEvery(da.deleteProduct, handleDeleteProduct)
	yield takeEvery(da.undeleteProduct, handleUndeleteProduct)
	yield takeEvery(aa.loggedIn, handleLoginDone)
	yield takeEvery(aa.loggedOut, handleLogout)

	yield takeEvery('Offline/STATUS_CHANGED', handleOfflineStatusChanged)

	/* Init our online state from redux-offline, in case we miss the online status while we're waiting to be initialised */
	const online = (yield select(state => state.offline.online)) as boolean
	yield put(da.onlineStateChange(online))
}
