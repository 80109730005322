import { ImagesSummary } from 'modules/project/images'

export enum ExportFormat {
	Summary = 'Summmary',
	Detail = 'Detail',
}

export interface WorkroomSummary {
	images: ImagesSummary
	headerRows: number[]
}

export interface PurchaseOrderSupplier {
	supplier: string
	purchaseOrderNumber?: string
}
