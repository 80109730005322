/* Import the component from the component path */
import Component from '../components/ProductForm'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as dt from 'modules/database/types'
import * as ds from 'modules/database/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	project: dt.Project
	product: dt.Product
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const product = state.product.currentProduct!

	return {
		project: ds.projectById(state, state.product.projectId!),
		product,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
