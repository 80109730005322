import * as React from 'react'
import { NavigatorToolbar, RouteProps } from 'onsenui-react-redux-navigator'
import { Props, Actions, OwnProps } from '../containers/UserDetails'
import { Page } from 'react-onsenui'
import * as t from '../types'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { forComponentProps, Controller, Input } from 'changeling'
import { allCountries } from 'country-region-data'

export default class UserDetails extends React.Component<Props & Actions & OwnProps & RouteProps<unknown>> {

	private controller = forComponentProps(this, 'formValue', 'onChangeUser')

	public render() {
		return (
			<Page className="screen-modal">
				<div className="modal-content">
					<div className="width-limit -site -pageboundaries">
						<div className="body">
							<NavigatorToolbar 
								route={this.props.route} 
								title="Your Profile"
								renderToolbar={(navigationController: unknown, props: { title: string }, actions: unknown) => {
									return (
										<header className="modal-header">
											<div className="contents">
												<a onClick={this.props.onCancel} className="back">Cancel</a>
												<h1 className="title-page">{props.title}</h1>
											</div>
										</header>
									)
								}}
							/>
							<div className="modal-body">
								<form className="form-layout">
									<ff.Heading>User Details</ff.Heading>
									{this.renderUserDetails(this.controller)}

									<ff.Heading>Defaults</ff.Heading>
									{this.renderDefaults(this.controller)}
									{this.renderTaxDetails(this.controller.controller('taxDetails'))}
						
									<div className="row -buttons">
										<div className="button-group">
											<a onClick={this.onSave} className="button-link -action">Save details</a>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Page>
		)
	}

	private onClose = () => {
		this.props.onCancel()
	}

	private onSave = () => {
		this.props.onSave()
	}

	private renderUserDetails = (controller: Controller<t.User>) => {
		const countryOptions: { label: string; value: string }[] = allCountries.map(country => {
			return {
				label: country[0],
				value: country[1],
			}
		})
		countryOptions.unshift({
			label: '',
			value: '',
		})
		
		return (
			<>
				<ff.Text title="Given name" prop="givenName" controller={controller} />
				<ff.Text title="Family name" prop="familyName" controller={controller} />
				<ff.Text title="Organisation" prop="organisation" controller={controller} />
				<ff.FormRow type="select" title="Country">
					<Input.Select controller={this.controller} prop="country" options={countryOptions} className="select" />
				</ff.FormRow>
				<ff.Text title="Delivery address" prop="deliveryAddress" controller={controller} />
			</>
		)
	}

	private renderDefaults = (controller: Controller<t.User>) => {
		return (
			<>
				<ff.TextArea title="Terms &amp; Conditions of Sale" prop="termsAndConditions" controller={controller} />
			</>
		)
	}

	private renderTaxDetails = (controller: Controller<dt.TaxDetails | undefined>) => {
		return (
			<>
				<ff.Numeric title="Tax rate" prop="taxRate" units="%" controller={controller} />
				<ff.Text title="Tax name (abbreviation)" prop="taxName" maxLength={4} controller={controller} />
			</>
		)
	}

}
