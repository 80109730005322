import { takeEvery, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as a from './actions'
import * as t from './types'
import * as aa from 'modules/auth/actions'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as s from './selectors'
import { db } from 'modules/database'
import { store } from 'modules/root'

let combosSnapshotUnsubscribe: (() => void) | undefined

function downloadComboBoxUserData() {
	const user = firebase.auth().currentUser
	if (!user) {
		return
	}

	if (combosSnapshotUnsubscribe) {
		combosSnapshotUnsubscribe()
		combosSnapshotUnsubscribe = undefined
	}
	combosSnapshotUnsubscribe = db.collection('users').doc(user.uid).collection('combos').onSnapshot(function(querySnapshot: firebase.firestore.QuerySnapshot) {
		querySnapshot.docChanges().forEach(change => {
			/* Check if the change is from the server or local */
			if (!change.doc.metadata.hasPendingWrites) {
				const code = change.doc.id
				const data = change.doc.data() as t.ComboBoxData
				store.dispatch(a.downloadedComboBoxData({ code, data }))
			}
		})
	})
}

function handleLoginDone() {
	downloadComboBoxUserData()
}

function handleLogout() {
	if (combosSnapshotUnsubscribe) {
		combosSnapshotUnsubscribe()
		combosSnapshotUnsubscribe = undefined
	}
}

function* handleUseValue(action: a.UseValueAction): SagaIterator {
	const user = firebase.auth().currentUser
	if (!user) {
		return
	}

	const code = action.payload.code
	const data = (yield select(s.comboBoxData, code)) as ReturnType<typeof s.comboBoxData>

	db.collection('users').doc(user.uid).collection('combos').doc(code).set(data)
}

/** The saga function for this module that is run from our root saga. */
export default function* saga(): SagaIterator {
	yield takeEvery(aa.loggedIn, handleLoginDone)
	yield takeEvery(aa.loggedOut, handleLogout)
	yield takeEvery(a.useValue, handleUseValue)
}
