import * as React from 'react'

interface Props {
	message: string
}

/**
 * Interface for private internal component state.
 */
interface State {
	
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	
}

export default class MessageBar extends React.Component<Props, State> {

	public state = INITIAL_STATE

	public render() {
		return (
			<>
				<div className="message-bar">
					<p>{this.props.message}</p>
				</div>
				{/* Include a spacer with the same content, but not position: fixed so we have space at the bottom of a scrolling screen */}
				<div className="message-bar -spacer">
					<p>{this.props.message}</p>
				</div>
			</>
		)
	}
}
