import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as cf from '../functions'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { titleCase } from 'title-case'
import { toNumber } from 'modules/root/functions'
import { outputCurtainProduct } from './common'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'

interface Row {
	quantity?: number
	productType?: string
	room?: string
	window?: string
	type?: string
	code?: string
	colour?: string
	brackets?: string
	bracketsType?: string
	fixingDetails?: string
	width?: number
	length?: number
	notes?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.quantity,
		row.productType,
		row.room,
		row.window,
		row.type,
		row.code,
		row.colour,
		row.brackets,
		row.bracketsType,
		row.fixingDetails,
		row.width,
		row.length,
		row.notes,
	]
}

const Header: HeaderRow<Row> = {
	quantity: 'Quantity',
	productType: 'Product Type',
	room: 'Room',
	window: 'Window',
	type: 'Type',
	code: 'Code',
	colour: 'Colour',
	brackets: 'Brackets',
	bracketsType: 'Brackets Type',
	fixingDetails: 'Fixing Details',
	width: 'Width (mm)',
	length: 'Length (mm)',
	notes: 'Notes',
}

export default function exportCurtainsWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header: Header,
		outputProduct: (_supplier, _product, _summary) => {
			return outputCurtainProduct(_supplier, _product, _summary, outputCurtain)
		},
		rowToRow,
	})
}

function outputCurtain(supplier: PurchaseOrderSupplier, o: dtp.CurtainOverview, curtainSet: string, c: dtp.CurtainDetail | undefined, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c || !c.hardware) {
		return []
	}

	const outputRod = c.hardware.rod && c.hardware.rodOptions && c.hardware.rodOptions.supplier === supplier.supplier
	const outputFinials = outputRod && c.hardware.finialsApplied && c.hardware.finialsOptions
	const outputBends = outputRod && c.hardware.bendsApplied && c.hardware.bendsOptions
	const outputCording = outputRod && c.hardware.cordingApplied && c.hardware.cordingOptions
	const outputHoldbacks = c.hardware.holdbacksApplied && c.hardware.holdbackOptions && c.hardware.holdbackOptions.supplier === supplier.supplier
	const outputFlickSticks = c.hardware.flickSticks && c.hardware.flickSticksOptions && c.hardware.flickSticksOptions.supplier === supplier.supplier
	const outputAutomation = c.hardware.automation && c.hardware.automationOptions && c.hardware.automationOptions.supplier === supplier.supplier
	if (!outputRod && !outputHoldbacks && !outputFlickSticks && !outputAutomation) {
		return []
	}

	const result: Row[] = []

	// 'Quantity', Product Type', 'Room', 'Window', 'Type', 'Code', 'Colour', 
	// 'Brackets', 'Brackets Type', Track Width (mm)', 'Drop (mm)',
	// 'Notes'
	if (outputRod && c.hardware.rodOptions) {
		const data: Row = {}
		data.quantity = 1
		data.productType = c.hardware.rod ? titleCase(c.hardware.rod) : undefined
		data.room = o.room
		data.window = o.window
		data.type = curtainSet
		data.code = c.hardware.rodOptions.code
		data.colour = c.hardware.rodOptions.color

		if (c.hardware.bracketsApplied) {
			data.brackets = titleCase(c.hardware.bracketsApplied)
			if (c.hardware.bracketsOptions) {
				data.bracketsType = c.hardware.bracketsOptions.type
			}
		}

		data.fixingDetails = c.hardware.rodOptions.fixingDetails

		const trackWidth = c.measures && cf.calculateTrackWidth(c.measures)
		data.width = trackWidth && trackWidth.getResultOrUndefined()

		data.notes = c.hardware.rodOptions.notes
		result.push(data)
	}
	if (outputFinials && c.hardware.finialsOptions) {
		const data: Row = {}
		data.quantity = c.hardware.finialsOptions.quantity
		data.productType = 'Finials'
		data.room = o.room
		data.window = o.window
		data.code = c.hardware.finialsOptions.type
		if (c.hardware.finialsOptions.customColour) {
			data.colour = c.hardware.finialsOptions.colour
		} else if (c.hardware.rodOptions) {
			data.colour = c.hardware.rodOptions.color
		}

		data.width = c.hardware.finialsOptions.width

		data.notes = c.hardware.finialsOptions.notes
		result.push(data)
	}
	if (outputCording && c.hardware.cordingOptions) {
		const data: Row = {}
		data.quantity = 1
		data.productType = 'Cording'
		data.room = o.room
		data.window = o.window
		data.type = c.hardware.cordingOptions.side
		data.code = c.specifications && c.specifications.draw
		data.length = c.hardware.cordingOptions.length
		result.push(data)
	}
	if (outputBends && c.hardware.bendsOptions) {
		const data: Row = {}
		data.quantity = toNumber(c.hardware.bendsOptions.quantity)
		data.productType = 'Bends'
		data.room = o.room
		data.window = o.window
		data.code = c.hardware.bendsOptions.bends
		data.notes = c.hardware.bendsOptions.notes
		result.push(data)
	}
	if (outputHoldbacks && c.hardware.holdbackOptions) {
		if (c.hardware.holdbackOptions.quantityLeft) {
			const data: Row = {}
			data.quantity = toNumber(c.hardware.holdbackOptions.quantityLeft)
			data.productType = 'Holdback'
			data.room = o.room
			data.window = o.window
			data.type = 'Left'
			data.code = c.hardware.holdbackOptions.name
			data.colour = c.hardware.holdbackOptions.colour
			
			result.push(data)
		}
		if (c.hardware.holdbackOptions.quantityRight) {
			const data: Row = {}
			data.quantity = toNumber(c.hardware.holdbackOptions.quantityRight)
			data.productType = 'Holdback'
			data.room = o.room
			data.window = o.window
			data.type = 'Right'
			data.code = c.hardware.holdbackOptions.name
			data.colour = c.hardware.holdbackOptions.colour
			
			result.push(data)
		}
	}
	if (outputFlickSticks && c.hardware.flickSticksOptions) {
		const data: Row = {}
		data.quantity = toNumber(c.hardware.flickSticksOptions.quantity)
		data.productType = 'Flick Sticks'
		data.room = o.room
		data.window = o.window
		data.type = curtainSet
		data.code = c.hardware.flickSticksOptions.name
		data.colour = c.hardware.flickSticksOptions.colour
		data.length = toNumber(c.hardware.flickSticksOptions.length)
		
		result.push(data)
	}
	if (outputAutomation && c.hardware.automationOptions) {
		if (c.hardware.automationOptions.motorBrand) {
			const data: Row = {}
			data.quantity = 1
			data.productType = 'Motor'
			data.room = o.room
			data.window = o.window
			data.type = c.hardware.automationOptions.hardWired ? 'Hard-wired' : 'Wireless'
			data.code = c.hardware.automationOptions.motorBrand

			const trackWidth = c.measures && cf.calculateTrackWidth(c.measures)
			const finishedLength = c.measures && cf.calculateFinishedLength(c.measures)
			data.width = trackWidth && trackWidth.getResultOrUndefined()
			data.length = finishedLength && finishedLength.getResultOrUndefined()

			data.notes = c.hardware.automationOptions.notes
			
			result.push(data)
		}
		if (c.hardware.automationOptions.remotes) {
			const data: Row = {}
			data.quantity = toNumber(c.hardware.automationOptions.numberOfRemotes)
			data.productType = 'Remote'
			data.room = o.room
			data.window = o.window
			if (c.hardware.automationOptions.numberOfRemoteChannels !== undefined) {
				data.type = (`${c.hardware.automationOptions.numberOfRemoteChannels} ${c.hardware.automationOptions.numberOfRemoteChannels === 1 ? 'channel' : 'channels'}`)
			}
			data.code = c.hardware.automationOptions.remoteModel
			data.colour = c.hardware.automationOptions.colourOfRemotes
			
			result.push(data)
		}
	}

	return result
}
