import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import ons from 'onsenui'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import { init } from 'modules/root'

import * as Sentry from '@sentry/browser'

let deviceReady = false

document.addEventListener(
	'deviceready', 
	function() {
		console.log('STARTUP: Early device ready')
		deviceReady = true
	},
	false)

Sentry.init({
	dsn: 'https://e6c0d91770f7410bbc111e9fbf4571ca@sentry.io/1447634',
})

registerServiceWorker()

// if (process.env.NODE_ENV !== 'production') {
// 	const {whyDidYouUpdate} = require('why-did-you-update')
// 	whyDidYouUpdate(React)
// }

declare global {
	interface Window {
		LaunchScreen: {
			hide: () => void
		}
	}
}

function startApp() {
	console.log('STARTUP: Starting app')
	init().then(() => {
		const root = ReactDOM.createRoot(document.getElementById('root')!)
		root.render(
			<App />,
		)
	})
}

if (ons.platform.isIPhoneX() && document.documentElement) { // Utility function
	// Add empty attribute to the <html> element
	document.documentElement.setAttribute('onsflag-iphonex-portrait', '')
	document.documentElement.setAttribute('onsflag-iphonex-landscape', '')
}

ons.ready(function() {
	/* Don't wait for deviceready when debugging in the browser */
	if (!ons.platform.isWebView()) {
		console.log('STARTUP: Starting app automatically as not running in Cordova')
		startApp()
	} else if (deviceReady) {
		console.log('STARTUP: Cordova already signalled readiness…')
		if (window.LaunchScreen) {
			window.LaunchScreen.hide()
		}
		startApp()
	} else {
		console.log('STARTUP: Waiting for Cordova to signal readiness…')
		document.addEventListener(
			'deviceready', 
			function() {
				console.log('STARTUP: Device ready')
				if (window.LaunchScreen) {
					window.LaunchScreen.hide()
				}
				startApp()
			},
			false)
	}
})
