import { CommonProductDetails, CommonOverview } from './common'
import { Pricing, AnnotatedImage } from 'modules/database/types'

export interface Cushion extends CommonProductDetails {
	overview?: CushionOverview
	measures?: CushionMeasures
	fabric?: CushionFabric
	specifications?: CushionSpecifications
}

export interface CushionOverview extends CommonOverview {
	quantity?: number
	cushionType?: CushionType
}

export interface CushionMeasures {
	width?: number
	height?: number
	depth?: number
}

export interface CushionFabric {
	fabric?: boolean
	supplier?: string
	collection?: string
	name?: string
	colour?: string
	photo?: AnnotatedImage
	width?: number
	price?: Pricing

	backFabricDifferent?: boolean
	backFabricOptions?: CushionBackFabric
	
	fill?: boolean
	fillOptions?: CushionFillOptions

	piping?: boolean
	pipingOptions?: CushionPipingOptions

	trim?: boolean
	trimOptions?: CushionTrimOptions

	flange?: boolean
	flangeOptions?: CushionFlangeOptions
}

export interface CushionSpecifications {
	workroomSupplier?: string
	makingPrice?: Pricing
	topStitched?: boolean
	notes?: string
}

export enum CushionType {
	Standard = 'Standard',
	Squab = 'Squab',
}

export interface CushionBackFabric {
	supplier?: string
	collection?: string
	name?: string
	colour?: string
	photo?: AnnotatedImage
	width?: number
	price?: Pricing
}

export interface CushionFillOptions {
	supplier?: string
	type?: string
	price?: Pricing
}

export interface CushionPipingOptions {
	supplier?: string
	collection?: string
	name?: string
	colour?: string
	photo?: AnnotatedImage
	width?: number
	price?: Pricing

	squabPipingType?: CushionSquabPipingType
}

export interface CushionTrimOptions {
	supplier?: string
	name?: string
	price?: Pricing
}

export interface CushionFlangeOptions {
	supplier?: string
	collection?: string
	name?: string
	colour?: string
	photo?: AnnotatedImage
	width?: number
	price?: Pricing
	
	flangeWidth?: number
}

export enum CushionSquabPipingType {
	top = 'Top',
	topBottom = 'Top & Bottom',
}
