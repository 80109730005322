/* Import the component from the component path */
import Component from '../components/ProjectDetailsWrapper'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
// import * as a from '../actions'
import * as dt from 'modules/database/types'
import * as t from '../types'
import * as ds from 'modules/database/selectors'
import { RouteProps } from 'onsenui-react-redux-navigator'

/** Interface for properties that are passed to the container/ */
export interface OwnProps extends RouteProps<t.ProjectRouteProps> {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	project?: dt.Project
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const projectId = ownProps.route.props!.projectId 
	const project = ds.projectByIdOrUndefined(state, projectId)

	return {
		project,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(a.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
