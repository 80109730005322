import { reducerWithInitialState } from 'typescript-fsa-reducers'
import prepare, { produce } from 'immer'

/* Import our module's actions */
import * as a from './actions'
import * as dt from 'modules/database/types'
import * as da from 'modules/database/actions'
import * as pa from 'modules/project/actions'

import { makeNewProduct } from './functions'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	currentProduct?: dt.Product
	projectId?: dt.ProjectID
	currentForm?: string
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	/* Note that we end each property with a comma, so we can add new properties without modifying this line
	(improve your git diffs!).
	*/
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.editProduct, (state, payload) => prepare(state, draft => {
	draft.currentProduct = payload.product
	draft.projectId = payload.projectId
	draft.currentForm = undefined
}))

reducer.case(a.updateCurrentProductDetails, (state, details) => prepare(state, draft => {
	if (draft.currentProduct) {
		draft.currentProduct.details = details
	}
}))

reducer.case(a.newProduct, (state, payload) => prepare(state, draft => {
	draft.currentProduct = makeNewProduct(payload.productType)
	draft.projectId = payload.projectId
	draft.currentForm = undefined
}))

reducer.case(a.chooseProductForm, (state, form) => prepare(state, draft => {
	draft.currentForm = form
}))

/** Update the current product if it is downloaded */
reducer.case(da.downloadedProducts, (state, payload) => produce(state, draft => {
	const currentProduct = state.currentProduct
	if (currentProduct && payload.projectId === state.projectId) {
		const product = payload.products.find(p => p.productId === currentProduct.productId)
		if (product) {
			draft.currentProduct = product
		}
	}
}))

/** Blank the product reducer when the project changes. */
reducer.case(pa.chooseProject, (state): StoreState => produce(state, draft => INITIAL_STATE))
