import * as React from 'react'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './BlindForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { FormFieldVariant } from 'modules/forms/components/FormRow'
import { wrapComponent, forComponentProps, Controller, Input } from 'changeling'
import { blindName, defaultHemAllowance } from '../functions'
import { COMPATIBLEKEYS } from 'changeling/dist/types'
import * as cf from '../functions'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class BlindFormSpecifications extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('specifications')

	public render() {
		const value = this.controller.snapshot().value || {}
		const measures = this.rootController.snapshot('measures').value
		const hemAllowanceDefault = defaultHemAllowance(this.props.value)
		const blindType = this.props.value.overview && this.props.value.overview.blindType

		const widthCalculation = measures && cf.calculateWidth(measures)
		const finishedLengthCalculation = measures && cf.calculateFinishedLength(measures)

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{blindName(this.props.value)} specifications</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Blind}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
					/>
				)}

				<ff.ComboBox title="Workroom supplier" prop="workroomSupplier" code={ComboBoxCode.WORKROOM_SUPPLIER} controller={this.controller} staticValues={['Prestige CMT']} />
				{blindType !== dtp.BlindType.Venetian &&
				<ff.Dropdown 
					title="Header type" 
					value={this.controller.snapshot('headerType').value}
					onChange={this.controller.snapshot('headerType').onChange}
					options={[
						{ label: dtp.BlindHeaderType.standard, value: dtp.BlindHeaderType.standard },
						{ label: dtp.BlindHeaderType.backRoll, value: dtp.BlindHeaderType.backRoll },
						{ label: dtp.BlindHeaderType.flatStack, value: dtp.BlindHeaderType.flatStack },
						{ label: dtp.BlindHeaderType.flatStackWithFadeSkirt, value: dtp.BlindHeaderType.flatStackWithFadeSkirt },
						{ label: dtp.BlindHeaderType.frontRoll, value: dtp.BlindHeaderType.frontRoll },
						{ label: dtp.BlindHeaderType.graduated, value: dtp.BlindHeaderType.graduated },
						{ label: dtp.BlindHeaderType.relaxed, value: dtp.BlindHeaderType.relaxed },
						{ label: dtp.BlindHeaderType.festoon, value: dtp.BlindHeaderType.festoon },
					]}
				/>
				}
				<ff.Dropdown 
					title="Fixing details" 
					value={this.controller.snapshot('fixingDetails').value}
					onChange={this.controller.snapshot('fixingDetails').onChange}
					options={[
						{ label: 'Face fix', value: 'Face fix' },
						{ label: 'Top fix', value: 'Top fix' },
						{ label: 'Inside fix', value: 'Inside fix' },
					]}
				/>

				{blindType === dtp.BlindType.Roman && (
					<>
						{measures && <ff.Display title="Stack height" value={measures.stackHeight} units="mm" variant={FormFieldVariant.Short} />}
						<ff.Numeric title="Hem allowance" prop="hemAllowance" units="mm" placeholder={hemAllowanceDefault ? `${hemAllowanceDefault}` : undefined} controller={this.controller} />
					</>
				)}

				<ff.Toggle
					title="Blind butting"
					controller={this.controller}
					prop="butting"
					options={[
						{ label: 'Butting', value: true },
						{ label: 'No Butting', value: false },
					]}
					showClear={true}
				/>

				{widthCalculation && (
					<ff.Display title="Blind width" value={widthCalculation.getResult()} units="mm" variant={FormFieldVariant.Short} calculation={widthCalculation} />
				)}
				{finishedLengthCalculation && (
					<ff.Display title="Drop" value={finishedLengthCalculation.getResult()} units="mm" variant={FormFieldVariant.Short} calculation={finishedLengthCalculation} />
				)}
				<ff.Price title="Pricing" prop="makingPrice" project={this.props.project} controller={this.controller} />

				{blindType === dtp.BlindType.Roller && (
					<>
						<ff.Heading>Roller blind details</ff.Heading>
						<ff.ComboBox title="Fabric Collection" prop="rollerBlindFabricCollection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.workroomSupplier} controller={this.controller} />
						<ff.ComboBox title="Fabric Name" prop="rollerBlindFabricName" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.workroomSupplier}|${value.rollerBlindFabricCollection}`} controller={this.controller} />
						<ff.ComboBox title="Fabric Colour" prop="rollerBlindFabricColour" code={ComboBoxCode.COLOUR} controller={this.controller} />
						<ff.Photo title="Photo of fabric" prop="rollerBlindFabricPhoto" path={[...this.props.path, 'specifications', 'rollerBlindFabricPhoto']} projectId={this.props.project.projectId} controller={this.controller} />
						<ff.Text title="Set No." controller={this.controller} prop="rollerBlindSetNo" />
						<ff.ComboBox title="Bottom Rail Shape" code={ComboBoxCode.BOTTOM_RAIL_SHAPE} controller={this.controller} prop="rollerBlindBottomRailShape" staticValues={['Elliptical', 'Oval', 'Round', 'Stata', 'Square']} />
						<ff.ComboBox title="Bottom Rail Colour" prop="rollerBlindBottomRailColour" code={ComboBoxCode.COLOUR} comboContext="hardware" controller={this.controller} />
					</>
				)}
				{blindType === dtp.BlindType.Venetian && (
					<>
						<ff.Heading>Venetians details</ff.Heading>
						<ff.Toggle
							title="Material"
							controller={this.controller}
							prop="venetianMaterial"
							options={([
								{ label: dtp.VenetianMaterial.Aluminium, value: dtp.VenetianMaterial.Aluminium },
								{ label: dtp.VenetianMaterial.Wood, value: dtp.VenetianMaterial.Wood },
								{ label: dtp.VenetianMaterial.Honeycomb, value: dtp.VenetianMaterial.Honeycomb },
							])}
							showClear={true}
						/>
						<ff.Numeric title="Size" controller={this.controller} prop="venetianSize" units="mm" />
						<ff.ComboBox title="Colour" prop="venetianColour" code={ComboBoxCode.COLOUR} controller={this.controller} staticValues={['Black', 'Bone', 'Frost', 'Ivory', 'Sandstone', 'Snow', 'Walnut', 'White', 'White Snow']} />
					</>
				)}
				{blindType === dtp.BlindType.Roman && (
					<ff.Heading>Roman Blind details</ff.Heading>
				)}
				{blindType === dtp.BlindType.Roman && (
					<ff.Toggle
						title="Control type"
						controller={this.controller}
						prop="controlType"
						options={[
							{ label: dtp.BlindControlType.chain, value: dtp.BlindControlType.chain },
							{ label: dtp.BlindControlType.cord, value: dtp.BlindControlType.cord },
						]}
						showClear={true}
					/>
				)}
				<ff.Toggle
					title="Control position"
					controller={this.controller}
					prop="controlPosition"
					options={[
						{ label: dtp.BlindControlPosition.left, value: dtp.BlindControlPosition.left },
						{ label: dtp.BlindControlPosition.right, value: dtp.BlindControlPosition.right },
					]}
					showClear={true}
				/>

				{ blindType === dtp.BlindType.Roller || blindType === dtp.BlindType.Roman ?
					<ff.ComboBox title="Control length" prop="controlLength" code={ComboBoxCode.CONTROL_LENGTH} controller={this.controller} staticValues={[500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750]}/> :
					<ff.Numeric title="Control length" prop="controlLength" units="mm" controller={this.controller} /> 
				}
				<ff.ComboBox title="Chain colour" prop="chainColour" code={ComboBoxCode.COLOUR} comboContext="chain" controller={this.controller} staticValues={['White']} />
				<ff.ComboBox title="Control colour" prop="controlColour" code={ComboBoxCode.COLOUR} comboContext="control" controller={this.controller} staticValues={['Black', 'Corded', 'Cream', 'Grey', 'Ivory', 'Steel', 'White']} />
				<ff.ComboBox title="Bracket colour" prop="bracketColour" code={ComboBoxCode.COLOUR} comboContext="hardware" controller={this.controller} />

				{blindType === dtp.BlindType.Roller && this.renderStandard('Chain Tensioner (Child Safety)', 'Chain tensioner required?', 'chainTensioner', 'chainTensionerOptions')}
				{blindType === dtp.BlindType.Roller && this.renderStandard('Fascia or Pelmets', 'Fascia or pelmets required?', 'fascia', 'fasciaOptions')}
				{(blindType === dtp.BlindType.Venetian || blindType === dtp.BlindType.Roman) && this.renderStandard('Custom Fascia or Pelmets', 'Custom fascia or pelmets required?', 'fascia', 'fasciaOptions')}
				{(blindType === dtp.BlindType.Roller || blindType === dtp.BlindType.Venetian) && this.renderStandard('Sill Clips', 'Sill clips required?', 'sillClips', 'sillClipsOptions')}
				{blindType === dtp.BlindType.Roller && this.renderStandard('Soft Lift Spring Assist', 'Soft lift spring assist required?', 'softLiftSpringAssist', 'softLiftSpringAssistOptions')}
				{(blindType === dtp.BlindType.Roller || blindType === dtp.BlindType.Roman) && this.renderStandard('Steel Chain', 'Steel chain required?', 'steelChain', 'steelChainOptions')}
				
				<ff.Heading>Extras</ff.Heading>
				<Input.Indexed 
					controller={this.controller}
					prop="extras" 
					renderEach={(eachController, cursor, actions) => {
						return (
							<div key={cursor.index} className="extra form-layout">
								<ff.ComboBox 
									title="Extra name" 
									code={ComboBoxCode.BLIND_EXTRAS} 
									controller={eachController} 
									prop="name"
								/>
								<ff.Price title="Price" project={this.props.project} controller={eachController} prop="pricing" />
								<button onClick={() => actions.onRemove(cursor.index)} type="button" className="button-link -secondary -micro">Remove</button>
							</div>
						)
					}}
					renderAfter={(actions) => {
						return (
							<div className="row -addextra">
								<div className="button-group">
									<button onClick={() => actions.onPush({})} type="button" className="button-link -secondary -small -icon -new -action">Add Extra</button>
								</div>
							</div>
						)
					}}
				/>

				<ff.TextArea title="Workroom instructions" prop="notes" controller={this.controller} />
			</>
		)
	}

	private renderStandard = (heading: string, question: string, questionKey: COMPATIBLEKEYS<dtp.BlindSpecifications, boolean | undefined>, optionsKey: COMPATIBLEKEYS<dtp.BlindSpecifications, dtp.BlindHardwareOptions | undefined>) => {
		const value = this.props.value.specifications || {}
		return (
			<>
				<ff.Heading>{heading}</ff.Heading>
				<ff.Toggle
					title={question}
					controller={this.controller}
					prop={questionKey}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]}
					showClear={true}
				/>
				{value[questionKey] === true && this.renderStandardOptions(this.controller.controller(optionsKey))}
			</>
		)
	}
	
	private renderStandardOptions = (controller: Controller<dtp.BlindHardwareOptions | undefined>) => {
		return (
			<>
				<ff.Price title="Pricing" prop="price" project={this.props.project} controller={controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = (item.value as dt.Product).details as dtp.Blind
		if (product && product.specifications) {
			this.controller.snapshot().onChange(product.specifications)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected blind doesn’t have specifications.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(BlindFormSpecifications)
