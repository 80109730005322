/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'

interface OwnProps {
	title: string
	type: string
	note?: string
	fieldVariant?: FormFieldVariant
	rowVariant?: FormRowVariant
	units?: string
}

export enum FormFieldVariant {
	Normal = '',
	Short = '-short',
	Wide = '-wide',
	WithOther = '-withother',
	WithButton = '-withbutton',
}

export enum FormRowVariant {
	Normal,
	Toggle,
}

export default class FormRow extends React.PureComponent<React.PropsWithChildren<OwnProps>> {

	/**
	 * An ES6 function definition. We define the function like this, rather than as per
	 * the render() function below so that it binds `this` automatically.
	 */
	// doExample = () => {
	// 	this.props.onExample(this.state.myValue)
	// }

	public render() {
		const { fieldVariant, rowVariant } = this.props

		return (
			<div className={'row' + (rowVariant ? (rowVariant === FormRowVariant.Toggle ? ' -toggle' : '') : '')}>
				<div className={'form-field' + (fieldVariant ? ` ${fieldVariant}` : '') + (this.props.type === 'options' ? ' -topalign' : '')}>
					<label className="label">{this.props.title}</label>
					<div className={'form-input -' + this.props.type}>
						{this.props.children && this.props.children}
					</div>
					{this.props.units && (
						<small className="units">{this.props.units}</small>
					)}
					{this.props.note && (
						<p className="note">{this.props.note}</p>
					)}
				</div>
			</div>
		)
	}
}
