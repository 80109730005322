import { reducerWithInitialState } from 'typescript-fsa-reducers'

/* Import our module's actions */
import * as a from './actions'
import * as t from './types'
import * as aa from 'modules/auth/actions'
import produce from 'immer'
import * as _ from 'lodash'
import { migrateProject, migrateProduct } from './migration'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	/** The uid of the user who owns the projects */
	userUid?: string
	/**
	 * Whether the value in userUid is valid or not. This is to help with migration from older versions that did not have
	 * the userUid property, so an undefined userUid does not necessarily mean no user, it just means we didn't record it.
	 */
	userUidIsValid?: boolean
	
	projects: StoreProjects
	online: boolean
}

export interface StoreProjects {
	[projectId: string]: t.Project | undefined
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	/* Note that we end each property with a comma, so we can add new properties without modifying this line
	(improve your git diffs!).
	 */
	projects: {},
	online: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.cases([a.updateProject, a.updateWholeProject], (state, payload) => produce(state, draft => {
	draft.projects[payload.projectId] = {
		...payload, 
		whenUpdated: new Date().toISOString(),
	}
}))

reducer.case(a.downloadedProjects, (state, payloads) => produce(state, draft => {
	for (const projectId in payloads) {
		let payload = payloads[projectId]

		/* Merge products from the existing project, as we don't get products from the download */
		const existingProject = draft.projects[payload.projectId]
		if (existingProject && existingProject.products) {
			payload = {
				...payload,
				products: existingProject.products,
			}
		}
		if (!payload.products) {
			payload = {
				...payload,
				products: {},
			}
		}

		/* Reset whenUploaded to last updated date so we don't re-upload */
		payload.whenUploaded = payload.whenUpdated

		draft.projects[payload.projectId] = payload
	}
}))

reducer.case(a.deleteProject, (state, projectId): StoreState => produce(state, draft => {
	const project = draft.projects[projectId]
	if (!project) {
		return
	}

	project.deleted = true
	project.whenDeleted = new Date().toISOString()
}))

reducer.case(a.updateProduct, (state, payload): StoreState => produce(state, draft => {
	const now = new Date()

	const project = draft.projects[payload.projectId]
	if (!project) {
		return
	}

	const product = project.products[payload.product.productId]
	const saveWhenUploaded = product ? product.whenUploaded : undefined

	const newProduct = {
		...payload.product,
		whenUpdated: now.toISOString(),
		/* Preserve the value we already have of whenUploaded, as it cannot be updated by updateProduct as the caller may not have
		   our latest value, as we update in this reducer, not in the product module.
		 */
		whenUploaded: saveWhenUploaded,
	}
	project.products[payload.product.productId] = newProduct

	/* Fix up legacy data */
	if (!newProduct.whenCreated) {
		newProduct.whenCreated = now.toISOString()
	}

	project.whenUpdated = now.toISOString()
}))

reducer.case(a.deleteProduct, (state, { projectId, productId }): StoreState => produce(state, draft => {
	const project = draft.projects[projectId]
	if (project) {
		const product = project.products[productId]
		if (product) {
			product.deleted = true
			product.whenDeleted = new Date().toISOString()
		}
	}
}))

reducer.case(a.undeleteProduct, (state, { projectId, productId }): StoreState => produce(state, draft => {
	const project = draft.projects[projectId]
	if (project) {
		const product = project.products[productId]
		if (product) {
			product.deleted = false
			product.whenDeleted = undefined
		}
	}
}))

reducer.case(a.downloadedProducts, (state, payload) => produce(state, draft => {
	if (!draft.projects[payload.projectId]) {
		console.error('Downloaded a product for a project that doesn\'t exist', payload.projectId)
		return
	}

	const project = draft.projects[payload.projectId]
	if (!project) {
		return
	}
	if (!project.products) {
		project.products = {}
	}

	for (const product of payload.products) {
		project.products[product.productId] = {
			...product,
			/* Reset whenUploaded to last updated date so we don't re-upload */
			whenUploaded: product.whenUpdated,
		}
	}
}))

reducer.case(a.uploadedProject, (state, payload): StoreState => produce(state, draft => {
	const project = draft.projects[payload.projectId]
	if (project) {
		project.whenUploaded = payload.whenUpdated
	}
}))

reducer.case(a.uploadedProduct, (state, payload): StoreState => produce(state, draft => {
	if (!draft.projects[payload.projectId]) {
		console.error('Uploaded a product for a project that doesn\'t exist', payload.projectId)
		return
	}
	const project = draft.projects[payload.projectId]
	if (!project) {
		return
	}
	if (!project.products) {
		project.products = {}
	}

	const product = project.products[payload.product.productId]
	if (product) {
		product.whenUploaded = payload.product.whenUpdated
	}
}))

reducer.case(aa.login, (state) => produce(state, draft => {
	if (!draft.userUidIsValid) {
		/* If the user requests to sign in, then we can now say that the userUid field is valid as blank,
		   and therefore we'll blank the projects when they login successfully, as there was no user logged in.
		 */
		draft.userUidIsValid = true
		draft.userUid = undefined
	}
}))

reducer.case(aa.loggedIn, (state, payload) => produce(state, draft => {
	if (draft.userUid !== payload.uid) {
		if (draft.userUidIsValid) {
			/* Blank the projects as this is a new user */
			draft.projects = {}
		} else {
			/* The user gets logged in automatically when the app launches if Firebase has remembered them,
			   so if we haven't seen an explicit login request, we can now say that we trust that the logged in
			   user is the user who was logged in before, and therefore owns the projects.
			 */
			draft.userUidIsValid = true
		}
	}
	draft.userUid = payload.uid
}))

reducer.case(a.databaseReady, (state): StoreState => produce(state, draft => {
	_.values(draft.projects).forEach(project => {
		migrateProject(project!)
		_.values(project!.products).forEach(product => {
			migrateProduct(product!)
		})
	})
}))

reducer.case(a.onlineStateChange, (state, payload) => produce(state, draft => {
	draft.online = payload
}))
