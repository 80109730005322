import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as cf from '../functions'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { toNumber } from 'modules/root/functions'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'

interface Row {
	quantity?: number
	productType?: string
	room?: string
	window?: string
	type?: string
	code?: string
	colour?: string
	brackets?: string
	bracketsType?: string
	width?: number
	finishedLength?: number
	notes?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.quantity,
		row.productType,
		row.room,
		row.window,
		row.type,
		row.code,
		row.colour,
		row.brackets,
		row.bracketsType,
		row.width,
		row.finishedLength,
		row.notes,
	]
}

const Header: HeaderRow<Row> = {
	quantity: 'Quantity',
	productType: 'Product Type',
	room: 'Room',
	window: 'Window',
	type: 'Type',
	code: 'Code',
	colour: 'Colour',
	brackets: 'Brackets',
	bracketsType: 'Brackets Type',
	width: 'Width (mm)',
	finishedLength: 'Drop (mm)',
	notes: 'Notes',
}

export default function exportCurtainsWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header: Header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(supplier: PurchaseOrderSupplier, c: dtp.Blind, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c || !c.hardware) {
		return []
	}

	const o = c.overview
	if (!o) {
		return []
	}

	const outputAutomation = c.hardware.automation && c.hardware.automationOptions && c.hardware.automationOptions.supplier === supplier.supplier
	if (!outputAutomation) {
		return []
	}

	const result: Row[] = []

	// 'Quantity', Product Type', 'Room', 'Window', 'Type', 'Code', 'Colour', 
	// 'Brackets', 'Brackets Type', Track Width (mm)', 'Drop (mm)',
	// 'Notes'
	if (outputAutomation && c.hardware.automationOptions) {
		if (c.hardware.automationOptions.motorBrand) {
			const data: Row = {}
			data.quantity = 1
			data.productType = 'Motor'
			data.room = o.room
			data.window = o.window
			data.type = c.hardware.automationOptions.hardWired ? 'Hard-wired' : 'Wireless'
			data.code = c.hardware.automationOptions.motorBrand

			const width = c.measures && cf.calculateWidth(c.measures)
			const finishedLength = c.measures && cf.calculateFinishedLength(c.measures)
			data.width = width && width.getResultOrUndefined()
			data.finishedLength = finishedLength && finishedLength.getResultOrUndefined()

			data.notes = c.hardware.automationOptions.notes
			
			result.push(data)
		}
		if (c.hardware.automationOptions.remotes) {
			const data: Row = {}
			data.quantity = toNumber(c.hardware.automationOptions.numberOfRemotes)
			data.productType = 'Remote'
			data.room = o.room
			data.window = o.window
			if (c.hardware.automationOptions.numberOfRemoteChannels !== undefined) {
				data.type = `${c.hardware.automationOptions.numberOfRemoteChannels} ${c.hardware.automationOptions.numberOfRemoteChannels === 1 ? 'channel' : 'channels'}`
			}
			data.code = c.hardware.automationOptions.remoteModel
			data.colour = c.hardware.automationOptions.colourOfRemotes
			
			result.push(data)
		}
	}

	return result
}
