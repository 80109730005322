/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { getAssetURL, convertURLForLocal } from 'modules/storage'
import { ProgressCircular } from 'react-onsenui'
import * as _ from 'lodash'

interface OwnProps {
	path: string
	alt?: string
}

interface State {
	photoPath?: string
	photoUrl?: string
	photoError?: string
	loading: boolean
	errorCount: number
}

const INITIAL_STATE: State = {
	loading: false,
	errorCount: 0,
}

export default class PhotoDisplay extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	private unmounted = false

	public render() {
		const { loading, photoUrl, photoError } = this.state

		if (loading) {
			return (
				<ProgressCircular indeterminate={true} />
			)
		}
		if (photoError) {
			return (
				<img src="" alt="Could not be loaded" />
			)
		}

		if (photoUrl) {
			return (
				<img src={convertURLForLocal(photoUrl)} alt={this.props.alt} />
			)
		}

		return null
	}

	public componentDidMount() {
		this.loadImage()
	}

	public componentDidUpdate() {
		this.loadImage()
	}

	public componentWillUnmount() {
		this.unmounted = true
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps>, nextState: Readonly<State>): boolean {
		if (nextProps.path !== this.props.path) {
			return true
		}
		if (nextProps.alt !== this.props.alt) {
			return true
		}
		if (!_.isEqual(nextState, this.state)) {
			return true
		}
		return false
	}

	private loadImage = () => {
		const value = this.props.path

		if (!value && this.state.photoUrl) {
			this.setState({ photoPath: undefined, photoUrl: undefined, photoError: undefined })
			return
		}

		if (value !== this.state.photoPath || (this.state.photoError && this.state.errorCount < 3)) {
			if (value === this.state.photoPath && this.state.loading) {
				/* We're already loading this one; prevent re-entry when we update state */
				return
			}

			if (value !== this.state.photoPath) {
				this.setState({ loading: true, photoPath: value, errorCount: 0, photoError: undefined })
			} else {
				this.setState({ loading: true })
			}
			
			getAssetURL(value).then(url => {
				if (this.unmounted) {
					return
				}

				// console.debug('PHOTO DISPLAY: got url', url)
				this.setState({
					photoPath: value,
					photoUrl: url,
					loading: false,
					photoError: undefined,
					errorCount: 0,
				})
			}).catch(error => {
				if (this.unmounted) {
					return
				}

				console.log('PHOTO DISPLAY: failed to get url', this.state.errorCount, error)
				this.setState({
					photoPath: value,
					photoUrl: undefined,
					loading: false,
					photoError: `Failed to download photo: ${error}`,
					errorCount: this.state.errorCount + 1,
				})

				setTimeout(this.loadImage, 1000)
			})
		}
	}
	
}
