import { reducerWithInitialState } from 'typescript-fsa-reducers'

/* Import our module's actions */
import * as a from './actions'
import * as t from './types'
import * as aa from 'modules/auth/actions'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	user: t.User
	userAdministrative?: t.UserAdministrative
	editingUser?: Partial<t.User>
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	user: {},
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

/** Reducer function for the exampleAction that returns a new state using an implicit return. */
reducer.case(a.downloadedUser, (state, payload): StoreState => ({
	...state,
	user: payload,
}))
reducer.case(a.downloadedUserAdmin, (state, payload): StoreState => ({
	...state,
	userAdministrative: payload,
}))

reducer.case(a.updateEditingUser, (state, payload): StoreState => ({
	...state,
	editingUser: payload,
}))

reducer.case(a.saveUser, (state): StoreState => ({
	...state,
	user: {
		...state.user,
		...state.editingUser,
	},
}))

reducer.cases([aa.login, aa.loggedOut], (state) => INITIAL_STATE)
