import * as React from 'react'
import * as _ from 'lodash'

import { Props } from '../containers/StorageMessageBar'
import MessageBar from 'modules/root/components/MessageBar'

export default class StorageMessageBar extends React.Component<Props> {

	public render() {
		let uploadsQueued = 0
		let uploadsInProgress = 0
		let totalBytesToUpload = 0
		let totalBytesUploaded = 0

		_.forOwn(this.props.uploads, (value, key) => {
			if (value.uploading) {
				uploadsInProgress += 1
				totalBytesUploaded += value.progress
			} else {
				uploadsQueued += 1
			}
			totalBytesToUpload += value.size
		})

		if (!this.props.online) {
			if (uploadsQueued === 0 && uploadsInProgress === 0) {
				return (
					<MessageBar message={'You are working offline. Any changes will be uploaded when you go online.'} />
				)
			} else {
				const uploadsToReport = uploadsQueued + uploadsInProgress
				return (
					<MessageBar message={'You are working offline. There ' + (uploadsToReport === 1 ? 'is' : 'are') + ' ' + uploadsToReport + ' upload' + (uploadsToReport !== 1 ? 's' : '') + ' queued.'}/>
				)
			}
		} else if (uploadsInProgress > 0) {
			if (totalBytesUploaded === 0) {
				return (
					<MessageBar message={`Uploading ${Math.round(totalBytesToUpload / 1024)} KB (${uploadsInProgress} file${uploadsInProgress !== 1 ? 's' : ''})`} />
				)
			} else {
				return (
					<MessageBar message={`Uploading ${Math.round(totalBytesUploaded / 1024)} KB of ${Math.round(totalBytesToUpload / 1024)} KB (${uploadsInProgress} file${uploadsInProgress !== 1 ? 's' : ''})`} />
				)
			}
		}

		return null
	}
}
