import * as dt from 'modules/database/types'
import * as excel from 'exceljs'
import * as ut from 'modules/user/types'
import { productsByType, allProducts } from './functions'
import { fontBoldLarge, formatCurrency, addLogo, addConsultant, addProject, addCompany, addTitle, fontBoldMedium, autoSize, addSubtitle, alignLastColumn } from './excel'
import { productTypeInfo } from 'modules/database/functions'
import exportCurtainsSummary from 'modules/product/curtains/quote'
import exportBlindsSummary from 'modules/product/blinds/quote'
import exportCushionsSummary from 'modules/product/cushions/quote'
import { calculatePricing, PricingResult } from './calculations'
import { addImagesSheet, ImagesSummary } from './images'

export interface QuoteSummary {
	totalPricing: PricingResult
	images: ImagesSummary
	headerRows: number[]
}

export default async function createQuote(project: dt.Project, user: ut.User): Promise<Blob> {
	const wb = new excel.Workbook()
	const sheet = wb.addWorksheet('Quote')
	sheet.views = [
		{
			showGridLines: false,
		},
	]
	sheet.pageSetup = {
		orientation: 'portrait',
		fitToWidth: 1,
		paperSize: 9,
	}

	await addLogo(sheet, 1, 1)

	addCompany(sheet, user)
	addTitle(sheet, 'QUOTATION')

	sheet.addRow([])

	addConsultant(sheet, user)
	addProject(sheet, project)

	sheet.addRow([])

	const sheetDataFrom = sheet.rowCount

	const productTypes = dt.AllProductTypes
	const pbt = productsByType(allProducts(project))
	const summary: QuoteSummary = {
		totalPricing: {
			cost: 0,
			rrp: 0,
		},
		images: {
			images: [],
		},
		headerRows: [],
	}

	productTypes.forEach(productType => {
		const products = pbt.productsByType[productType]
		if (products && products.products.length) {
			const ptInfo = productTypeInfo(productType)
			addSubtitle(sheet, ptInfo.collectiveName)

			switch (productType) {
				case dt.ProductType.Curtain:
					exportCurtainsSummary(sheet, project, products.products, summary)
					break
				case dt.ProductType.Blind:
					exportBlindsSummary(sheet, project, products.products, summary)
					break
				case dt.ProductType.Cushion:
					exportCushionsSummary(sheet, project, products.products, summary)
					break
				default:
					throw new Error(`quote: Unsupported product type: ${productType}`)
			}
			
			sheet.addRow([])
		}
	})

	const priceColumn = alignLastColumn(sheet, sheetDataFrom, sheet.rowCount, summary.headerRows)

	addSubtotal(sheet, project, summary, priceColumn)
	sheet.addRow([])

	if (project.costs) {
		let anyCosts = false
		if (project.costs.travel) {
			if (addCost(sheet, 'Travel', project.costs.travel, project.taxDetails, summary, priceColumn)) {
				anyCosts = true
			}
		}
		if (project.costs.consulting) {
			if (addCost(sheet, 'Consulting', project.costs.consulting, project.taxDetails, summary, priceColumn)) {
				anyCosts = true
			}
		}
		if (project.costs.installation) {
			if (addCost(sheet, 'Installation', project.costs.installation, project.taxDetails, summary, priceColumn)) {
				anyCosts = true
			}
		}
		if (project.costs.additionalCharges) {
			const label = project.costs.additionalChargesLabel || 'Additional Charges'
			if (addCost(sheet, label, project.costs.additionalCharges, project.taxDetails, summary, priceColumn)) {
				anyCosts = true
			}
		}

		if (anyCosts) {
			sheet.addRow([])
		}
	}

	addTotal(sheet, project, summary, priceColumn)

	const sheetDataTo = sheet.rowCount
	autoSize(sheet, sheetDataFrom, sheetDataTo)

	if (user.termsAndConditions) {
		sheet.addRow([])
		const termsRow = sheet.addRow([user.termsAndConditions])
		sheet.addRow([])
		sheet.addRow([])
		sheet.addRow([])
		sheet.addRow([])
		sheet.addRow([])
		const lastTermsRow = sheet.addRow([])
		termsRow.alignment = {
			vertical: 'top',
			wrapText: true,
		}
		sheet.mergeCells(termsRow.number, 1, lastTermsRow.number, priceColumn)
	}

	await addImagesSheet(wb, summary.images)

	return wb.xlsx.writeBuffer().then(data => {
		return new Blob([data as ArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
	})
}

function addCost(sheet: excel.Worksheet, title: string, pricing: dt.Pricing, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary, valueColumn: number): boolean {
	if (pricing.mode === dt.PricingMode.Cost && pricing.cost === undefined) {
		return false
	}
	if (pricing.mode === dt.PricingMode.RRP && pricing.rrp === undefined) {
		return false
	}

	const calculation = calculatePricing(pricing, taxDetails, 1, title)
	if (calculation.isErrored()) {
		throw new Error(calculation.formatErrorsAsText())
	}

	const value = calculation.getResult()
	if (!value || !value.rrp) {
		return false
	}

	summary.totalPricing.cost += value.cost
	summary.totalPricing.rrp += value.rrp

	const row = sheet.addRow([])
	fontBoldMedium(row)
	row.getCell(1).value = title
	row.getCell(valueColumn).value = value.rrp
	formatCurrency(row.getCell(valueColumn))
	return true
}

function addSubtotal(quoteSheet: excel.Worksheet, project: dt.Project, summary: QuoteSummary, valueColumn: number) {
	const taxName = project.taxDetails && project.taxDetails.taxName

	const subTotalRow = quoteSheet.addRow([])
	fontBoldLarge(subTotalRow)
	subTotalRow.getCell(1).value = taxName ? `Sub Total incl ${taxName}` : 'Sub Total'
	subTotalRow.getCell(valueColumn).value = summary.totalPricing.rrp
	formatCurrency(subTotalRow.getCell(valueColumn))
}

function addTotal(quoteSheet: excel.Worksheet, project: dt.Project, summary: QuoteSummary, valueColumn: number) {
	const taxName = project.taxDetails && project.taxDetails.taxName
	
	const totalRow = quoteSheet.addRow([])
	fontBoldLarge(totalRow)
	totalRow.getCell(1).value = taxName ? `Total incl ${taxName}` : 'Total'
	totalRow.getCell(valueColumn).value = summary.totalPricing.rrp
	formatCurrency(totalRow.getCell(valueColumn))
}
