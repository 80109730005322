/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'

type HeadingType = 'sub' | 'section' | 'page'

interface OwnProps {
	type?: HeadingType
}

export default class Heading extends React.PureComponent<React.PropsWithChildren<OwnProps>> {

	public render() {
		const { type } = this.props
		return (
			<h2 className={`title-${type || 'sub'}`}>{this.props.children}</h2>
		)
	}
	
}
