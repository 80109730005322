import * as React from 'react'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './BlindForm'
import * as cf from '../functions'
import { formatNumber } from 'modules/root/functions'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { forComponentProps, Input, wrapComponent } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class BlindFormMeasures extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('measures')

	public render() {
		const snapshot = this.controller.snapshot()
		const value = snapshot.value || {}

		const sameReturnSize = value.returnSizesEqual !== false

		const widthCalculation = cf.calculateWidth(value, true)
		const heightCalculation = cf.calculateFinishedLength(value, true)

		const blindType = this.props.value.overview && this.props.value.overview.blindType

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{cf.blindName(this.props.value)} measures</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Blind}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
					/>
				)}

				<ff.Heading>Calculate blind width</ff.Heading>
				
				<ff.Radios
					prop="widthCalculationMethod"
					controller={this.controller}
					title="Calculation method"
					inline={false}
					options={[
						{ label: 'Inside measurement', value: dtp.BlindWidthCalculationMethod.inside },
						{ label: 'Outside measurement', value: dtp.BlindWidthCalculationMethod.outside },
					]}
				/>

				<div className="row">
					<div className="form-field -grouped">
						<label className="label">
							Architrave to Architrave
						</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Inside width</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="archToArchWidthInside" />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Outside width</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="archToArchWidthOutside" />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Return size</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Left</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="returnSizeLeft" />
							</div>
							<div className="joiner">
								<button type="button" className={`link${sameReturnSize ? ' -linked' : ''}`} onClick={this.onSameReturnSize}>Same size for both</button>
							</div>
							<div className="form-input -text -stacked">
								<label className="label">Right</label>
								{!sameReturnSize ? (
									<Input.Number type="number" className="field" controller={this.controller} prop="returnSizeRight" />
								) : (
									<input type="number" className="field" disabled={true} value={value.returnSizeLeft || ''} />
								)}
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				
				<div className="row -subsection">
					<div className="form-field -topalign">
						<h2 className="label -strong">Calculated width</h2>
						<div className="form-input -text -stacked">
							{widthCalculation.isSuccess() ? (
								<p className="value">{formatNumber(widthCalculation.getResult())} mm</p>
							) : value.useCustomWidth ? null : widthCalculation.formatErrors()}
							<p className="note">{widthCalculation.formatFormula()}</p>
							<ul className="option-inputs -inline">
								<li className="option -checkbox">
									<label className="label">
										<Input.Checkable type="checkbox" className="checkbox" controller={this.controller} prop="useCustomWidth" checkedValue={true} /><span className="substitute" />
										Specify a custom width
									</label>
								</li>
							</ul>
							{value.useCustomWidth && (
								<div className="form-input -text">
									<Input.Number type="number" className="field" controller={this.controller} prop="customWidth" /><small className="units">mm</small>
								</div>
							)}
						</div>
					</div>
				</div>
				
				<ff.Heading>Calculate blind height</ff.Heading>
				<ff.Radios
					prop="finishedLengthCalculationMethod"
					controller={this.controller}
					title="Calculation method"
					inline={false}
					options={[
						{ label: 'Inside measurement', value: dtp.BlindFinishedLengthCalculationMethod.inside },
						{ label: 'Outside measurement', value: dtp.BlindFinishedLengthCalculationMethod.outside },
					]}
				/>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">
							Architrave to Architrave
						</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Inside height</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="archToArchHeightInside" />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Outside height</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="archToArchHeightOutside" />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Fix Above Architrave</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<Input.Number type="number" className="field" controller={this.controller} prop="aboveArch" />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div> 
				<div className="row">
					<div className="form-field -grouped">
						<label className="label">Hem Below Architrave</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<Input.Number type="number" className="field" controller={this.controller} prop="belowArch" />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				{blindType === dtp.BlindType.Roman && (
					<div className="row">
						<div className="form-field -grouped">
							<label className="label">Stack Height</label>
							<div className="group">
								<div className="form-input -text -stacked">
									<Input.Number type="number" className="field" controller={this.controller} prop="stackHeight" />
								</div>
								<small className="units">mm</small>
							</div>
						</div>
					</div>
				)}
				<div className="row -subsection">
					<div className="form-field -topalign">
						<h2 className="label -strong">Calculated drop</h2>
						<div className="form-input -text -stacked">
							{heightCalculation.isSuccess() ? (
								<p className="value">{formatNumber(heightCalculation.getResult())} mm</p>
							) : value.useCustomHeight ? null : heightCalculation.formatErrors()}
							
							<p className="note">{heightCalculation.formatFormula()}</p>
							<ul className="option-inputs -inline">
								<li className="option -checkbox">
									<label className="label">
										<Input.Checkable type="checkbox" className="checkbox" controller={this.controller} prop="useCustomHeight" checkedValue={true} /><span className="substitute" />
										Specify a custom drop
									</label>
								</li>
							</ul>
							{value.useCustomHeight && (
								<div className="form-input -text">
									<Input.Number type="number" className="field" controller={this.controller} prop="customHeight" /><small className="units">mm</small>
								</div>
							)}
						</div>
					</div>
				</div>
				{blindType === dtp.BlindType.Roman && (
					<>
						<ff.Heading>Other reference measurements</ff.Heading>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Room</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">Wall to wall</label>
										<Input.Number type="number" prop="wallToWall" className="field" controller={this.controller} />
									</div>
									<div className="joiner" />
									<div className="form-input -text -stacked">
										<label className="label">Ceiling to floor</label>
										<Input.Number type="number" prop="ceilingToFloor" className="field" controller={this.controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Architrave</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">Width</label>
										<Input.Number type="number" prop="archToArchWidth" className="field" controller={this.controller} />
									</div>
									<div className="joiner" />
									<div className="form-input -text -stacked">
										<label className="label">Height</label>
										<Input.Number type="number" prop="archToArchHeight" className="field" controller={this.controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label" />
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">To left wall</label>
										<Input.Number type="number" prop="wallToArchLeft" className="field" controller={this.controller} />
									</div>
									<div className="joiner" />
									<div className="form-input -text -stacked">
										<label className="label">To right wall</label>
										<Input.Number type="number" prop="wallToArchRight" className="field" controller={this.controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label" />
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">To ceiling</label>
										<Input.Number type="number" prop="ceilingToArch" className="field" controller={this.controller} />
									</div>
									<div className="joiner" />
									<div className="form-input -text -stacked">
										<label className="label">To floor</label>
										<Input.Number type="number" prop="floorToArch" className="field" controller={this.controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -grouped">
								<label className="label">Sill</label>
								<div className="group">
									<div className="form-input -text -stacked">
										<label className="label">To floor</label>
										<Input.Number type="number" prop="sillToFloor" className="field" controller={this.controller} />
									</div>
									<small className="units">mm</small>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		)
	}

	private onSameReturnSize = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const snapshot = this.controller.snapshot('returnSizesEqual')
		const value = snapshot.value || false

		const sameReturnSize = value !== false
		const newSameReturnSize = !sameReturnSize

		snapshot.onChange(newSameReturnSize)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = (item.value as dt.Product).details as dtp.Blind
		if (product.measures) {
			this.controller.snapshot().onChange(product.measures)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected blind doesn’t have measures.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(BlindFormMeasures)
