import * as dt from 'modules/database/types'
import * as s from './selectors'
import * as t from './types'
import * as f from './functions'
import { RootStoreState } from 'modules/root'

export type { ComboBoxValue, ComboBoxResult } from './types'

export enum ComboBoxCode {
	ROOM = 'ROOM',
	WINDOW = 'WINDOW',
	FABRIC_SUPPLIER = 'FABRIC_SUPPLIER',
	FABRIC_COLLECTION = 'FABRIC_COLLECTION',
	FABRIC_NAME = 'FABRIC_NAME',
	LINING = 'LINING',
	TRIM = 'TRIM',
	HARDWARE_SUPPLIER = 'HARDWARE_SUPPLIER',
	HARDWARE_CODE = 'HARDWARE_CODE',
	COLOUR = 'COLOUR',
	FINIALS_TYPE = 'FINIALS_TYPE',
	BRACKETS_TYPE = 'BRACKETS_TYPE',
	HOLDBACK_NAME = 'HOLDBACK_TYPE',
	FLICK_STICKS_NAME = 'FLICK_STICKS_NAME',
	AUTOMATION_MOTOR_BRAND = 'AUTOMATION_MOTOR_BRAND',
	REMOTE_MODEL = 'REMOTE_MODEL',
	HEADER_TYPE = 'HEADER_TYPE',
	WORKROOM_SUPPLIER = 'WORKROOM_SUPPLIER',
	CURTAIN_EXTRAS = 'CURTAIN_EXTRAS',

	/* Blinds */
	BOTTOM_RAIL_SHAPE = 'BOTTOM_RAIL_SHAPE',
	BLIND_EXTRAS = 'BLIND_EXTRAS',
	CONTROL_LENGTH = 'CONTROL_LENGTH',

	/* Cushions */
	FILL = 'FILL',
}

export function comboBoxValues(state: RootStoreState, projectId: dt.ProjectID, code: ComboBoxCode, context?: string): t.ComboBoxResult {
	const userData = s.comboBoxUserData(state)
	const project = s.comboBoxProjectData(state, projectId)

	return f.comboBoxValues(userData, project, code, context)
}

export { useValue as useValueAction } from './actions'
