import { takeEvery, put, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { navigatorPush, navigatorPop } from 'onsenui-react-redux-navigator'
import * as a from './actions'
import * as s from './selectors'
import * as ds from 'modules/database/selectors'
import * as da from 'modules/database/actions'
import * as t from './types'
import { v4 as uuid } from 'uuid'
import platform from 'modules/platform'
import * as _ from 'lodash'
import * as df from 'modules/database/functions'

function* handleChooseProject(action: a.ChooseProjectAction): SagaIterator {
	console.log('PROJECT: choose project', action.payload)
	const props: t.ProjectRouteProps = {
		projectId: action.payload,
	}

	yield put(navigatorPush({
		navigator: 'maqasa',
		route: {
			component: 'ProjectDetails',
			props,
		},
	}))
}

function* handleEditProject(action: a.EditProjectAction): SagaIterator {
	const project = (yield select(ds.projectByIdOrUndefined, action.payload)) as ReturnType<typeof ds.projectByIdOrUndefined>
	if (project) {
		console.log('PROJECT: edit project', project)
		yield put(a.updateEditingProject(project))

		yield put(navigatorPush({
			navigator: 'maqasa',
			route: {
				component: 'EditProjectForm',
			},
			options: {
				animation: 'lift',
			},
		}))
	} else {
		throw new Error(`Project not found: ${action.payload}`)
	}
}

function* handleNewProject(): SagaIterator {
	yield put(navigatorPush({
		navigator: 'maqasa',
		route: {
			component: 'EditProjectForm',
		},
		options: {
			animation: 'lift',
		},
	}))
}

function* handleDuplicateProject(action: a.DuplicateProjectAction): SagaIterator {
	const project = _.cloneDeep(action.payload)
	project.projectId = uuid()
	project.name = `Copy of ${df.projectTitle(project)}`
	yield put(da.updateWholeProject(project))
	yield put(a.chooseProject(project.projectId))
}

function* handleImportProject(action: ReturnType<typeof a.importProject>): SagaIterator {
	const project = action.payload
	if (project) {
		/* Assign the project a new uuid */
		project.projectId = uuid()
		
		console.log('Importing project...', project)
		yield put(da.updateWholeProject(project))
	} else {
		platform.alert('No project to import')
	}
}

function* handleCloseProjectForm(): SagaIterator {
	yield put(navigatorPop('maqasa'))
}

function* handleSaveProject(): SagaIterator {
	const project = (yield select(s.currentProject)) as ReturnType<typeof s.currentProject>
	const newProject = project ? project.whenCreated === project.whenUpdated : false
	if (project) {
		yield put(da.updateProject(project))
	}
	yield put(a.closeProjectForm())
	if (newProject && project) {
		yield put(a.chooseProject(project.projectId))
	}
}

function* handleOpenUserDetails(): SagaIterator {
	yield put(navigatorPush({
		navigator: 'maqasa',
		route: {
			component: 'UserDetails',
		},
		options: {
			animation: 'lift',
		},
	}))
}

function* handleOpenChangePassword(): SagaIterator {
	yield put(navigatorPush({
		navigator: 'maqasa',
		route: {
			component: 'ChangePassword',
		},
		options: {
			animation: 'lift',
		},
	}))
}

/** The saga function for this module that is run from our root saga. */
export default function* saga(): SagaIterator {
	yield takeEvery(a.chooseProject, handleChooseProject)
	yield takeEvery(a.saveProject, handleSaveProject)
	yield takeEvery(a.editProject, handleEditProject)
	yield takeEvery(a.newProject, handleNewProject)
	yield takeEvery(a.importProject, handleImportProject)
	yield takeEvery(a.closeProjectForm, handleCloseProjectForm)
	yield takeEvery(a.openUserDetails, handleOpenUserDetails)
	yield takeEvery(a.openChangePassword, handleOpenChangePassword)
	yield takeEvery(a.duplicateProject, handleDuplicateProject)
}
