/**
 * Functions for sending OAuth2 requests to the server.
 */

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { db } from 'modules/database'
import { store } from 'modules/root'
import { downloadUser } from 'modules/user/actions'
import { deleteUndefined } from 'modules/database/migration'

/** Attempt to obtain an AccessToken with the given credentials. */
export function authenticate(username: string, password: string): Promise<firebase.auth.UserCredential> {
	console.log('AUTH: authenticate')
	return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		.then(function() {
			return firebase.auth().signInWithEmailAndPassword(username, password)
		})
		.catch(function(error: firebase.FirebaseError) {
			console.error(`AUTH: ${error.message}`)
			throw error
		})
}

export interface RegistrationForm {
	emailAddress: string
	password: string
	givenName?: string
	familyName?: string
	organisation?: string
	country?: string
}

export async function register(form: RegistrationForm): Promise<firebase.auth.UserCredential> {
	console.log('AUTH: register')
	try {
		await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		const userCredential = await firebase.auth().createUserWithEmailAndPassword(form.emailAddress, form.password)
		console.log('register', userCredential)
		if (userCredential.user) {
			const data = {
				givenName: form.givenName,
				familyName: form.familyName,
				organisation: form.organisation,
				country: form.country,
				terms: new Date().toUTCString(), /* The time at which they accepted the terms */
			}
			deleteUndefined(data)
			await db.collection('users').doc(userCredential.user.uid).set(data)

			/* We downloaded the user as soon as the user was logged in, so we need to download again now that we've updated the user */
			store.dispatch(downloadUser())
		}
		return userCredential
	} catch (error) {
		console.error(`AUTH: ${(error as Error).message}`)
		throw error
	}
}

export function logOut(): Promise<void> {
	console.log('AUTH: logOut')
	return firebase.auth().signOut()
}

/** Attempt to refresh the AccessToken using the given refresh token.
 * Returns a new AccessToken.
 */
// export function refresh(refreshToken: string): Promise<firebase.auth.UserCredential> {
// const config = getAuthConfig()
// let query = {
// 	client_id: config.clientId,
// 	client_secret: config.clientSecret,
// 	grant_type: 'refresh_token',
// 	refresh_token: refreshToken,
// }
// let formData = url.format({ query }).substring(1)

// let options: RequestInit = {
// 	method: 'POST',
// 	body: formData,
// 	headers: {
// 		'content-type': 'application/x-www-form-urlencoded'
// 	}
// }
// 	return fetchAccessToken(options)
// }

/** Refresh the access token, apply it to the store, and return a promise
 * indicating whether or not it was successful. This methods gets the current
 * refresh token from the store, so there's no need to know any context to
 * call it.
 */
// export function refreshTokenAndApply(): Promise<AccessToken> {
// 	return new Promise((resolve, reject) => {
// 		let accessToken = store.getState().auth.accessToken
// 		if (accessToken) {
// 			refresh(accessToken.refresh_token).then(refreshedAccessToken => {
// 				store.dispatch(actions.refreshedToken(refreshedAccessToken))
// 				resolve(refreshedAccessToken)
// 			}).catch(error => {
// 				store.dispatch(actions.refreshTokenFailed(Date.now()))
// 				reject(error)
// 			})
// 		} else {
// 			reject(new Error('Not logged in'))
// 		}
// 	})
// }
