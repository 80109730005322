import { RootStoreState } from 'modules/root'
import * as dt from 'modules/database/types'
import { createSelector } from 'reselect'
import { v4 as uuid } from 'uuid'
import { currentUser } from 'modules/user/selectors'
import { User } from 'modules/user/types'

export const currentProjectId = (state: RootStoreState) => state.project.currentProject && state.project.currentProject.projectId
export const currentProject = (state: RootStoreState) => state.project.currentProject

export const currentProjectFormState = createSelector(
	currentProject,
	currentUser,
	(project, user) => {
		if (!project) {
			return defaultProject(user)
		}

		return project
	},
)

function defaultProject(user: User): dt.Project {
	return {
		address: '',
		propertyType: dt.PropertyType.House,
		projectId: uuid(),
		products: {},
		whenCreated: new Date().toISOString(),
		whenUpdated: new Date().toISOString(),
		taxDetails: user.taxDetails,
		schemaVersion: dt.CurrentProjectSchemaVersion,
	}
}
