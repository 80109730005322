import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as cf from '../functions'
import { toNumber } from 'modules/root/functions'
import { AvailablePurchaseOrderSupplier } from 'modules/project/purchaseorders'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { titleCase } from 'title-case'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'

interface Row {
	product?: string
	room?: string
	window?: string
	blindType?: string
	rollerBlindSetNo?: string
	rollerBlindBottomRailShape?: string
	rollerBlindBottomRailColour?: string
	venetianBladeSize?: number
	headerType?: string
	stackHeight?: number
	fixingDetails?: string
	controlType?: string
	controlPosition?: string
	controlLength?: number
	controlColour?: string
	fabricSupplier?: string
	fabricCollection?: string
	fabric?: string
	fabricColour?: string
	fabricOrientation?: string
	fabricWidth?: number
	fabricDrops?: number
	fabricCutLength?: number
	liningSupplier?: string
	lining?: string
	liningColour?: string
	liningWidth?: number
	liningDrops?: number
	liningCutLength?: number
	interliningSupplier?: string
	interlining?: string
	interliningWidth?: number
	interliningDrops?: number
	interliningCutLength?: number
	trimSupplier?: string
	trim?: string
	width?: number
	finishedLength?: number
	workroomInstructions?: string
	installationNotes?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.product,
		row.room,
		row.window,
		row.blindType,
		row.rollerBlindSetNo,
		row.rollerBlindBottomRailShape,
		row.rollerBlindBottomRailColour,
		row.venetianBladeSize, 
		row.headerType,
		row.stackHeight,
		row.fixingDetails,
		row.controlType,
		row.controlPosition,
		row.controlLength,
		row.controlColour,
		row.fabricSupplier,
		row.fabricCollection,
		row.fabric,
		row.fabricColour,
		row.fabricOrientation,
		row.fabricWidth,
		row.fabricDrops,
		row.fabricCutLength,
		row.liningSupplier,
		row.lining,
		row.liningColour,
		row.liningWidth,
		row.liningDrops,
		row.liningCutLength,
		row.interliningSupplier,
		row.interlining,
		row.interliningWidth,
		row.interliningDrops,
		row.interliningCutLength,
		row.trimSupplier,
		row.trim,
		row.width,
		row.finishedLength,
		row.workroomInstructions,
		row.installationNotes,
	]
}

const Header: HeaderRow<Row> = {
	product: 'Product', 
	room: 'Room', 
	window: 'Window', 
	blindType: 'Blind Type', 

	rollerBlindSetNo: 'Set No.', 
	rollerBlindBottomRailShape: 'Bottom Rail Shape', 
	rollerBlindBottomRailColour: 'Bottom Rail Colour',
	venetianBladeSize: 'Blade Size (mm)', 

	headerType: 'Header Type', 
	stackHeight: 'Stack Height (mm)',
	fixingDetails: 'Fixing Details',

	controlType: 'Control Type', 
	controlPosition: 'Control Position', 
	controlLength: 'Control Length (mm)', 
	controlColour: 'Control Colour', 

	fabricSupplier: 'Fabric Supplier', 
	fabricCollection: 'Fabric Collection', 
	fabric: 'Fabric / Material', 
	fabricColour: 'Fabric / Material Colour', 
	fabricOrientation: 'Fabric Orientation', 
	fabricWidth: 'Fabric / Blade Width (mm)', 
	fabricDrops: 'Fabric Drops ()', 
	fabricCutLength: 'Fabric Cut Length (mm)', 

	liningSupplier: 'Lining Supplier', 
	lining: 'Lining', 
	liningColour: 'Lining Colour', 
	liningWidth: 'Lining Width (mm)', 
	liningDrops: 'Lining Drops ()', 
	liningCutLength: 'Lining Cut Length (mm)', 

	interliningSupplier: 'Interlining Supplier', 
	interlining: 'Interlining', 
	interliningWidth: 'Interlining Width (mm)', 
	interliningDrops: 'Interlining Drops ()', 
	interliningCutLength: 'Interlining Cut Length (mm)', 

	trimSupplier: 'Trim Supplier', 
	trim: 'Trim', 

	width: 'Width (mm)', 
	finishedLength: 'Drop (mm)',

	workroomInstructions: 'Workroom Instructions', 
	installationNotes: 'Installation Notes',
}

export default function exportCurtainsWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header: Header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(supplier: AvailablePurchaseOrderSupplier, c: dtp.Blind, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c || !c.specifications || c.specifications.workroomSupplier !== supplier.supplier) {
		return []
	}

	const o = c.overview
	if (!o) {
		return []
	}

	const blindType = o.blindType

	const data: Row = {}

	// 'Product', 'Room', 'Window', 'Blind Type', 
	data.product = 'Blinds'
	data.room = o.room
	data.window = o.window
	data.blindType = o.blindType ? titleCase(o.blindType) : undefined
	
	// 'Set No.', 'Bottom Rail Shape',
	if (blindType === dtp.BlindType.Roller) {
		data.rollerBlindSetNo = c.specifications ? c.specifications.rollerBlindSetNo : undefined
		data.rollerBlindBottomRailShape = c.specifications ? c.specifications.rollerBlindBottomRailShape : undefined
		data.rollerBlindBottomRailColour = c.specifications ? c.specifications.rollerBlindBottomRailColour : undefined
	}

	// 'Header Type', 
	data.headerType = c.specifications ? c.specifications.headerType : undefined
	data.stackHeight = c.measures ? toNumber(c.measures.stackHeight) : undefined
	data.fixingDetails = c.specifications ? c.specifications.fixingDetails : undefined

	// 'Control Type', 'Control Position', 'Control Length', 'Control Colour', 
	data.controlType = c.specifications ? c.specifications.controlType : undefined
	data.controlPosition = c.specifications ? c.specifications.controlPosition : undefined
	data.controlLength = c.specifications ? toNumber(c.specifications.controlLength) : undefined
	data.controlColour = c.specifications ? c.specifications.controlColour : undefined

	const quantities = cf.calculateQuantities(c).getResult()

	switch (blindType) {
		case dtp.BlindType.Roman:
			// 'Fabric Supplier', 'Fabric Collection', 'Fabric / Material', 'Fabric / Material Colour', 'Fabric Orientation', 'Fabric / Blade Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
			if (c.fabric && c.fabric.fabric) {
				data.fabricSupplier = c.fabric.supplier
				data.fabricCollection = c.fabric.collection
				data.fabric = c.fabric.name
				data.fabricColour = c.fabric.colour
				data.fabricOrientation = c.fabric.orientation ? titleCase(c.fabric.orientation) : undefined
				data.fabricWidth = toNumber(c.fabric && c.fabric.width)
				data.fabricDrops = quantities.fabric ? quantities.fabric.drops : undefined
				data.fabricCutLength = quantities.fabric ? quantities.fabric.cutLength : undefined
			}

			// 'Lining Supplier', 'Lining', 'Lining Colour', 'Lining Width (mm)', 'Lining Drops ()', 'Lining Cut Length (mm)', 
			if (c.fabric && c.fabric.lined && c.fabric.liningOptions) {
				data.liningSupplier = c.fabric.liningOptions.supplier
				data.lining = c.fabric.liningOptions.type
				data.liningColour = c.fabric.liningOptions.colour
				data.liningWidth = toNumber(c.fabric.liningOptions.width)
				data.liningDrops = quantities.lining ? quantities.lining.drops : undefined
				data.liningCutLength = quantities.lining ? quantities.lining.cutLength : undefined
			}

			// 'Interlining Supplier', 'Interlining', 'Interlining Width (mm)', 'Interlining Drops ()', 'Interlining Cut Length (mm)', 
			if (c.fabric && c.fabric.lined && c.fabric.liningOptions && c.fabric.liningOptions.interlined && c.fabric.liningOptions.interliningOptions) {
				data.interliningSupplier = c.fabric.liningOptions.interliningOptions.supplier
				data.interlining = c.fabric.liningOptions.interliningOptions.type ? titleCase(c.fabric.liningOptions.interliningOptions.type) : undefined
				data.interliningWidth = c.fabric.liningOptions.interliningOptions.width
				data.interliningDrops = quantities.interlining ? quantities.interlining.drops : undefined
				data.interliningCutLength = quantities.interlining ? quantities.interlining.cutLength : undefined
			}

			// 'Trim Supplier', 'Trim', 
			if (c.fabric && c.fabric.trim && c.fabric.trimOptions) {
				data.trimSupplier = c.fabric.trimOptions.supplier
				data.trim = c.fabric.trimOptions.name
			}
			break
		case dtp.BlindType.Roller:
			// 'Fabric Supplier', 'Fabric Collection', 'Fabric / Material', 'Fabric / Material Colour', 'Fabric Orientation', 'Fabric / Blade Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
			data.fabricCollection = c.specifications ? c.specifications.rollerBlindFabricCollection : undefined
			data.fabric = c.specifications ? c.specifications.rollerBlindFabricName : undefined
			data.fabricColour = c.specifications ? c.specifications.rollerBlindFabricColour : undefined
			break
		case dtp.BlindType.Venetian:
			// 'Fabric Supplier', 'Fabric Collection', 'Fabric / Material', 'Fabric Colour', Fabric Orientation', 'Fabric / Blade Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
			data.fabric = c.specifications ? c.specifications.venetianMaterial : undefined
			data.fabricColour = c.specifications ? c.specifications.venetianColour : undefined
			data.venetianBladeSize = c.specifications ? c.specifications.venetianSize : undefined
			break
		default:
			throw new Error(`Unsupported blind type: ${blindType}`)
	}

	// 'Width (mm)', 'Drop (mm)',
	const width = c.measures && cf.calculateWidth(c.measures)
	const finishedLength = c.measures && cf.calculateFinishedLength(c.measures)
	data.width = width && width.getResultOrUndefined()
	data.finishedLength = finishedLength && finishedLength.getResultOrUndefined()

	// 'Workroom Instructions', 'Installation Notes',
	data.workroomInstructions = c.specifications.notes
	data.installationNotes = c.installation && c.installation.notes && c.installation.notes.length ? c.installation.notes : undefined

	return [data]
}
