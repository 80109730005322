import * as React from 'react'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CurtainForm'
import { ComboBoxCode } from 'modules/combobox'
import * as cf from '../functions'
import { FormFieldVariant } from 'modules/forms/components/FormRow'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { Controller, forComponentProps, wrapComponent } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CurtainFormHardware extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('hardware')

	public render() {
		return this.renderHardware(this.controller)
	}

	private renderHardware = (controller: Controller<dtp.CurtainHardware | undefined>) => {
		const values = controller.snapshot().value || {}
		
		const notFirstCurtain = this.props.path[0] !== 'curtain1'
		const rodOrTrackString = cf.rodOrTrack(this.props.value)

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{this.props.curtainName} hardware</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Curtain}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						specialGroup={
							notFirstCurtain ? {
								items: [{
									name: 'Curtain 1',
									type: 'curtain1',
								}],
							} : undefined
						}
						productToItems={cf.curtainProductGroupItems.bind(undefined, this.props.productId)}
					/>
				)}

				<ff.Heading>{rodOrTrackString}</ff.Heading>
				<ff.Toggle
					title="Rod or track?"
					prop="rod"
					controller={controller}
					options={[
						{ label: 'Rod', value: dtp.RodOrTrack.Rod },
						{ label: 'Track', value: dtp.RodOrTrack.Track },
						{ label: 'Not required', value: undefined },
					]}
					showClear={true}
				/>

				{values.rod && this.renderRodOptions(controller.controller('rodOptions'))}
				{!values.rod && (
					<ff.Numeric title="Number of hooks" units="hooks" prop="numberOfHooks" controller={controller} />
				)}

				{values.rod && (
					<>
						<ff.Heading>Finials</ff.Heading>
						<ff.Toggle
							title="Finials required?"
							prop="finialsApplied"
							controller={controller}
							options={[
								{ label: 'Required', value: true },
								{ label: 'Not required', value: false },
							]}
							showClear={true}
						/>
						{values.finialsApplied === true && this.renderFinialsOptions(controller.controller('finialsOptions'))}

						<ff.Heading>Brackets</ff.Heading>
						<ff.Toggle
							title="Brackets required?"
							prop="bracketsApplied"
							controller={controller}
							options={[
								{ label: 'Standard', value: dtp.CurtainHardwareBrackets.Standard },
								{ label: 'Concealed', value: dtp.CurtainHardwareBrackets.Concealed },
								{ label: 'Not required', value: undefined },
							]}
							showClear={true}
						/>
						{values.bracketsApplied !== undefined && this.renderBracketsOptions(controller.controller('bracketsOptions'))}

						<ff.Heading>Cording</ff.Heading>
						<ff.Toggle
							title="Cording required?"
							prop="cordingApplied"
							controller={controller}
							options={[
								{ label: 'Required', value: true },
								{ label: 'Not required', value: false },
							]}
							showClear={true}
						/>
						{values.cordingApplied === true && this.renderCordingOptions(controller.controller('cordingOptions'))}

						<ff.Heading>Bends / Mitres</ff.Heading>
						<ff.Toggle
							title="Bends / Mitres required?"
							prop="bendsApplied"
							controller={controller}
							options={[
								{ label: 'Required', value: true },
								{ label: 'Not required', value: false },
							]}
							showClear={true}
						/>
						{values.bendsApplied === true && this.renderBendsOptions(controller.controller('bendsOptions'))}
					</>
				)}

				<ff.Heading>Holdbacks</ff.Heading>
				<ff.Toggle
					title="Holdbacks required?"
					prop="holdbacksApplied"
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]}
					showClear={true}
				/>
				{values.holdbacksApplied === true && this.renderHoldbacksOptions(controller.controller('holdbackOptions'))}

				<ff.Heading>Flick sticks</ff.Heading>
				<ff.Toggle
					title="Flick sticks required?"
					prop="flickSticks"
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]}
					showClear={true}
				/>
				{values.flickSticks === true && this.renderFlickSticksOptions(controller.controller('flickSticksOptions'))}

				<ff.Heading>Automation</ff.Heading>
				<ff.Toggle
					title="Automation required?"
					prop="automation"
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]}
					showClear={true}
				/>
				{values.automation === true && this.renderAutomationOptions(controller.controller('automationOptions'))}
			</>
		)
	}

	private renderRodOptions = (controller: Controller<dtp.RodOptions | undefined>) => {
		const details = this.props.value
		const measures = details.measures
		const trackWidthCalculation = measures && cf.calculateTrackWidth(measures)
		const values = controller.snapshot().value || {}

		return (
			<>
				<ff.Display title="Calculated track width" value={trackWidthCalculation && trackWidthCalculation.getResult()} units="mm" calculation={trackWidthCalculation} variant={FormFieldVariant.Short} />
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.HARDWARE_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Code" prop="code" code={ComboBoxCode.HARDWARE_CODE} comboContext={values.supplier} controller={controller} />
				<ff.ComboBox title="Colour" prop="color" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Numeric title="Height" prop="height" units="mm" controller={controller} />
				<ff.Price title="Price" units="unit" prop="price" project={this.props.project} controller={controller} />
				<ff.Dropdown 
					title="Fixing details" 
					value={values.fixingDetails}
					onChange={controller.snapshot('fixingDetails').onChange}
					options={[
						{ label: 'Face fix', value: 'Face fix' },
						{ label: 'Top fix', value: 'Top fix' },
						{ label: 'Wall to wall', value: 'Wall to wall' },
						{ label: 'Inside fix', value: 'Inside fix' },
					]}
				/>
				<ff.TextArea title="Notes" prop="notes" controller={controller} />
			</>
		)
	}

	private renderFinialsOptions = (controller: Controller<dtp.FinialsOptions | undefined>) => {
		const rodOrTrackString = cf.rodOrTrack(this.props.value)
		const values = controller.snapshot().value || {}

		return (
			<>
				<ff.ComboBox title="Type" prop="type" code={ComboBoxCode.FINIALS_TYPE} controller={controller} />
				<ff.Toggle
					title="Colour" 
					prop="customColour"
					controller={controller}
					options={[
						{ label: `Same as ${rodOrTrackString}`, value: undefined },
						{ label: 'Custom', value: true },
					]}
				/>
				{values.customColour && (
					<ff.ComboBox title="" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				)}
				<ff.Price title="Price" units="unit" prop="price" project={this.props.project} controller={controller} />
				<ff.Numeric title="Quantity" units="finials" prop="quantity" controller={controller} />
				<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
				<ff.TextArea title="Notes" prop="notes" controller={controller} />
			</>
		)
	}

	private renderBracketsOptions = (controller: Controller<dtp.BracketsOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Type" prop="type" code={ComboBoxCode.BRACKETS_TYPE} controller={controller} />
			</>
		)
	}

	private renderCordingOptions = (controller: Controller<dtp.CordingOptions | undefined>) => {
		const sideSnapshot = controller.snapshot('side')
		return (
			<>
				<ff.Dropdown
					value={sideSnapshot.value}
					onChange={sideSnapshot.onChange}
					title="Side"
					options={[
						{ label: 'Left', value: 'Left' },
						{ label: 'Right', value: 'Right' },
					]}
					narrow={true}
				/>
				<ff.Numeric title="Cord length" units="mm" prop="length" controller={controller} />
			</>
		)
	}

	private renderBendsOptions = (controller: Controller<dtp.BendsOptions | undefined>) => {
		return (
			<>
				{/* <ff.Dropdown
					formState={formState} 
					onFormStateChange={onFormStateChange}
					title="Bends / Mitres"
					name="bends"
					options={[
						// TODO
					]}
				/> */}
				<ff.Numeric title="Quantity" units="bends" prop="quantity" controller={controller} />
				<ff.Price title="Price" units="unit" prop="price" project={this.props.project} controller={controller} />
				<ff.TextArea title="Notes" prop="notes" controller={controller} />
			</>
		)
	}

	private renderHoldbacksOptions = (controller: Controller<dtp.HoldbackOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.HARDWARE_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Product name" prop="name" code={ComboBoxCode.HOLDBACK_NAME} controller={controller} />
				<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Price title="Pricing" units="unit" prop="price" project={this.props.project} controller={controller} />
				<ff.Numeric title="Left quantity" units="holdbacks" prop="quantityLeft" controller={controller} />
				<ff.Numeric title="Right quantity" units="holdbacks" prop="quantityRight" controller={controller} />
			</>
		)
	}

	private renderFlickSticksOptions = (controller: Controller<dtp.FlickSticksOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.HARDWARE_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Product name" prop="name" code={ComboBoxCode.FLICK_STICKS_NAME} controller={controller} />
				<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Numeric title="Length" units="mm" prop="length" controller={controller} />
				<ff.Price title="Pricing" units="unit" prop="price" project={this.props.project} controller={controller} />
				<ff.Numeric title="Quantity" units="flick sticks" prop="quantity" controller={controller} />
			</>
		)
	}

	private renderAutomationOptions = (controller: Controller<dtp.AutomationOptions | undefined>) => {
		const values = controller.snapshot().value || {}
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.HARDWARE_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Motor Brand" prop="motorBrand" code={ComboBoxCode.AUTOMATION_MOTOR_BRAND} controller={controller} />
				<ff.Toggle
					prop="wiredSide" 
					title="Wiring" 
					controller={controller}
					showClear={true}
					options={[
						{ label: 'Left', value: 'left' },
						{ label: 'Right', value: 'right' },
					]}
				/>
				<ff.Toggle 
					prop="hardWired" 
					title="" 
					controller={controller}
					options={[
						{ label: 'Hard-wired', value: true },
						{ label: 'Wireless', value: false },
					]}
				/>
				<ff.Toggle 
					prop="remotes" 
					title="Remotes" 
					controller={controller}
					options={[
						{ label: 'Remotes', value: true },
						{ label: 'No remotes', value: false },
					]}
				/>

				{values.remotes === true && (
					<>
						<ff.Numeric title="Quantity of remotes" prop="numberOfRemotes" units="remotes" controller={controller} />
						<ff.Numeric title="Number of remote channels" prop="numberOfRemoteChannels" units="channels" controller={controller} />
						<ff.ComboBox title="Colour of remotes" prop="colourOfRemotes" code={ComboBoxCode.COLOUR} comboContext="control" controller={controller} />
						<ff.ComboBox title="Remote model" prop="remoteModel" code={ComboBoxCode.REMOTE_MODEL} controller={controller} />
					</>
				)}

				<ff.Price title="Pricing" prop="price" project={this.props.project} controller={controller} />

				<ff.TextArea title="Electrical contractor notes" prop="electricalContractor" controller={controller} />
				<ff.TextArea title="Builders notes" prop="builder" controller={controller} />
				<ff.TextArea title="Automation notes" prop="notes" controller={controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'curtain1') {
			this.onCopyFromCurtain1()
			return
		}

		const product = item.value as dtp.CurtainDetail
		if (product && product.hardware) {
			this.controller.snapshot().onChange(product.hardware)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected curtain doesn’t have hardware.')
		}
	}

	private onCopyFromCurtain1 = () => {
		const root = this.props.rootFormValue
		if (root.curtain1 && root.curtain1.hardware) {
			this.controller.snapshot().onChange(root.curtain1.hardware)
			this.setState({ showCopy: false })
		} else {
			platform.alert('Curtain 1 does not have hardware available')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(CurtainFormHardware)
