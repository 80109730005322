import * as React from 'react'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './BlindForm'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { wrapComponent, forComponentProps } from 'changeling'
import { blindName } from '../functions'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class BlindFormInstallation extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('installation')

	public render() {
		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{blindName(this.props.value)} installation</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Blind}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
					/>
				)}

				<ff.TextArea title="Installation notes" prop="notes" controller={this.controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = (item.value as dt.Product).details as dtp.Blind
		if (product && product.installation) {
			this.controller.snapshot().onChange(product.installation)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected blind doesn’t have installation information.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(BlindFormInstallation)
