/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow, { FormRowVariant } from './FormRow'
import * as _ from 'lodash'
import { Input, Snapshot, forComponentProps, Controller } from 'changeling'
import { KEYORTHIS, KEY, PROPERTYORTHIS } from 'changeling/dist/types'

interface OwnProps<T> extends Snapshot<T | undefined> {
	title: string
	options: ToggleOption<T>[]
	showClear?: boolean
}

interface ToggleOption<T> {
	label: string
	value: T
}

class Toggle<T> extends React.Component<OwnProps<T>> {

	private static COUNTER = 0

	private controller = forComponentProps(this)
	private counter: number

	public constructor(props: OwnProps<T>) {
		super(props)

		this.counter = ++Toggle.COUNTER
	}

	public render() {
		const { title, showClear, options } = this.props
		return (
			<FormRow title={title} type="toggle" rowVariant={FormRowVariant.Toggle}>
				<div className="toggle-selector">
					<ul className="toggles">
						{options.map((option, index) => (
							<li className="toggle" key={index}>
								<label className="label">
									<Input.Checkable
										type="radio" 
										name={`toggle-${this.counter}`}
										controller={this.controller}
										prop="this"
										checkedValue={option.value}
										className="radio"
									/>
									<span className="substitute" />
									<span className="text">{option.label}</span>
								</label>
							</li>
						))}
					</ul>
					{showClear && (
						<button type="button" className="button-link -small -text -icon -cancel" onClick={this.onClear}>Clear</button>
					)}
				</div>
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps<T>>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		if (!_.isEqual(this.props.options, nextProps.options)) {
			return true
		}
		if (this.props.showClear !== nextProps.showClear) {
			return true
		}
		return false
	}

	private onClear = (evt: React.MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault()

		this.props.onChange(undefined)
	}
}

interface OwnPropsWrapper<T, K extends KEYORTHIS<T>> {
	controller: Controller<T>
	prop: K
	title: string
	options: ToggleOption<PROPERTYORTHIS<T, K>>[]
	showClear?: boolean
}

export default class ToggleWrapper<T, K extends KEYORTHIS<T>> extends React.Component<OwnPropsWrapper<T, K>> {

	public render() {
		const { controller, prop, ...rest } = this.props
		const snapshot = prop !== 'this' ? controller.snapshot(prop as KEY<T>) : controller.snapshot()
		return (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			<Toggle value={snapshot.value as any} onChange={snapshot.onChange as any} {...rest} />
		)
	}

}
