import { PricingResult } from 'modules/project/calculations'
import Calculation from 'modules/root/calculations'

export interface GenericPricing {
	pricing?: PricingResult
}

export function sumGenericPricing(a: Calculation<GenericPricing> | undefined, b: Calculation<GenericPricing> | undefined): Calculation<GenericPricing> | undefined {
	if (a === undefined) {
		return b
	}
	const aresult = a.getResult()
	if (b === undefined) {
		return a
	}
	const bresult = b.getResult()
	const result = new Calculation<GenericPricing>({
		pricing: {
			cost: (aresult.pricing ? aresult.pricing.cost : 0) + (bresult.pricing ? bresult.pricing.cost : 0),
			rrp: (aresult.pricing ? aresult.pricing.rrp : 0) + (bresult.pricing ? bresult.pricing.rrp : 0),
		},
	})
	
	result.withErrors(a)
	result.withErrors(b)
	return result
}
