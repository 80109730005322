/**
 * Reducer for authentication.
 * 
 * Responsible for updating our authentication status in the Redux state.
 */

import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import { readyAction } from '../root/actions'
import produce from 'immer'
import firebase from 'firebase/compat/app'

export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	loggingIn: boolean
	user?: firebase.User
	error?: string
}

const INITIAL_STATE: StoreState = produce(
	{
		loggingIn: false,
	},
	draft => draft)

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.loginError, (state, error) => produce(state, draft => {
	draft.loggingIn = false
	draft.error = error.message
}))

reducer.case(actions.login, (state) => produce(state, draft => {
	draft.loggingIn = true
	draft.error = undefined
}))

reducer.case(readyAction, (state) => produce(state, draft => {
	draft.loggingIn = false
	draft.error = undefined
}))

/* The user has been logged out remove our stored access token from the state. */
reducer.case(actions.loggedOut, (state) => produce(state, draft => INITIAL_STATE))

reducer.case(actions.loggedIn, (state, user) => produce(state, draft => {
	draft.user = user
	draft.loggingIn = false
	draft.error = undefined
}))
