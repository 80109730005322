/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/ProjectsList'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as aa from 'modules/auth/actions'
import { StoreProjects } from 'modules/database/reducer'
import * as dt from 'modules/database/types'
import * as a from 'modules/project/actions'
import * as us from 'modules/user/selectors'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	projects: DeepReadonly<StoreProjects>
	accountName?: string
	username?: string
	subscriber?: boolean
	endDate?: Date
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onLogout: () => void
	onProject: (projectId: dt.ProjectID) => void
	onNewProject: () => void
	onUserDetails: () => void
	onChangePassword: () => void
	onImportProject: (project: dt.Project) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	const subscriber = us.subscriber(state)
	
	return {
		projects: state.database.projects,
		accountName: state.user.user.organisation ? state.user.user.organisation : state.auth.user ? state.auth.user.displayName || state.auth.user.email || undefined : undefined,
		username: us.currentUserFullName(state),
		subscriber,
		endDate: subscriber === true ? us.subscriptionRenewalDate(state) : subscriber === false ? us.trialExpiryDate(state) : undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onLogout: () => {
		dispatch(aa.logout())
	},
	onProject: (projectId) => {
		dispatch(a.chooseProject(projectId))
	},
	onNewProject: () => {
		dispatch(a.newProject())
	},
	onUserDetails: () => {
		dispatch(a.openUserDetails())
	},
	onChangePassword: () => {
		dispatch(a.openChangePassword())
	},
	onImportProject: (project) => {
		dispatch(a.importProject(project))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
