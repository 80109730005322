/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/Login'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module files */
import * as actions from '../actions'

/**
 * Interface for properties that the container passes to the component.
 */
interface Props {
	loggedIn: boolean
	loggingIn: boolean
	error?: string
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
interface Actions {
	onLogin: (username: string, password: string) => void
	onLogout: () => void
}

export type ComponentProps = Props & Actions

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		loggedIn: state.auth.user !== undefined,
		loggingIn: state.auth.loggingIn,
		error: state.auth.error,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onLogin: (username, password) => {
		dispatch(actions.login({ username, password }))
	},
	onLogout: () => {
		dispatch(actions.logout())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
