import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/ProductForm'
import { BackButton, Page } from 'react-onsenui'
import { NavigationController, NavigatorToolbar, RouteProps } from 'onsenui-react-redux-navigator'

import * as dt from 'modules/database/types'
import CurtainForm from '../curtains/containers/CurtainForm'
import BlindForm from '../blinds/containers/BlindForm'
import * as df from 'modules/database/functions'
import StorageMessageBar from 'modules/storage/containers/StorageMessageBar'
import CushionForm from 'modules/product/cushions/containers/CushionForm'
import { OwnProps as NavigatorToolbarProps, RenderToolbarActions } from 'onsenui-react-redux-navigator/dist/containers/NavigatorToolbar'

export default class ProductForm extends React.Component<OwnProps & Props & Actions & RouteProps<Record<string, unknown>>> {

	public render() {
		const { product } = this.props

		return (
			<Page className="screen-project" renderToolbar={this.renderToolbar}>
				{this.renderForm(product.type)}
				<StorageMessageBar />
			</Page>
		)
	}

	private renderToolbar = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const BackButtonWithChildren = BackButton as any
		return (
			<NavigatorToolbar 
				route={this.props.route} 
				title={df.productTitle(this.props.product)}
				center={(
					<div className="contents">
						<h1 className="title-page">{df.productTitle(this.props.product)}</h1>
					</div>
				)}
				renderLeft={(navigationController: NavigationController, props: NavigatorToolbarProps, actions: RenderToolbarActions) => {
					const previousRoute = actions.previousRoute()
					if (!previousRoute) {
						return null
					}
					return (
						<div className="left">
							<BackButtonWithChildren onClick={actions.pop.bind(this, navigationController)}>
								Project
							</BackButtonWithChildren>
						</div>
					)
				}}
			/>
		)
	}

	private renderForm = (type: dt.ProductType) => {
		switch (type) {
			case dt.ProductType.Curtain:
				return <CurtainForm route={this.props.route} />
			case dt.ProductType.Blind:
				return <BlindForm route={this.props.route} />
			case dt.ProductType.Cushion:
				return <CushionForm route={this.props.route} />
			default:
				return (
					<p>ProductForm: Unknown product type: {type}</p>
				)
		}
	}

}