import actionCreatorFactory from 'typescript-fsa'

import * as t from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Database')

export const databaseReady = actionCreator('DATABASE_READY')

export const updateProject = actionCreator<DeepReadonly<t.Project>>('UPDATE_PROJECT')
export type UpdateProjectAction = ReturnType<typeof updateProject>
export const updateWholeProject = actionCreator<t.Project>('UPDATE_WHOLE_PROJECT')

export const deleteProject = actionCreator<t.ProjectID>('DELETE_PROJECT')
export type DeleteProjectAction = ReturnType<typeof deleteProject>

export interface UpdateProductPayload {
	projectId: t.ProjectID
	product: DeepReadonly<t.Product>
}
export const updateProduct = actionCreator<UpdateProductPayload>('UPDATE_PRODUCT')
export type UpdateProductAction = ReturnType<typeof updateProduct>

export interface DeleteProductPayload {
	projectId: t.ProjectID
	productId: t.ProductID
}
export const deleteProduct = actionCreator<DeleteProductPayload>('DELETE_PRODUCT')
export type DeleteProductAction = ReturnType<typeof deleteProduct>

export const undeleteProduct = actionCreator<DeleteProductPayload>('UNDELETE_PRODUCT')
export type UndeleteProductAction = ReturnType<typeof undeleteProduct>

export const downloadedProjects = actionCreator<Projects>('DOWNLOADED_PROJECTS')

export interface DownloadedProductsPayload {
	projectId: t.ProjectID
	products: t.Product[]
}
export const downloadedProducts = actionCreator<DownloadedProductsPayload>('DOWNLOADED_PRODUCTS')

export const uploadedProject = actionCreator<t.Project>('UPLOADED_PROJECT')
export const uploadedProduct = actionCreator<UpdateProductPayload>('UPLOADED_PRODUCT')

export const onlineStateChange = actionCreator<boolean>('ONLINE_STATE_CHANGE')

export interface Projects {
	[projectId: string]: t.Project
}
