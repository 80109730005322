import * as React from 'react'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CushionForm'
import * as cf from '../functions'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { forComponentProps, Input, wrapComponent } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CushionFormMeasures extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('measures')
	private overviewController = this.rootController.controller('overview')

	public render() {
		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{cf.cushionName(this.props.value)} measures</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Cushion}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
					/>
				)}

				<div className="row">
					<div className="form-field -grouped">
						<label className="label">
							Cushion dimensions
						</label>
						<div className="group">
							<div className="form-input -text -stacked">
								<label className="label">Width</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="width" />
							</div>
							<div className="joiner" />
							<div className="form-input -text -stacked">
								<label className="label">Height</label>
								<Input.Number type="number" className="field" controller={this.controller} prop="height" />
							</div>
							<small className="units">mm</small>
						</div>
					</div>
				</div>
				{this.props.value.overview && this.props.value.overview.cushionType === dtp.CushionType.Squab && (
					<div className="row">
						<div className="form-field -grouped">
							<label className="label">Squab dimensions</label>
							<div className="group">
								<div className="form-input -text -stacked">
									<label className="label">Depth</label>
									<Input.Number type="number" className="field" controller={this.controller} prop="depth" />
								</div>
								<small className="units">mm</small>
							</div>
						</div>
					</div>
				)}
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = (item.value as dt.Product).details as dtp.Cushion
		if (product.measures) {
			this.controller.snapshot().onChange(product.measures)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected cushion doesn’t have measures.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(CushionFormMeasures)
