import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/BlindForm'
import { RouteProps } from 'onsenui-react-redux-navigator'

import ProductFormOverview from './BlindFormOverview'
import ProductFormFabric from './BlindFormFabric'
import ProductFormMeasures from './BlindFormMeasures'
import ProductFormSpecifications from './BlindFormSpecifications'
import ProductFormHardware from './BlindFormHardware'
import ProductFormInstallation from './BlindFormInstallation'
import ProductFormCalculations from './BlindFormCalculations'

import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import $ from 'jquery'
import { forComponentProps, Snapshot } from 'changeling'

enum NavigateOption {
	Forward,
	Backward,
}

export interface SubFormProps extends Snapshot<dtp.Blind> {
	project: dt.Project
	productId: dt.ProductID
	path: string[]
}

/**
 * Interface for private internal component state.
 */

enum FormPart {
	Overview = 'Overview',
	Measures1 = 'Measures1',
	Fabric1 = 'Fabric1',
	Specifications1 = 'Specifications1',
	Hardware1 = 'Hardware1',
	Installation1 = 'Installation1',
	Calculations1 = 'Calculations1',
}

const FormParts = [FormPart.Overview, FormPart.Measures1, FormPart.Fabric1, FormPart.Specifications1, FormPart.Hardware1, 
	FormPart.Installation1, FormPart.Calculations1]

interface State {
	menuOpen: boolean
}

const INITIAL_STATE: State = {
	menuOpen: false,
}

type ComponentProps = Props & Actions & OwnProps & RouteProps

export default class BlindForm extends React.Component<ComponentProps, State> {

	public state = INITIAL_STATE

	private controller = forComponentProps(this, 'formValue', 'onChangeFormValue')

	public render() {
		return (
			<div className="site-body -split">
				<div className="width-limit -site">
					{this.renderSidebar()}
					{this.renderMobileNav()}
				
					<div className="project-body">
						<div className="content">
							
							<form className="form-layout" onSubmit={this.onSubmit}>
								{this.renderForm()}
							</form>
							<br />
						</div>
					</div>
				</div>
			</div>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<Props & Actions & OwnProps & RouteProps>, nextState: State) {
		if (this.props.project !== nextProps.project) {
			return true
		}
		if (this.props.productId !== nextProps.productId) {
			return true
		}
		if (this.props.currentForm !== nextProps.currentForm) {
			return true
		}
		if (this.props.formValue !== nextProps.formValue) {
			return true
		}
		if (this.state !== nextState) {
			return true
		}
		return true
	}

	public componentDidUpdate() {
		const toolbar = $('ons-toolbar')
		const mobileNav = $('.mobile-nav')
		if (mobileNav) {
			mobileNav.css('top', toolbar.outerHeight() + 'px')
		}
	}

	public componentDidMount() {
		const toolbar = $('ons-toolbar')
		const mobileNav = $('.mobile-nav')
		if (mobileNav) {
			mobileNav.css('top', toolbar.outerHeight() + 'px')
		}
	}

	private renderSidebar = () => {
		const blindType = this.props.formValue.overview && this.props.formValue.overview.blindType

		return (
			<aside className={'project-sidebar mobile-hide' + (this.state.menuOpen ? ' menu-open' : '')}>
				<div className="content">
					<nav className="side-nav">
						<ul className="navitems">
							{this.renderNavItem(FormPart.Overview, 'Overview')}
							{this.renderNavItem(FormPart.Measures1, 'Measures')}
							{blindType === dtp.BlindType.Roman && this.renderNavItem(FormPart.Fabric1, 'Fabric & lining & trim')}
							{this.renderNavItem(FormPart.Specifications1, 'Specifications')}
							{this.renderNavItem(FormPart.Hardware1, 'Hardware')}
							{this.renderNavItem(FormPart.Installation1, 'Installation')}
							{this.renderNavItem(FormPart.Calculations1, 'Calculations')}
							<li className="navitem -subsection"><strong className="link"><a onClick={this.onNewProduct}>+ New blind</a></strong></li>
						</ul>
					</nav>
				</div>
			</aside>
		)
	}

	private renderMobileNav = () => {
		return (
			<div className={'mobile-nav' + (this.state.menuOpen ? ' menu-open' : '')}>
				<label className={'prev label' + (this.leftNavText() === '' ? ' -empty' : '')} onClick={this.onNavigateLeft}>{this.leftNavText()}</label>
				<div className="site-menu-hamburgers main" onClick={this.toggleMenu}>
					<label className="menuburger js-menuburger" htmlFor="site-drawer-state" aria-hidden="true">
						<span className="ingredient -bun -top">
							<span className="bar"/>
						</span>
						<span className="ingredient -patty">
							<span className="bar"/>
						</span>
						<span className="ingredient -bun -bottom">
							<span className="bar"/>
						</span>
					</label>
				</div>
				<label className={'next label' + (this.rightNavText() === '' ? ' -empty' : '')} onClick={this.onNavigateRight}>{this.rightNavText()}</label>
			</div>
		)
	}

	private currentForm = () => {
		return this.props.currentForm as FormPart || FormPart.Overview
	}

	private renderForm = () => {
		switch (this.currentForm()) {
			case FormPart.Overview:
				return (
					<ProductFormOverview 
						project={this.props.project} 
						productId={this.props.productId} 
						path={['currentProduct']} 
						controller={this.controller}
						prop="this"
					/>
				)
			case FormPart.Measures1:
				return <ProductFormMeasures path={['currentProduct']} project={this.props.project} productId={this.props.productId} controller={this.controller} prop="this" />
			case FormPart.Fabric1:
				return <ProductFormFabric path={['currentProduct']} project={this.props.project} productId={this.props.productId} controller={this.controller} prop="this" />
			case FormPart.Specifications1:
				return <ProductFormSpecifications path={['currentProduct']} project={this.props.project} productId={this.props.productId} controller={this.controller} prop="this" />
			case FormPart.Hardware1:
				return <ProductFormHardware path={['currentProduct']} project={this.props.project} productId={this.props.productId} controller={this.controller} prop="this" />
			case FormPart.Installation1:
				return <ProductFormInstallation path={['currentProduct']} project={this.props.project} productId={this.props.productId} controller={this.controller} prop="this" />
			case FormPart.Calculations1:
				return <ProductFormCalculations path={['currentProduct']} project={this.props.project} productId={this.props.productId} controller={this.controller} prop="this" />
			default:
				return (
					<p>Unsupported form: {this.currentForm()}</p>
				)
		}
	}

	private renderNavItem = (form: FormPart, title: string) => {
		return (
			<li className={'navitem' + (this.currentForm() === form ? ' -selected' : '')}><a onClick={this.onChooseNavItem.bind(this, form)} className="link">{title}</a></li>
		)
	}

	private onChooseNavItem = (form: FormPart) => {
		this.props.onChooseProductForm(form)
		this.setState({ menuOpen: false })
	}

	private toggleMenu = () => {
		this.setState({
			menuOpen: !this.state.menuOpen,
		})
	}

	private onSubmit = (evt: React.FormEvent) => {
		evt.preventDefault()
	}

	private onFormValueChange = (newValue: dtp.Blind) => {
		this.props.onChangeFormValue(newValue)
	}

	private navigate = (option: NavigateOption) => {
		const current = FormParts.indexOf(this.currentForm())
		switch (option) {
			case NavigateOption.Backward:
				if (current > 0) {
					this.onChooseNavItem(FormParts[current - 1])
				}
				break
			case NavigateOption.Forward:
				if (current < FormParts.length - 1) {
					this.onChooseNavItem(FormParts[current + 1])
				}
				break
			default:
				break
		}
	}

	private onNavigateLeft = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.navigate(NavigateOption.Backward)
	}

	private onNavigateRight = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.navigate(NavigateOption.Forward)
	}

	private leftNavText = (): string => {
		let result = (FormParts.indexOf(this.currentForm()) - 1 >= 0) ? FormParts[FormParts.indexOf(this.currentForm()) - 1].toString() : ''

		if (result.substr(result.length - 1) === '1' || result.substr(result.length - 1) === '2') {
			result = result.substr(0, result.length - 1)
		}

		return result
	}

	private rightNavText = (): string => {
		let result = ((FormParts.indexOf(this.currentForm()) + 1) < FormParts.length) ? FormParts[FormParts.indexOf(this.currentForm()) + 1].toString() : ''

		if (result.substr(result.length - 1) === '1' || result.substr(result.length - 1) === '2') {
			result = result.substr(0, result.length - 1)
		}

		return result
	}

	private onNewProduct = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.props.onNewProduct(this.props.project.projectId, dt.ProductType.Blind)
	}
}
