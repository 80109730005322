import * as React from 'react'
import * as dtp from 'modules/database/types/curtains'
import * as dtf from 'modules/database/types/fabric'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CurtainForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import * as cf from 'modules/product/curtains/functions'
import { wrapComponent, forComponentProps, Controller } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CurtainFormFabric extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('fabric')

	public render() {
		return this.renderFabric(this.controller)
	}

	private renderFabric = (controller: Controller<dtp.CurtainFabric | undefined>) => {
		const value = controller.snapshot().value || {}
		const notFirstCurtain = this.props.path[0] !== 'curtain1'

		const quantitiesCalculation = cf.calculateCurtainQuantities(this.props.value || {})

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{this.props.curtainName} fabric</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Curtain}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						specialGroup={
							notFirstCurtain ? {
								items: [{
									name: 'Curtain 1',
									type: 'curtain1',
								}],
							} : undefined
						}
						productToItems={cf.curtainProductGroupItems.bind(undefined, this.props.productId)}
					/>
				)}

				<ff.Heading>Fabric</ff.Heading>
				<ff.Toggle 
					title="Fabric required?" 
					prop="fabric" 
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.fabric === true && (
					<>
						<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
						<ff.ComboBox title="Collection" prop="collection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.supplier} controller={controller} />
						<ff.ComboBox title="Fabric" prop="name" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.supplier}|${value.collection}`} controller={controller} />
						<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
						<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
						<ff.Photo title="Photo of fabric" prop="photo" path={[...this.props.path, 'fabric', 'photo']} projectId={this.props.project.projectId} controller={controller} />
					
						<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
						<ff.Radios 
							title="" 
							prop="orientation" 
							controller={controller}
							options={[
								{ label: 'Drop', value: dtf.FabricOrientation.Drop },
								{ label: 'Continuous', value: dtf.FabricOrientation.Continuous },
							]}
						/>
						{quantitiesCalculation.formatErrorsForProperty('fabric.width')}

						<ff.Dropdown 
							title="Curtain fullness"
							value={value.fullness}
							onChange={controller.snapshot('fullness').onChange}
							options={[
								{ label: '10% of cover', value: 10 },
								{ label: '20% of cover', value: 20 },
								{ label: '30% of cover', value: 30 },
								{ label: '40% of cover', value: 40 },
								{ label: '50% of cover', value: 50 },
								{ label: '60% of cover', value: 60 },
								{ label: '70% of cover', value: 70 },
								{ label: '75% of cover', value: 75 },
								{ label: '80% of cover', value: 80 },
								{ label: '90% of cover', value: 90 },
								{ label: '100% of cover', value: 100 },
								{ label: '110% of cover', value: 110 },
								{ label: '120% of cover', value: 120 },
								{ label: '130% of cover', value: 130 },
								{ label: '140% of cover', value: 140 },
								{ label: '150% of cover', value: 150 },
							]}
							showOther={true}
							units="% of cover"
						/>
						
						<ff.Toggle 
							title="Pattern repeat" 
							prop="patternRepeat" 
							controller={controller}
							options={[
								{ label: 'Repeat', value: true },
								{ label: 'No repeat', value: false },
							]} 
							showClear={true}
						/>
						
						{value.patternRepeat === true && this.renderPatternRepeatOptions(controller.controller('patternRepeatOptions'))}
					</>
				)}

				<ff.Heading>Lining</ff.Heading>
				<ff.Toggle 
					title="Lining required?" 
					prop="lined" 
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.lined === true && this.renderLiningOptions(controller.controller('liningOptions'))}

				<ff.Heading>Trim</ff.Heading>
				<ff.Toggle 
					title="Trim required?" 
					prop="trim" 
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.trim === true && this.renderTrimOptions(controller.controller('trimOptions'))}
			</>
		)
	}

	private renderPatternRepeatOptions = (controller: Controller<dtf.PatternRepeatOptions | undefined>) => {
		return (
			<>
				<ff.Numeric title="Horizontal repeat" units="mm" prop="horizontalRepeat" controller={controller} />
				<ff.Numeric title="Vertical repeat" units="mm" prop="verticalRepeat" controller={controller} />
			</>
		)
	}

	private renderLiningOptions = (controller: Controller<dtp.LiningOptions | undefined>) => {
		const value = controller.snapshot().value || {}
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Lining" prop="type" code={ComboBoxCode.LINING} controller={controller} />
				<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
				<ff.Radios 
					title="" 
					prop="orientation" 
					controller={controller}
					options={[
						{ label: 'Drop', value: dtf.FabricOrientation.Drop },
						{ label: 'Continuous', value: dtf.FabricOrientation.Continuous },
					]}
				/>
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />

				<ff.Heading>Interlining</ff.Heading>
				<ff.Toggle
					title="Interlining / Bumf required?" 
					prop="interlined" 
					controller={controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.interlined === true && this.renderInterliningOptions(controller.controller('interliningOptions'))}
			</>
		)
	}

	private renderInterliningOptions = (controller: Controller<dtp.InterliningOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.Radios 
					title="Type"
					prop="type"
					controller={controller}
					options={[
						{ label: 'Light', value: dtp.InterliningTypes.Light },
						{ label: 'Heavy', value: dtp.InterliningTypes.Heavy },
					]}
				/>
				<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
				<ff.Radios 
					title="" 
					prop="orientation" 
					controller={controller}
					options={[
						{ label: 'Drop', value: dtf.FabricOrientation.Drop },
						{ label: 'Continuous', value: dtf.FabricOrientation.Continuous },
					]}
				/>
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
			</>
		)
	}

	private renderTrimOptions = (controller: Controller<dtp.TrimOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Trim" prop="name" code={ComboBoxCode.TRIM} controller={controller} />
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
				<ff.Radios
					title="Hem"
					prop="hem"
					controller={controller}
					options={[
						{ label: 'Yes', value: true },
						{ label: 'No', value: false },
					]}
				/>
				<ff.Radios
					title="Leading edge"
					prop="leadingEdge"
					controller={controller}
					options={[
						{ label: 'Yes', value: true },
						{ label: 'No', value: false },
					]}
				/>
				<ff.Radios
					title="Outer edge"
					prop="outerEdge"
					controller={controller}
					options={[
						{ label: 'Yes', value: true },
						{ label: 'No', value: false },
					]}
				/>
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'curtain1') {
			this.onCopyFromCurtain1()
			return
		}

		const product = item.value as dtp.CurtainDetail
		if (product && product.fabric) {
			this.controller.snapshot().onChange(product.fabric)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected curtain doesn’t have fabric.')
		}
	}

	private onCopyFromCurtain1 = () => {
		const root = this.props.rootFormValue
		if (root.curtain1 && root.curtain1.fabric) {
			this.controller.snapshot().onChange(root.curtain1.fabric)
			this.setState({ showCopy: false })
		} else {
			platform.alert('Curtain 1 does not have fabric available')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(CurtainFormFabric)
