import { AnnotatedImage, Pricing } from 'modules/database/types'
import { CommonOverview, CommonProductDetails, BaseHardwareOptions } from './common'
import { FabricOrientation, PatternRepeatOptions } from './fabric'

export interface Curtain extends CommonProductDetails {
	overview?: CurtainOverview
	curtain1?: CurtainDetail
	curtain2?: CurtainDetail
}

export interface CurtainOverview extends CommonOverview {
	curtainSet?: CurtainSet
}

export interface CurtainDetail {
	fabric?: CurtainFabric
	measures?: CurtainMeasures
	specifications?: CurtainSpecifications
	hardware?: CurtainHardware
	installation?: CurtainInstallation
}

export interface CurtainFabric {
	fabric?: boolean
	supplier?: string
	collection?: string
	colour?: string
	name?: string
	photo?: AnnotatedImage
	width?: number
	fullness?: number
	orientation?: FabricOrientation
	price?: Pricing
	patternRepeat?: boolean
	patternRepeatOptions?: PatternRepeatOptions
	lined?: boolean
	liningOptions?: LiningOptions
	trim?: boolean
	trimOptions?: TrimOptions
}

export interface CurtainMeasures {
	archToArchWidth?: number
	archToArchHeight?: number
	stackSizeLeft?: number
	stackSizeRight?: number
	stackSizesEqual?: boolean
	returnSizeLeft?: number
	returnSizeRight?: number
	returnSizesEqual?: boolean
	wallToArchLeft?: number
	wallToArchRight?: number
	floorAllowance?: number
	hemDetails?: number
	hemDetailsDeduct?: number
	curtainAboveArch?: number
	ceilingToArch?: number
	floorToArch?: number
	wallToWall?: number
	ceilingToFloor?: number
	corniceToFloor?: number
	useCustomTrackLength?: boolean
	customTrackLength?: number
	finishedLengthCalculationMethod?: CurtainLengthCalculationMethod
	curtainBelowCeiling?: number
	curtainBelowCornice?: number
	archToFloor?: number
	curtainBelowArch?: number
	otherAllowance?: number
	otherAllowanceDeduct?: number
	sillToFloor?: number
	useCustomFinishedLength?: boolean
	customFinishedLength?: number
}

export enum CurtainLengthCalculationMethod {
	cornice = 'cornice',
	ceiling = 'ceiling',
	aboveArchitrave = 'aboveArchitrave',
	onArchitrave = 'onArchitrave',
}

export enum CurtainPricingMethod {
	unit = 'unit',
	metre = 'metre',
}

export enum CurtainDraw {
	evenPair = 'Even pair',
	lefthandDraw = 'Lefthand draw',
	righthandDraw = 'Righthand draw',
	unevenPair = 'Uneven pair',
}

export enum CurtainStackingOptions {
	right = 'Right',
	left = 'Left',
	nil = 'Nil',
}

export interface CurtainSpecifications {
	workroomSupplier?: string
	headerType?: string
	draw?: CurtainDraw
	stacking?: CurtainStackingOptions
	widthLeft?: number
	widthRight?: number
	hemAllowance?: number
	hooking?: number
	makingPriceMethod?: CurtainPricingMethod
	makingPricePerDrop?: Pricing
	makingPricePerMetre?: Pricing
	notes?: string
	extras?: CurtainExtra[]
}

export const CurtainSpecificationsHemAllowanceDefault = 300

export interface CurtainHardware {
	rod?: RodOrTrack
	rodOptions?: RodOptions
	numberOfHooks?: number
	finialsApplied?: boolean
	finialsOptions?: FinialsOptions
	bracketsApplied?: CurtainHardwareBrackets
	bracketsOptions?: BracketsOptions
	cordingApplied?: boolean
	cordingOptions?: CordingOptions
	bendsApplied?: boolean
	bendsOptions?: BendsOptions
	holdbacksApplied?: boolean
	holdbackOptions?: HoldbackOptions
	flickSticks?: boolean
	flickSticksOptions?: FlickSticksOptions
	automation?: boolean
	automationOptions?: AutomationOptions
	extras?: CurtainExtra[]
}

/** Discontinued 20/5/2019 */
export interface LegacyCurtainHardware {
	rodOrTrack?: RodOrTrack
	hardwareRequired?: boolean
	hardwareOptions?: LegacyHardwareOptions
	noHardwareOptions?: LegacyNoHardwareOptions
}

export interface CurtainInstallation {
	notes?: string
}

export enum CurtainSet {
	Single = 'single',
	Double = 'double'
}

export interface LegacyNoHardwareOptions {
	numberOfHooks?: number
}

export interface RodOptions {
	supplier?: string
	fixingDetails?: string
	code?: string
	color?: string
	height?: number
	price?: Pricing
	notes?: string
}

export interface LegacyHardwareOptions {
	supplier?: string
	fixingDetails?: string
	rodCode?: string
	rodColor?: string
	rodHeight?: number
	rodPrice?: Pricing
	finialsApplied?: boolean
	finialsOptions?: FinialsOptions
	bracketsApplied?: CurtainHardwareBrackets
	bracketsOptions?: BracketsOptions
	cordingApplied?: boolean
	cordingOptions?: CordingOptions
	bendsApplied?: boolean
	bendsOptions?: BendsOptions
	holdbacksApplied?: boolean
	holdbackOptions?: HoldbackOptions
	flickSticks?: boolean
	flickSticksOptions?: FlickSticksOptions
	automation?: boolean
	automationOptions?: AutomationOptions
	notes?: string
}

export interface FinialsOptions {
	type?: string
	customColour?: boolean
	colour?: string
	quantity?: number
	price?: Pricing
	width?: number
	notes?: string
}

export enum CurtainHardwareBrackets {
	Standard = 'standard',
	Concealed = 'concealed',
}

export interface BracketsOptions {
	type?: string
}

export interface CordingOptions {
	side?: string
	length?: number
}

export interface BendsOptions extends BaseHardwareOptions {
	bends?: string
	notes?: string
}

export interface HoldbackOptions extends BaseHardwareOptions {
	supplier?: string
	name?: string
	colour?: string
	quantityLeft?: number
	quantityRight?: number
}

export interface FlickSticksOptions extends BaseHardwareOptions {
	supplier?: string
	name?: string
	colour?: string
	length?: number
}

export interface AutomationOptions {
	supplier?: string
	remotes?: boolean
	numberOfRemotes?: number
	numberOfRemoteChannels?: number
	colourOfRemotes?: string
	notes?: string
	motorBrand?: string
	wiredSide?: 'left' | 'right'
	hardWired?: boolean
	remoteModel?: string
	electricalContractor?: string
	builder?: string
	price?: Pricing
}

export enum RodOrTrack {
	Rod = 'rod',
	Track = 'track'
}

export interface WorkroomOptions {
	charge?: string
	makingPrice?: number
}

export interface LiningOptions {
	type?: string
	width?: number
	orientation?: FabricOrientation
	price?: Pricing
	supplier?: string
	colour?: string
	interlined?: boolean
	interliningOptions?: InterliningOptions
}

export interface InterliningOptions {
	type?: InterliningTypes
	width?: number
	orientation?: FabricOrientation
	price?: Pricing
	supplier?: string
}

export enum InterliningTypes {
	Light = 'light',
	Heavy = 'heavy'
}

export interface TrimOptions {
	supplier?: string
	name?: string
	price?: Pricing
	hem?: boolean
	leadingEdge?: boolean
	outerEdge?: boolean
}

export interface CurtainExtra {
	name?: string
	pricing?: Pricing
}
