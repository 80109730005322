import * as React from 'react'
import * as ff from 'modules/forms'
import { SubFormProps } from './CushionForm'
import * as cf from '../functions'
import { formatNumber, calculateMarkup } from 'modules/root/functions'
import Calculation from 'modules/root/calculations'
import { GenericPricing } from 'modules/product/common/pricing'
import { wrapComponent, forComponentProps } from 'changeling'

class CushionFormCalculations extends React.Component<SubFormProps> {

	private controller = forComponentProps(this)

	public render() {
		const { project } = this.props

		const details = this.controller.snapshot().value || {}
		const measures = details.measures
		
		const taxDetails = project.taxDetails
		const quantitiesCalculation = cf.calculateQuantities(details)
		const quantities = quantitiesCalculation.getResult()
		const pricing = cf.calculateProductPricing(details, taxDetails)
		const widthCalculation = measures && cf.calculateWidth(measures)
		const heightCalculation = measures && cf.calculateFinishedLength(measures)
		const depthCalculation = measures && cf.calculateDepth(details)

		const fabricQuantities = quantities.fabric && quantities.fabric.getResult()
		const backFabricQuantities = quantities.backFabric && quantities.backFabric.getResult()
		const pipingQuantities = quantities.piping && quantities.piping.getResult()
		const flangeQuantities = quantities.flange && quantities.flange.getResult()
		const trimQuantities = quantities.trim && quantities.trim.getResult()

		return (
			<>
				<ff.Heading type="section">{cf.cushionName(this.props.value)} calculations</ff.Heading>

				<ff.Heading>Measurements</ff.Heading>
				<div className="calculations-table">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{this.outputMeasure('Quantity', quantities.quantity && quantities.quantity.getResult(), undefined, quantities.quantity)}
							{this.outputMeasure('Width', widthCalculation && widthCalculation.getResult(), 'mm', widthCalculation)}
							{this.outputMeasure('Height', heightCalculation && heightCalculation.getResult(), 'mm', heightCalculation)}
							{depthCalculation && this.outputMeasure('Depth', depthCalculation.getResult(), 'mm', depthCalculation)}
							{this.outputMeasure('Fabric width', details.fabric && details.fabric.width ? details.fabric.width : undefined, 'mm', undefined)}
							{details.fabric && details.fabric.backFabricDifferent && (
								<>
									{this.outputMeasure('Back fabric width', details.fabric.backFabricOptions && details.fabric.backFabricOptions.width ? details.fabric.backFabricOptions.width : undefined, 'mm', undefined)}
								</>
							)}
						</tbody>
					</table>
				</div>

				<ff.Heading>Calculations</ff.Heading>
				<div className="calculations-table">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{this.outputMeasure('Fabric cut length', fabricQuantities && fabricQuantities.cutLength, 'mm', quantities.fabric || quantitiesCalculation)}

							{this.outputMeasure('Back fabric cut length', backFabricQuantities && backFabricQuantities.cutLength, 'mm', quantities.backFabric || quantitiesCalculation)}

							{this.outputMeasure('Piping fabric cut length', pipingQuantities && pipingQuantities.cutLength, 'mm', quantities.piping || quantitiesCalculation)}

							{this.outputMeasure('Flange fabric cut length', flangeQuantities && flangeQuantities.cutLength, 'mm', quantities.flange || quantitiesCalculation)}

							{this.outputMeasure('Trim cut length', trimQuantities && trimQuantities.cutLength, 'mm', quantities.trim || quantitiesCalculation)}

							{/* {this.outputMeasure('Trim', quantities.trim ? formatNumber(quantities.trim, 0) : undefined, 'mm', quantitiesCalculation)} */}

							{/* {this.outputMeasure('Total trim', quantities.trim ? formatNumber(quantities.trim, 0) : undefined, 'mm', quantitiesCalculation, 'total')} */}
						</tbody>
					</table>
				</div>

				<ff.Heading>Pricing</ff.Heading>
				<div className="calculations-table">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Cost Price{taxDetails ? ` excl ${taxDetails.taxName}` : ''}</th>
								<th>Sell Price{taxDetails ? ` incl ${taxDetails.taxName}` : ''}</th>
								<th>Markup</th>
							</tr>
						</thead>
						<tbody>
							{this.outputCost('Fabric', pricing.fabric)}
							{this.outputCost('Back fabric', pricing.backFabric)}
							{this.outputCost('Fill', pricing.fill)}
							{this.outputCost('Piping', pricing.piping)}
							{this.outputCost('Flange', pricing.flange)}
							{this.outputCost('Trim', pricing.trim)}
							{this.outputCost('Making', pricing.specifications)}

							{this.outputCost('TOTAL', pricing.total, 'total')}
						</tbody>
					</table>
				</div>
			</>
		)
	}

	private outputMeasure = (title: string, value: string | number | undefined, units: string | undefined, calculation: Calculation<unknown> | undefined, className?: 'total' | 'subtotal') => {
		if (calculation && calculation.isErrored()) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td>{calculation.formatErrors()}</td>
				</tr>
			)
		} else if (value !== undefined) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td><ff.DisplayValue value={value} units={units} /></td>
				</tr>
			)
		} else {
			return null
		}
	}

	private outputCost = (title: string, calculation: Calculation<GenericPricing> | undefined, className?: 'total' | 'subtotal') => {
		if (!calculation) {
			return null
		}

		if (calculation.isErrored()) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td colSpan={3}>{calculation.formatErrors()}</td>
				</tr>
			)
		}

		const value = calculation.getResult()
		if (!value || !value.pricing) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td>—</td>
					<td colSpan={2} />
				</tr>
			)
		} else {
			const taxDetails = this.props.project.taxDetails
			const markup = calculateMarkup(value.pricing.cost, value.pricing.rrp, taxDetails)

			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td>${formatNumber(value.pricing.cost, 2)}</td>
					<td>${formatNumber(value.pricing.rrp, 2)}</td>
					<td>{formatNumber(markup, { dp: 0, truncateDp: true })}%</td>
				</tr>
			)
		}
	}
}

export default wrapComponent(CushionFormCalculations)
