/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow, { FormFieldVariant } from './FormRow'
import * as _ from 'lodash'
import { Snapshot, forComponentProps } from 'changeling'

interface OwnProps<T> extends Snapshot<T | undefined> {
	title: string
	options: DropdownOption<T>[]
	showOther?: boolean
	narrow?: boolean
	convertOther?: (value: string) => T | undefined
	displayOther?: (value: T | undefined) => string
	units?: string
}

export interface DropdownOption<T> {
	label: string
	value: T
}

interface State {
	showOther: boolean
}

const INITIAL_STATE: State = {
	showOther: false,
}

export default class Dropdown<T> extends React.Component<OwnProps<T>, State> {

	public state = INITIAL_STATE

	private controller = forComponentProps(this)

	public render() {
		const { title, options, showOther, units, displayOther, narrow } = this.props

		const value = this.controller.snapshot().value
		
		const valueIndex = value !== undefined ? options.findIndex(option => option.value === value) : undefined
		const currentValueIsOther = valueIndex === -1
		const otherActive = showOther && (this.state.showOther || currentValueIsOther)

		return (
			<FormRow title={title} type="select" fieldVariant={otherActive ? FormFieldVariant.WithOther : narrow ? FormFieldVariant.Short : undefined}>
				<select
					className={`${otherActive ? 'select -withother' : 'select'}`}
					value={otherActive ? 'other' : valueIndex}
					onChange={this.onChange}
				>
					<option value="" />
					{options.map((option, index) => (
						<option value={index} key={index}>{option.label}</option>
					))}
					{showOther && (
						<option value="other">Other</option>
					)}
				</select>
				{otherActive && (
					<>
						<input type="text" className="field -short" value={displayOther ? displayOther(value) : `${value}`} onChange={this.onOtherChange} />
						{units && (
							<span className="units">{units}</span>
						)}
					</>
				)}
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps<T>>, nextState: Readonly<State>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		if (!_.isEqual(this.props.options, nextProps.options)) {
			return true
		}
		if (this.props.showOther !== nextProps.showOther) {
			return true
		}
		if (!_.isEqual(this.state, nextState)) {
			return true
		}
		return false
	}

	private onChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedIndex = evt.target.selectedIndex
		const snapshot = this.controller.snapshot()
		if (selectedIndex === 0) {
			snapshot.onChange(undefined)
		} else if (selectedIndex > this.props.options.length) {
			this.setState({ showOther: true })
		} else {
			snapshot.onChange(this.props.options[selectedIndex - 1].value)
			this.setState({ showOther: false })
		}
	}

	private onOtherChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const value = this.props.convertOther ? this.props.convertOther(evt.target.value) : evt.target.value as any as T

		this.controller.snapshot().onChange(value)
	}
}
