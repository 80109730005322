export enum FabricOrientation {
	Drop = 'drop',
	Continuous = 'continuous',
	Tessellate = 'tessellate', /* Used for cushions to get multiple fronts and backs out of the fabric */
	TesellateHorizontalOnly = 'tessellate-horizontal', /* Cushions don't go either way as fabric may have patterns */
	Lineal = 'lineal', /* Used for piping etc, where we can tolerate seams */
}

export interface PatternRepeatOptions {
	horizontalRepeat?: number
	verticalRepeat?: number
}
