/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import Calculation from 'modules/root/calculations'
import { formatNumber } from 'modules/root/functions'

interface OwnProps {
	value?: string | number
	units?: string
	unitsFirst?: boolean
	calculation?: Calculation<unknown>
}

export default class DisplayValue extends React.PureComponent<OwnProps> {

	public render() {
		const { value, units, unitsFirst, calculation } = this.props
		return (
			<>
				{calculation && !calculation.isSuccess() ? calculation.formatErrors() : value !== undefined ? (
					<p>{units && unitsFirst ? units : null}{typeof (value) === 'number' ? formatNumber(value) : value} {units && !unitsFirst ? units : null}</p>
				) : (
					<p>—</p>
				)}
			</>
		)
	}

}
