/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow, { FormFieldVariant } from './FormRow'
import { forComponentProps, Input, Snapshot, wrapComponent } from 'changeling'

interface OwnProps extends Snapshot<number | undefined> {
	title: string
	units: string
	placeholder?: string
}

type ComponentProps = OwnProps

class Numeric extends React.Component<ComponentProps> {

	private controller = forComponentProps(this)

	public render() {
		const { title, units, placeholder } = this.props

		const value = this.controller.snapshot().value
		const invalid = value && isNaN(parseFloat(value as unknown as string))
		return (
			<FormRow title={title} type="text" fieldVariant={FormFieldVariant.Short} units={units}>
				<Input.Number type="number"
					controller={this.controller}
					prop="this"
					className={`field${invalid ? ' error' : ''}`} 
					placeholder={placeholder}
				/>
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<ComponentProps>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.units !== nextProps.units) {
			return true
		}
		if (this.props.placeholder !== nextProps.placeholder) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		return false
	}

}

export default wrapComponent(Numeric)
