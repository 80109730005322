/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RootStoreState } from 'modules/root'
import * as t from './types'
import * as _ from 'lodash'
import { allProducts } from 'modules/project/functions'

export const projectById = (state: RootStoreState, projectId: t.ProjectID) => {
	const result = state.database.projects[projectId]
	if (!result) {
		throw new Error(`Project ${projectId} was not found`)
	}
	if (result.deleted) {
		throw new Error(`Project ${projectId} is deleted`)
	}
	return result
}

export const projectByIdOrUndefined = (state: RootStoreState, projectId: t.ProjectID) => state.database.projects[projectId]

export const productById = (state: RootStoreState, projectId: t.ProjectID, productId: t.ProductID) => {
	const result = projectById(state, projectId).products[productId]
	if (!result) {
		throw new Error(`Product ${productId} was not found in project ${projectId}`)
	}
	if (result.deleted) {
		throw new Error(`Product ${productId} in project ${projectId} is deleted`)
	}
	return result
}

export const productByIdOrUndefined = (state: RootStoreState, projectId: t.ProjectID, productId: t.ProductID) => {
	const product = projectByIdOrUndefined(state, projectId)?.products[productId]
	if (!product || product.deleted) {
		return undefined
	}
	return product
}

export const allProjects = (state: RootStoreState) => {
	return _.keys(state.database.projects).map(projectId => state.database.projects[projectId]!)
}

export const projectProductsById = (state: RootStoreState, projectId: t.ProjectID) => {
	const project = projectById(state, projectId)
	return allProducts(project)
}

export const projectsRequiringUpload = (state: RootStoreState) => allProjects(state).filter(o => !o.whenUploaded || o.whenUploaded < o.whenUpdated)
export const productsRequiringUpload = (state: RootStoreState, projectId: t.ProjectID) => projectProductsById(state, projectId).filter(o => !o.whenUploaded || o.whenUploaded < o.whenUpdated)

export const anythingRequiresUpload = (state: RootStoreState) => {
	if (projectsRequiringUpload(state).length > 0) {
		return true
	}

	const projects = allProjects(state)
	for (const project of projects) {
		if (productsRequiringUpload(state, project.projectId).length > 0) {
			return true
		}
	}

	return false
}