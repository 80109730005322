import * as React from 'react'
import { Provider } from 'react-redux'

import { store, routingStoreStateSelector } from 'modules/root'
import { ReduxNavigator } from 'onsenui-react-redux-navigator'

import 'onsenui/css/onsenui.css'
import './modules/frontend/css/main.less'
import ProjectsList from 'modules/project/containers/ProjectsList'
import ProjectDetails from 'modules/project/containers/ProjectDetailsWrapper'
import EditProjectForm from 'modules/project/containers/EditProjectForm'
import ProductForm from 'modules/product/containers/ProductForm'
import ReadyGate from 'modules/offline/containers/ReadyGate'
import Login from 'modules/auth/containers/Login'
import AnnotateImageScreen from 'modules/forms/containers/AnnotateImageScreen'
import UserDetails from 'modules/user/containers/UserDetails'
import ChangePassword from 'modules/user/containers/ChangePassword'

class Waiting extends React.Component {
	public render() {
		return (
			<div className="loading-state -simple">
				<h1 className="text">One moment…</h1>
			</div>
		)
	}
}

export class MyPage extends React.Component {
	public render() {
		return (
			<ReduxNavigator 
				id="maqasa"
				rootRoute={{
					component: 'ProjectsList',
					title: 'Projects',
				}}
				swipeable={false}
				stateSelector={routingStoreStateSelector}
				componentRegistry={{
					ProjectsList,
					ProjectDetails,
					ProductForm,
					EditProjectForm,
					NewProjectForm: EditProjectForm, /* Legacy support */
					AnnotateImageScreen,
					UserDetails,
					ChangePassword,
				}}
			/>
		)
	}
}

class App extends React.Component {
	public render() {
		return (
			<Provider store={store}>
				<ReadyGate waitComponent={Waiting}>
					<Login/>	
				</ReadyGate>
			</Provider>
		)
	}
}

export default App
