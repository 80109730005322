/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/ComboBox'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { useValueAction, comboBoxValues, ComboBoxResult, ComboBoxCode } from 'modules/combobox'
import * as prs from 'modules/product/selectors'
import { wrapComponent } from 'changeling'
import { ComboBoxValueType } from 'modules/combobox/types'

/* Import module actions */
// import * as a from '../actions'

/** Interface for properties that the container passes to the component. */
export interface Props {
	values: ComboBoxResult
}

export interface ContainerOwnProps {
	comboContext?: string
	title: string
	code: ComboBoxCode
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onUseValue: (value: ComboBoxValueType | undefined) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: ContainerOwnProps): Props => {
	const projectId = prs.projectId(state)!
	return {
		values: comboBoxValues(state, projectId, ownProps.code, ownProps.comboContext),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: ContainerOwnProps): Actions => ({
	onUseValue: (value) => {
		if (value) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			dispatch(useValueAction({
				code: ownProps.code,
				value,
				context: ownProps.comboContext,
			}))
		}
	},
})

/* https://github.com/piotrwitek/react-redux-typescript-guide/issues/55 */
// export default function ComboBoxContainer<FORM, K extends keyof FORM>() {
// 	return connect<Props, Actions>(mapStateToProps, mapDispatchToProps)(Component as new(props: ComponentProps<FORM, K>) => Component<FORM, K>)
// }

export default wrapComponent(connect(mapStateToProps, mapDispatchToProps)(Component))
