import * as React from 'react'
import { NavigatorToolbar, RouteProps } from 'onsenui-react-redux-navigator'
import { Page } from 'react-onsenui'
import * as ff from 'modules/forms'
import { forComponentState } from 'changeling'
import { ComponentProps } from '../containers/ChangePassword'
import produce from 'immer'
import platform from 'modules/platform'

interface State {
	password?: string
	newPassword?: string
	confirmPassword?: string
}

const INITIAL_STATE: State = {

}

type Props = ComponentProps & RouteProps<unknown>

export default class UserDetails extends React.Component<Props, State> {

	public state = INITIAL_STATE

	private controller = forComponentState(this)

	public render() {
		return (
			<Page className="screen-modal">
				<div className="modal-content">
					<div className="width-limit -site -pageboundaries">
						<div className="body">
							<NavigatorToolbar 
								route={this.props.route} 
								title="Change Password"
								renderToolbar={(navigationController: unknown, props: { title: string }, actions: unknown) => {
									return (
										<header className="modal-header">
											<div className="contents">
												<a onClick={this.onClose} className="back">Cancel</a>
												<h1 className="title-page">{props.title}</h1>
											</div>
										</header>
									)
								}}
							/>
							<div className="modal-body">
								<form className="form-layout">
									<ff.Text type="password" title="Current password" controller={this.controller} prop="password" />
									<ff.Text type="password" title="New password" controller={this.controller} prop="newPassword" />
									<ff.Text type="password" title="Confirm password" controller={this.controller} prop="confirmPassword" />
						
									<div className="row -buttons">
										<div className="button-group">
											<button type="submit" onClick={this.onSave} className="button-link -action">Change Password</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Page>
		)
	}

	private onClose = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onCancel()
	}

	private onSave = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (!this.state.password) {
			platform.alert('Please enter your current password.')
			return
		}
		if (!this.state.newPassword || this.state.newPassword.length < 8) {
			platform.alert('Your new password must be longer than 8 characters.')
			return
		}
		if (this.state.newPassword !== this.state.confirmPassword) {
			platform.alert('Your password was not entered the same both times. Please re-enter your password.')
			this.setState(produce((draft: State) => {
				draft.password = ''
				draft.confirmPassword = ''
			}))
			return
		}

		this.props.onSave(this.state.password, this.state.newPassword)
	}

}
