/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { Props, Actions } from '../containers/ProjectsList'
import { NavigatorToolbar, Route, RouteProps } from 'onsenui-react-redux-navigator'
import { Page } from 'react-onsenui'
import * as dt from 'modules/database/types'
import * as df from 'modules/database/functions'
import * as _ from 'lodash'
import DateComponent from 'modules/forms/components/Date'
import PhotoDisplay from 'modules/forms/components/PhotoDisplay'
import StorageMessageBar from 'modules/storage/containers/StorageMessageBar'
import moment from 'moment'
import platform from 'modules/platform'
import escapeStringRegexp from 'escape-string-regexp'
import { compareProjectsByLastUpdated, compareProjectsByName } from '../functions'

interface ShowOpenFilePickerOptions {
	multiple?: boolean
	types?: ShowOpenFilePickerType[]
}

interface ShowOpenFilePickerType {
	description?: string
	accept?: {
		[mimeType: string]: string[]
	}
}

declare global {
	interface Window {
		/**
		 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/showOpenFilePicker#browser_compatibility
		 */
		showOpenFilePicker: (options: ShowOpenFilePickerOptions) => Promise<FileSystemFileHandle[]>
	}
}

enum ViewOption {
	AllProjects = 'all projects',
	ActiveProjects = 'active projects',
	ArchivedProjects = 'archived projects',
}

enum SortOrder {
	LastUpdated = 'last updated',
	Name = 'name',
}

/**
 * Interface for private internal component state.
 */
interface State {
	sortingMenuOpen: boolean
	filteringMenuOpen: boolean
	userMenuOpen: boolean
	viewOption: ViewOption
	sortOrder: SortOrder
	searchKeyword?: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	sortingMenuOpen: false,
	filteringMenuOpen: false,
	userMenuOpen: false,
	viewOption: ViewOption.ActiveProjects,
	sortOrder: SortOrder.LastUpdated,
}

type ComponentProps = Props & Actions & RouteProps

export default class ProjectsList extends React.Component<ComponentProps, State> {

	public state = INITIAL_STATE

	private static augmentRoute = (route: Route): Route => {
		const result = { ...route }
		result.title = 'Projects'
		return result
	}

	public render() {
		let projects = _.keys(this.props.projects).map((id, index) => this.props.projects[id]!)
		projects = this.filterProjects(projects)
		this.sortProjects(projects)

		const version = document.documentElement.getAttribute('data-appversion')
		
		return ( 
			<Page renderToolbar={this.renderToolbar}>
				<div className="site-body">
					<div className="width-limit -site">
					
						<div className="project-nav">
							{this.renderSubscription()}
							{this.renderViewOptions()}
							{this.renderOrderOptions()}
							<div className="form-input -text -search">
								<input
									className="field"
									value={this.state.searchKeyword}
									onChange={(event) => {
										event.preventDefault()
										this.setState({ searchKeyword: event.target.value }) 
									}
									}
								/>
								<div className="icon" />
							</div>
							<div className="button-group">
								<a className="button-link -action -icon -new" onClick={this.showNewForm}>New<span className="extended"> project</span></a>
								{this.renderAdminOptions()}
								{/* <a className="button-link -text -icon -edit">Edit<span className="extended"> projects</span></a> */}
							</div>
						</div>
						<div className="project-list">
							{projects.map((project, index) => (
								this.renderRow(project, index)
							))}
						</div>
						{version !== '%%VERSION%%' && (
							<div>
								<p><small className="subtle">Maqasa {version}</small></p>
							</div>
						)}
					</div>
				</div>
				<StorageMessageBar />
			</Page>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<ComponentProps>, nextState: Readonly<State>) {
		if (this.props.projects !== nextProps.projects || this.props.accountName !== nextProps.accountName || this.props.username !== nextProps.username
			|| this.props.subscriber !== nextProps.subscriber || this.props.endDate !== nextProps.endDate) {
			return true
		}
		if (!_.isEqual(this.state, nextState)) {
			return true
		}
		return false
	}

	private renderAdminOptions() {
		return (
			<>
				<a className="button-link -action" onClick={this.importProject}>Import<span className="extended"> project</span></a>
			</>
		)
	}

	private importProject = async(evt: React.MouseEvent) => {
		evt.preventDefault()

		async function getImportJSON() {
			if (typeof window.showOpenFilePicker === 'function') {
				try {
					const files = await window.showOpenFilePicker({
						types: [
							{
								description: 'JSON files',
								accept: {
									'text/*': ['.json'],
								},
							},
						],
					})
					if (files.length) {
						const file = await files[0].getFile()
						return await file.text()
					}
					console.log('files', files)
					return
				} catch (error) {
					return
				}
			} else {
				return window.prompt('Please paste the project JSON to import:')
			}
		}

		const json = await getImportJSON()
		console.log('JSON IS', json)
		if (!json) {
			return
		}

		let project: dt.Project | undefined
		try {
			project = JSON.parse(json)
		} catch (error) {
			project = undefined
		}

		if (!project) {
			platform.alert('Invalid JSON. Please check that you’re using a JSON project export file.')
			return
		}

		if (typeof project.schemaVersion !== 'number') {
			platform.alert('JSON does not appear to contain a project. Please check that you’re using a JSON project export file.')
			return
		}

		this.props.onImportProject(project)
		platform.alert(`Imported project: ${df.projectTitle(project)}`)
	}

	private renderToolbar = () => {
		return (
			<NavigatorToolbar 
				route={this.props.route} 
				title="Projects"
				center={(
					<div className="contents">
						<h1 className="title-page"><div className="site-logo">Maqasa</div></h1>
						<p className="title-section">{this.props.accountName}</p>
					</div>
				)}
				right={(
					<div className="contents">
						<div className={`option-menu -reversed ${this.state.userMenuOpen ? '-active' : ''}`}>
							<p className="title"><a onClick={this.toggleUserMenu} className="selected">{this.props.username}</a></p>
							<ul className="options">
								<li><a className="label" onClick={this.onUserDetails}>Your profile</a></li>
								<li><a className="label" onClick={this.onChangePassword}>Change password</a></li>
								<li><a className="label" onClick={this.onLogout}>Sign out</a></li>
							</ul>
						</div>
					</div>
				)}
			/>
		)
	}

	private toggleUserMenu = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.setState({ userMenuOpen: !this.state.userMenuOpen })
	}

	private onUserDetails = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.setState({
			userMenuOpen: false,
		})
		this.props.onUserDetails()
	}

	private onChangePassword = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.setState({
			userMenuOpen: false,
		})
		this.props.onChangePassword()
	}

	private onLogout = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.setState({
			userMenuOpen: false,
		})
		this.props.onLogout()
	}

	private renderRow = (row: dt.Project, index: number): JSX.Element => {
		return (
			<div className="project" key={index}>
				<figure className="media-element">
					<a onClick={this.onListItemClick.bind(this, row)} className="image">
						<div className="aspect">
							{row.photo && row.photo.imagePath && (
								<PhotoDisplay path={row.photo.annotatedImagePath || row.photo.imagePath} alt={df.projectTitle(row)} />
							)}
						</div>
					</a>
				
					<figcaption className="caption">
						<h4 className="name"><a onClick={this.onListItemClick.bind(this, row)}>{df.projectTitle(row)}</a></h4>
						{row.whenUpdated && (
							<p className="metadata">Updated <DateComponent date={row.whenUpdated} format="D MMM YYYY" /></p>
						)}
					</figcaption>
				</figure>
			</div>
		)
	}

	private filterProjects(projects: dt.Project[]): dt.Project[] {
		/* Remove deleted */
		projects = projects.filter(p => !p.deleted)

		const viewOption = this.state.viewOption
		const projectsToFilter = projects.filter(project => {
			if (project.whenArchived) {
				return viewOption === ViewOption.AllProjects || viewOption === ViewOption.ArchivedProjects
			} else {
				return viewOption === ViewOption.AllProjects || viewOption === ViewOption.ActiveProjects
			}
		})

		const searchKeyword = this.state.searchKeyword && this.state.searchKeyword.toLowerCase()
		if (searchKeyword) {
			return projectsToFilter.filter((project) => project.name && project.name.toLowerCase().match(escapeStringRegexp(searchKeyword)))
		} else {
			return projectsToFilter
		}
	}

	private sortProjects(projects: dt.Project[]) {
		switch (this.state.sortOrder) {
			case SortOrder.LastUpdated:
				projects.sort((a, b) => {
					const result = compareProjectsByLastUpdated(a, b)
					if (result !== 0) {
						return result
					}
					return compareProjectsByName(a, b)
				})
				break
			case SortOrder.Name:
				projects.sort((a, b) => {
					const result = compareProjectsByName(a, b)
					if (result !== 0) {
						return result
					}
					return compareProjectsByLastUpdated(a, b)
				})
				break
			default:
				break
		}
	}

	private renderViewOptions() {
		const current = this.state.viewOption
		return (
			<div className={`option-menu ${this.state.filteringMenuOpen ? '-active' : ''}`}>
				<p className="title">View <a onClick={this.showFilteringMenu} className="selected">{current}</a></p>
				<ul className="options">
					{this.renderMenuOption('All projects', current === ViewOption.AllProjects, this.onViewOptionAllProjects)}
					{this.renderMenuOption('Active projects', current === ViewOption.ActiveProjects, this.onViewOptionActiveProjects)}
					{this.renderMenuOption('Archived projects', current === ViewOption.ArchivedProjects, this.onViewOptionArchivedProjects)}
				</ul>
			</div>
		)
	}

	private renderOrderOptions() {
		const current = this.state.sortOrder
		return (
			<div className={`option-menu ${this.state.sortingMenuOpen ? '-active' : ''}`}>
				<p className="title">Order by <a onClick={this.showSortingMenu} className="selected">{current}</a></p>
				<ul className="options">
					{this.renderMenuOption('Last updated', current === SortOrder.LastUpdated, this.onSortByLastUpdated)}
					{this.renderMenuOption('Name', current === SortOrder.Name, this.onSortByName)}
				</ul>
			</div>
		)
	}

	private renderMenuOption(title: string, selected: boolean, handler: (evt: React.MouseEvent) => void) {
		if (selected) {
			return (
				<li><span className="label" onClick={handler}>{title}</span></li>
			)
		} else {
			return (
				<li><a className="label" onClick={handler}>{title}</a></li>
			)
		}
	}

	private onListItemClick = (project: dt.Project, evt: React.MouseEvent) => {
		evt.preventDefault()
		if (!this.canUseApp()) {
			platform.alert('Please subscribe or renew your subscription to continue using Maqasa.')
			return
		}
		this.props.onProject(project.projectId)
	}

	private showNewForm = () => {
		if (!this.canUseApp()) {
			platform.alert('Please subscribe or renew your subscription to continue using Maqasa.')
			return
		}
		this.props.onNewProject()
	}

	private showSortingMenu = () => {
		this.setState({ sortingMenuOpen: !this.state.sortingMenuOpen, filteringMenuOpen: false })
	}

	private showFilteringMenu = () => {
		this.setState({ filteringMenuOpen: !this.state.filteringMenuOpen, sortingMenuOpen: false })
	}

	private onSortByName = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ sortingMenuOpen: false, sortOrder: SortOrder.Name })
	}

	private onSortByLastUpdated = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ sortingMenuOpen: false, sortOrder: SortOrder.LastUpdated })
	}

	private onViewOption = (viewOption: ViewOption, evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ filteringMenuOpen: false, viewOption })
	}

	private onViewOptionAllProjects = (evt: React.MouseEvent) => {
		this.onViewOption(ViewOption.AllProjects, evt)
	}

	private onViewOptionActiveProjects = (evt: React.MouseEvent) => {
		this.onViewOption(ViewOption.ActiveProjects, evt)
	}

	private onViewOptionArchivedProjects = (evt: React.MouseEvent) => {
		this.onViewOption(ViewOption.ArchivedProjects, evt)
	}

	private renderSubscription() {
		if (this.props.subscriber === true) {
			const diff = moment(this.props.endDate).diff(moment())
			if (diff <= 0) {
				return <div className="banner-message"><p>Your subscription has expired. <a href="https://maqasa.app/subscribe" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Please renew your subscription to continue using Maqasa.</a></p></div>
			} else if (diff < 7 * 24 * 3600 * 1000) {
				return <div className="banner-message"><p>Your subscription expires {moment(this.props.endDate).fromNow()}. <a href="https://maqasa.app/subscribe" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Please renew now to ensure continued access to Maqasa.</a></p></div>
			} else {
				/* Subscription is good, no warning */
				return null
			}
		} else if (this.props.subscriber === false) {
			const diff = moment(this.props.endDate).diff(moment())
			if (diff <= 0) {
				return <div className="banner-message"><p>Your trial has ended. <a href="https://maqasa.app/subscribe" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Please subscribe to continue using Maqasa.</a></p></div>
			} else {
				return <div className="banner-message"><p>Your trial ends {moment(this.props.endDate).fromNow()}. <a href="https://maqasa.app/subscribe" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Please subscribe to ensure continued access to Maqasa.</a></p></div>
			}
		} else {
			/* State not loaded yet */
			return null
		}
	}

	private canUseApp() {
		return this.props.endDate && this.props.endDate.getTime() > Date.now()
	}
}
