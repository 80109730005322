/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as excel from 'exceljs'
import { imageToBase64 } from './functions'
import * as ut from 'modules/user/types'
import * as uf from 'modules/user/functions'
import * as dt from 'modules/database/types'
import { PurchaseOrderSupplier } from './purchaseorders/types'
import logoPath from '../frontend/img/maqasa-blue.png'

/** Determined this value based upon experimentation */
export const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5
export const PIXELS_PER_EXCEL_HEIGHT_UNIT = 1.24

export function fontTitle(row: excel.Row) {
	row.font = {
		bold: true,
		size: 22,
	}
	row.height = 28
}

export function fontBold(row: excel.Row) {
	row.font = {
		bold: true,
	}
}

export function fontBoldLarge(row: excel.Row) {
	row.font = {
		bold: true,
		size: 18,
	}
	row.height = 24
}

export function fontBoldMedium(row: excel.Row) {
	row.font = {
		bold: true,
		size: 14,
	}
	row.height = 20
}

export function formatCurrency(cell: excel.Cell) {
	cell.numFmt = '"$"0.00'
}

export function formatDate(cell: excel.Cell) {
	cell.numFmt = 'dd/MM/yyyy'
}

export async function addLogo(sheet: excel.Worksheet, col: number, row: number): Promise<excel.Row> {
	const logo = await imageToBase64(logoPath, 'image/png')
	const logoRow = sheet.addRow([])
	logoRow.height = logo.height / 2 / PIXELS_PER_EXCEL_HEIGHT_UNIT

	const logoId = sheet.workbook.addImage({
		base64: logo.base64,
		extension: 'png',
	})
	sheet.addImage(logoId, {
		tl: { col: col - 1, row: row - 1 },
		ext: { width: logo.width / 2, height: logo.height / 2 },
		editAs: 'oneCell',
	})

	sheet.addRow([])
	return logoRow
}

export function addCompany(sheet: excel.Worksheet, user: ut.User) {
	if (user.organisation) {
		const companyRow = sheet.addRow([user.organisation])
		fontTitle(companyRow)
	}
}

export function addConsultant(sheet: excel.Worksheet, user: ut.User) {
	const consultantRow = sheet.addRow([`Consultant: ${uf.displayName(user)}`])
	fontBoldLarge(consultantRow)

	sheet.addRow([])
}

export function addDeliverTo(sheet: excel.Worksheet, rowIndex: number, user: ut.User) {
	const cellIndex = Math.min(sheet.columnCount - 1, 7)
	sheet.getCell(rowIndex, cellIndex).value = 'DELIVER TO'
	if (user.organisation) {
		sheet.getCell(rowIndex + 1, cellIndex).value = user.organisation
	}
	if (user.deliveryAddress) {
		sheet.getCell(rowIndex + 2, cellIndex).value = user.deliveryAddress
	}
}

export function addProject(sheet: excel.Worksheet, project: dt.Project) {
	fontBold(sheet.addRow(['Project']))
	if (project.name) {
		sheet.addRow([project.name])
	}
	if (project.address) {
		sheet.addRow([project.address])
	}
	if (project.contactName) {
		sheet.addRow([project.contactName])
	}
	if (project.phone) {
		sheet.addRow([`Ph: ${project.phone}`])
	}
	if (project.mobile) {
		sheet.addRow([`Mob: ${project.mobile}`])
	}
	if (project.email) {
		sheet.addRow([project.email])
	}

	sheet.addRow([])	
}

export function addProjectName(sheet: excel.Worksheet, project: dt.Project) {
	if (!project.name) {
		return
	}

	fontBold(sheet.addRow(['Project']))
	sheet.addRow([project.name])
	sheet.addRow([])
}

export function addProjectNameAndAddress(sheet: excel.Worksheet, project: dt.Project) {
	if (!project.name) {
		return
	}

	fontBold(sheet.addRow(['Project']))
	sheet.addRow([project.name])
	if (project.address) {
		sheet.addRow([project.address])
	}
	if (project.phone) {
		sheet.addRow([`Ph: ${project.phone}`])
	}
	if (project.mobile) {
		sheet.addRow([`Mob: ${project.mobile}`])
	}
	if (project.email) {
		sheet.addRow([project.email])
	}
	sheet.addRow([])
}

export function addClient(sheet: excel.Worksheet, client: dt.Client) {
	fontBold(sheet.addRow(['Client']))
	if (client.name) {
		sheet.addRow([client.name])
	}
	if (client.address) {
		sheet.addRow([client.address])
	}
	if (client.phone) {
		sheet.addRow([`Ph: ${client.phone}`])
	}
	if (client.mobile) {
		sheet.addRow([`Mob: ${client.mobile}`])
	}
	if (client.email) {
		sheet.addRow([client.email])
	}

	sheet.addRow([])
}

export function addTitle(sheet: excel.Worksheet, title: string) {
	sheet.addRow([])
	
	const dateRow = sheet.addRow([new Date()])
	formatDate(dateRow.getCell(1))
	dateRow.alignment = { horizontal: 'left' }
	dateRow.getCell(2).merge(dateRow.getCell(1))

	sheet.addRow([])

	const titleRow = sheet.addRow([`${title}`])
	fontTitle(titleRow)
	titleRow.font.underline = true
}

export function addSubtitle(sheet: excel.Worksheet, subtitle: string) {
	const subtitleRow = sheet.addRow([`${subtitle}`])
	fontTitle(subtitleRow)
	subtitleRow.font.underline = true
	sheet.addRow([])
}

export function addSupplier(sheet: excel.Worksheet, supplier: PurchaseOrderSupplier) {
	const supplierRow = sheet.addRow([supplier.supplier])
	fontTitle(supplierRow)

	if (supplier.purchaseOrderNumber) {
		const poRow = sheet.addRow([`Purchase Order: ${supplier.purchaseOrderNumber}`])
		fontBold(poRow)
	}

	sheet.addRow([])
}

/** Auto-size the given worksheet from the given row number up to but not including the `toRow` number (if given, otherwise to the end of the sheet). */
export function autoSize(sheet: excel.Worksheet, fromRow: number, toRow?: number) {
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	if (!ctx) {
		return
	}

	const maxColumnLengths: number[] = []
	sheet.eachRow((row, rowNum) => {
		if (rowNum < fromRow || (toRow !== undefined && rowNum >= toRow)) {
			return
		}

		row.eachCell((cell, num) => {
			let cellStringValue: string
			if (typeof cell.value === 'string') {
				cellStringValue = cell.value
			} else if (typeof cell.value === 'number') {
				cellStringValue = `${cell.value}`
				if (cell.numFmt && cell.numFmt.startsWith('$')) {
					cellStringValue = `$${cellStringValue}`
				}
			} else {
				cellStringValue = `${cell.value}`
			}

			if (maxColumnLengths[num] === undefined) {
				maxColumnLengths[num] = 0
			}

			const fontSize = cell.font && cell.font.size ? cell.font.size : 11
			const bold = cell.font && cell.font.bold
			ctx.font = `${bold ? 'bold ' : ''}${fontSize}pt Calibri`
			const metrics = ctx.measureText(cellStringValue)
			const cellWidth = metrics.width
			
			maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth)
		})
	})

	for (let i = 1; i <= sheet.columnCount; i++) {
		const col = sheet.getColumn(i)
		const width = maxColumnLengths[i]
		if (width) {
			col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1
		}
	}
}

/** Apply a function to each cell in a rectangular area */
export function applyToCells(sheet: excel.Worksheet, func: (cell: excel.Cell) => void, fromRow: number, fromCol: number, toRow?: number, toCol?: number) {
	toCol = toCol || sheet.columnCount
	toRow = toRow || sheet.rowCount
	for (let i = fromCol; i <= toCol; i++) {
		for (let j = fromRow; j <= toRow; j++) {
			const cell = sheet.getCell(j, i)
			func(cell)
		}
	}
}

export function hideEmptyColumns(sheet: excel.Worksheet, excludeRows: number[], fromCol: number, toCol?: number) {
	toCol = toCol || sheet.columnCount
	const toRow = sheet.rowCount

	for (let i = fromCol; i <= toCol; i++) {
		let empty = true
		for (let j = 1; j <= toRow; j++) {
			if (excludeRows.indexOf(j) !== -1) {
				continue
			}

			const value = sheet.getCell(j, i).value
			if (value !== undefined && value !== '' && value !== null) {
				empty = false
				break
			}
		}

		if (empty) {
			const col = sheet.getColumn(i)
			col.hidden = true
		}
	}
}

/** 
 * Align the columns in a header row using our alignment rules.
 * 
 * If a column has units specified in ()s at the end then it is aligned right. If the specified units
 * are blank, e.g. (), then the brackets are removed, so you can add " ()" to the end of your header
 * cell to align it right.
 */
export function alignHeaderRow(row: excel.Row) {
	const cells = row.cellCount
	for (let i = 1; i <= cells; i++) {
		const cell = row.getCell(i)
		if (typeof cell.value === 'string' && cell.value.indexOf(' (') !== -1) {
			cell.alignment = { horizontal: 'center' }

			if (cell.value.endsWith(' ()')) {
				cell.value = cell.value.substring(0, cell.value.length - 3)
			} else if (cell.value.endsWith(' ($)')) {
				cell.alignment = { horizontal: 'right' }
				cell.value = cell.value.substring(0, cell.value.length - 4)
			}
		}
	}
}

export function formatCellForHeader(cell: excel.Cell, header: string) {
	if (header.indexOf(' (') !== -1) {
		cell.alignment = { horizontal: 'center' }
	}
	if (header.endsWith('($)')) {
		cell.alignment = { horizontal: 'right' }
		formatCurrency(cell)
	}
}

export function alignLastColumn(sheet: excel.Worksheet, fromRow: number, toRow: number, headerRows: number[]): number {
	let longestHeader = 0

	for (const headerRow of headerRows) {
		const row = sheet.getRow(headerRow)
		for (let col = 1; col <= sheet.columnCount; col++) {
			if (row.getCell(col).value && col > longestHeader) {
				longestHeader = col
			}
		}
	}

	for (let i = 0; i < headerRows.length; i++) {
		let headerCols = 0
		const headerRow = sheet.getRow(headerRows[i])
		for (let col = longestHeader; col >= 0; col--) {
			if (headerRow.getCell(col).value) {
				headerCols = col
				break
			}
		}

		if (headerCols < longestHeader) {
			for (let j = headerRows[i]; j < (i + 1 < headerRows.length ? headerRows[i + 1] : toRow); j++) {
				const row = sheet.getRow(j)
				row.getCell(longestHeader).value = row.getCell(headerCols).value
				row.getCell(longestHeader).alignment = row.getCell(headerCols).alignment
				row.getCell(longestHeader).font = row.getCell(headerCols).font
				row.getCell(longestHeader).numFmt = row.getCell(headerCols).numFmt
				row.getCell(headerCols).value = ''
			}
		}
	}

	/* The minimum column is 1 */
	return Math.max(longestHeader, 1)
}

export function alignCells(sheet: excel.Worksheet, summarySheetDataFrom: number): void {
	applyToCells(
		sheet, 
		(cell) => {
			if (cell.type === excel.ValueType.String) {
				if (cell.alignment) {
					cell.alignment.wrapText = true
				} else {
					cell.alignment = {
						wrapText: true,
					}
				}
				cell.alignment.vertical = 'top'
			} else if (cell.type === excel.ValueType.Number) {
				if (cell.alignment) {
					cell.alignment.horizontal = 'center'
				} else {
					cell.alignment = {
						horizontal: 'center',
					}
				}
				cell.alignment.vertical = 'top'
			}
		}, 
		summarySheetDataFrom, 
		1,
	)
}
