/**
 * The root saga file that defines the root saga.
 */

import { all, put, take } from 'redux-saga/effects'

/* Import module sagas */
import authSaga from 'modules/auth/sagas'
import platformSaga from 'modules/platform/sagas'
import projectSaga from 'modules/project/sagas'
import productSaga from 'modules/product/sagas'
import databaseSaga from 'modules/database/sagas'
import storageSaga from 'modules/storage/sagas'
import formsSaga from 'modules/forms/sagas'
import comboboxSaga from 'modules/combobox/sagas'
import userSaga from 'modules/user/sagas'

import { persistReadyAction, readyAction } from './actions'
import { databaseReady } from 'modules/database/actions'
import { SagaIterator } from 'redux-saga'

/** Waits until the prerequisite actions have been fired, then dispatches the readyAction */
function* waitForReady() {
	yield all([
		take(persistReadyAction),
		take(databaseReady),
	])

	yield put(readyAction())
}

/** The root saga that starts all of the other sagas. */
export default function* rootSaga(): SagaIterator {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	yield all<any>([
		waitForReady(),
		authSaga(),
		platformSaga(),
		projectSaga(),
		productSaga(),
		databaseSaga(),
		storageSaga(),
		formsSaga(),
		comboboxSaga(),
		userSaga(),
	])
}
