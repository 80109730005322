/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { Page, ProgressCircular } from 'react-onsenui'
import { Props, Actions } from '../containers/AnnotateImageScreen'
import { NavigatorToolbar, RouteProps } from 'onsenui-react-redux-navigator'
import * as t from '../types'
import { SketchField } from 'react-sketch'
import ReactResizeDetector from 'react-resize-detector'
import { getAssetURL, convertURLForLocal, getAnnotations } from 'modules/storage'

interface OwnProps {
	// exampleProperty: string
}

/**
 * Interface for private internal component state.
 */
interface State {
	imagePath?: string
	loadingImage: boolean
	loadingAnnotations: boolean
	imageWidth: number
	imageHeight: number
	canvasScale: number
	parentWidth?: number
	width?: number
	height?: number

	tool: string
	lineColor: string
	canUndo: boolean
	canRedo: boolean
	canRemove: boolean

	image?: HTMLImageElement
	annotations?: string
	updateSketch: boolean
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	loadingImage: false,
	loadingAnnotations: false,
	width: 100,
	height: 100,
	imageWidth: 100,
	imageHeight: 100,
	canvasScale: 1.0,
	tool: 'pencil',
	lineColor: 'red',
	canUndo: false,
	canRedo: false,
	canRemove: false,
	updateSketch: false,
}

export default class AnnotateImageScreen extends React.Component<Props & Actions & OwnProps & RouteProps<t.AnnotateRouteProps>, State> {

	public state = INITIAL_STATE

	private sketch = React.createRef<SketchField>()

	private unmounted = false

	public render() {
		const loading = (this.state.loadingImage || this.state.loadingAnnotations)
		const { width, height, imageWidth, imageHeight, canvasScale, tool, lineColor, canUndo, canRedo, canRemove } = this.state

		return (
			<Page className="screen-modal">
				<div className="modal-content -annotation">
					<div className="width-limit -site -pageboundaries">
						<div className="body">
							{this.renderToolbar()}
							<div className="modal-body" style={{ position: 'relative' }}>
								{loading && (
									<div 
										style={{
											position: 'absolute',
											left: '50%',
										}}
									>
										<ProgressCircular indeterminate={true} />
									</div>
								)}
								<ReactResizeDetector handleWidth={true} handleHeight={true} onResize={this.onResize} />
								<div style={{ width: `${width}px`, height: `${height}px`, margin: '0 auto' }}>
									<SketchField
										width={imageWidth}
										height={imageHeight}
										ref={this.sketch}
										lineColor={lineColor}
										fillColor={lineColor}
										style={{
											visibility: loading ? 'hidden' : 'visible',
											transform: `translate(-${imageWidth * (1.0 - canvasScale) / 2}px,-${imageHeight * (1.0 - canvasScale) / 2}px) scale(${canvasScale}, ${canvasScale})`,
										}}
										widthCorrection={0}
										tool={tool}
										onChange={this.onSketchChange}
									/>
								</div>
								<div className="annotation-toolbar">
									<div className="group">
										<button type="button" onClick={this.onDrawMode} className={tool === 'pencil' ? 'option -draw -selected' : 'option -draw'}>Draw</button>
										<button type="button" onClick={this.onSelectMode} className={tool === 'select' ? 'option -select -selected' : 'option -select'}>Select</button>
									</div>
									<div className="group">
										<button type="button" onClick={this.onChooseColor.bind(this, 'red')} className={lineColor === 'red' ? 'option -colour -red -selected' : 'option -colour -red'}>Red</button>
										<button type="button" onClick={this.onChooseColor.bind(this, 'black')} className={lineColor === 'black' ? 'option -colour -black -selected' : 'option -colour -black'}>Black</button>
										<button type="button" onClick={this.onChooseColor.bind(this, 'white')} className={lineColor === 'white' ? 'option -colour -white -selected' : 'option -colour -white'}>White</button>
									</div>
									<button type="button" onClick={this.onText} className="option -text">Text</button>
									<div className="group">
										<button type="button" onClick={canUndo ? this.onUndo : this.noop} className={canUndo ? 'option -undo' : 'option -undo -disabled'}>Undo</button>
										<button type="button" onClick={canRedo ? this.onRedo : this.noop} className={canRedo ? 'option -redo' : 'option -redo -disabled'}>Redo</button>
									</div>
									<button type="button" onClick={canRemove ? this.onRemoveSelected : this.noop} className={canRemove ? 'option -delete' : 'option -delete -disabled'}>Remove</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page >
		)
	}

	public componentDidMount() {
		this.loadImage()
		this.updateDimensions()
		this.updateSketch()
	}

	public componentDidUpdate() {
		this.loadImage()
		this.updateDimensions()
		this.updateSketch()
	}

	public componentWillUnmount() {
		this.unmounted = true
	}

	private onDrawMode = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.sketch.current) {
			this.sketch.current.clearSelection()
		}
		this.setState({ tool: 'pencil' })
	}

	private onSelectMode = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ tool: 'select' })
	}

	private onText = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (!this.sketch.current) {
			return
		}
		const text = window.prompt('What text would you like to add?')
		if (text) {
			const wrappedText = this.wrapText(text, 40)
			const textBox = this.sketch.current.addText(
				wrappedText, 
				{
					stroke: this.state.lineColor, 
					fill: this.state.lineColor, 
					fontFamily: 'Sans-serif',
					width: 100,
				},
			) as fabric.IText

			/* Scale the font size to fit */
			if (this.state.width && this.state.height && textBox.width && textBox.height && textBox.fontSize) {
				const ratio = Math.min(this.state.width / textBox.width, this.state.height / textBox.height)
				if (ratio < 1) {
					textBox.fontSize *= (ratio * 0.9)
					textBox.left = this.state.width * 0.05
					if (textBox.top) {
						textBox.top = Math.max(0, textBox.top)
					}
				}
			}

			this.setState({ tool: 'select' })
		}
	}

	private wrapText = (text: string, wrapAt: number): string => {
		return text.replace(/(.{20}\S*)/g, '$1\n').replace(/\s*$/gm, '').replace(/^\s*/gm, '')
	}

	private onChooseColor = (color: string, evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ lineColor: color })
	}

	private onUndo = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.sketch.current) {
			this.sketch.current.undo()
		}
	}

	private onRedo = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.sketch.current) {
			this.sketch.current.redo()
		}
	}

	private onRemoveSelected = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.sketch.current) {
			this.sketch.current.removeSelected()
		}
	}

	private noop = (evt: React.MouseEvent) => {
		evt.preventDefault()
	}

	private onSketchChange = () => {
		if (!this.sketch.current) {
			return
		}

		const canUndo = this.sketch.current.canUndo()
		const canRedo = this.sketch.current.canRedo()
		const canRemove = this.sketch.current.hasSelection()

		if (canUndo !== this.state.canUndo || canRedo !== this.state.canRedo || canRemove !== this.state.canRemove) {
			this.setState({
				canUndo,
				canRedo,
				canRemove,
			})
		}
	}

	private onResize = (width?: number, height?: number) => {
		console.log(`ANNOTATE: resized to ${width}x${height}`)
		this.setState({
			parentWidth: width,
		})
	}

	private updateDimensions = () => {
		const { imageWidth, imageHeight, parentWidth, canvasScale, width, height } = this.state
		if (!imageWidth || !imageHeight || !parentWidth) {
			return
		}

		const newCanvasScale = Math.min(1.0, parentWidth / imageWidth)
		const newWidth = Math.floor(imageWidth * canvasScale)
		const newHeight = Math.floor(imageHeight * canvasScale)

		if (newCanvasScale === canvasScale && newWidth === width && newHeight === height) {
			return
		}
		
		this.setState({
			width: newWidth,
			height: newHeight,
			canvasScale: newCanvasScale,
		})
	}

	private updateSketch = () => {
		if (!this.state.updateSketch || !this.sketch.current) {
			return
		}

		const { image, annotations } = this.state
		if (annotations) {
			this.sketch.current.fromJSON(annotations)
		}
		if (image) {
			/* We need a timeout as react-sketch has one in fromJSON */
			setTimeout(
				() => {
					this.sketch.current!.setBackgroundFromImage(image)
				},
				100,
			)
		}

		this.setState({ updateSketch: false })
	}

	private loadImage = () => {
		const value = this.props.route.props && this.props.route.props.image
		if (!value) {
			return
		}
		/* Legacy data has value as a string containing the image path */
		const imagePath = typeof value === 'string' ? value : value.imagePath
		if (!imagePath) {
			return
		}

		const { parentWidth } = this.state
		if (!parentWidth) {
			return
		}

		/* Check if we've already loaded the current image */
		if (this.state.imagePath === imagePath) {
			return
		}
		
		this.setState({ loadingImage: true, imagePath })

		getAssetURL(imagePath).then(url => {
			if (this.unmounted) {
				return
			}

			url = convertURLForLocal(url)

			console.log('ANNOTATE: got url', url)

			/* Load the image to determine the dimensions */
			const img = new Image()
			img.onload = () => {
				if (this.unmounted) {
					return
				}

				this.setState({
					loadingImage: false,
					imageWidth: img.width,
					imageHeight: img.height,
					image: img,
					updateSketch: true,
				})
			}
			img.onerror = () => {
				if (this.unmounted) {
					return
				}

				console.warn('ANNOTATE: failed to load image', url)
				this.props.onCancel()
			}
			img.crossOrigin = 'anonymous'
			img.src = url
		}).catch(error => {
			if (this.unmounted) {
				return
			}

			console.log('ANNOTATE: failed to get url', error)
			this.props.onCancel()
		})

		if (value.annotationsPath) {
			this.setState({ loadingAnnotations: true })

			getAnnotations(value.annotationsPath).then(json => {
				if (this.unmounted) {
					return
				}

				this.setState({ loadingAnnotations: false, annotations: json, updateSketch: true })
			}).catch(error => {
				if (this.unmounted) {
					return
				}
				
				console.log('ANNOTATE: failed to get annotations', error)
				this.setState({ loadingAnnotations: false })
			})
		}
	}

	private renderToolbar = () => {
		return (
			<NavigatorToolbar 
				route={this.props.route}
				center={(
					<div className="contents">
						<h1 className="title-page">Annotate Image</h1>
					</div>
				)}
				renderToolbar={(navigationController: unknown, props: { title: string }, actions: unknown) => {
					return (
						<header className="modal-header">
							<div className="contents">
								<a onClick={this.onSave} className="back">Save</a>
								<h1 className="title-page">{props.title}</h1>
							</div>
						</header>
					)
				}}
			/>
		)
	}

	private onSave = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (!this.sketch.current) {
			this.props.onCancel()
			return
		}

		const value = this.props.route.props && this.props.route.props.image
		if (!value) {
			this.props.onCancel()
			return
		}

		const path = this.props.route.props && this.props.route.props.path
		if (!path) {
			this.props.onCancel()
			return
		}

		const imagePath = typeof value === 'string' ? value : value.imagePath
		if (!imagePath) {
			this.props.onCancel()
			return
		}

		const json = this.sketch.current.toJSON()

		/* We remove the background image from the JSON as must add it each time to use a new image URL (as the old one may no longer be valid) */
		delete json.backgroundImage
		
		try {
			this.sketch.current.clearSelection()
			/* Render slightly later so we don't include any selection handles */
			setTimeout(
				() => {
					this.sketch.current!.getElement().toBlob(
						(blob: Blob | null) => {
							if (blob) {
								this.props.onSave(imagePath, path, blob, json)
							}
						},
						'image/jpeg',
						0.5,
					)
				},
				100,
			)
		} catch (error) {
			console.error('ANNOTATE: failed to export canvas: ', error)
			this.props.onCancel()
		}
	}
}
