import Calculation from 'modules/root/calculations'
import * as dt from 'modules/database/types'
import { calculateRRP } from 'modules/root/functions'

export interface PricingResult {
	cost: number
	rrp: number
}

export function calculatePricing(price: dt.Pricing | undefined, taxDetails: dt.TaxDetails | undefined, quantity: number, name: string, property?: string): Calculation<PricingResult | undefined> {
	const calculation = new Calculation<PricingResult | undefined>(undefined)

	if (!price) {
		return calculation.withErrorMessage(`${name} pricing has not been set.`)
	}

	const effectiveMode = price.mode !== undefined ? price.mode : dt.PricingMode.Cost
	switch (effectiveMode) {
		case dt.PricingMode.Cost: {
			const cost = calculation.toNumber(price.cost, `${name} cost price`, property ? [`${property}.price.cost`] : undefined)
			const markup = calculation.toNumber(price.markup, `${name} markup`, property ? [`${property}.price.markup`] : undefined, { operation: '*' })
			const rrp = calculateRRP(cost, markup, taxDetails)

			if (!isNaN(cost)) {
				return calculation.withResult({
					cost: cost * quantity,
					rrp: rrp * quantity,
				})
			} else {
				return calculation
			}
		}
		case dt.PricingMode.RRP: {
			const cost = calculation.toNumber(price.cost, `${name} cost price`)
			const rrp = calculation.toNumber(price.rrp, `${name} sell price`)

			if (!isNaN(cost) && !isNaN(rrp)) {
				return calculation.withResult({
					cost: cost * quantity,
					rrp: rrp * quantity,
				})
			} else {
				return calculation
			}
		}
		case dt.PricingMode.NA:
			return calculation
		default:
			return calculation.withErrorMessage(`Unsupported pricing mode: ${price.mode}`)
	}
}
