/**
 * Collect available purchase order information for a project.
 */

import * as _ from 'lodash'
import * as dt from 'modules/database/types'
import { addCurtainsPurchaseOrders } from 'modules/product/curtains/purchaseorders'
import { addBlindsPurchaseOrders } from 'modules/product/blinds/purchaseorders'
import { addCushionsPurchaseOrders } from 'modules/product/cushions/purchaseorders'
import { productsByType, allProducts } from './functions'

export interface AvailablePurchaseOrdersResult {
	fabric: AvailablePurchaseOrderSupplier[]
	workroom: AvailablePurchaseOrderSupplier[]
	hardware: AvailablePurchaseOrderSupplier[]
}

export interface AvailablePurchaseOrders {
	fabric: AvailablePurchaseOrderSuppliers
	workroom: AvailablePurchaseOrderSuppliers
	hardware: AvailablePurchaseOrderSuppliers
}

export interface AvailablePurchaseOrderSuppliers {
	[supplier: string]: AvailablePurchaseOrderSupplier | undefined
}

export interface AvailablePurchaseOrderSupplier {
	supplier: string
}

/** Return that available purchase orders details */
export function availablePurchaseOrders(project: dt.Project): AvailablePurchaseOrdersResult {
	const working: AvailablePurchaseOrders = {
		fabric: {},
		workroom: {},
		hardware: {},
	}

	const pbt = productsByType(allProducts(project))

	dt.AllProductTypes.forEach(productType => {
		const products = pbt.productsByType[productType]
		if (products && products.products.length) {
			switch (productType) {
				case dt.ProductType.Curtain:
					addCurtainsPurchaseOrders(project, products.products, working)
					break
				case dt.ProductType.Blind:
					addBlindsPurchaseOrders(project, products.products, working)
					break
				case dt.ProductType.Cushion:
					addCushionsPurchaseOrders(project, products.products, working)
					break
				default:
					console.warn(`availablePurchaseOrders: Unsupported product type: ${productType}`)
			}
		}
	})

	/* Create result */
	const result: AvailablePurchaseOrdersResult = {
		fabric: sortedSuppliers(working.fabric),
		workroom: sortedSuppliers(working.workroom),
		hardware: sortedSuppliers(working.hardware),
	}
	return result
}

function sortedSuppliers(suppliers: AvailablePurchaseOrderSuppliers): AvailablePurchaseOrderSupplier[] {
	return _.keys(suppliers).sort().map(supplier => suppliers[supplier]!)
}

/** Add supplier information to the list of available suppliers */
export function addSupplier(suppliers: AvailablePurchaseOrderSuppliers, supplier: string) {
	if (!suppliers[supplier]) {
		suppliers[supplier] = {
			supplier,
		}
	}
}
