import * as dt from 'modules/database/types'

interface FormatNumberOptions {
	dp?: number
	truncateDp?: boolean
	units?: string
	addCommas?: boolean
}

const DEFAULT_FORMAT_NUMBER_OPTIONS: FormatNumberOptions = {
	addCommas: true,
}

export function formatNumber(value: number | undefined, dp?: number): string
export function formatNumber(value: number | undefined, options?: FormatNumberOptions): string
export function formatNumber(value: number | undefined, maybeOptions?: FormatNumberOptions | number): string {
	if (value === undefined) {
		return '-'
	}
	if (isNaN(value)) {
		return 'Error'
	}
	if (value === Infinity) {
		return 'Infinity'
	}

	const options: FormatNumberOptions = {
		...DEFAULT_FORMAT_NUMBER_OPTIONS,
		...(maybeOptions !== undefined ? (typeof maybeOptions === 'number' ? { dp: maybeOptions } : maybeOptions) : {}),
	}

	let result: string
	if (options.dp !== undefined) {
		if (value === 0) {
			if (options.dp > 0) {
				result = `0.${'0'.repeat(options.dp)}`
			} else {
				result = '0'
			}
		} else {
			result = `${Math.round(value * Math.pow(10, options.dp))}`
			if (options.dp > 0) {
				result = result.substring(0, result.length - options.dp) + '.' + result.substring(result.length - options.dp)
			}
		}
	} else {
		result = `${value}`
	}

	if (result.startsWith('.')) {
		result = `0${result}`
	}

	if (options.truncateDp) {
		if (result.lastIndexOf('.') !== -1) {
			while (result.endsWith('0')) {
				result = result.substring(0, result.length - 1)
			}
			if (result.endsWith('.')) {
				result = result.substring(0, result.length - 1)
			}
		}
	}

	if (options.addCommas) {
		let i = result.indexOf('.') 
		if (i === -1) {
			i = result.length
		}

		const startOfNumber = result.startsWith('-') ? 1 : 0

		i -= 3
		while (i > startOfNumber) {
			result = result.substring(0, i) + ',' + result.substring(i)
			i -= 3
		}
	}
	if (options.units !== undefined) {
		if (options.units.indexOf('$') !== -1) {
			result = options.units + result
		} else {
			result += ` ${options.units}`
		}
	}
	return result
}

export function addTax(excl: number, taxDetails: dt.TaxDetails | undefined): number {
	if (!taxDetails) {
		return excl
	}

	return excl * (100.0 + parseFloat(`${taxDetails.taxRate}`)) / 100.0
}

export function removeTax(incl: number, taxDetails: dt.TaxDetails | undefined): number {
	if (!taxDetails || !taxDetails.taxRate) {
		return incl
	}

	return incl / (100.0 + parseFloat(`${taxDetails.taxRate}`)) * 100.0
}

export function calculateMarkup(cost: number, sell: number, taxDetails: dt.TaxDetails | undefined) {
	const rrpexcltax = removeTax(sell, taxDetails)
	if (!cost) {
		return 0
	}
	const markup = rrpexcltax / cost * 100 - 100
	return Math.round(markup * 100) / 100
}

export function calculateRRP(cost: number, markup: number, taxDetails: dt.TaxDetails | undefined) {
	const rrp = addTax(cost * (100 + 1 * markup) / 100, taxDetails)
	return Math.round(rrp * 100) / 100
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toNumber(value: any): number | undefined {
	if (value === undefined) {
		return undefined
	}

	const result = parseFloat(value as unknown as string)
	if (isNaN(result)) {
		return undefined
	}

	return result
}

export function roundUpTo100(value: number): number {
	return Math.ceil(value / 100) * 100
}

/** Simone rounds if the value is > 0.1 over */
export function roundUpWithLeeway(value: number): number {
	let result = Math.floor(value)
	if (value - result > 0.1) {
		result += 1
	}
	return result
}

export function formatMillimetresToMetres(millimetres?: number): string | undefined {
	if (millimetres === undefined) {
		return undefined
	}

	return formatNumber(millimetres / 1000, 1)
}
