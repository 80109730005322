import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import Calculation from 'modules/root/calculations'
import { FabricQuantities, GenericFabricOptions, GenericFabricTargetCalculations, calculateGenericFabricQuantity, calculateGenericFabricPricing } from 'modules/product/common/fabric'
import { sumGenericPricing, GenericPricing } from 'modules/product/common/pricing'
import { calculatePricing } from 'modules/project/calculations'
import { calculateGenericHardwarePricing } from 'modules/product/common/hardware'
import { FabricOrientation } from 'modules/database/types/fabric'

const BLIND_FULLNESS = 0

export function blindTypeName(type: dtp.BlindType, long?: boolean): string {
	switch (type) {
		case dtp.BlindType.Roller: return long ? 'Roller Blind' : 'Roller'
		case dtp.BlindType.Roman: return long ? 'Roman Blind' : 'Roman'
		case dtp.BlindType.Venetian: return long ? 'Venetian Blind' : 'Venetian'
		default:
			throw new Error(`blindTypeName: Unknown blind type ${type}`)
	}
}

export function blindName(blind: dtp.Blind) {
	if (blind.overview && blind.overview.blindType) {
		return blindTypeName(blind.overview.blindType, true)
	} else {
		return 'Blind'
	}
}

export function calculateWidth(measures: dtp.BlindMeasures, ignoreCustom?: boolean): Calculation<number> {
	const calculation = new Calculation(NaN)

	if (!ignoreCustom && measures.useCustomWidth) {
		const result = calculation.toNumber(measures.customWidth, 'Custom width')
		return calculation.withResult(result)
	}

	const method = measures.widthCalculationMethod
	if (!method) {
		return calculation.withErrorMessage('Width calculation method not set')
	}

	let total = 0
	switch (method) {
		case dtp.BlindWidthCalculationMethod.inside:
			total += calculation.toNumber(measures.archToArchWidthInside, 'Architrave to Architrave (Inside Width)')
			break
		case dtp.BlindWidthCalculationMethod.outside:
			total += calculation.toNumber(measures.archToArchWidthOutside, 'Architrave to Architrave (Outside Width)')
			break
		default:
			return calculation.withErrorMessage(`Unsupported width calculation method : ${method}`)
	}

	total +=
		calculation.toNumber(measures.returnSizeLeft, 'Return Left', ['measures.returnSizeLeft'], { defaultValue: 0, operation: '+' }) + 
		calculation.toNumber(measuresReturnSizeRight(measures), 'Return Right', ['measures.returnSizeRight'], { defaultValue: 0, operation: '+' })
	
	return calculation.withResult(total)
}

export function calculateFinishedLength(measures: dtp.BlindMeasures, ignoreCustom?: boolean): Calculation<number> {
	const calculation = new Calculation(NaN)

	if (!ignoreCustom && measures.useCustomHeight) {
		const result = calculation.toNumber(measures.customHeight, 'Custom height')
		return calculation.withResult(result)
	}

	const method = measures.finishedLengthCalculationMethod
	if (!method) {
		return calculation.withErrorMessage('Drop calculation method not set')
	}

	let total = 0
	switch (method) {
		case dtp.BlindFinishedLengthCalculationMethod.inside:
			total += calculation.toNumber(measures.archToArchHeightInside, 'Architrave to Architrave (Inside Height)')
			break
		case dtp.BlindFinishedLengthCalculationMethod.outside:
			total += calculation.toNumber(measures.archToArchHeightOutside, 'Architrave to Architrave (Outside Height)')
			break
		default:
			return calculation.withErrorMessage(`Unsupported Drop calculation method : ${method}`)
	}
	
	total += calculation.toNumber(measures.aboveArch, 'Fix Above Architrave', ['measures.aboveArch'], { defaultValue: 0, operation: '+' })
	total += calculation.toNumber(measures.belowArch, 'Hem Below Architrave', ['measures.belowArch'], { defaultValue: 0, operation: '+' })
	return calculation.withResult(total)
}

export function measuresReturnSizeRight(measures: dtp.BlindMeasures) {
	if (measures.returnSizesEqual === false) {
		return measures.returnSizeRight
	} else {
		return measures.returnSizeLeft
	}
}

interface BlindQuantities {
	fabric?: FabricQuantities
	lining?: FabricQuantities
	interlining?: FabricQuantities
	trim?: number
}

export function defaultHemAllowance(blind: dtp.Blind): number | undefined {
	if (blind.overview && blind.overview.blindType === dtp.BlindType.Roman) {
		return 400
	}

	return undefined
}

export function calculateQuantities(detail: dtp.Blind): Calculation<BlindQuantities> {
	const result: BlindQuantities = {}
	const calculation = new Calculation(result)

	if (!detail.overview) {
		return calculation.withErrorMessage('Blind overview options are not set')
	}
	const blindType = detail.overview.blindType
	if (!blindType) {
		return calculation.withErrorMessage('Blind type is not set')
	}

	const fabric: dtp.BlindFabric | undefined = blindType === dtp.BlindType.Roman ? detail.fabric : {
		fabric: false,
		lined: false,
		trim: false,
	}
	const measures = detail.measures
	const specifications = detail.specifications
	if (!fabric) {
		return calculation.withErrorMessage('Fabric options are not set')
	}
	if (!measures) {
		return calculation.withErrorMessage('Measures are not set')
	}

	const hemAllowanceCalculation = new Calculation(NaN)
	const hemAllowance = hemAllowanceCalculation.toNumber(specifications && specifications.hemAllowance, 'Hem allowance', ['specifications.hemAllowance'], { defaultValue: defaultHemAllowance(detail), operation: '+' })
	hemAllowanceCalculation.withResult(hemAllowance)

	/* Simone 7 June 2022: The side hem allowance should change to 300 for continuous Roman blinds only (up from 200.) This is because sometimes the fabric house cuts on an angle and we can loose 100mm because of that. */
	/* Simone 28 July 2022: Also,  to calculate meterage for Continuous fabric for Romans please add 150mm to the blind width.  At the moment you are adding 300.  Example Blinds with Continuous fabric that are 2000 wide requires 2150 rounded up the meterage is 2.2M. */
	const sideHemAllowance = blindType === dtp.BlindType.Roman && fabric.orientation === FabricOrientation.Continuous ? 150 : 200
	
	const targetCalculations: GenericFabricTargetCalculations = {
		width: calculateWidth(measures),
		length: calculateFinishedLength(measures),
		hemAllowance: hemAllowanceCalculation,
		sideHemAllowance: new Calculation(sideHemAllowance),
		quantity: new Calculation(1),
	}

	if (fabric.lined) {
		if (!fabric.liningOptions) {
			calculation.withErrorMessage('Lining options are not set')
		} else {
			const liningOptions: GenericFabricOptions = {
				...fabric.liningOptions,
				fullness: BLIND_FULLNESS,
			}

			result.lining = calculation.mergeErrors(calculateGenericFabricQuantity(liningOptions, targetCalculations, 'Lining', 'fabric.liningOptions'))

			if (fabric.liningOptions.interlined) {
				if (!fabric.liningOptions.interliningOptions) {
					calculation.withErrorMessage('Interlining options are not set')
				} else {
					const interliningOptions: GenericFabricOptions = {
						...fabric.liningOptions.interliningOptions,
						fullness: BLIND_FULLNESS,
					}
			
					result.interlining = calculation.mergeErrors(calculateGenericFabricQuantity(interliningOptions, targetCalculations, 'Interlining', 'fabric.liningOptions.interliningOptions'))
				}
			}
		}
	}

	if (fabric.fabric) {
		result.fabric = calculation.mergeErrors(calculateGenericFabricQuantity(
			{
				...fabric, 
				fullness: BLIND_FULLNESS,
			},
			targetCalculations, 
			'Blind',
			'fabric'),
		)
	}

	if (fabric.trim && fabric.trimOptions) {
		const width = targetCalculations.width.getResult()
		const finishedLength = targetCalculations.length.getResult()
		const widthWithFullness = width * (1 + BLIND_FULLNESS / 100)

		let trimTotal = 0
		if (fabric.trimOptions.hem) {
			trimTotal += widthWithFullness + 70 /* To allow trim to be tucked around */
		}
		if (fabric.trimOptions.leadingEdge) {
			trimTotal += (finishedLength + 100) * 2
		}
		if (fabric.trimOptions.outerEdge) {
			trimTotal += (finishedLength + 100) * 2
		}
		if (trimTotal === 0) {
			calculation.withErrorMessage('Trim has not specified hem, leading edge or outer edge.')
		}
		result.trim = trimTotal
	}
	return calculation
}

interface BlindHardwarePricing {
	automation?: Calculation<GenericPricing>
	total?: Calculation<GenericPricing>
}

interface BlindSpecificationsPricing {
	making?: Calculation<GenericPricing>
	chainTensioner?: Calculation<GenericPricing>
	fascia?: Calculation<GenericPricing>
	sillClips?: Calculation<GenericPricing>
	softLiftSpringAssist?: Calculation<GenericPricing>
	steelChain?: Calculation<GenericPricing>
	extras?: Calculation<GenericPricing>
	total?: Calculation<GenericPricing>
}

interface BlindPricings {
	fabric?: Calculation<GenericPricing>
	lining?: Calculation<GenericPricing>
	interlining?: Calculation<GenericPricing>
	trim?: Calculation<GenericPricing>
	totalFabric?: Calculation<GenericPricing>
	specifications?: BlindSpecificationsPricing
	hardware?: BlindHardwarePricing
	total?: Calculation<GenericPricing>
}

export function calculateProductPricing(detail: dtp.Blind, taxDetails: dt.TaxDetails | undefined): BlindPricings {
	const result: BlindPricings = {}

	const fabricQuantitiesCalculation = calculateQuantities(detail)
	const fabricQuantities = fabricQuantitiesCalculation.getResult()

	if (fabricQuantities.fabric && detail.fabric) {
		result.fabric = calculateGenericFabricPricing(
			{
				...detail.fabric, 
				fullness: BLIND_FULLNESS,
			},
			fabricQuantities.fabric,
			taxDetails,
			'Fabric',
			'fabric',
		).withErrors(fabricQuantitiesCalculation)
	} else if (fabricQuantitiesCalculation.isErrored()) {
		result.fabric = new Calculation<GenericPricing>({}).withErrors(fabricQuantitiesCalculation)
	}
	if (fabricQuantities.lining && detail.fabric && detail.fabric.liningOptions) {
		result.lining = calculateGenericFabricPricing(
			{
				...detail.fabric.liningOptions,
				fullness: BLIND_FULLNESS,
			},
			fabricQuantities.lining,
			taxDetails,
			'Lining',
			'fabric.liningOptions',
		)
		
		if (fabricQuantities.interlining && detail.fabric.liningOptions.interlined && detail.fabric.liningOptions.interliningOptions) {
			result.interlining = calculateGenericFabricPricing(
				{
					...detail.fabric.liningOptions.interliningOptions,
					fullness: BLIND_FULLNESS,
				},
				fabricQuantities.interlining,
				taxDetails,
				'Interlining',
				'fabric.liningOptions.interliningOptions',
			)
		}
	}
	if (fabricQuantities.trim && detail.fabric && detail.fabric.trimOptions) {
		result.trim = new Calculation<GenericPricing>({})
		result.trim.withResult({
			pricing: result.trim.mergeErrors(calculatePricing(detail.fabric.trimOptions.price, taxDetails, fabricQuantities.trim / 1000, 'Trim')),
		})
	}

	result.totalFabric = sumGenericPricing(result.fabric, result.lining)
	result.totalFabric = sumGenericPricing(result.totalFabric, result.interlining)
	result.totalFabric = sumGenericPricing(result.totalFabric, result.trim)

	if (detail.specifications) {
		result.specifications = {}
		const pricing = detail.specifications.makingPrice
		if (pricing) {
			result.specifications.making = new Calculation<GenericPricing>({})
			result.specifications.making.withResult({
				pricing: result.specifications.making.mergeErrors(calculatePricing(pricing, taxDetails, 1, 'Making cost')),
			})
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.making)
		}
		if (detail.specifications.chainTensioner) {
			result.specifications.chainTensioner = calculateGenericHardwarePricing(detail.specifications.chainTensionerOptions, taxDetails, 'Chain Tensioner', undefined, 1)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.chainTensioner)
		}
		if (detail.specifications.fascia) {
			result.specifications.fascia = calculateGenericHardwarePricing(detail.specifications.fasciaOptions, taxDetails, 'Fascia or Pelmets', undefined, 1)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.fascia)
		}
		if (detail.specifications.sillClips) {
			result.specifications.sillClips = calculateGenericHardwarePricing(detail.specifications.sillClipsOptions, taxDetails, 'Sill Clips', undefined, 1)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.sillClips)
		}
		if (detail.specifications.softLiftSpringAssist) {
			result.specifications.softLiftSpringAssist = calculateGenericHardwarePricing(detail.specifications.softLiftSpringAssistOptions, taxDetails, 'Soft Lift Spring Assist', undefined, 1)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.softLiftSpringAssist)
		}
		if (detail.specifications.steelChain) {
			result.specifications.steelChain = calculateGenericHardwarePricing(detail.specifications.steelChainOptions, taxDetails, 'Steel Chain', undefined, 1)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.steelChain)
		}

		if (detail.specifications.extras) {
			result.specifications.extras = detail.specifications.extras.reduce(
				(prev, curr) => {
					const extraPricing = new Calculation<GenericPricing>({})
					const p = calculatePricing(curr.pricing, taxDetails, 1, curr.name || 'Extra')
					extraPricing.withResult({
						pricing: extraPricing.mergeErrors(p),
					})
					return sumGenericPricing(prev, extraPricing)!
				},
				new Calculation<GenericPricing>({}),
			)
			result.specifications.total = sumGenericPricing(result.specifications.total, result.specifications.extras)
		}
	}
	
	if (detail.hardware) {
		result.hardware = {}

		if (detail.hardware.automation) {
			result.hardware.automation = calculateGenericHardwarePricing(detail.hardware.automationOptions, taxDetails, 'Automation', undefined, 1)
			result.hardware.total = sumGenericPricing(result.hardware.total, result.hardware.automation)
		}
	}

	result.total = sumGenericPricing(result.totalFabric, result.specifications && result.specifications.total)
	result.total = sumGenericPricing(result.total, result.hardware && result.hardware.total)

	return result
}
