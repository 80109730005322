import * as t from './types'

export function displayName(user: t.User): string | undefined {
	if (user.givenName && user.familyName) {
		return `${user.givenName} ${user.familyName}`.trim()
	} else if (user.givenName) {
		return user.givenName.trim()
	} else if (user.familyName) {
		return user.familyName.trim()
	} else {
		return undefined
	}
}
