/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/CurtainForm'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
// import { saveCurtain } from '../actions'

/* Import module actions */
import * as a from '../../actions'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as s from '../../selectors'
import * as prs from 'modules/product/selectors'
import * as ds from 'modules/database/selectors'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	project: dt.Project
	productId: dt.ProductID
	formValue: dtp.Curtain
	currentForm?: string
}

export interface OwnProps {
	// onModalDismiss: () => void
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onChangeFormValue: (newFormValue: dtp.Curtain | undefined) => void
	onChooseProductForm: (form: string) => void
	onNewProduct: (projectId: dt.ProjectID, productType: dt.ProductType) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	const projectId = prs.projectId(state)!
	const project = ds.projectById(state, projectId)

	return {
		project,
		productId: state.product.currentProduct!.productId,
		formValue: s.currentProductFormValue(state),
		currentForm: state.product.currentForm,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onChangeFormValue: (newFormValue: dtp.Curtain | undefined) => {
		if (newFormValue) {
			dispatch(a.updateCurrentProductDetails(newFormValue))
		}
	},
	onChooseProductForm: (form) => {
		dispatch(a.chooseProductForm(form))
	},
	onNewProduct: (projectId, productType) => {
		dispatch(a.newProduct({
			projectId,
			productType,
			navigationReplace: true,
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
