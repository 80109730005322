import * as excel from 'exceljs'
import * as dt from 'modules/database/types'
import * as dtc from 'modules/database/types/common'
import { fontBold, alignHeaderRow, formatCellForHeader } from 'modules/project/excel'
import { productTitle } from 'modules/database/functions'
import { QuoteSummary } from 'modules/project/quote'

/** Type to create a row header type for a given row object. */
export type HeaderRow<ROW> = {
	[P in keyof ROW]-?: string
}

interface QuoteSheetOptions<PRODUCT extends dtc.CommonProductDetails, ROW> {
	createHeader?: (sheet: excel.Worksheet) => excel.Row
	header?: HeaderRow<ROW>
	outputProduct: (product: PRODUCT, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary) => ROW[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rowToRow: (row: ROW) => any[]
}

interface ValidColumns { 
	[columnIndex: number]: boolean
}

export function exportQuoteSheet<PRODUCT extends dtc.CommonProductDetails, ROW>(sheet: excel.Worksheet, products: DeepReadonly<dt.Product[]>, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary, options: QuoteSheetOptions<PRODUCT, ROW>) {
	const rows = products.flatMap(p => {
		const d = p.details as dtc.CommonProductDetails
		if (!d) {
			return []
		}
		if (!d.overview) {
			return []
		}
		
		try {
			return options.outputProduct(d as PRODUCT, taxDetails, summary)
		} catch (error) {
			throw new Error(`${productTitle(p)}: \n${(error as Error).message}`)
		}
	})

	if (rows.length) {
		const rowArrays = rows.map(row => options.rowToRow(row))

		/* Determine columns that have values */
		const validColumns: ValidColumns = {}
		rowArrays.forEach(rowArray => {
			rowArray.forEach((columnValue, columnIndex) => {
				if (columnValue !== undefined && columnValue !== '') {
					if (!validColumns[columnIndex]) {
						validColumns[columnIndex] = true
					}
				}
			})
		})

		/* Output header */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const headerTitles = chooseColumns(options.rowToRow(options.header as any as ROW), validColumns)
		const header = options.createHeader ? options.createHeader(sheet) : options.header ? createHeader(sheet, headerTitles) : undefined
		if (header) {
			fontBold(header)
			header.border = {
				bottom: {
					style: 'thin',
				},
			}

			summary.headerRows.push(header.number)
		}

		rowArrays.forEach(rowArray => {
			const row = sheet.addRow(chooseColumns(rowArray, validColumns))
			let col = 0
			for (let i = 0; i < rowArray.length; i++) {
				if (validColumns[i]) {
					formatCellForHeader(row.getCell(col + 1), headerTitles[col])
					col++
				}
			}
		})
	}
}

function createHeader(sheet: excel.Worksheet, header: string[]): excel.Row {
	const row = sheet.addRow(header)
	alignHeaderRow(row)
	return row
}

function chooseColumns<T>(rowArray: T[], validColumns: ValidColumns): T[] {
	const result: T[] = []
	rowArray.forEach((columnValue, columnIndex) => {
		if (validColumns[columnIndex]) {
			result.push(columnValue)
		}
	})
	return result
}
