import { createSelector } from 'reselect'
import { RootStoreState } from 'modules/root'
import { TRIAL_DURATION_MILLIS } from './contants'

export const currentUser = (state: RootStoreState) => state.user.user
export const currentEditingUser = (state: RootStoreState) => state.user.editingUser

export const currentUserFormState = createSelector(
	currentUser,
	currentEditingUser,
	(user, editingUser) => {
		if (editingUser) {
			return editingUser
		}

		return user
	},
)

export const currentUserFullName = createSelector(
	currentUser,
	(user) => {
		if (user.givenName && user.familyName) {
			return `${user.givenName} ${user.familyName}`
		} else if (user.givenName) {
			return user.givenName
		} else if (user.familyName) {
			return user.familyName
		} else {
			return 'Anonymous'
		}
	},
)

export const subscriber = (state: RootStoreState) => state.user.userAdministrative ? !!state.user.userAdministrative.whenSubscribedTo : undefined
export const subscriptionRenewalDate = (state: RootStoreState) => state.user.userAdministrative ? state.user.userAdministrative.whenSubscribedTo ? new Date(state.user.userAdministrative.whenSubscribedTo) : new Date() : undefined
export const trialExpiryDate = (state: RootStoreState) => state.user.user.whenCreated ? new Date(Date.parse(state.user.user.whenCreated) + TRIAL_DURATION_MILLIS) : new Date()
