import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as cf from '../functions'
import { toNumber } from 'modules/root/functions'
import { AvailablePurchaseOrderSupplier } from 'modules/project/purchaseorders'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { titleCase } from 'title-case'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'
import { outputCurtainProduct } from './common'

const Header: HeaderRow<Row> = {
	product: 'Product', 
	room: 'Room', 
	window: 'Window', 
	trackType: 'Track Type', 

	headerType: 'Header Type', 
	draw: 'Draw', 
	fullness: 'Fullness (%)', 

	fabricSupplier: 'Fabric Supplier', 
	fabricCollection: 'Fabric Collection', 
	fabric: 'Fabric', 
	fabricColour: 'Fabric Colour', 
	fabricOrientation: 'Fabric Orientation', 
	fabricWidth: 'Fabric Width (mm)', 
	fabricDrops: 'Fabric Drops ()', 
	fabricCutLength: 'Fabric Cut Length (mm)', 

	liningSupplier: 'Lining Supplier', 
	lining: 'Lining', 
	liningColour: 'Lining Colour', 
	liningWidth: 'Lining Width (mm)', 
	liningDrops: 'Lining Drops ()', 
	liningCutLength: 'Lining Cut Length (mm)', 

	interliningSupplier: 'Interlining Supplier', 
	interlining: 'Interlining', 
	interliningWidth: 'Interlining Width (mm)', 
	interliningDrops: 'Interlining Drops ()', 
	interliningCutLength: 'Interlining Cut Length (mm)', 

	trimSupplier: 'Trim Supplier', 
	trim: 'Trim', 

	trackWidth: 'Track Width (mm)', 
	returns: 'Returns', 
	curtainWidth: 'Curtain Width (mm)', 
	finishedLength: 'Drop (mm)', 
	hooking: 'Hooking ()', 

	workroomInstructions: 'Workroom Instructions', 
	installationNotes: 'Installation Notes',

	hardwareSupplier: 'Hardware Supplier', 
	hardwareCode: 'Hardware',
}

interface Row {
	product?: string
	room?: string
	window?: string
	trackType?: string
	headerType?: string
	draw?: string
	fullness?: number
	fabricSupplier?: string
	fabricCollection?: string
	fabric?: string
	fabricColour?: string
	fabricOrientation?: string
	fabricWidth?: number
	fabricDrops?: number
	fabricCutLength?: number
	liningSupplier?: string
	lining?: string
	liningColour?: string
	liningWidth?: number
	liningDrops?: number
	liningCutLength?: number
	interliningSupplier?: string
	interlining?: string
	interliningWidth?: number
	interliningDrops?: number
	interliningCutLength?: number
	trimSupplier?: string
	trim?: string
	trackWidth?: number
	returns?: string
	curtainWidth?: number
	finishedLength?: number
	hooking?: number
	workroomInstructions?: string
	installationNotes?: string
	hardwareSupplier?: string
	hardwareCode?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.product,
		row.room,
		row.window,
		row.trackType,
		row.headerType,
		row.draw,
		row.fullness,
		row.fabricSupplier,
		row.fabricCollection,
		row.fabric,
		row.fabricColour,
		row.fabricOrientation,
		row.fabricWidth,
		row.fabricDrops,
		row.fabricCutLength,
		row.liningSupplier,
		row.lining,
		row.liningColour,
		row.liningWidth,
		row.liningDrops,
		row.liningCutLength,
		row.interliningSupplier,
		row.interlining,
		row.interliningWidth,
		row.interliningDrops,
		row.interliningCutLength,
		row.trimSupplier,
		row.trim,
		row.trackWidth,
		row.returns,
		row.curtainWidth,
		row.finishedLength,
		row.hooking,
		row.workroomInstructions,
		row.installationNotes,
		row.hardwareSupplier,
		row.hardwareCode,
	]
}

export default function exportCurtainsWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header: Header,
		outputProduct: (_supplier, _product, _summary) => {
			return outputCurtainProduct(_supplier, _product, _summary, outputCurtain)
		},
		rowToRow,
	})
}

function outputCurtain(supplier: AvailablePurchaseOrderSupplier, o: dtp.CurtainOverview, curtainSet: string, c: dtp.CurtainDetail | undefined, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c || !c.specifications || c.specifications.workroomSupplier !== supplier.supplier) {
		return []
	}

	const data: Row = {}

	// 'Product', 'Room', 'Window', 'Track Type', 
	data.product = 'Curtains'
	data.room = o.room
	data.window = o.window
	data.trackType = curtainSet
	
	// 'Header Type', 'Draw', 'Fullness (%)',
	data.headerType = c.specifications ? c.specifications.headerType : undefined
	data.draw = c.specifications ? c.specifications.draw : undefined
	data.fullness = toNumber(c.fabric && c.fabric.fullness)

	const quantities = cf.calculateCurtainQuantities(c).getResult()

	// 'Fabric Supplier', 'Fabric', 'Fabric Orientation', 'Fabric Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
	if (c.fabric && c.fabric.fabric) {
		data.fabricSupplier = c.fabric.supplier
		data.fabric = c.fabric.name
		data.fabricColour = c.fabric.colour
		data.fabricOrientation = c.fabric.orientation ? titleCase(c.fabric.orientation) : undefined
		data.fabricWidth = toNumber(c.fabric && c.fabric.width)
		data.fabricDrops = quantities.fabric ? quantities.fabric.drops : undefined
		data.fabricCutLength = quantities.fabric ? quantities.fabric.cutLength : undefined
	}

	// 'Lining Supplier', 'Lining', 'Lining Width (mm)', 'Lining Drops ()', 'Lining Cut Length (mm)', 
	if (c.fabric && c.fabric.lined && c.fabric.liningOptions) {
		data.liningSupplier = c.fabric.liningOptions.supplier
		data.lining = c.fabric.liningOptions.type
		data.liningColour = c.fabric.liningOptions.colour
		data.liningWidth = toNumber(c.fabric.liningOptions.width)
		data.liningDrops = quantities.lining ? quantities.lining.drops : undefined
		data.liningCutLength = quantities.lining ? quantities.lining.cutLength : undefined
	}

	// 'Interlining Supplier', 'Interlining', 'Interlining Width (mm)', 'Interlining Drops ()', 'Interlining Cut Length (mm)', 
	if (c.fabric && c.fabric.lined && c.fabric.liningOptions && c.fabric.liningOptions.interlined && c.fabric.liningOptions.interliningOptions) {
		data.interliningSupplier = c.fabric.liningOptions.interliningOptions.supplier
		data.interlining = c.fabric.liningOptions.interliningOptions.type ? titleCase(c.fabric.liningOptions.interliningOptions.type) : undefined
		data.interliningWidth = c.fabric.liningOptions.interliningOptions.width
		data.interliningDrops = quantities.interlining ? quantities.interlining.drops : undefined
		data.interliningCutLength = quantities.interlining ? quantities.interlining.cutLength : undefined
	}

	// 'Trim Supplier', 'Trim', 
	if (c.fabric && c.fabric.trim && c.fabric.trimOptions) {
		data.trimSupplier = c.fabric.trimOptions.supplier
		data.trim = c.fabric.trimOptions.name
	}

	// 'Track Width (mm)', 'Returns', 'Curtain Width (mm)', 'Drop (mm)', 'Hooking ()', 
	const trackWidth = c.measures && cf.calculateTrackWidth(c.measures)
	const curtainWidth = c.measures && cf.calculateCurtainWidth(c.measures)
	const finishedLength = c.measures && cf.calculateFinishedLength(c.measures)
	data.trackWidth = trackWidth && trackWidth.getResultOrUndefined()
	if (c.measures) {
		const returnsLeft = c.measures.returnSizeLeft
		const returnsRight = cf.measuresReturnSizeRight(c.measures)
		if (returnsLeft && returnsRight) {
			data.returns = `${returnsLeft}L + ${returnsRight}R`
		} else if (returnsLeft) {
			data.returns = `${returnsLeft}L`
		} else if (returnsRight) {
			data.returns = `${returnsRight}R`
		}
	}
	data.curtainWidth = curtainWidth && curtainWidth.getResultOrUndefined()
	data.finishedLength = finishedLength && finishedLength.getResultOrUndefined()
	data.hooking = c.specifications ? toNumber(c.specifications.hooking) : undefined

	// 'Workroom Instructions', 'Installation Notes',
	data.workroomInstructions = c.specifications.notes
	data.installationNotes = c.installation && c.installation.notes && c.installation.notes.length ? c.installation.notes : undefined

	// 'Hardware Supplier', 'Hardware Code',
	if (c.hardware && c.hardware.rod && c.hardware.rodOptions) {
		data.hardwareSupplier = c.hardware.rodOptions.supplier
		data.hardwareCode = c.hardware.rodOptions.code
	}

	return [data]
}
