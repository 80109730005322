/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'

import * as dt from 'modules/database/types'
import * as df from 'modules/database/functions'
import * as ff from 'modules/forms'
import * as pf from 'modules/project/functions'

interface OwnProps {
	productId: dt.ProductID
	productType: dt.ProductType
	project: dt.Project
	copyButtonLabel?: string
	onCopy: (item: CopyFromProductGroupItem) => void
	onCancel: () => void

	productToItems?: (p: dt.Product) => CopyFromProductGroupItem[]
	specialGroup?: CopyFromProductGroup
}

/**
 * Interface for private internal component state.
 */
interface State {
	selectedValue?: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	// myValue: 'Example',
}

interface CopyFromProductGroup {
	name?: string
	items: CopyFromProductGroupItem[]
}

export interface CopyFromProductGroupItem {
	name: string
	type: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any
}

export default class CopyFromOtherProduct extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	public render() {
		const { copyButtonLabel } = this.props
		
		const groups = this.groups()
		const selectedValue = this.selectedValue()
		return (
			<>
				<div className="copy-form">
					<ff.Heading>Copy from</ff.Heading>
					<div className="form-input -select">
						<select className="select" onChange={this.onSelectChange} value={selectedValue}>
							{groups.map((group, groupIndex) => {
								if (group.items.length === 0) {
									return null
								}
								return (
									<React.Fragment key={groupIndex}>
										<option />
										{group.name && (
											<option value="" disabled={true} key={`group-${groupIndex}`}>{group.name}</option>
										)}
										{group.items.map((item, itemIndex) => (
											<option key={`group-${groupIndex}-${itemIndex}`} value={`${groupIndex}|${itemIndex}`}>{item.name}</option>
										))}
									</React.Fragment>
								)
							})}
						</select>
					</div>
					<div className="button-group">
						<button type="button" onClick={this.onCopy} disabled={!selectedValue} className="button-link -secondary -small">{copyButtonLabel || 'Copy'}</button>
						<button type="button" onClick={this.onCancel} className="button-link -small -text">Cancel</button>
					</div>
				</div>
			</>
		)
	}

	private groups = (): CopyFromProductGroup[] => {
		const products = pf.allProducts(this.props.project)
		const groups: CopyFromProductGroup[] = this.groupByType(products, this.props.productToItems || this.defaultProductToItems)
		if (this.props.specialGroup) {
			groups.unshift(this.props.specialGroup)
		}
		return groups
	}

	private groupByType = (products: dt.Product[], productToItems: (p: dt.Product) => CopyFromProductGroupItem[]): CopyFromProductGroup[] => {
		const productsByType = pf.productsByType(products)
		return productsByType.types.map(productType => {
			const productsOfType = productsByType.productsByType[productType].products

			const group: CopyFromProductGroup = {
				name: df.productTypeInfo(productType).collectiveName,
				items: [],
			}

			productsOfType.map(p => productToItems(p)).forEach(is => {
				is.forEach(i => group.items.push(i))
			})

			return group
		})
	}

	private defaultProductToItems = (p: dt.Product): CopyFromProductGroupItem[] => {
		const { productId } = this.props
		const expectedProductType = this.props.productType

		if (p.type !== expectedProductType || p.productId === productId) {
			return []
		}

		const result: CopyFromProductGroupItem[] = []
		result.push({
			name: df.productTitle(p),
			type: 'product',
			value: p,
		})
		return result
	}

	private selectedValue = (): string => {
		if (this.state.selectedValue) {
			return this.state.selectedValue
		}

		return ''
	}

	private onSelectChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedIndex = evt.target.selectedIndex
		const selectedValue = evt.target.options[selectedIndex].value
		this.setState({ selectedValue })
	}

	private onCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()

		const selectedValue = this.selectedValue()
		if (selectedValue !== undefined) {
			const groupIndex = parseInt(selectedValue.split('|')[0], 10)
			const itemIndex = parseInt(selectedValue.split('|')[1], 10)

			const item = this.groups()[groupIndex].items[itemIndex]
			this.props.onCopy(item)
		}
	}

	private onCancel = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.props.onCancel) {
			this.props.onCancel()
		}
	}
}
