import { reducerWithInitialState } from 'typescript-fsa-reducers'

/* Import our module's actions */
import * as a from './actions'
import { UploadStatuses } from './types'
import produce from 'immer'
import { readyAction } from 'modules/root/actions'
import * as _ from 'lodash'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	uploads: UploadStatuses
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	/* Note that we end each property with a comma, so we can add new properties without modifying this line
	(improve your git diffs!).
	 */
	uploads: {},
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.queueUploadAction, (state, payload): StoreState => produce(state, draft => {
	draft.uploads[payload.path] = {
		uploading: false,
		size: payload.size,
		metadata: payload.metadata,
		progress: 0,
		retriable: payload.retriable,
	}
}))

reducer.case(a.dequeueUploadAction, (state, payload): StoreState => produce(state, draft => {
	delete draft.uploads[payload]
}))

reducer.case(a.startUploadAction, (state, path): StoreState => produce(state, draft => {
	draft.uploads[path].uploading = true
}))

reducer.case(a.uploadProgressAction, (state, payload): StoreState => produce(state, draft => {
	draft.uploads[payload.path].progress = payload.progress
}))

reducer.case(a.stopUploadAction, (state, path): StoreState => produce(state, draft => {
	draft.uploads[path].uploading = false
}))

reducer.case(readyAction, (state): StoreState => produce(state, draft => {
	if (!draft.uploads) {
		draft.uploads = {}
	}

	/* Reset the upload status of all uploads */
	_.forOwn(draft.uploads, (value, key) => {
		value.uploading = false
		value.progress = 0
	})
}))
