import * as React from 'react'
import * as ff from 'modules/forms'
import { SubFormProps } from './CurtainForm'
import * as cf from '../functions'
import { formatNumber, calculateMarkup } from 'modules/root/functions'
import Calculation from 'modules/root/calculations'
import { GenericPricing } from 'modules/product/common/pricing'
import { wrapComponent, forComponentProps } from 'changeling'

class CurtainFormCalculations extends React.Component<SubFormProps> {

	private controller = forComponentProps(this)

	public render() {
		const { project } = this.props

		const details = this.controller.snapshot().value || {}
		const measures = details.measures
		
		const taxDetails = project.taxDetails
		const quantitiesCalculation = cf.calculateCurtainQuantities(details)
		const quantities = quantitiesCalculation.getResult()
		const pricing = cf.calculateCurtainPricing(details, taxDetails)
		const trackWidthCalculation = measures && cf.calculateTrackWidth(measures)
		const curtainWidthCalculation = measures && cf.calculateCurtainWidth(measures)
		const curtainFinishedLengthCalculation = measures && cf.calculateFinishedLength(measures)

		return (
			<>
				<ff.Heading type="section">{this.props.curtainName} calculations</ff.Heading>

				<ff.Heading>Measurements</ff.Heading>
				<div className="calculations-table">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{this.outputMeasure('Track width', trackWidthCalculation && trackWidthCalculation.getResult(), 'mm', trackWidthCalculation)}
							{this.outputMeasure('Curtain width', curtainWidthCalculation && curtainWidthCalculation.getResult(), 'mm', curtainWidthCalculation)}
							{this.outputMeasure('Drop', curtainFinishedLengthCalculation && curtainFinishedLengthCalculation.getResult(), 'mm', curtainFinishedLengthCalculation)}
							{this.outputMeasure('Fabric width', details.fabric && details.fabric.width ? details.fabric.width : undefined, 'mm', undefined)}
							{this.outputMeasure('Fullness', details.fabric && details.fabric.fullness ? details.fabric.fullness : undefined, '%', undefined)}
							{this.outputMeasure('Pattern repeat horizontal', details.fabric && details.fabric.patternRepeat && details.fabric.patternRepeatOptions ? details.fabric.patternRepeatOptions.horizontalRepeat : undefined, 'mm', undefined)}
							{this.outputMeasure('Pattern repeat vertical', details.fabric && details.fabric.patternRepeat && details.fabric.patternRepeatOptions ? details.fabric.patternRepeatOptions.verticalRepeat : undefined, 'mm', undefined)}
						</tbody>
					</table>
				</div>

				<ff.Heading>Calculations</ff.Heading>
				<div className="calculations-table">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{this.outputMeasure('Fabric drops', quantities.fabric && quantities.fabric.drops, undefined, quantitiesCalculation)}
							{this.outputMeasure('Fabric cut length', quantities.fabric && quantities.fabric.cutLength, 'mm', quantitiesCalculation)}
							{this.outputMeasure('Lining drops', quantities.lining && quantities.lining.drops, undefined, quantitiesCalculation)}
							{this.outputMeasure('Lining cut length', quantities.lining && quantities.lining.cutLength ? formatNumber(quantities.lining.cutLength, 0) : undefined, 'mm', quantitiesCalculation)}
							{this.outputMeasure('Interlining drops', quantities.interlining && quantities.interlining.drops, undefined, quantitiesCalculation)}
							{this.outputMeasure('Interlining cut length', quantities.interlining && quantities.interlining.cutLength ? formatNumber(quantities.interlining.cutLength, 0) : undefined, 'mm', quantitiesCalculation)}
							{this.outputMeasure('Trim', quantities.trim ? formatNumber(quantities.trim, 0) : undefined, 'mm', quantitiesCalculation)}

							{this.outputMeasure('Total fabric', quantities.fabric && quantities.fabric.quantity ? formatNumber(quantities.fabric.quantity) : undefined, 'mm', quantitiesCalculation, 'total')}
							{this.outputMeasure('Total lining', quantities.lining && quantities.lining.quantity ? formatNumber(quantities.lining.quantity) : undefined, 'mm', quantitiesCalculation, 'total')}
							{this.outputMeasure('Total interlining', quantities.interlining && quantities.interlining.quantity ? formatNumber(quantities.interlining.quantity) : undefined, 'mm', quantitiesCalculation, 'total')}
							{this.outputMeasure('Total trim', quantities.trim ? formatNumber(quantities.trim, 0) : undefined, 'mm', quantitiesCalculation, 'total')}
						</tbody>
					</table>
				</div>

				<p>Note: Where a fabric has a vertical pattern repeat and the drop quantity is an odd number we have added one extra repeat to the meterage calculation. If you are unfamiliar with calculating fabric we recommend an expert checks this prior to ordering.</p>
				
				<ff.Heading>Pricing</ff.Heading>
				<div className="calculations-table">
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Cost Price{taxDetails ? ` excl ${taxDetails.taxName}` : ''}</th>
								<th>Sell Price{taxDetails ? ` incl ${taxDetails.taxName}` : ''}</th>
								<th>Markup</th>
							</tr>
						</thead>
						<tbody>
							{this.outputCost('Fabric', pricing.fabric)}
							{this.outputCost('Lining', pricing.lining)}
							{this.outputCost('Interlining', pricing.interlining)}
							{this.outputCost('Trim', pricing.trim)}
							{this.outputCost('Subtotal fabric', pricing.totalFabric, 'subtotal')}

							{pricing.specifications && (
								<>
									{this.outputCost('Making', pricing.specifications.making)}
									{this.outputCost('Extras', pricing.specifications.extras)}
									{this.outputCost('Subtotal Specifications', pricing.specifications.total, 'subtotal')}
								</>
							)}

							{pricing.hardware && (
								<>
									{this.outputCost(cf.rodOrTrack(details), pricing.hardware.rod)}
									{this.outputCost('Finials', pricing.hardware.finials)}
									{this.outputCost('Holdbacks', pricing.hardware.holdbacks)}
									{this.outputCost('Bends', pricing.hardware.bends)}
									{this.outputCost('Flick sticks', pricing.hardware.flickSticks)}
									{this.outputCost('Automation', pricing.hardware.automation)}
									{this.outputCost('Subtotal hardware', pricing.hardware.total, 'subtotal')}
								</>
							)}

							{this.outputCost('TOTAL', pricing.total, 'total')}
						</tbody>
					</table>
				</div>
			</>
		)
	}

	private outputMeasure = (title: string, value: string | number | undefined, units: string | undefined, calculation: Calculation<unknown> | undefined, className?: 'total' | 'subtotal') => {
		if (calculation && calculation.isErrored()) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td>{calculation.formatErrors()}</td>
				</tr>
			)
		} else if (value !== undefined) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td><ff.DisplayValue value={value} units={units} /></td>
				</tr>
			)
		} else {
			return null
		}
	}

	private outputCost = (title: string, calculation: Calculation<GenericPricing> | undefined, className?: 'total' | 'subtotal') => {
		if (!calculation) {
			return null
		}

		if (calculation.isErrored()) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td colSpan={3}>{calculation.formatErrors()}</td>
				</tr>
			)
		}

		const value = calculation.getResult()
		if (!value || !value.pricing) {
			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td>—</td>
					<td colSpan={2} />
				</tr>
			)
		} else {
			const taxDetails = this.props.project.taxDetails
			const markup = calculateMarkup(value.pricing.cost, value.pricing.rrp, taxDetails)

			return (
				<tr className={className}>
					<th scope="row">{title}</th>
					<td>${formatNumber(value.pricing.cost, 2)}</td>
					<td>${formatNumber(value.pricing.rrp, 2)}</td>
					<td>{formatNumber(markup, { dp: 0, truncateDp: true })}%</td>
				</tr>
			)
		}
	}
}

export default wrapComponent(CurtainFormCalculations)
