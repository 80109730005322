/*
Utilities for singleton operations
*/
interface SingletonOperationPromises {
	[path: string]: {
		resolve: (value: unknown) => void
		reject: (error: unknown) => void
	}[]
}

const promises: SingletonOperationPromises = {}

export function startOperation(id: string) {
	promises[id] = []
}

export function resolveOperation(id: string, result?: unknown) {
	const operationPromises = promises[id]
	if (operationPromises) {
		delete promises[id]
		for (const promise of operationPromises) {
			promise.resolve(result)
		}
	}
}

export async function promiseForOperation(id: string) {
	if (promises[id]) {
		return new Promise(function(resolve, reject) {
			promises[id].push({
				resolve,
				reject,
			})
		})
	} else {
		return Promise.resolve()
	}
}

export function rejectOperation(id: string, error: unknown) {
	const operationPromises = promises[id]
	if (operationPromises) {
		delete promises[id]
		for (const promise of operationPromises) {
			promise.reject(error)
		}
	}
}
