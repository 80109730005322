/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/StorageMessageBar'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
// import * as a from '../actions'
import { UploadStatuses } from '../types'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	uploads: UploadStatuses
	online: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		uploads: state.storage.uploads,
		online: state.database.online,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(a.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
