import firebase from 'firebase/compat/app'

export let db: firebase.firestore.Firestore
export let storage: firebase.storage.Storage

export function setDatabase(newDatabase: firebase.firestore.Firestore) {
	db = newDatabase
}

export function setStorage(newStorage: firebase.storage.Storage) {
	storage = newStorage
}
