/* Import the component from the component path */
import Component from '../components/EditProjectForm'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as dt from 'modules/database/types'
import * as a from '../actions'
import * as ut from 'modules/user/types'
import { currentProjectFormState } from '../selectors'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	project?: dt.Project
	newProject: boolean
	user: ut.User
}

export interface OwnProps {
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSaveProject: () => void
	onProjectChange: (newProject: dt.Project) => void
	onCancel: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const project = currentProjectFormState(state)

	return {
		project: project,
		newProject: project ? project.whenCreated === project.whenUpdated : true,
		user: state.user.user,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSaveProject: () => {
		dispatch(a.saveProject())
	},
	onProjectChange: (project) => {
		dispatch(a.updateEditingProject(project))
	},
	onCancel: () => {
		dispatch(a.closeProjectForm())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)