import actionCreatorFactory from 'typescript-fsa'

import * as dt from 'modules/database/types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Product')

export interface EditProductPayload {
	projectId: dt.ProjectID
	product: dt.Product
}
export const editProduct = actionCreator<EditProductPayload>('EDIT_PRODUCT')

export interface NewProductPayload {
	projectId: dt.ProjectID
	productType: dt.ProductType
	navigationReplace?: boolean
}
export const newProduct = actionCreator<NewProductPayload>('NEW_PRODUCT')

export const updateCurrentProductDetails = actionCreator<dt.ProductDetails>('UPDATE_CURRENT_PRODUCT')

export const chooseProductForm = actionCreator<string>('CHOOSE_PRODUCT_FORM')
