import { takeEvery, put, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { navigatorPush, navigatorReplace } from 'onsenui-react-redux-navigator'

import * as a from './actions'
import * as s from './selectors'
import * as da from 'modules/database/actions'

function* handleChooseProduct(): SagaIterator {
	yield put(navigatorPush({
		navigator: 'maqasa',
		route: {
			component: 'ProductForm',
		},
	}))
}

function* handleNewProduct(action: ReturnType<typeof a.newProduct>): SagaIterator {
	if (action.payload.navigationReplace) {
		yield put(navigatorReplace({
			navigator: 'maqasa',
			route: {
				component: 'ProductForm',
			},
		}))
	} else {
		yield put(navigatorPush({
			navigator: 'maqasa',
			route: {
				component: 'ProductForm',
			},
		}))
	}
}

function* handleUpdateProduct(): SagaIterator {
	const product = (yield select(s.updateProductPayload)) as ReturnType<typeof s.updateProductPayload>
	if (product) {
		yield put(da.updateProduct(product))
	}
}

/** The saga function for this module that is run from our root saga. */
export default function* productSagas(): SagaIterator {
	yield takeEvery(a.editProduct, handleChooseProduct)
	yield takeEvery(a.newProduct, handleNewProduct)
	yield takeEvery(a.updateCurrentProductDetails, handleUpdateProduct)
}
