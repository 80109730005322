/* Import the component from the component path */
import Component from '../components/UserDetails'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as a from '../actions'
import { currentUserFormState } from '../selectors'
import { User } from 'modules/user/types'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	formValue: User
}

export interface OwnProps {
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSave: () => void
	onChangeUser: (newUser: User) => void
	onCancel: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		formValue: currentUserFormState(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSave: () => {
		dispatch(a.saveUser())
	},
	onChangeUser: (user) => {
		dispatch(a.updateEditingUser(user))
	},
	onCancel: () => {
		dispatch(a.closeUserDetails())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
