import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as cf from './functions'
import { QuoteSummary } from 'modules/project/quote'
import { exportQuoteSheet, HeaderRow } from '../common/quotes'

interface Row {
	room?: string
	window?: string
	header?: string
	fabric?: string
	lining?: string
	hardwareCode?: string
	hardwareColour?: string
	finials?: string
	holdbacks?: string
	sellPrice?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.room,
		row.window,
		row.header,
		row.fabric,
		row.lining,
		row.hardwareCode,
		row.hardwareColour,
		row.finials,
		row.holdbacks,
		row.sellPrice,
	]
}

export default function exportQuote(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, summary: QuoteSummary) {
	const header: HeaderRow<Row> = {
		room: 'Room',
		window: 'Window',
		header: 'Header',
		fabric: 'Fabric',
		lining: 'Lining',
		hardwareCode: 'Hardware',
		hardwareColour: 'Colour',
		finials: 'Finials',
		holdbacks: 'Holdbacks',
		sellPrice: 'Price ($)',
	}

	exportQuoteSheet(sheet, products, project.taxDetails, summary, {
		header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(p: dtp.Curtain, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary): Row[] {
	if (!p.overview) {
		return []
	}

	const result: Row[] = []
	const curtainSet = cf.curtainSet(p)
	if (p.curtain1) {
		result.push(outputCurtain(p.overview, (curtainSet === dtp.CurtainSet.Double ? ' C1' : ''), p.curtain1, taxDetails, summary))
	}
	if (curtainSet === dtp.CurtainSet.Double && p.curtain2) {
		result.push(outputCurtain(p.overview, ' C2', p.curtain2, taxDetails, summary))
	}
	return result
}

function outputCurtain(o: dtp.CurtainOverview, curtainSet: string, c: dtp.CurtainDetail, taxDetails: dt.TaxDetails | undefined, summary: QuoteSummary): Row {
	const pricing = cf.calculateCurtainPricing(c, taxDetails)
	if (pricing.total && pricing.total.isErrored()) {
		throw new Error(pricing.total.formatErrorsAsText())
	}

	const totalPricing = pricing.total && pricing.total.getResult()

	const row: Row = {}
	row.room = o && o.room
	row.window = o && o.window ? `${o.window} ${curtainSet}` : curtainSet ? curtainSet : undefined
	row.header = c && c.specifications && c.specifications.headerType
	row.fabric = c.fabric && c.fabric.name
	row.lining = c.fabric && c.fabric.lined && c.fabric.liningOptions ? c.fabric.liningOptions.type : undefined
	row.hardwareCode = c.hardware && c.hardware.rod && c.hardware.rodOptions ? c.hardware.rodOptions.code : undefined
	row.hardwareColour = c.hardware && c.hardware.rod && c.hardware.rodOptions ? c.hardware.rodOptions.color : undefined
	row.finials = c.hardware && c.hardware.rod && c.hardware.finialsApplied && c.hardware.finialsOptions ? c.hardware.finialsOptions.type : undefined
	row.holdbacks = c.hardware && c.hardware.rod && c.hardware.holdbacksApplied && c.hardware.holdbackOptions ? c.hardware.holdbackOptions.name : undefined
	row.sellPrice = totalPricing && totalPricing.pricing ? totalPricing.pricing.rrp : undefined

	if (totalPricing && totalPricing.pricing) {
		summary.totalPricing.cost += totalPricing.pricing.cost
		summary.totalPricing.rrp += totalPricing.pricing.rrp
	}

	return row
}
