import localForage from 'localforage'

export default function createLocalForageStorage(dbName: string) {
	const db = localForage.createInstance({
		name: dbName,
	})
	return {
		db,
		getItem: db.getItem,
		setItem: db.setItem,
		removeItem: db.removeItem,
		getAllKeys: db.keys,
	}
}
