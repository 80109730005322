import actionCreatorFactory from 'typescript-fsa'
import * as dt from 'modules/database/types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Forms')

interface AnnotateImagePayload {
	value: dt.AnnotatedImage
	path: string[]
}

export const annotateImage = actionCreator<AnnotateImagePayload>('ANNOTATE_IMAGE')
export type AnnotateImageAction = ReturnType<typeof annotateImage>

interface AnnotatedImagePayload {
	path: string
	dataPath: string[]
	annotatedImage: Blob
	annotations: string
}

export const annotatedImage = actionCreator<AnnotatedImagePayload>('ANNOTATED_IMAGE')
export type AnnotatedImageAction = ReturnType<typeof annotatedImage>
