import * as dt from './types'
import * as common from './types/common'
import * as blinds from './types/blinds'
import * as cushions from './types/cushions'
import { blindTypeName } from 'modules/product/blinds/functions'
import * as _ from 'lodash'
import { cushionTypeName } from 'modules/product/cushions/functions'

export function productTypeInfo(productType: dt.ProductType): dt.ProductTypeInfo {
	switch (productType) {
		case dt.ProductType.Curtain:
			return {
				name: 'Curtain',
				collectiveName: 'Curtains',
			}
		case dt.ProductType.Cushion:
			return {
				name: 'Cushion or Squab',
				collectiveName: 'Cushions & Squabs',
			}
		case dt.ProductType.Blind:
			return {
				name: 'Blind',
				collectiveName: 'Blinds',
			}
		default:
			throw new Error(`productTypeInfo: Unsupported product type: ${productType}`)
	}
}

export function projectTitle(project: dt.Project): string {
	let title: string
	if (project.name) {
		title = project.name
	} else if (project.address) {
		title = project.address
	} else {
		title = 'Untitled project'
	}
	if (project.whenArchived) {
		title = `${title} (Archived)`
	}
	return title
}

export function productTitle(product: dt.Product): string {
	if (product.details) {
		switch (product.type) {
			case dt.ProductType.Curtain: {
				return commonProductTitle(product)
			}
			case dt.ProductType.Blind: {
				if (product.details) {
					const blindDetails: blinds.Blind = product.details
					if (blindDetails.overview && blindDetails.overview.blindType) {
						return commonProductTitle(product, blindTypeName(blindDetails.overview.blindType, true))
					} else {
						return commonProductTitle(product)
					}
				} else {
					return commonProductTitle(product)
				}
			}
			case dt.ProductType.Cushion: {
				if (product.details) {
					const cushionDetails: cushions.Cushion = product.details
					if (cushionDetails.overview && cushionDetails.overview.cushionType) {
						return commonProductTitle(product, cushionTypeName(cushionDetails.overview.cushionType, true))
					}
				}
				return commonProductTitle(product)
			}
			default:
				throw new Error(`productTitle: Unsupported product type: ${product.type}`)
		}
	} else {
		const typeInfo = productTypeInfo(product.type)
		return `New ${typeInfo.name}`
	}
}

function commonProductTitle(product: common.CommonProduct, customProductTypeName?: string): string {
	if (product.details) {
		const components = []
		if (product.details.overview && product.details.overview.room) {
			components.push(product.details.overview.room)
		}
		if (product.details.overview && product.details.overview.window) {
			components.push(product.details.overview.window)
		}
		if (customProductTypeName) {
			components.push(customProductTypeName)
		} else {
			const typeInfo = productTypeInfo(product.type)
			components.push(typeInfo.name)
		}
		if (product.deleted) {
			components.push('(Deleted)')
		}
		return components.join(' ')
	} else {
		const typeInfo = productTypeInfo(product.type)
		return `New ${typeInfo.name}`
	}
}

/**
 * Return a clone with all keys with undefined values missing.
 * This seems to be necessary as Firebase hates undefineds:
 * 
 * FirebaseError: Function DocumentReference.set() called with invalid data. Unsupported field value: undefined (found in field ...)
 * 
 * See https://stackoverflow.com/questions/52955635/lodash-clonedeepwith-to-omit-undefined
 */
export function cloneDeepWithoutUndefined<T>(obj: T): T {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return _.transform(obj as _.Dictionary<any>, (r: any, v, k) => {
		if (_.isUndefined(v)) {
			return
		}
		r[k] = _.isObject(v) ? cloneDeepWithoutUndefined(v) : v
	})
}
