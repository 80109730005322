/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/ProjectDetails'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as dt from 'modules/database/types'
import * as da from 'modules/database/actions'
import * as a from '../actions'
import * as ds from 'modules/database/selectors'
import * as t from '../types'
import * as f from '../functions'
import * as pra from 'modules/product/actions'
import * as ut from 'modules/user/types'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	project: DeepReadonly<dt.Project>
	productsByType: t.ProductsByType
	deletedProducts: DeepReadonly<dt.Product>[]
	deletedProductsByType: t.ProductsByType
	user: DeepReadonly<ut.User>
}

export interface OwnProps {
	projectId: dt.ProjectID
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onOpenEditForm: () => void
	onNewProduct: (type: dt.ProductType) => void
	onEditProduct: (product: dt.Product) => void
	onDeleteProduct: (productId: dt.ProductID) => void
	onUndeleteProduct: (productId: dt.ProductID) => void
	onChangeProject: (newProject: dt.Project) => void
	onDuplicateProject: (project: dt.Project) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const project = ds.projectById(state, ownProps.projectId)

	const deletedProducts = f.deletedProducts(project)
	
	return {
		project,
		productsByType: f.productsByType(f.allProducts(project)),
		deletedProducts,
		deletedProductsByType: f.productsByType(deletedProducts),
		user: state.user.user,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onOpenEditForm: () => {
		dispatch(a.editProject(ownProps.projectId))
	},
	onNewProduct: (productType) => {
		dispatch(pra.newProduct({ projectId: ownProps.projectId, productType }))
	},
	onEditProduct: (product) => {
		dispatch(pra.editProduct({ projectId: ownProps.projectId, product }))
	},
	onDeleteProduct: (productId) => {
		dispatch(da.deleteProduct({ projectId: ownProps.projectId, productId }))
	},
	onUndeleteProduct: (productId) => {
		dispatch(da.undeleteProduct({ projectId: ownProps.projectId, productId }))
	},
	onChangeProject: (newProject) => {
		dispatch(da.updateProject(newProject))
	},
	onDuplicateProject: (project) => {
		dispatch(a.duplicateProject(project))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
