/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow from './FormRow'
import * as dt from 'modules/database/types'
import { formatNumber, calculateMarkup, toNumber, calculateRRP } from 'modules/root/functions'
import { forComponentProps, Snapshot, wrapComponent } from 'changeling'

interface OwnProps extends Snapshot<dt.Pricing | undefined> {
	title: string
	units?: string
	project: dt.Project
	readOnly?: boolean
}

class Price extends React.Component<OwnProps> {

	private static COUNTER = 0

	private controller = forComponentProps(this)
	private counter: number

	public constructor(props: OwnProps) {
		super(props)

		this.counter = ++Price.COUNTER
	}

	public render() {
		const { title, units, project, readOnly } = this.props

		const value = this.controller.snapshot().value
		const effectiveMode = value !== undefined && value.mode !== undefined ? value.mode : dt.PricingMode.Cost

		const taxDetails = project.taxDetails
		const actualTitle = title + (units ? ` per ${units}` : '')
		return (
			<FormRow title={actualTitle} type="options">
				<ul className="option-inputs -inline">
					<li className="option -radio">
						<label className="label">
							<input type="radio" name={`price-${this.counter}-mode`} className="radio" value={dt.PricingMode.Cost} onChange={this.onChangeMode} readOnly={readOnly} checked={effectiveMode === dt.PricingMode.Cost} /><span className="substitute"/>
							Cost + Markup
						</label>
					</li>
					<li className="option -radio">
						<label className="label">
							<input type="radio" name={`price-${this.counter}-mode`} className="radio" value={dt.PricingMode.RRP} onChange={this.onChangeMode} readOnly={readOnly} checked={effectiveMode === dt.PricingMode.RRP} /><span className="substitute"/>
							Sell Price
						</label>
					</li>
					<li className="option -radio">
						<label className="label">
							<input type="radio" name={`price-${this.counter}-mode`} className="radio" value={dt.PricingMode.NA} onChange={this.onChangeMode} readOnly={readOnly} checked={effectiveMode === dt.PricingMode.NA} /><span className="substitute"/>
							N/A
						</label>
					</li>
				</ul>
				{effectiveMode === dt.PricingMode.Cost && (
					<div className="form-field -short -options">
						<small className="units">Cost{taxDetails ? ` excl ${taxDetails.taxName}` : ''}: $</small>
						<div className="form-input -text"><input type="number" className="field" value={value !== undefined ? value.cost !== undefined ? value.cost : '' : ''} onChange={this.onChangeCost} readOnly={readOnly} /></div>
						<div className="break">&nbsp;</div>
						<small className="units">Markup: </small>
						<div className="form-input -text"><input type="number" className="field" value={value !== undefined ? value.markup !== undefined ? value.markup : '' : ''} onChange={this.onChangeMarkup} readOnly={readOnly} /> </div>
						<small className="units -following">%</small>
						<div className="break">&nbsp;</div>
						{this.renderRRP()}
					</div>
				)}
				{effectiveMode === dt.PricingMode.RRP && (
					<div className="form-field -short -options">
						<small className="units">Sell{taxDetails ? ` incl ${taxDetails.taxName}` : ''}: $</small>
						<div className="form-input -text"><input type="number" className="field" value={value !== undefined ? value.rrp !== undefined ? value.rrp : '' : ''} onChange={this.onChangeRRP} readOnly={readOnly} /></div>
						<div className="break">&nbsp;</div>
						<small className="units">Cost{taxDetails ? ` excl ${taxDetails.taxName}` : ''}: $</small>
						<div className="form-input -text"><input type="number" className="field" value={value !== undefined ? value.cost !== undefined ? value.cost : '' : ''} onChange={this.onChangeCost} readOnly={readOnly} /></div>
						<div className="break">&nbsp;</div>
						{this.renderMarkup()}
					</div>
				)}
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		return false
	}

	private renderRRP = () => {
		const { units, project } = this.props

		const value = this.controller.snapshot().value
		if (!value) {
			return null
		}

		if (!value.cost || !value.markup) {
			return null
		}

		const rrp = calculateRRP(value.cost, value.markup, project.taxDetails)
		return (
			<p className="note">Sell Price: ${formatNumber(rrp, 2)}{project.taxDetails ? ` incl ${project.taxDetails.taxName}` : ''}{units && (<> per {units}</>)}</p>
		)
	}

	private renderMarkup = () => {
		const { project } = this.props

		const value = this.controller.snapshot().value
		if (!value) {
			return null
		}

		if (!value.rrp || !value.cost) {
			return null
		}

		const markup = calculateMarkup(value.cost, value.rrp, project.taxDetails)
		if (isNaN(markup)) {
			return null
		}

		return (
			<p className="note">Markup: {formatNumber(markup, { dp: 2, truncateDp: true })}%</p>
		)
	}

	private onChangeCost = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newCost = toNumber(evt.target.value)

		const snapshot = this.controller.snapshot()
		const newValue: dt.Pricing = snapshot.value ? { ...snapshot.value, cost: newCost } : { mode: dt.PricingMode.Cost, cost: newCost }
		if (newCost !== undefined && newValue.markup !== undefined) {
			newValue.rrp = calculateRRP(newCost, newValue.markup, this.props.project.taxDetails)
		}
		snapshot.onChange(newValue)
	}

	private onChangeMarkup = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newMarkup = toNumber(evt.target.value)

		const snapshot = this.controller.snapshot()
		const newValue: dt.Pricing = snapshot.value ? { ...snapshot.value, markup: newMarkup } : { mode: dt.PricingMode.Cost, markup: newMarkup }
		if (newValue.cost !== undefined && newValue.markup !== undefined) {
			newValue.rrp = calculateRRP(newValue.cost, newValue.markup, this.props.project.taxDetails)
		}
		snapshot.onChange(newValue)
	}

	private onChangeRRP = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newRRP = toNumber(evt.target.value)

		const snapshot = this.controller.snapshot()
		const newValue: dt.Pricing = snapshot.value ? { ...snapshot.value, rrp: newRRP } : { mode: dt.PricingMode.Cost, rrp: newRRP }
		if (newValue.cost !== undefined && newValue.rrp !== undefined) {
			newValue.markup = calculateMarkup(newValue.cost, newValue.rrp, this.props.project.taxDetails)
		}
		snapshot.onChange(newValue)
	}

	private onChangeMode = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const checked = evt.target.checked
		const checkedValue = evt.target.value as dt.PricingMode

		if (checked) {
			const snapshot = this.controller.snapshot()
			const newValue: dt.Pricing = snapshot.value ? { ...snapshot.value, mode: checkedValue } : { mode: checkedValue }
			snapshot.onChange(newValue)
		}
	}
}

export default wrapComponent(Price)
