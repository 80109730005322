/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow, { FormFieldVariant } from './FormRow'
import Calculation from 'modules/root/calculations'

interface OwnProps {
	title: string
	value?: string | number
	units?: string
	unitsFirst?: boolean
	calculation?: Calculation<unknown>
	variant?: FormFieldVariant
}

export default class Display extends React.PureComponent<OwnProps> {

	public render() {
		const { title, value, units, unitsFirst, calculation, variant } = this.props
		return (
			<FormRow title={title} type="text" fieldVariant={variant || FormFieldVariant.Wide}>
				{calculation && !calculation.isSuccess() ? calculation.formatErrors() : value !== undefined ? (
					<p>{units && unitsFirst ? units : null}{value} {units && !unitsFirst ? units : null}</p>
				) : (
					<p>-</p>
				)}
				
			</FormRow>
		)
	}

}
