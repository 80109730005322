import * as dt from 'modules/database/types'
import excel from 'exceljs'
import * as ut from 'modules/user/types'
import { addLogo, addConsultant, addCompany, addTitle, autoSize, addSupplier, addDeliverTo, addProjectName, alignCells } from '../excel'
import { productsByType, allProducts } from '../functions'
import exportCurtainsWorkroomSummary from 'modules/product/curtains/purchaseorders/fabric'
import exportBlindsWorkroomSummary from 'modules/product/blinds/purchaseorders/fabric'
import exportCushionsWorkroomSummary from 'modules/product/cushions/purchaseorders/fabric'
import { addImagesSheet } from '../images'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from './types'

export default async function createQuote(project: dt.Project, supplier: PurchaseOrderSupplier, user: ut.User): Promise<Blob> {
	const wb = new excel.Workbook()
	const orderSheet = wb.addWorksheet('Order')
	orderSheet.pageSetup = {
		orientation: 'landscape',
		fitToWidth: 1,
		paperSize: 9,
	}

	await addLogo(orderSheet, 1, 1)

	addCompany(orderSheet, user)
	addTitle(orderSheet, 'FABRIC ORDER')
	const deliverToRowIndex = orderSheet.rowCount + 1

	addSupplier(orderSheet, supplier)
	addConsultant(orderSheet, user)
	addProjectName(orderSheet, project)

	orderSheet.addRow([])

	const summarySheetDataFrom = orderSheet.rowCount

	const pbt = productsByType(allProducts(project))
	const orderSummary: WorkroomSummary = {
		images: {
			images: [],
		},
		headerRows: [],
	}

	dt.AllProductTypes.forEach(productType => {
		const products = pbt.productsByType[productType]
		if (products && products.products.length) {
			switch (productType) {
				case dt.ProductType.Curtain:
					exportCurtainsWorkroomSummary(orderSheet, project, products.products, supplier, ExportFormat.Summary, orderSummary)
					break
				case dt.ProductType.Blind:
					exportBlindsWorkroomSummary(orderSheet, project, products.products, supplier, ExportFormat.Summary, orderSummary)
					break
				case dt.ProductType.Cushion:
					exportCushionsWorkroomSummary(orderSheet, project, products.products, supplier, ExportFormat.Summary, orderSummary)
					break
				default:
					throw new Error(`purchaseorders/fabric: Unsupported product type: ${productType}`)
			}
			
			orderSheet.addRow([])
		}
	})

	autoSize(orderSheet, summarySheetDataFrom)
	alignCells(orderSheet, summarySheetDataFrom)

	addDeliverTo(orderSheet, deliverToRowIndex, user)

	await addImagesSheet(wb, orderSummary.images)

	return wb.xlsx.writeBuffer().then(data => {
		return new Blob([data as ArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
	})
}
