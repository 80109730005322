import actionCreatorFactory from 'typescript-fsa'
import { ComboBoxCode } from './'
import * as t from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('ComboBox')

/* An example action with an interface as its payload. */
export interface UseValuePayload {
	code: ComboBoxCode
	value: t.ComboBoxValueType
	context?: string
}
export const useValue = actionCreator<UseValuePayload>('USE_VALUE')
export type UseValueAction = ReturnType<typeof useValue>

interface DownloadedComboBoxDataPayload {
	code: string
	data: t.ComboBoxData
}
export const downloadedComboBoxData = actionCreator<DownloadedComboBoxDataPayload>('DOWNLOADED_DATA')
