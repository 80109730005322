import { v4 as uuid } from 'uuid'

import { store } from 'modules/root'
import { storage } from 'modules/database'
import * as as from 'modules/auth/selectors'
import * as a from './actions'
import * as dt from 'modules/database/types'
import * as _ from 'lodash'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import platform from 'modules/platform'
import { rejectOperation, resolveOperation, promiseForOperation, startOperation } from './singleton-operations'

export function savePhoto(photo: Blob | ArrayBuffer, projectId: dt.ProjectID): Promise<string> {
	return new Promise((resolve, reject) => {
		const path = pathForNewPhoto(projectId)
		uploadAsset(photo, path, {
			contentType: 'image/jpeg',
			cacheControl: 'private, max-age=31536000',
		}).then(() => {
			/* We don't do anything, as we've already reported the path */
		}).catch(error => {
			platform.alert(error.message)
		})

		/* Report the path the image will be at immediately so we can save the path into the object that needs it */
		resolve(path)
	})
}

export interface SaveAnnotationsResult {
	annotationsPath: string
	annotatedImagePath: string
}

export function saveAnnotations(photoPath: string, annotatedImage: Blob, annotations: string): Promise<SaveAnnotationsResult> {
	const suffix = photoPath.lastIndexOf('.')
	const annotationsDir = suffix !== -1 ? photoPath.substring(0, suffix) : photoPath
	const annotationsPath = `${annotationsDir}/${uuid()}`
	
	const result: SaveAnnotationsResult = {
		annotationsPath: `${annotationsPath}.json`,
		annotatedImagePath: `${annotationsPath}.jpg`,
	}

	return Promise.all([
		uploadAsset(new Blob([annotations]), result.annotationsPath, {
			contentType: 'text/json',
			cacheControl: 'private, max-age=31536000',
		}),
		uploadAsset(annotatedImage, result.annotatedImagePath, {
			contentType: 'image/jpeg',
			cacheControl: 'private, max-age=31536000',
		}),
	]).then(() => result)
}

export function deleteAsset(path: string) {
	storage.ref(path).delete().catch(error => console.warn(`Failed to delete asset from cloud storage: ${error.message}`))
}

function uploadAsset(asset: Blob | ArrayBuffer, path: string, metadata?: firebase.storage.UploadMetadata): Promise<void> {
	return new Promise((resolve, reject) => {
		const size = uploadSize(asset)
		console.log('STORAGE: uploadAsset:', path, size)

		if (window.requestFileSystem !== undefined) {
			console.log('STORAGE: uploadAsset using Cordova filesystem')
			cordovaSaveAsset(asset, path).then(url => {
				console.log('STORAGE: uploadAsset saved to filesystem')
				resolve()
				uploadRetriable()
			}).catch(error => {
				console.error('STORAGE: failed to save new asset:', path, error)
				uploadNotRetriable()
			})
		} else {
			console.log('STORAGE: uploadAsset without filesystem support')
			uploadNotRetriable()
		}

		function uploadRetriable() {
			store.dispatch(a.queueUploadAction({ path, size, metadata, retriable: true }))
			actuallyUpload(true)
		}
		function uploadNotRetriable() {
			store.dispatch(a.queueUploadAction({ path, size, metadata, retriable: false }))
			actuallyUpload(false)
		}
		function actuallyUpload(retriable: boolean) {
			console.log('STORAGE: starting upload', path, metadata ? JSON.stringify(metadata) : '')
			if (!retriable) {
				startOperation(path)
			}

			const task = storage.ref(path).put(asset, metadata)
			handleUploadTask(path, retriable, task).then(() => {
				if (!retriable) {
					resolveOperation(path)
					resolve()
				}
			}).catch(error => {
				if (!retriable) {
					rejectOperation(path, error)
				}

				Sentry.captureException(error)
				reject(error)
			})
		}
	})
}

function uploadSize(asset: Blob | ArrayBuffer | string): number {
	if (asset instanceof Blob) {
		return asset.size
	} else if (typeof asset === 'string') {
		return asset.length
	} else {
		return asset.byteLength
	}
}

export function runQueuedUploads() {
	if (!window.requestFileSystem) {
		return
	}

	const uploads = store.getState().storage.uploads
	console.log(`STORAGE: running upload queue with ${Object.keys(uploads).length} entries`)
	
	_.forOwn(uploads, (value, path) => {
		if (!value.uploading) {
			fileEntryForCachedAsset(path).then(entry => {
				entry.file(
					file => {
						/* We need to use FileReader to read an ArrayBuffer as it's not a real File */
						const reader = new FileReader()
						reader.onloadend = function() {
							const contents = this.result
							if (contents) {
								/* contents is an ArrayBuffer */
								console.log('STORAGE: starting queued upload', path, value.metadata ? JSON.stringify(value.metadata) : '')
								const task = storage.ref(path).put(contents as ArrayBuffer, value.metadata)
								handleUploadTask(path, true, task).catch(reason => {
									Sentry.captureException(reason)
								})
							} else {
								console.error('STORAGE: empty contents for cached asset:', path)
								store.dispatch(a.dequeueUploadAction(path))
							}
						}
						reader.readAsArrayBuffer(file)
					},
					error => {
						console.error('STORAGE: failed to read cached asset:', path, error.code)
						store.dispatch(a.dequeueUploadAction(path))
					},
				)
			}).catch(error => {
				console.error('STORAGE: failed to retrieve cached asset:', path)
				store.dispatch(a.dequeueUploadAction(path))
			})
		}
	})

	console.log('STORAGE: finished running upload queue')
}

function handleUploadTask(path: string, retriable: boolean, task: firebase.storage.UploadTask): Promise<string> {
	store.dispatch(a.startUploadAction(path))

	return new Promise((resolve, reject) => {
		task.on(
			firebase.storage.TaskEvent.STATE_CHANGED, 
			(snapshot: firebase.storage.UploadTaskSnapshot) => {
				console.log('STORAGE: upload state changed:', path, snapshot.state, snapshot.bytesTransferred)

				store.dispatch(a.uploadProgressAction({ path, progress: snapshot.bytesTransferred }))
			},
			(error) => {
				console.error('STORAGE: upload failed', error.message)
				store.dispatch(a.stopUploadAction(path))
				if (!retriable) {
					store.dispatch(a.dequeueUploadAction(path))
				}
				reject(error)
			},
			() => {
				console.log('STORAGE: upload succeeded:', path)

				store.dispatch(a.stopUploadAction(path))
				store.dispatch(a.dequeueUploadAction(path))
				resolve(path)
			},
		)
	})
}

/** Return the Google Storage path to store a new photo for the current user and project. */
function pathForNewPhoto(projectId: dt.ProjectID) {
	const state = store.getState()
	const user = as.currentUser(state)

	if (user && projectId) {
		const imageUuid = uuid()
		return `/u/${user.uid}/p/${projectId}/${imageUuid}.jpg`
	} else {
		throw new Error('Not logged in or no project')
	}
}

/** Save the given photo locally for the given Google Storage path. */
function cordovaSaveAsset(blob: Blob | ArrayBuffer | string, path: string): Promise<string> {
	startOperation(path)

	return new Promise((resolve, reject) => {
		fileEntryForCachedAsset(path, { create: true, exclusive: true }).then(entry => {
			/* Cache the file locally */
			entry.createWriter(
				(writer) => {
					writer.onwriteend = function() {
						console.log('STORAGE: successfully cached asset:', path)

						const localUrl = entry.nativeURL
						resolve(localUrl)
						resolveOperation(path)
					}
					writer.onerror = function(error: ProgressEvent) {
						console.warn('STORAGE: failed to write cached asset:', entry.fullPath, error)
						reject(error)
						rejectOperation(path, error)
					}
					if (blob instanceof Blob) {
						writer.write(blob)
					} else {
						const actualBlob = new Blob([blob])
						writer.write(actualBlob)
					}
				},
				(error) => {
					console.warn('STORAGE: failed to create writer for cached asset:', entry.fullPath, error)
					reject(error)
					rejectOperation(path, error)
				},
			)
		}).catch(error => {
			console.warn('STORAGE: failed to save asset locally:', error)
			reject(error)
			rejectOperation(path, error)
		})
	})
}

/** The filename to use to store the given Google Storage path asset at locally. */
function filenameForStoragePath(path: string): string {
	return path.substring(path.lastIndexOf('/') + 1)
}

/** Obtain the URL to load a given asset from.
 * 
 * This function attempts to use locally cached files, and to cache remote files if possible.
 */
export function getAssetURL(path: string): Promise<string> {
	if (window.requestFileSystem !== undefined) {
		console.log('STORAGE: using cordovaGetAssetURL to find asset URL for', path)
		return cordovaGetAssetURL(path)
	} else {
		console.log('STORAGE: using webGetAssetURL to find asset URL for', path)
		return webGetAssetURL(path)
	}
}

export async function getAnnotations(path: string): Promise<string> {
	const url = await getAssetURL(path)
	const response = await fetch(convertURLForLocal(url))
	if (response.status === 200) {
		return response.text()
	} else {
		throw new Error(`Failed to load annotations: ${response.status}`)
	}
}

/** Returns a promise that resolves to a FileEntry to use to access or create a cached asset. */
function fileEntryForCachedAsset(path: string, options?: Flags): Promise<FileEntry> {
	return new Promise((resolve, reject) => {
		window.requestFileSystem(
			window.PERSISTENT, 
			5 * 1024 * 1024, 
			(fileSystem) => {
				(fileSystem.root as unknown as DirectoryEntry).getDirectory(
					'photos',
					{ create: true },
					(directoryEntry) => {
						const filename = filenameForStoragePath(path)
						directoryEntry.getFile(
							filename,
							options,
							(entry) => {
								resolve(entry)
							},
							(error) => {
								reject(`Failed to get cache file ${path}: ${error.code}`)
							},
						)
					},
					(error) => {
						reject(`Failed to get cache directory: ${error.code}`)
					},
				)
			},
			(error) => {
				reject(`Failed to access file system: ${error.code}`)
			},
		)
	})
}

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Ionic: any
	}
}

/** Create a URL we can load in cordova-plugin-ionic-webview */
export function convertURLForLocal(url: string): string {
	if (window.Ionic && window.Ionic.WebView && window.Ionic.WebView.convertFileSrc) {
		return window.Ionic.WebView.convertFileSrc(url)
	} else {
		return url.replace(/^file:\/\//, 'ionic://localhost/_app_file_')
	}
}

/** Get a URL to use to access the given Google Storage path. */
async function cordovaGetAssetURL(path: string): Promise<string> {
	await promiseForOperation(path)

	return new Promise((resolve, reject) => {
		fileEntryForCachedAsset(path, { create: true }).then((entry) => {
			const localUrl = entry.nativeURL

			entry.getMetadata(
				(metadata) => {
					if (metadata.size > 0) {
						console.debug('STORAGE: using existing file:', localUrl)
						resolve(localUrl)
					} else {
						/* Downloading and caching file */
						console.debug('STORAGE: downloading uncached file:', path)

						storage.ref(path).getDownloadURL().then((url) => {
							/* Requires CORS setup: https://firebase.google.com/docs/storage/web/download-files?authuser=1 */
							const xhr = new XMLHttpRequest()
							xhr.open('GET', url, true)
							xhr.responseType = 'blob'
							xhr.onloadend = function() {
								if (this.status === 200) {
									const blob = new Blob([this.response], { type: this.getResponseHeader('Content-Type') || 'image/jpeg' })
									
									/* Cache the file locally */
									entry.createWriter(
										(writer) => {
											writer.onwriteend = function() {
												console.log('STORAGE: successfully cached asset:', localUrl)
												resolve(localUrl)
											}
											writer.onerror = function(error: ProgressEvent) {
												console.warn('STORAGE: failed to write cached asset:', entry.fullPath, error)

												/* Use the remote URL instead */
												resolve(url)
											}
											writer.write(blob)
										},
										(error) => {
											console.warn('STORAGE: failed to create writer for cached asset:', entry.fullPath, error)

											/* Use the remote URL instead */
											resolve(url)
										},
									)
								} else {
									console.warn('STORAGE: failed to download file:', url, this.statusText)

									/* Use the remote URL instead */
									resolve(url)
								}
							}
							xhr.send()
						}).catch((error) => {
							reject(error)
						})
					}
				},
				(error) => {
					reject(`Failed to get entry metadata: ${error.code}`)
				},
			)
		}).catch(error => {
			reject(error)
		})
	})
}

async function webGetAssetURL(path: string): Promise<string> {
	await promiseForOperation(path)

	return storage.ref(path).getDownloadURL()
}
