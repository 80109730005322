import * as _ from 'lodash'
import * as dt from 'modules/database/types'
import * as df from 'modules/database/functions'
import * as saver from 'file-saver'
import * as t from './types'

export interface ImageResult {
	base64: string
	width: number
	height: number
}

export function imageToBase64(url: string, type: string, crossOrigin?: string, quality?: number): Promise<ImageResult> {
	return new Promise<ImageResult>((resolve, reject) => {
		const img = new Image()
		img.onload = function() {
			const canvas = document.createElement('canvas')
			canvas.width = img.width
			canvas.height = img.height
			
			const context = canvas.getContext('2d')
			if (!context) {
				reject('Cannot create canvas context')
				return
			}
			context.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height)
			const base64 = canvas.toDataURL(type, quality)
			resolve({
				base64,
				width: img.width,
				height: img.height,
			})
		}
		img.onerror = function() {
			reject(`Failed to load image: ${url}`)
		}
		if (crossOrigin) {
			img.crossOrigin = crossOrigin
		}
		img.src = url
	})
}

function cordovaWriteTemporaryFile(blob: Blob, filename: string): Promise<FileEntry> {
	return new Promise((resolve, reject) => {
		window.requestFileSystem(
			window.TEMPORARY, 
			5 * 1024 * 1024, 
			(fs: FileSystem) => {
				(fs.root as unknown as DirectoryEntry).getFile(
					filename,
					{ create: true },
					(fileEntry) => {
						fileEntry.createWriter(
							(writer) => {
								writer.onerror = (event) => {
									reject(`Failed to write temporary file: ${event}`)
								}
								writer.onwriteend = (event) => {
									resolve(fileEntry)
								}
								
								writer.write(blob)
							},
							(error) => {
								reject(`Failed to write temporary file: ${error.code}`)
							},
						)
					},
					(error) => {
						reject(`Failed to get path to write temporary file: ${error.code}`)
					},
				)
			},
			(error) => {
				reject(`Failed to access filesystem to write temporary file: ${error.code}`)
			},
		)
	})
}

export function cordovaShare(options: SocialSharingOptions, blob: Blob, filename: string): Promise<SocialSharingResult> {
	return cordovaWriteTemporaryFile(blob, filename).then(fileEntry => {
		options.files = [fileEntry.nativeURL]

		return new Promise<SocialSharingResult>((resolve, reject) => {
			window.plugins.socialsharing.shareWithOptions(
				options,
				(result) => resolve(result),
				(error) => reject(error),
			)
		})
	})
}

/**
 * Make a filename safe for sharing.
 * @param filename 
 */
function safeFilename(filename: string) {
	return filename.replace(/[^-a-zA-Z0-9_ \\.]/g, '_')
}

export function share(options: SocialSharingOptions, blob: Blob, filename: string): Promise<void> {
	filename = safeFilename(filename)
	
	if (window.plugins && window.plugins.socialsharing) {
		return cordovaShare(options, blob, filename).then(result => {
			return
		})
	} else {
		return new Promise((resolve) => {
			saver.saveAs(blob, filename)
			resolve()
		})
	}
}

/** Returns all of the products in the project grouped by type. */
export function productsByType(products: dt.Product[]): t.ProductsByType {
	const result: t.ProductsByType = {
		types: [],
		productsByType: {},
	}

	products.forEach(product => {
		if (!result.productsByType[product.type]) {
			result.types.push(product.type)
			result.productsByType[product.type] = {
				products: [],
			}
		}

		result.productsByType[product.type].products.push(product)
	})

	/* Sort products by creation date */
	result.types.forEach(productType => {
		result.productsByType[productType].products = result.productsByType[productType].products.sort(defaultProductSort)
	})

	result.types = result.types.sort()
	return result
}

/** Sort products into the default order. */
export function defaultProductSort(product1: dt.Product, product2: dt.Product): number {
	const n1 = df.productTitle(product1)
	const n2 = df.productTitle(product2)

	if (n1 < n2) {
		return -1
	} else if (n1 > n2) {
		return 1
	} else if (product1.whenCreated < product2.whenCreated) {
		return -1
	} else if (product1.whenCreated > product2.whenCreated) {
		return 1
	} else {
		return 0
	}
}

/** Returns a sorted array of the products of the given type from the given project. */
export function productsOfType(project: dt.Project, type: dt.ProductType): dt.Product[] {
	return allProducts(project).filter(p => p.type === type).sort(defaultProductSort)
}

/** Return all of the products in the given project. */
export function allProducts(project: DeepReadonly<dt.Project>): dt.Product[] {
	const result: dt.Product[] = []
	_.keys(project.products).forEach(productId => {
		const product = project.products[productId]
		if (product && !product.deleted) {
			result.push(product)
		}
	})
	return result
}

/** Return all of the deleted products in the given project. */
export function deletedProducts(project: DeepReadonly<dt.Project>): dt.Product[] {
	const result: dt.Product[] = []
	_.keys(project.products).forEach(productId => {
		const product = project.products[productId]
		if (product && product.deleted) {
			result.push(product)
		}
	})
	return result
}

export function compareProjectsByLastUpdated(a: dt.Project, b: dt.Project): number {
	if (!a.whenUpdated && !b.whenUpdated) {
		return 0
	} else if (!a.whenUpdated && b.whenUpdated) {
		return 1
	} else if (!b.whenUpdated && a.whenUpdated) {
		return -1
	} else if (a.whenUpdated < b.whenUpdated) {
		return 1
	} else if (a.whenUpdated > b.whenUpdated) {
		return -1
	} else {
		return 0
	}
}

export function compareProjectsByName(a: dt.Project, b: dt.Project): number {
	const na = df.projectTitle(a)
	const nb = df.projectTitle(b)
	if (na < nb) {
		return -1
	} else if (na > nb) {
		return 1
	} else {
		return 0
	}
}
