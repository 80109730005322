import * as React from 'react'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CushionForm'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import * as cf from 'modules/product/curtains/functions'
import { forComponentProps, Controller, wrapComponent } from 'changeling'
import { cushionName } from '../functions'
import { productTitle } from 'modules/database/functions'
import { CurtainDetail } from 'modules/database/types/curtains'
import * as blinds from 'modules/database/types/blinds'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CushionFormFabric extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE
	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('fabric')

	public render() {
		const value = this.controller.snapshot().value || {}

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{cushionName(this.props.value)} fabric</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Cushion}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						productToItems={(p: dt.Product): CopyFromProductGroupItem[] => {
							if (!p.details || p.productId === this.props.productId) {
								return []
							}
							
							if (p.type === dt.ProductType.Cushion) {
								return [{
									name: productTitle(p),
									type: 'product',
									value: p,
								}]
							}
							if (p.type === dt.ProductType.Blind && (p.details as blinds.Blind).overview && (p.details as blinds.Blind).overview!.blindType === blinds.BlindType.Roman) {
								return [{
									name: productTitle(p),
									type: 'blind',
									value: p,
								}]
							}
							if (p.type === dt.ProductType.Curtain) {
								return cf.curtainProductGroupItems(this.props.productId, p)
							}

							return []
						}}
					/>
				)}

				<ff.Heading>Fabric</ff.Heading>
				<ff.Toggle 
					title="Fabric required?" 
					controller={this.controller}
					prop="fabric"
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.fabric === true && (
					<>
						<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={this.controller} />
						<ff.ComboBox title="Collection" prop="collection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.supplier} controller={this.controller} />
						<ff.ComboBox title="Fabric" prop="name" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.supplier}|${value.collection}`} controller={this.controller} />
						<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={this.controller} />
						<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={this.controller} />
						<ff.Photo title="Photo of fabric" prop="photo" path={[...this.props.path, 'fabric', 'photo']} projectId={this.props.project.projectId} controller={this.controller} />
						<ff.Numeric title="Width" units="mm" prop="width" controller={this.controller} />

						<ff.Heading>Back fabric</ff.Heading>
						<ff.Toggle
							title="Back fabric?"
							controller={this.controller}
							prop="backFabricDifferent"
							options={[
								{ label: 'Same as front', value: false },
								{ label: 'Different', value: true },
							]}
							showClear={true}
						/>
						{value.backFabricDifferent === true && this.renderBackFabricOptions(this.controller.controller('backFabricOptions'))}
					</>
				)}

				<ff.Heading>Fill</ff.Heading>
				<ff.Toggle 
					title="Fill required?" 
					prop="fill" 
					controller={this.controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.fill === true && this.renderFillOptions(this.controller.controller('fillOptions'))}

				<ff.Heading>Piping</ff.Heading>
				<ff.Toggle 
					title="Piping required?" 
					prop="piping" 
					controller={this.controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.piping === true && this.renderPipingOptions(this.controller.controller('pipingOptions'))}

				<ff.Heading>Flange</ff.Heading>
				<ff.Toggle 
					title="Flange required?" 
					prop="flange" 
					controller={this.controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.flange === true && this.renderFlangeOptions(this.controller.controller('flangeOptions'))}

				<ff.Heading>Trim</ff.Heading>
				<ff.Toggle 
					title="Trim required?" 
					prop="trim" 
					controller={this.controller}
					options={[
						{ label: 'Required', value: true },
						{ label: 'Not required', value: false },
					]} 
					showClear={true}
				/>
				{value.trim === true && this.renderTrimOptions(this.controller.controller('trimOptions'))}
			</>
		)
	}

	private renderBackFabricOptions = (controller: Controller<dtp.CushionBackFabric | undefined>) => {
		const value = controller.snapshot().value || {}
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Collection" prop="collection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.supplier} controller={controller} />
				<ff.ComboBox title="Fabric" prop="name" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.supplier}|${value.collection}`} controller={controller} />
				<ff.ComboBox title="Colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
				<ff.Photo title="Photo of fabric" prop="photo" path={[...this.props.path, 'fabric', 'backFabricOptions', 'photo']} projectId={this.props.project.projectId} controller={controller} />
				<ff.Numeric title="Width" units="mm" prop="width" controller={controller} />
			</>
		)
	}

	private renderFillOptions = (controller: Controller<dtp.CushionFillOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.WORKROOM_SUPPLIER} controller={controller} />
				<ff.ComboBox 
					title="Type" 
					prop="type" 
					code={ComboBoxCode.FILL} 
					controller={controller}
					staticValues={[
						'Feather', 'Dacron',
					]}
				/>
				<ff.Price title="Price" units="cushion" prop="price" project={this.props.project} controller={controller} />
			</>
		)
	}

	private renderPipingOptions = (controller: Controller<dtp.CushionPipingOptions | undefined>) => {
		const value = controller.snapshot().value || {}
		return (
			<>
				<ff.ComboBox title="Fabric supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Fabric collection" prop="collection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.supplier} controller={controller} />
				<ff.ComboBox title="Fabric name" prop="name" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.supplier}|${value.collection}`} controller={controller} />
				<ff.ComboBox title="Fabric colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
				<ff.Photo title="Photo of fabric" prop="photo" path={[...this.props.path, 'fabric', 'pipingOptions', 'photo']} projectId={this.props.project.projectId} controller={controller} />
				<ff.Numeric title="Fabric width" units="mm" prop="width" controller={controller} />

				{this.props.value.overview && this.props.value.overview.cushionType === dtp.CushionType.Squab && (
					<ff.Radios
						title="Type"
						prop="squabPipingType"
						controller={controller}
						options={[
							{ label: dtp.CushionSquabPipingType.top, value: dtp.CushionSquabPipingType.top },
							{ label: dtp.CushionSquabPipingType.topBottom, value: dtp.CushionSquabPipingType.topBottom },
						]}
					/>
				)}
			</>
		)
	}

	private renderFlangeOptions = (controller: Controller<dtp.CushionFlangeOptions | undefined>) => {
		const value = controller.snapshot().value || {}
		return (
			<>
				<ff.ComboBox title="Fabric supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Fabric collection" prop="collection" code={ComboBoxCode.FABRIC_COLLECTION} comboContext={value.supplier} controller={controller} />
				<ff.ComboBox title="Fabric name" prop="name" code={ComboBoxCode.FABRIC_NAME} comboContext={`${value.supplier}|${value.collection}`} controller={controller} />
				<ff.ComboBox title="Fabric colour" prop="colour" code={ComboBoxCode.COLOUR} controller={controller} />
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
				<ff.Photo title="Photo of fabric" prop="photo" path={[...this.props.path, 'fabric', 'photo']} projectId={this.props.project.projectId} controller={controller} />
				<ff.Numeric title="Fabric width" units="mm" prop="width" controller={controller} />

				<ff.Numeric title="Flange width" units="mm" prop="flangeWidth" controller={controller} />
			</>
		)
	}

	private renderTrimOptions = (controller: Controller<dtp.CushionTrimOptions | undefined>) => {
		return (
			<>
				<ff.ComboBox title="Supplier" prop="supplier" code={ComboBoxCode.FABRIC_SUPPLIER} controller={controller} />
				<ff.ComboBox title="Trim" prop="name" code={ComboBoxCode.TRIM} controller={controller} />
				<ff.Price title="Price" units="metre" prop="price" project={this.props.project} controller={controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'product') {
			const product = (item.value as dt.Product).details as dtp.Cushion
			if (product && product.fabric) {
				this.controller.snapshot().onChange(product.fabric)
				this.setState({ showCopy: false })
			} else {
				platform.alert('The selected cushion doesn’t have fabric.')
			}
		}

		if (item.type === 'curtain') {
			const curtain = item.value as CurtainDetail
			if (curtain && curtain.fabric) {
				this.controller.snapshot().onChange(curtain.fabric as dtp.CushionFabric)
				this.setState({ showCopy: false })
			} else {
				platform.alert('The selected curtain doesn’t have fabric.')
			}
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(CushionFormFabric)
