/**
 * Date component
 * 
 * A pure component wrapper of Moment that removes unwanted re-renders of the Moment component.
 */

import * as React from 'react'
import Moment from 'react-moment'

interface OwnProps {
	date: Date | string
	format: string
}

export default class Date extends React.PureComponent<OwnProps> {

	public render() {
		const { date, format } = this.props

		/* Setting interval=0 stops the moment element from auto updating */
		return (
			<Moment date={date} format={format} interval={0} />
		)
	}
	
}
