import * as React from 'react'
import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { SubFormProps } from './CurtainForm'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import * as cf from 'modules/product/curtains/functions'
import { Controller, forComponentProps, wrapComponent } from 'changeling'
import platform from 'modules/platform'

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class CurtainFormInstallation extends React.Component<SubFormProps, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('installation')

	public render() {
		return this.renderInstallation(this.controller)
	}

	private renderInstallation = (controller: Controller<dtp.CurtainInstallation | undefined>) => {
		const notFirstCurtain = this.props.path[0] !== 'curtain1'

		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">{this.props.curtainName} installation</ff.Heading>
				
				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Curtain}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						specialGroup={
							notFirstCurtain ? {
								items: [{
									name: 'Curtain 1',
									type: 'curtain1',
								}],
							} : undefined
						}
						productToItems={cf.curtainProductGroupItems.bind(undefined, this.props.productId)}
					/>
				)}

				<ff.TextArea title="Installation notes" prop="notes" controller={controller} />
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		if (item.type === 'curtain1') {
			this.onCopyFromCurtain1()
			return
		}

		const product = item.value as dtp.CurtainDetail
		if (product && product.installation) {
			this.controller.snapshot().onChange(product.installation)
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected curtain doesn’t have installation information.')
		}
	}

	private onCopyFromCurtain1 = () => {
		const root = this.props.rootFormValue
		if (root.curtain1 && root.curtain1.installation) {
			this.controller.snapshot().onChange(root.curtain1.installation)
			this.setState({ showCopy: false })
		} else {
			platform.alert('Curtain 1 does not have installation available')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}
}

export default wrapComponent(CurtainFormInstallation)
