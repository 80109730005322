import * as React from 'react'
import * as dtp from 'modules/database/types/blinds'
import * as dt from 'modules/database/types'
import * as ff from 'modules/forms'
import { ComboBoxCode } from 'modules/combobox'
import CopyFromOtherProduct, { CopyFromProductGroupItem } from 'modules/product/components/CopyFromOtherProduct'
import { wrapComponent, Snapshot, forComponentProps } from 'changeling'
import produce from 'immer'
import { blindTypeName } from '../functions'
import platform from 'modules/platform'

interface Props extends Snapshot<dtp.Blind> {
	path: string[]
	project: dt.Project
	productId: dt.ProductID
}

interface State {
	showCopy: boolean
}

const INITIAL_STATE: State = {
	showCopy: false,
}

class BlindFormOverview extends React.Component<Props, State> {

	public state = INITIAL_STATE

	private rootController = forComponentProps(this)
	private controller = this.rootController.controller('overview')

	public render() {
		return (
			<>
				<a href="#" onClick={this.toggleCopy} className="copy-link -from">Copy from…</a>
				<ff.Heading type="section">Room layout</ff.Heading>

				{this.state.showCopy && (
					<CopyFromOtherProduct
						productId={this.props.productId}
						productType={dt.ProductType.Blind}
						project={this.props.project}
						onCopy={this.onCopy}
						onCancel={this.onCancelCopy}
						copyButtonLabel="Copy All"
					/>
				)}

				<ff.ComboBox title="Room" code={ComboBoxCode.ROOM} controller={this.controller} prop="room" />
				<ff.ComboBox title="Window" code={ComboBoxCode.WINDOW} controller={this.controller} prop="window" />

				<ff.Photo title="Photo of window - BEFORE" path={[...this.props.path, 'overview', 'photo']} projectId={this.props.project.projectId} controller={this.controller} prop="photo" />
				<ff.Photo title="Photo of window - AFTER" path={[...this.props.path, 'overview', 'photoAfter']} projectId={this.props.project.projectId} controller={this.controller} prop="photoAfter" />
				
				<ff.Heading>Blind type</ff.Heading>

				<ff.Toggle 
					title="Blind type" 
					prop="blindType" 
					controller={this.controller}
					options={[
						{ label: blindTypeName(dtp.BlindType.Roman), value: dtp.BlindType.Roman },
						{ label: blindTypeName(dtp.BlindType.Roller), value: dtp.BlindType.Roller },
						{ label: blindTypeName(dtp.BlindType.Venetian), value: dtp.BlindType.Venetian },
					]}
				/>
			</>
		)
	}

	private toggleCopy = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ showCopy: !this.state.showCopy })
	}

	private onCopy = (item: CopyFromProductGroupItem) => {
		const product = item.value as dt.Product
		if (product.details) {
			const other = product.details as dtp.Blind

			const snapshot = this.rootController.snapshot()
			snapshot.onChange(produce(snapshot.value, draft => {
				if (other.overview) {
					if (!draft.overview) {
						draft.overview = {}
					}
					draft.overview.blindType = other.overview.blindType
				}
				if (other.fabric) {
					draft.fabric = other.fabric
				}
				if (other.measures) {
					draft.measures = other.measures
				}
				if (other.specifications) {
					draft.specifications = other.specifications
				}
				if (other.hardware) {
					draft.hardware = other.hardware
				}
			}))
			this.setState({ showCopy: false })
		} else {
			platform.alert('The selected blind hasn’t been completed.')
		}
	}

	private onCancelCopy = () => {
		this.setState({ showCopy: false })
	}

}

export default wrapComponent(BlindFormOverview)
