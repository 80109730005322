import * as excel from 'exceljs'
import * as dtp from 'modules/database/types/cushions'
import * as dt from 'modules/database/types'
import * as cf from '../functions'
import { ExportFormat, WorkroomSummary, PurchaseOrderSupplier } from 'modules/project/purchaseorders/types'
import { toNumber } from 'modules/root/functions'
import { addImageToImagesSummary } from 'modules/project/images'
import { exportPurchaseOrderSheet, HeaderRow } from 'modules/product/common/purchaseorders'

interface Row {
	collection?: string
	name?: string
	colour?: string
	width?: number
	drops?: number
	cutLength?: number
	total?: number

	fillType?: string
	fillQuantity?: number
	fillWidth?: number
	fillHeight?: number
	fillDepth?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rowToRow(row: Row): any[] {
	return [
		row.collection,
		row.name,
		row.colour,
		row.width,
		row.drops,
		row.cutLength,
		row.total,
		row.fillType,
		row.fillQuantity,
		row.fillWidth,
		row.fillHeight,
		row.fillDepth,
	]
}

const header: HeaderRow<Row> = {
	collection: 'Fabric Collection', 
	name: 'Fabric', 
	colour: 'Fabric Colour', 
	width: 'Fabric Width (mm)', 
	drops: 'Fabric Drops ()', 
	cutLength: 'Fabric Cut Length (mm)', 
	total: 'Fabric Total (m)',

	fillType: 'Fill',
	fillQuantity: 'Fill Quantity ()',
	fillWidth: 'Fill Width (mm)',
	fillHeight: 'Fill Height (mm)',
	fillDepth: 'Fill Depth (mm)',
}

export default function exportWorkroomSummary(sheet: excel.Worksheet, project: dt.Project, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary) {
	exportPurchaseOrderSheet(sheet, products, supplier, format, summary, {
		header,
		outputProduct,
		rowToRow,
	})
}

function outputProduct(supplier: PurchaseOrderSupplier, c: dtp.Cushion, summary: WorkroomSummary): Row[] {
	/* Check supplier */
	if (!c.fabric) {
		return []
	}

	const outputFabric = (c.fabric.fabric && c.fabric.supplier === supplier.supplier)
	const outputBackFabric = (c.fabric.backFabricDifferent && c.fabric.backFabricOptions && c.fabric.backFabricOptions.supplier === supplier.supplier)
	const outputFill = (c.fabric.fill && c.fabric.fillOptions && c.fabric.fillOptions.supplier === supplier.supplier)
	const outputPiping = (c.fabric.piping && c.fabric.pipingOptions && c.fabric.pipingOptions.supplier === supplier.supplier)
	const outputFlange = (c.fabric.flange && c.fabric.flangeOptions && c.fabric.flangeOptions.supplier === supplier.supplier)
	const outputTrim = (c.fabric.trim && c.fabric.trimOptions && c.fabric.trimOptions.supplier === supplier.supplier)

	if (!outputFabric && !outputBackFabric && !outputFill && !outputPiping && !outputFlange && !outputTrim) {
		return []
	}

	const quantities = cf.calculateQuantities(c).getResult()
	const result: Row[] = []

	// 'Fabric Collection', 'Fabric', 'Fabric Width (mm)', 'Fabric Drops ()', 'Fabric Cut Length (mm)', 
	if (outputFabric) {
		const q = quantities.fabric && quantities.fabric.getResult()

		const data: Row = {}
		data.collection = c.fabric.collection
		data.name = c.fabric.name
		data.colour = c.fabric.colour
		data.width = toNumber(c.fabric.width)
		data.drops = q ? q.drops : undefined
		data.cutLength = q ? q.cutLength : undefined
		data.total = q && q.quantity ? q.quantity / 1000 : undefined
		result.push(data)
	}

	if (outputBackFabric && c.fabric.backFabricOptions) {
		const q = quantities.backFabric && quantities.backFabric.getResult()

		const data: Row = {}
		data.collection = c.fabric.backFabricOptions.collection
		data.name = c.fabric.backFabricOptions.name
		data.colour = c.fabric.backFabricOptions.colour
		data.width = toNumber(c.fabric.backFabricOptions.width)
		data.drops = q ? q.drops : undefined
		data.cutLength = q ? q.cutLength : undefined
		data.total = q && q.quantity ? q.quantity / 1000 : undefined
		result.push(data)
	}
	
	if (outputFill && c.fabric.fillOptions && c.overview && c.measures) {
		const data: Row = {}
		data.fillType = c.fabric.fillOptions.type
		data.fillQuantity = toNumber(c.overview.quantity)
		data.fillWidth = toNumber(c.measures.width)
		data.fillHeight = toNumber(c.measures.height)
		data.fillDepth = c.overview.cushionType === dtp.CushionType.Squab ? toNumber(c.measures.depth) : undefined
		result.push(data)
	}

	if (outputPiping && c.fabric.pipingOptions) {
		const q = quantities.piping && quantities.piping.getResult()

		const data: Row = {}
		data.collection = c.fabric.pipingOptions.collection
		data.name = c.fabric.pipingOptions.name
		data.colour = c.fabric.pipingOptions.colour
		data.width = toNumber(c.fabric.pipingOptions.width)
		data.drops = q ? q.drops : undefined
		data.cutLength = q ? q.cutLength : undefined
		data.total = q && q.quantity ? q.quantity / 1000 : undefined
		result.push(data)
	}

	if (outputFlange && c.fabric.flangeOptions) {
		const q = quantities.flange && quantities.flange.getResult()

		const data: Row = {}
		data.collection = c.fabric.flangeOptions.collection
		data.name = c.fabric.flangeOptions.name
		data.colour = c.fabric.flangeOptions.colour
		data.width = toNumber(c.fabric.flangeOptions.width)
		data.drops = q ? q.drops : undefined
		data.cutLength = q ? q.cutLength : undefined
		data.total = q && q.quantity ? q.quantity / 1000 : undefined
		result.push(data)
	}

	// 'Trim'
	if (outputTrim && c.fabric.trimOptions) {
		const q = quantities.trim && quantities.trim.getResult()

		const data: Row = {}
		data.name = c.fabric.trimOptions.name
		data.cutLength = q ? q.cutLength : undefined
		data.total = q && q.quantity ? q.quantity / 1000 : undefined
		result.push(data)
	}
		
	if (c.fabric && c.fabric.photo) {
		addImageToImagesSummary(summary.images, {
			photo: c.fabric.photo,
			key: `fabric: ${c.fabric.supplier} ${c.fabric.name}`, 
			title: `${c.fabric.supplier || ''}: ${c.fabric.name || ''}`, 
			specificName: c.overview ? `${c.overview.room || ''} ${c.overview.window || ''}` : '',
		})
	}

	return result
}
