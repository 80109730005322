/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow from './FormRow'
import { Input, Snapshot, wrapComponent, forComponentProps } from 'changeling'

interface OwnProps extends Snapshot<string | undefined> {
	title: string
	placeholder?: string
	maxLength?: number
	type?: string
	readOnly?: boolean
}

class Text extends React.Component<OwnProps> {

	private controller = forComponentProps(this)

	public render() {
		const { title, placeholder, maxLength, type, readOnly } = this.props
		return (
			<FormRow title={title} type="text">
				<Input.String 
					controller={this.controller}
					prop="this" 
					className="field" 
					placeholder={placeholder}
					maxLength={maxLength}
					autoComplete="off"
					type={type || 'text'}
					readOnly={readOnly}
				/>
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.placeholder !== nextProps.placeholder) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		if (this.props.onChange !== nextProps.onChange) {
			return true
		}
		return false
	}
}

export default wrapComponent(Text)
