import * as excel from 'exceljs'

import * as storage from 'modules/storage'
import { imageToBase64 } from './functions'
import { PIXELS_PER_EXCEL_HEIGHT_UNIT } from './excel'
import { AnnotatedImage } from 'modules/database/types'

export interface ImagesSummary {
	images: Image[]
}

export interface Image {
	/** A key to help us identify duplicate images. */
	key: string
	title: string
	path: string
	annotated: boolean
}

export async function addImagesSheet(wb: excel.Workbook, summary: ImagesSummary): Promise<void> {
	if (summary.images.length) {
		const imagesSheet = wb.addWorksheet('Images')
		imagesSheet.pageSetup = {
			orientation: 'portrait',
			fitToWidth: 1,
			paperSize: 9,
		}

		const imagePromises = summary.images.map(image => storage.getAssetURL(image.path).catch(() => null))
		const imageUrls = await Promise.all(imagePromises)

		/* Load images, but tolerate any that fail to load as projects can contain some broken images, and we don't want to prevent export */
		const base64Promises = imageUrls.map(url => url ? imageToBase64(storage.convertURLForLocal(url), 'image/jpg', 'anonymous', 50).catch(() => null) : null)
		const base64Images = await Promise.all(base64Promises)

		const imageIds = base64Images.map(base64 => base64 && wb.addImage({
			base64: base64.base64,
			extension: 'jpeg',
		}))

		let row = 0
		for (let i = 0; i < summary.images.length; i++) {
			const imageInfo = base64Images[i]
			if (!imageInfo) {
				continue
			}

			const ratio = Math.min(Math.min(1.0, 300 / imageInfo.width), 300 / imageInfo.height)

			imagesSheet.addImage(imageIds[i]!, {
				tl: { col: 0, row: row },
				ext: { width: imageInfo.width * ratio, height: imageInfo.height * ratio },
				editAs: 'oneCell',
			})

			const imageCell = imagesSheet.getRow(row + 1)
			imageCell.height = imageInfo.height * ratio / PIXELS_PER_EXCEL_HEIGHT_UNIT

			imagesSheet.getRow(row + 2).getCell(1).value = summary.images[i].title

			row += 3
		}
	}
}

interface PhotoInfo {
	photo: AnnotatedImage
	key: string
	title: string
	specificName: string
}

export function addImageToImagesSummary(summary: ImagesSummary, info: PhotoInfo) {
	const path = info.photo.annotatedImagePath || info.photo.imagePath
	const annotated = !!info.photo.annotatedImagePath
	if (path) {
		const addImage = annotated || summary.images.findIndex(otherImage => otherImage.key === info.key) === -1
		if (addImage) {
			summary.images.push({
				key: info.key,
				annotated,
				path,
				title: info.title + (annotated ? ` (${info.specificName})` : ''),
			})
		}
	}
}
