import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'

/* Import our module's actions */
import * as a from './actions'
import * as dt from 'modules/database/types'
import produce from 'immer'

export type StoreState = DeepReadonly<MutableStoreState>

interface MutableStoreState {
	currentProject?: dt.Project
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.updateEditingProject, (state, payload) => produce(state, draft => {
	draft.currentProject = payload
}))

reducer.case(a.newProject, (state) => produce(state, draft => {
	draft.currentProject = undefined
}))

reducer.case(a.closeProjectForm, (state) => produce(state, draft => {
	draft.currentProject = undefined
}))
