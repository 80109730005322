import { takeEvery, put, select, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as a from './actions'
import * as s from './selectors'
import * as aa from 'modules/auth/actions'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { db } from 'modules/database'
import { store } from 'modules/root'
import { navigatorPop } from 'onsenui-react-redux-navigator'
import { deleteUndefined } from 'modules/database/migration'
import platform from 'modules/platform'

let userSnapshotUnsubscribe: (() => void) | undefined
let userAdminSnapshotUnsubscribe: (() => void) | undefined

function downloadUser() {
	console.log('USER: downloading user')
	const user = firebase.auth().currentUser
	if (!user) {
		console.log('USER: not authenticated')
		return
	}

	if (userSnapshotUnsubscribe) {
		userSnapshotUnsubscribe()
		userSnapshotUnsubscribe = undefined
	}

	userSnapshotUnsubscribe = db.collection('users').doc(user.uid).onSnapshot(function(snapshot: firebase.firestore.DocumentSnapshot) {
		if (!snapshot.metadata.hasPendingWrites) {
			const data = snapshot.data()
			console.log('USER: downloaded user data', data)
			
			if (data) {
				store.dispatch(a.downloadedUser({
					...(data || {}),
					whenCreated: user.metadata.creationTime,
				}))
			}
		}
	})

	if (userAdminSnapshotUnsubscribe) {
		userAdminSnapshotUnsubscribe()
		userAdminSnapshotUnsubscribe = undefined
	}

	userAdminSnapshotUnsubscribe = db.collection('users-admin').doc(user.uid).onSnapshot(function(snapshot: firebase.firestore.DocumentSnapshot) {
		if (!snapshot.metadata.hasPendingWrites) {
			const data = snapshot.data()
			if (data) {
				console.log('USER: downloaded user admin data', data)
				
				store.dispatch(a.downloadedUserAdmin({
					/* data.whenSubscribedTo is a Timestamp */
					whenSubscribedTo: data.whenSubscribedTo ? data.whenSubscribedTo.toDate().getTime() : undefined,
				}))
			} else if (!snapshot.metadata.fromCache) {
				/* If there's no user admin data, the user is on a trial */
				console.log('USER: downloaded user admin data and there’s none')
				store.dispatch(a.downloadedUserAdmin({
					whenSubscribedTo: undefined,
				}))
			} else {
				/* If the result is cached, then don't change what's in our redux store... if the app is launched when offline
				   we get this, even though the user actually did have user admin data!
				 */
				console.log('USER: downloaded user admin data and there’s none, but it’s from cache')
			}
		}
	})
}

function handleLogout() {
	if (userSnapshotUnsubscribe) {
		userSnapshotUnsubscribe()
		userSnapshotUnsubscribe = undefined
	}
	if (userAdminSnapshotUnsubscribe) {
		userAdminSnapshotUnsubscribe()
		userAdminSnapshotUnsubscribe = undefined
	}
}

function* handleCloseUserDetails(): SagaIterator {
	yield put(navigatorPop('maqasa'))
}

function* handleSaveUser(): SagaIterator {
	const user = firebase.auth().currentUser
	if (user) {
		const data = (yield select(s.currentUser)) as ReturnType<typeof s.currentUser>
		deleteUndefined(data)
		db.collection('users').doc(user.uid).set(data)
	}

	yield put(navigatorPop('maqasa'))
}

function* handleChangePassword(action: ReturnType<typeof a.changePassword>): SagaIterator {
	const request = action.payload

	const user = firebase.auth().currentUser
	if (!user) {
		return
	}

	try {
		const credential = firebase.auth.EmailAuthProvider.credential(user.email!, request.currentPassword)
		yield call(() => user.reauthenticateWithCredential(credential))
		yield call(() => user.updatePassword(request.newPassword))
		yield put(navigatorPop('maqasa'))
	} catch (error) {
		platform.alert(`Your password could not be changed: ${(error as Error).message}`)
	}
}

/** The saga function for this module that is run from our root saga. */
export default function* userSaga(): SagaIterator {
	yield takeEvery([aa.loggedIn, a.downloadUser], downloadUser)
	yield takeEvery(aa.loggedOut, handleLogout)
	yield takeEvery(a.closeUserDetails, handleCloseUserDetails)
	yield takeEvery(a.saveUser, handleSaveUser)
	yield takeEvery(a.changePassword, handleChangePassword)
}
