/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import FormRow, { FormFieldVariant } from './FormRow'
import { Input, Snapshot, forComponentProps, wrapComponent } from 'changeling'

interface OwnProps extends Snapshot<string | undefined> {
	title: string
	placeholder?: string
}

class TextArea extends React.Component<OwnProps> {

	private controller = forComponentProps(this)

	public render() {
		const { title, placeholder } = this.props
		return (
			<FormRow title={title} type="textarea" fieldVariant={FormFieldVariant.Wide}>
				<Input.TextArea
					controller={this.controller}
					prop="this"
					className="field" 
					placeholder={placeholder}
				/>
			</FormRow>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<OwnProps>) {
		if (this.props.title !== nextProps.title) {
			return true
		}
		if (this.props.placeholder !== nextProps.placeholder) {
			return true
		}
		if (this.props.value !== nextProps.value) {
			return true
		}
		return false
	}
}

export default wrapComponent(TextArea)
