import * as t from './types'
import * as curtains from '../database/types/curtains' /* A relative import as Jest doesn't handle our baseUrl */
import * as blinds from '../database/types/blinds'
import * as cushions from '../database/types/cushions'
import * as dt from '../database/types'
import { ComboBoxCode } from './'
import { allProducts } from 'modules/project/functions'

interface ComboBoxValueWithContext {
	value?: t.ComboBoxValueType
	context?: string
}

const valuesByProductType: { [type: string]: <T extends dt.ProductDetails>(code: ComboBoxCode, details: T) => (ComboBoxValueWithContext | null | undefined)[] } = {
	[dt.ProductType.Curtain]: (code: ComboBoxCode, p: curtains.Curtain) => {
		switch (code) {
			case ComboBoxCode.ROOM:
				return [
					p.overview ? { value: p.overview.room } : null,
				]
			case ComboBoxCode.WINDOW:
				return [
					p.overview ? { value: p.overview.window } : null,
				]
			case ComboBoxCode.FABRIC_SUPPLIER:
				return [
					p.curtain1 && p.curtain1.fabric ? { value: p.curtain1.fabric.supplier } : null,
					p.curtain2 && p.curtain2.fabric ? { value: p.curtain2.fabric.supplier } : null,
					p.curtain1 && p.curtain1.fabric && p.curtain1.fabric.liningOptions ? { value: p.curtain1.fabric.liningOptions.supplier } : null,
					p.curtain2 && p.curtain2.fabric && p.curtain2.fabric.liningOptions ? { value: p.curtain2.fabric.liningOptions.supplier } : null,
					p.curtain1 && p.curtain1.fabric && p.curtain1.fabric.liningOptions && p.curtain1.fabric.liningOptions.interliningOptions ? { value: p.curtain1.fabric.liningOptions.interliningOptions.supplier } : null,
					p.curtain2 && p.curtain2.fabric && p.curtain2.fabric.liningOptions && p.curtain2.fabric.liningOptions.interliningOptions ? { value: p.curtain2.fabric.liningOptions.interliningOptions.supplier } : null,
					p.curtain1 && p.curtain1.fabric && p.curtain1.fabric.trimOptions ? { value: p.curtain1.fabric.trimOptions.supplier } : null,
					p.curtain2 && p.curtain2.fabric && p.curtain2.fabric.trimOptions ? { value: p.curtain2.fabric.trimOptions.supplier } : null,
				]
			case ComboBoxCode.FABRIC_COLLECTION:
				return [
					p.curtain1 && p.curtain1.fabric ? {
						value: p.curtain1.fabric.collection,
						context: p.curtain1 && p.curtain1.fabric ? p.curtain1.fabric.supplier : undefined,
					} : null,
					p.curtain2 && p.curtain2.fabric ? {
						value: p.curtain2.fabric.collection,
						context: p.curtain2 && p.curtain2.fabric ? p.curtain2.fabric.supplier : undefined,
					} : null,
				]
			case ComboBoxCode.FABRIC_NAME:
				return [
					p.curtain1 && p.curtain1.fabric ? {
						value: p.curtain1.fabric.name,
						context: p.curtain1 && p.curtain1.fabric ? `${p.curtain1.fabric.supplier}|${p.curtain1.fabric.collection}` : undefined,
					} : null,
					p.curtain2 && p.curtain2.fabric ? {
						value: p.curtain2.fabric.name,
						context: p.curtain2 && p.curtain2.fabric ? `${p.curtain2.fabric.supplier}|${p.curtain2.fabric.collection}` : undefined,
					} : null,
				]
			case ComboBoxCode.LINING:
				return [
					p.curtain1 && p.curtain1.fabric && p.curtain1.fabric.liningOptions ? { value: p.curtain1.fabric.liningOptions.type } : null,
					p.curtain2 && p.curtain2.fabric && p.curtain2.fabric.liningOptions ? { value: p.curtain2.fabric.liningOptions.type } : null,
				]
			case ComboBoxCode.TRIM:
				return [
					p.curtain1 && p.curtain1.fabric && p.curtain1.fabric.trimOptions ? { value: p.curtain1.fabric.trimOptions.name } : null,
					p.curtain2 && p.curtain2.fabric && p.curtain2.fabric.trimOptions ? { value: p.curtain2.fabric.trimOptions.name } : null,
				]
			case ComboBoxCode.HARDWARE_SUPPLIER:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.rodOptions ? { value: p.curtain1.hardware.rodOptions.supplier } : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.rodOptions ? { value: p.curtain2.hardware.rodOptions.supplier } : null,
				]
			case ComboBoxCode.HARDWARE_CODE:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.rodOptions ? {
						value: p.curtain1.hardware.rodOptions.code,
						context: p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.rodOptions ? p.curtain1.hardware.rodOptions.supplier : undefined,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.rodOptions ? {
						value: p.curtain2.hardware.rodOptions.code,
						context: p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.rodOptions ? p.curtain2.hardware.rodOptions.supplier : undefined,
					} : null,
				]
			case ComboBoxCode.COLOUR:
				return [
				/* Fabric */
					p.curtain1 && p.curtain1.fabric ? {
						value: p.curtain1.fabric.colour,
					} : null,
					p.curtain2 && p.curtain2.fabric ? {
						value: p.curtain2.fabric.colour,
					} : null,
					/* Lining */
					p.curtain1 && p.curtain1.fabric && p.curtain1.fabric.liningOptions ? {
						value: p.curtain1.fabric.liningOptions.colour,
					} : null,
					p.curtain2 && p.curtain2.fabric && p.curtain2.fabric.liningOptions ? {
						value: p.curtain2.fabric.liningOptions.colour,
					} : null,
					/* Rod */
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.rodOptions ? {
						value: p.curtain1.hardware.rodOptions.color,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.rodOptions ? {
						value: p.curtain2.hardware.rodOptions.color,
					} : null,
					/* Finials */
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.finialsOptions ? {
						value: p.curtain1.hardware.finialsOptions.colour,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.finialsOptions ? {
						value: p.curtain2.hardware.finialsOptions.colour,
					} : null,
					/* Holdbacks */
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.holdbackOptions ? {
						value: p.curtain1.hardware.holdbackOptions.colour, 
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.holdbackOptions ? {
						value: p.curtain2.hardware.holdbackOptions.colour, 
					} : null,
					/* Flicksticks */
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.flickSticksOptions ? {
						value: p.curtain1.hardware.flickSticksOptions.colour,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.flickSticksOptions ? {
						value: p.curtain2.hardware.flickSticksOptions.colour,
					} : null,
					/* Remotes */
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.automationOptions ? {
						value: p.curtain1.hardware.automationOptions.colourOfRemotes,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.automationOptions ? {
						value: p.curtain2.hardware.automationOptions.colourOfRemotes,
					} : null,
				]
			case ComboBoxCode.FINIALS_TYPE:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.finialsOptions ? {
						value: p.curtain1.hardware.finialsOptions.type,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.finialsOptions ? {
						value: p.curtain2.hardware.finialsOptions.type,
					} : null,
				]
			case ComboBoxCode.BRACKETS_TYPE:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.bracketsOptions ? {
						value: p.curtain1.hardware.bracketsOptions.type,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.bracketsOptions ? {
						value: p.curtain2.hardware.bracketsOptions.type, 
					} : null,
				]
			case ComboBoxCode.HOLDBACK_NAME:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.holdbackOptions ? {
						value: p.curtain1.hardware.holdbackOptions.name,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.holdbackOptions ? {
						value: p.curtain2.hardware.holdbackOptions.name, 
					} : null,
				]
			case ComboBoxCode.FLICK_STICKS_NAME:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.flickSticksOptions ? {
						value: p.curtain1.hardware.flickSticksOptions.name,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.flickSticksOptions ? {
						value: p.curtain2.hardware.flickSticksOptions.name,
					} : null,
				]
			case ComboBoxCode.AUTOMATION_MOTOR_BRAND:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.automationOptions ? {
						value: p.curtain1.hardware.automationOptions.motorBrand,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.automationOptions ? {
						value: p.curtain2.hardware.automationOptions.motorBrand,
					} : null,
				]
			case ComboBoxCode.REMOTE_MODEL:
				return [
					p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.automationOptions ? {
						value: p.curtain1.hardware.automationOptions.remoteModel,
					} : null,
					p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.automationOptions ? {
						value: p.curtain2.hardware.automationOptions.remoteModel,
					} : null,
				]
			case ComboBoxCode.HEADER_TYPE:
				return [
					p.curtain1 && p.curtain1.specifications ? { value: p.curtain1.specifications.headerType } : null,
					p.curtain2 && p.curtain2.specifications ? { value: p.curtain2.specifications.headerType } : null,
				]
			case ComboBoxCode.WORKROOM_SUPPLIER:
				return [
					p.curtain1 && p.curtain1.specifications ? { value: p.curtain1.specifications.workroomSupplier } : null,
					p.curtain2 && p.curtain2.specifications ? { value: p.curtain2.specifications.workroomSupplier } : null,
				]
			case ComboBoxCode.CURTAIN_EXTRAS: {
				let result: ComboBoxValueWithContext[] = []
				if (p.curtain1 && p.curtain1.specifications && p.curtain1.specifications.extras) {
					result = result.concat(p.curtain1.specifications.extras.filter(extra => !!extra.name).map(extra => ({ value: extra.name! })))
				}
				if (p.curtain2 && p.curtain2.specifications && p.curtain2.specifications.extras) {
					result = result.concat(p.curtain2.specifications.extras.filter(extra => !!extra.name).map(extra => ({ value: extra.name! })))
				}
				return result
			}
			default:
				return []
		}
	},
	[dt.ProductType.Blind]: (code: ComboBoxCode, p: blinds.Blind) => {
		switch (code) {
			case ComboBoxCode.ROOM:
				return [
					p.overview ? { value: p.overview.room } : null,
				]
			case ComboBoxCode.WINDOW:
				return [
					p.overview ? { value: p.overview.window } : null,
				]
			case ComboBoxCode.FABRIC_SUPPLIER:
				return [
					p.fabric ? { value: p.fabric.supplier } : null,
					p.fabric && p.fabric.liningOptions ? { value: p.fabric.liningOptions.supplier } : null,
					p.fabric && p.fabric.liningOptions && p.fabric.liningOptions.interliningOptions ? { value: p.fabric.liningOptions.interliningOptions.supplier } : null,
					p.fabric && p.fabric.trimOptions ? { value: p.fabric.trimOptions.supplier } : null,
				]
			case ComboBoxCode.FABRIC_COLLECTION:
				return [
					p.fabric ? {
						value: p.fabric.collection,
						context: p.fabric ? p.fabric.supplier : undefined,
					} : null,
					p.specifications ? {
						value: p.specifications.rollerBlindFabricCollection,
						context: p.specifications.workroomSupplier,
					} : null,
				]
			case ComboBoxCode.FABRIC_NAME:
				return [
					p.fabric ? {
						value: p.fabric.name,
						context: p && p.fabric ? `${p.fabric.supplier}|${p.fabric.collection}` : undefined,
					} : null,
					p.specifications ? {
						value: p.specifications.rollerBlindFabricName,
						context: `${p.specifications.workroomSupplier}|${p.specifications.rollerBlindFabricCollection}`,
					} : null,
				]
			case ComboBoxCode.LINING:
				return [
					p.fabric && p.fabric.liningOptions ? { value: p.fabric.liningOptions.type } : null,
				]
			case ComboBoxCode.TRIM:
				return [
					p.fabric && p.fabric.trimOptions ? { value: p.fabric.trimOptions.name } : null,
				]
			case ComboBoxCode.COLOUR:
				return [
				/* Fabric */
					p.fabric ? {
						value: p.fabric.colour,
					} : null,
					/* Lining */
					p.fabric && p.fabric.liningOptions ? {
						value: p.fabric.liningOptions.colour,
					} : null,
					/* Controls */
					p.specifications ? {
						value: p.specifications.controlColour,
					} : null,
					/* Chains */
					p.specifications ? {
						value: p.specifications.chainColour,
					} : null,
					/* Brackets */
					p.specifications ? {
						value: p.specifications.bracketColour,
					} : null,
					/* Venetians */
					p.specifications ? {
						value: p.specifications.venetianColour,
					} : null,
					/* Roller blind */
					p.specifications ? {
						value: p.specifications.rollerBlindFabricColour,
					} : null,
					p.specifications ? {
						value: p.specifications.rollerBlindBottomRailColour,
					} : null,
				/* Rod */
				// p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.rodOptions ? {
				// 	value: p.curtain1.hardware.rodOptions.color,
				// } : null,
				// p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.rodOptions ? {
				// 	value: p.curtain2.hardware.rodOptions.color,
				// } : null,
				// /* Finials */
				// p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.finialsOptions ? {
				// 	value: p.curtain1.hardware.finialsOptions.colour,
				// } : null,
				// p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.finialsOptions ? {
				// 	value: p.curtain2.hardware.finialsOptions.colour,
				// } : null,
				// /* Holdbacks */
				// p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.holdbackOptions ? {
				// 	value: p.curtain1.hardware.holdbackOptions.colour, 
				// } : null,
				// p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.holdbackOptions ? {
				// 	value: p.curtain2.hardware.holdbackOptions.colour, 
				// } : null,
				// /* Flicksticks */
				// p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.flickSticksOptions ? {
				// 	value: p.curtain1.hardware.flickSticksOptions.colour,
				// } : null,
				// p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.flickSticksOptions ? {
				// 	value: p.curtain2.hardware.flickSticksOptions.colour,
				// } : null,
				// /* Remotes */
				// p.curtain1 && p.curtain1.hardware && p.curtain1.hardware.automationOptions ? {
				// 	value: p.curtain1.hardware.automationOptions.colourOfRemotes,
				// } : null,
				// p.curtain2 && p.curtain2.hardware && p.curtain2.hardware.automationOptions ? {
				// 	value: p.curtain2.hardware.automationOptions.colourOfRemotes,
				// } : null,
				]
			case ComboBoxCode.WORKROOM_SUPPLIER:
				return [
					p.specifications ? { value: p.specifications.workroomSupplier } : null,
				]
			case ComboBoxCode.CONTROL_LENGTH:
				return [
					p.specifications ? { value: p.specifications.controlLength } : null,
				]
			case ComboBoxCode.BOTTOM_RAIL_SHAPE: 
				return [
					p.specifications ? {
						value: p.specifications.rollerBlindBottomRailShape,
					} : null,
				]
			case ComboBoxCode.BLIND_EXTRAS: {
				let result: ComboBoxValueWithContext[] = []
				if (p.specifications && p.specifications.extras) {
					result = result.concat(p.specifications.extras.filter(extra => !!extra.name).map(extra => ({ value: extra.name! })))
				}
				return result
			}
			default:
				return []
		}
	},
	[dt.ProductType.Cushion]: (code: ComboBoxCode, p: cushions.Cushion) => {
		switch (code) {
			case ComboBoxCode.ROOM:
				return [
					p.overview ? { value: p.overview.room } : null,
				]
			case ComboBoxCode.FABRIC_SUPPLIER:
				return [
					p.fabric ? { value: p.fabric.supplier } : null,
					p.fabric && p.fabric.backFabricDifferent && p.fabric.backFabricOptions ? { value: p.fabric.backFabricOptions.supplier } : null,
					p.fabric && p.fabric.piping && p.fabric.pipingOptions ? { value: p.fabric.pipingOptions.supplier } : null,
					p.fabric && p.fabric.flange && p.fabric.flangeOptions ? { value: p.fabric.flangeOptions.supplier } : null,
					p.fabric && p.fabric.trim && p.fabric.trimOptions ? { value: p.fabric.trimOptions.supplier } : null,
				]
			case ComboBoxCode.FABRIC_COLLECTION:
				return [
					p.fabric ? { value: p.fabric.collection } : null,
					p.fabric && p.fabric.backFabricDifferent && p.fabric.backFabricOptions ? { value: p.fabric.backFabricOptions.collection } : null,
					p.fabric && p.fabric.piping && p.fabric.pipingOptions ? { value: p.fabric.pipingOptions.collection } : null,
					p.fabric && p.fabric.flange && p.fabric.flangeOptions ? { value: p.fabric.flangeOptions.collection } : null,
				]
			case ComboBoxCode.FABRIC_NAME:
				return [
					p.fabric ? { value: p.fabric.name } : null,
					p.fabric && p.fabric.backFabricDifferent && p.fabric.backFabricOptions ? { value: p.fabric.backFabricOptions.name } : null,
					p.fabric && p.fabric.piping && p.fabric.pipingOptions ? { value: p.fabric.pipingOptions.name } : null,
					p.fabric && p.fabric.flange && p.fabric.flangeOptions ? { value: p.fabric.flangeOptions.name } : null,
					p.fabric && p.fabric.trim && p.fabric.trimOptions ? { value: p.fabric.trimOptions.name } : null,
				]
			case ComboBoxCode.COLOUR:
				return [
					p.fabric ? { value: p.fabric.colour } : null,
					p.fabric && p.fabric.backFabricDifferent && p.fabric.backFabricOptions ? { value: p.fabric.backFabricOptions.colour } : null,
					p.fabric && p.fabric.piping && p.fabric.pipingOptions ? { value: p.fabric.pipingOptions.colour } : null,
					p.fabric && p.fabric.flange && p.fabric.flangeOptions ? { value: p.fabric.flangeOptions.colour } : null,
				]
			case ComboBoxCode.WORKROOM_SUPPLIER:
				return [
					p.fabric && p.fabric.fill && p.fabric.fillOptions ? { value: p.fabric.fillOptions.supplier } : null,
					p.specifications ? { value: p.specifications.workroomSupplier } : null,
				]
			case ComboBoxCode.FILL:
				return [
					p.fabric && p.fabric.fill && p.fabric.fillOptions ? { value: p.fabric.fillOptions.type } : null,
				]
			default:
				return []
		}
	},
}

/**
 * Returns the values for the given combo box in use in the given project.
 * @param project 
 * @param code 
 */
export function comboBoxProjectValues(project: DeepReadonly<dt.Project>, code: ComboBoxCode, context?: string): t.ComboBoxValue[] {
	const products = allProducts(project)
	const values = new Set<t.ComboBoxValueType>()
	for (const product of products) {
		const type = product.type
		if (!product.details) {
			continue
		}

		const func = valuesByProductType[type]
		if (!func) {
			throw new Error(`comboBoxProjectValues: Unsupported product type ${type}`)
		}
		const potentialValues = func(code, product.details)

		potentialValues.forEach(value => {
			if (!value || !value.value || context !== value.context) {
				return
			}
			values.add(value.value)
		})
	}

	const result: t.ComboBoxValue[] = []
	values.forEach(value => {
		result.push({
			value,
		})
	})
	sortComboBoxValues(result)
	return result
}

/**
 * Returns the values for the given combo box that have been used by this user.
 * @param userData 
 * @param code 
 */
export function comboBoxUserValues(userData: DeepReadonly<t.ComboBoxUserData>, code: ComboBoxCode, context?: string): t.ComboBoxValue[] {
	const comboBoxData = userData.comboBoxes[code]
	if (!comboBoxData) {
		return []
	}

	const result: t.ComboBoxValue[] = []
	if (!context) {
		comboBoxData.values.forEach(value => {
			result.push(value.value)
		})
	} else {
		const valuesByContext = comboBoxData.byContext && comboBoxData.byContext[context]
		if (valuesByContext) {
			valuesByContext.forEach(value => {
				result.push(value.value)
			})
		}
	}
	sortComboBoxValues(result)
	return result
}

function sortComboBoxValues(values: t.ComboBoxValue[]) {
	values.sort((a, b) => {
		if (a.value < b.value) {
			return -1
		} else if (a.value > b.value) {
			return 1
		} else {
			return 0
		}
	})
}

export function comboBoxValues(userData: DeepReadonly<t.ComboBoxUserData>, project: DeepReadonly<dt.Project>, code: ComboBoxCode, context?: string): t.ComboBoxResult {
	const result: t.ComboBoxResult = {
		project: comboBoxProjectValues(project, code, context),
		user: comboBoxUserValues(userData, code, context),
	}
	return result
}

export function useValue(userData: t.ComboBoxUserData, code: ComboBoxCode, value: t.ComboBoxValueType, context?: string): void {
	let comboBoxData = userData.comboBoxes[code] as t.ComboBoxData
	if (!comboBoxData) {
		if (!context) {
			comboBoxData = {
				values: [],
				byContext: {},
			}
		} else {
			comboBoxData = {
				values: [],
				byContext: {
					[context]: [],
				},
			}
		}

		userData.comboBoxes[code] = comboBoxData
	} else {
		if (!context) {
			if (!comboBoxData.values) {
				comboBoxData.values = []
			}
		} else {
			if (!comboBoxData.byContext) {
				comboBoxData.byContext = {}
			}
			if (!comboBoxData.byContext[context]) {
				comboBoxData.byContext[context] = []
			}
		}
	}

	const values = (!context ? comboBoxData.values : comboBoxData.byContext[context])

	const existing = values.findIndex(entry => entry.value.value === value)
	if (existing !== -1) {
		values[existing].lastUsed = Date.now()
	} else {
		values.push({
			value: {
				value,
			},
			lastUsed: Date.now(),
		})
	}
}
