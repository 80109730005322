/* Import the component from the component path */
import Component from '../components/ChangePassword'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { navigatorPop } from 'onsenui-react-redux-navigator'
import { changePassword } from '../actions'

/**
 * Interface for properties that the container passes to the component.
 */
interface Props {
	
}

interface OwnProps {
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
interface Actions {
	onSave: (currentPassword: string, newPassword: string) => void
	onCancel: () => void
}

export type ComponentProps = Props & Actions

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSave: (currentPassword, newPassword) => {
		dispatch(changePassword({
			currentPassword,
			newPassword,
		}))
	},
	onCancel: () => {
		dispatch(navigatorPop('maqasa'))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
