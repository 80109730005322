import * as excel from 'exceljs'
import * as dt from 'modules/database/types'
import * as dtc from 'modules/database/types/common'
import { PurchaseOrderSupplier, ExportFormat, WorkroomSummary } from 'modules/project/purchaseorders/types'
import { fontBold, alignHeaderRow } from 'modules/project/excel'
import { productTypeInfo, productTitle } from 'modules/database/functions'

/** Type to create a row header type for a given row object. */
export type HeaderRow<ROW> = {
	[P in keyof ROW]-?: string
}

interface ExportSheetOptions<PRODUCT extends dtc.CommonProductDetails, ROW> {
	createHeader?: (sheet: excel.Worksheet, format: ExportFormat) => excel.Row
	header?: HeaderRow<ROW>
	outputProduct: (supplier: PurchaseOrderSupplier, product: PRODUCT, summary: WorkroomSummary) => ROW[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rowToRow: (row: ROW) => any[]
}

interface ValidColumns { 
	[columnIndex: number]: boolean
}

export function exportPurchaseOrderSheet<PRODUCT extends dtc.CommonProductDetails, ROW>(sheet: excel.Worksheet, products: DeepReadonly<dt.Product[]>, supplier: PurchaseOrderSupplier, format: ExportFormat, summary: WorkroomSummary, options: ExportSheetOptions<PRODUCT, ROW>) {
	const rows = products.flatMap(p => {
		const d = p.details as dtc.CommonProductDetails
		if (!d) {
			return []
		}
		if (!d.overview) {
			return []
		}
		
		try {
			return options.outputProduct(supplier, d as PRODUCT, summary)
		} catch (error) {
			throw new Error(`${productTitle(p)}: \n${(error as Error).message}`)
		}
	})

	if (rows.length) {
		const rowArrays = rows.map(row => options.rowToRow(row))

		/* Determine columns that have values */
		const validColumns: ValidColumns = {}
		rowArrays.forEach(rowArray => {
			rowArray.forEach((columnValue, columnIndex) => {
				if (columnValue !== undefined && columnValue !== '') {
					validColumns[columnIndex] = true
				}
			})
		})

		/* Output header */
		const ptInfo = productTypeInfo(products[0].type)
	
		sheet.addRow([ptInfo.collectiveName]).font = { bold: true, underline: true }
		sheet.addRow([])

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const headerTitles = chooseColumns(options.rowToRow(options.header as any as ROW), validColumns)
		const header = options.createHeader ? options.createHeader(sheet, format) : options.header ? createHeader(sheet, headerTitles) : undefined
		if (header) {
			fontBold(header)
			header.border = {
				bottom: {
					style: 'thin',
				},
			}

			summary.headerRows.push(header.number)
		}

		rowArrays.forEach(rowArray => sheet.addRow(chooseColumns(rowArray, validColumns)))
	}
}

function createHeader(sheet: excel.Worksheet, header: string[]): excel.Row {
	const row = sheet.addRow(header)
	alignHeaderRow(row)
	return row
}

function chooseColumns<T>(rowArray: T[], validColumns: ValidColumns): T[] {
	const result: T[] = []
	rowArray.forEach((columnValue, columnIndex) => {
		if (validColumns[columnIndex]) {
			result.push(columnValue)
		}
	})
	return result
}
