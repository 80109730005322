import actionCreatorFactory from 'typescript-fsa'
import firebase from 'firebase/compat/app'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Storage')

interface QueueUploadPayload {
	path: string
	size: number
	metadata?: firebase.storage.UploadMetadata
	retriable: boolean
}

export const queueUploadAction = actionCreator<QueueUploadPayload>('QUEUE_UPLOAD')
export const dequeueUploadAction = actionCreator<string>('DEQUEUE_UPLOAD')

interface UploadProgressPayload {
	path: string
	progress: number
}

export const startUploadAction = actionCreator<string>('START_UPLOAD')
export const uploadProgressAction = actionCreator<UploadProgressPayload>('UPLOAD_PROGRESS')
export const stopUploadAction = actionCreator<string>('STOP_UPLOAD')
