import * as dt from 'modules/database/types'
import Calculation from 'modules/root/calculations'
import { GenericPricing } from './pricing'
import { calculatePricing } from 'modules/project/calculations'
import { BaseHardwareOptions } from 'modules/database/types/common'

export function calculateGenericHardwarePricing(
	hardwareOptions: BaseHardwareOptions | undefined, 
	taxDetails: dt.TaxDetails | undefined, 
	name: string,
	customise?: (hardwareOptions: BaseHardwareOptions, calculation: Calculation<GenericPricing>) => BaseHardwareOptions,
	overrideQuantity?: number,
): Calculation<GenericPricing> {
	const result: GenericPricing = {}
	const calculation = new Calculation(result)

	if (!hardwareOptions) {
		return calculation.withErrorMessage(`${name} options are not set.`)
	}

	if (customise) {
		hardwareOptions = customise(hardwareOptions, calculation)
	}

	let quantity = hardwareOptions.quantity
	if (quantity === undefined) {
		quantity = overrideQuantity
	}
	if (quantity === undefined) {
		return calculation
	}

	result.pricing = calculation.mergeErrors(calculatePricing(hardwareOptions.price, taxDetails, quantity, name))
	return calculation
}
