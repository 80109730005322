import * as React from 'react'

interface ToNumberOptions {
	defaultValue?: number
	operation: string
}

interface CalculationValueError {
	name: string
	properties: string[]
}

interface CalculationMessage {
	message: string
	properties: string[]
}

export default class Calculation<T> {

	private result: T
	private missing: CalculationValueError[] = []
	private invalid: CalculationValueError[] = []
	private other: CalculationMessage[] = []
	private formula: string[] = []

	public constructor(result: T) {
		this.result = result
	}

	/** Converts the given value to a number or NaN if it is undefined or isn't a number.
	 * Or provide a defaultValue to use if the value is undefined. If the value is invalid
	 * NaN is always returned.
	 */
	public toNumber(value: number | undefined, name: string, properties?: string[], options?: ToNumberOptions) {
		if (!options) {
			options = {
				defaultValue: undefined,
				operation: '+',
			}
		}

		if (isBlank(value)) {
			if (options.defaultValue !== undefined) {
				if (options.defaultValue !== 0) {
					if (options.operation) {
						this.formula.push(options.operation)
						if (name) {
							this.formula.push(name)
						} else {
							this.formula.push(`${options.defaultValue}`)
						}
					}
				}
				return options.defaultValue
			} else {
				if (name) {
					this.withMissing(name, properties)
				}
				return NaN
			}
		}

		const result = parseFloat(value as unknown as string)
		if (isNaN(result) && name) {
			this.withInvalid(name, properties)
		}
		if (result !== 0 && options.operation) {
			this.formula.push(options.operation)
			if (name) {
				this.formula.push(name)
			} else {
				this.formula.push(`${result}`)
			}
		}
		return result
	}

	/** Converts a value to a boolean. If the value is undefined, the default value is returned. */
	public toBoolean(value: boolean | undefined, defaultValue: boolean) {
		if (value === undefined) {
			return defaultValue
		}

		return value
	}

	public getResult(): T {
		return this.result
	}

	public getResultOrUndefined(): T | undefined {
		if (this.isSuccess()) {
			return this.getResult()
		} else {
			return undefined
		}
	}

	public formatFormula(): string | null {
		if (this.formula.length === 0) {
			return null
		}

		let formatted = this.formula.join(' ')
		if (formatted.startsWith('+ ')) {
			formatted = formatted.substring(2)
		}
		return formatted
	}

	public withResult(result: T): Calculation<T> {
		this.result = result
		return this
	}

	public withErrorMessage(msg: string, properties?: string[]): Calculation<T> {
		this.other.push({
			message: msg,
			properties: properties || [],
		})
		return this
	}

	public withMissing(name: string, properties?: string[]) {
		this.missing.push({
			name,
			properties: properties || [],
		})
		return this
	}

	public withInvalid(name: string, properties?: string[]) {
		this.invalid.push({
			name,
			properties: properties || [],
		})
		return this
	}

	public withErrors(calculation: Calculation<unknown>): Calculation<T> {
		this.missing = this.missing.concat(calculation.missing)
		this.invalid = this.invalid.concat(calculation.invalid)
		this.other = this.other.concat(calculation.other)
		return this
	}

	public mergeErrors<S>(calculation: Calculation<S>): S {
		this.withErrors(calculation)
		this.formula = this.formula.concat(calculation.formula)
		return calculation.result
	}

	public isSuccess() {
		if (this.isErrored()) {
			return false
		}

		if (typeof (this.result) === 'number') {
			if (isNaN(this.result)) {
				return false
			}
		}

		return true
	}

	public isErrored() {
		return this.missing.length || this.invalid.length || this.other.length
	}

	public formatErrorsAsText(): string {
		if (!this.isErrored()) {
			return (
				'An unknown error occurred.'
			)
		}
	
		const lines: string[] = []
		if (this.missing.length > 0) {
			lines.push('The following values are missing:')
			uniq(this.missing.map(o => o.name)).forEach(el => lines.push(` * ${el}`))
		}
		if (this.invalid.length > 0) {
			lines.push('The following values are invalid:')
			uniq(this.invalid.map(o => o.name)).forEach(el => lines.push(` * ${el}`))
		}
		if (this.other.length > 0) {
			uniq(this.other.map(o => o.message)).forEach(el => lines.push(el))
		}
		return lines.join('\n')
	}

	public formatErrorsForProperty(property: string): JSX.Element | null {
		const missing = this.missing.filter(m => m.properties.indexOf(property) !== -1)
		const invalid = this.invalid.filter(m => m.properties.indexOf(property) !== -1)
		const other = this.other.filter(m => m.properties.indexOf(property) !== -1)

		if (missing.length) {
			return <p className="warning">{missing[0].name} is required.</p>
		} else if (invalid.length) {
			return <p className="warning">The value for {invalid[0].name} is invalid.</p>
		} else if (other.length) {
			return (other.length > 1 ? (
				<>
					<ul className="warning">
						{uniq(other.map(o => o.message)).map((el, index) => (
							<li key={index}>{el}</li>
						))}
					</ul>
				</>
			) : other.length === 1 ? (
				<p className="warning">{other[0].message}</p>
			) : null)
		} else {
			return null
		}
	}

	public formatErrors(): JSX.Element {
		if (!this.isErrored()) {
			return (
				<p className="warning">An unknown error occurred.</p>
			)
		}
	
		return (
			<>
				{this.missing.length > 0 && (
					<>
						<p className="warning">The following values are missing:</p>
						<ul className="warning">
							{uniq(this.missing.map(o => o.name)).map((el, index) => (
								<li key={index}>{el}</li>
							))}
						</ul>
					</>
				)}
				{this.invalid.length > 0 && (
					<>
						<p className="warning">The following values are invalid:</p>
						<ul className="warning">
							{uniq(this.invalid.map(o => o.name)).map((el, index) => (
								<li key={index}>{el}</li>
							))}
						</ul>
					</>
				)}
				{this.other.length > 1 ? (
					<>
						<ul className="warning">
							{uniq(this.other.map(o => o.message)).map((el, index) => (
								<li key={index}>{el}</li>
							))}
						</ul>
					</>
				) : this.other.length === 1 ? (
					<p className="warning">{this.other[0].message}</p>
				) : null}
			</>
		)
	}

}

function isBlank(value: number | undefined) {
	return (value === undefined || (value as unknown as string) === '')
}

function uniq(strings: string[]) {
	const seen: { [key: string]: boolean } = {}
	return strings.filter((str) => {
		if (seen[str]) {
			return false
		} else {
			seen[str] = true
			return true
		}
	})
}
