import * as dtp from 'modules/database/types/curtains'
import * as dt from 'modules/database/types'
import { AvailablePurchaseOrders, addSupplier } from 'modules/project/purchaseorders'
import * as cf from './functions'

export function addCurtainsPurchaseOrders(project: dt.Project, products: DeepReadonly<dt.Product[]>, result: AvailablePurchaseOrders) {
	for (const product of products) {
		if (!product.details) {
			continue
		}

		const curtain = product.details as dtp.Curtain
		const curtainSet = cf.curtainSet(curtain)
		if (curtain.curtain1) {
			handleCurtainDetails(curtain.curtain1, result)
		}
		if (curtainSet === dtp.CurtainSet.Double && curtain.curtain2) {
			handleCurtainDetails(curtain.curtain2, result)
		}
	}
}

function handleCurtainDetails(d: dtp.CurtainDetail, result: AvailablePurchaseOrders) {
	/* Fabric */
	if (d.fabric) {
		if (d.fabric.fabric && d.fabric.supplier) {
			addSupplier(result.fabric, d.fabric.supplier)
		}
		if (d.fabric.lined && d.fabric.liningOptions) {
			if (d.fabric.liningOptions.supplier) {
				addSupplier(result.fabric, d.fabric.liningOptions.supplier)
			}
			if (d.fabric.liningOptions.interlined && d.fabric.liningOptions.interliningOptions && d.fabric.liningOptions.interliningOptions.supplier) {
				addSupplier(result.fabric, d.fabric.liningOptions.interliningOptions.supplier)
			}
		}
		if (d.fabric.trim && d.fabric.trimOptions && d.fabric.trimOptions.supplier) {
			addSupplier(result.fabric, d.fabric.trimOptions.supplier)
		}
	}

	/* Workroom */
	if (d.specifications && d.specifications.workroomSupplier) {
		addSupplier(result.workroom, d.specifications.workroomSupplier)
	}

	/* Hardware */
	if (d.hardware) {
		if (d.hardware.rod && d.hardware.rodOptions && d.hardware.rodOptions.supplier) {
			addSupplier(result.hardware, d.hardware.rodOptions.supplier)
		}
		if (d.hardware.holdbacksApplied && d.hardware.holdbackOptions && d.hardware.holdbackOptions.supplier) {
			addSupplier(result.hardware, d.hardware.holdbackOptions.supplier)
		}
		if (d.hardware.flickSticks && d.hardware.flickSticksOptions && d.hardware.flickSticksOptions.supplier) {
			addSupplier(result.hardware, d.hardware.flickSticksOptions.supplier)
		}
		if (d.hardware.automation && d.hardware.automationOptions && d.hardware.automationOptions.supplier) {
			addSupplier(result.hardware, d.hardware.automationOptions.supplier)
		}
	}
}
